import { action, computed, observable } from 'mobx';

import { BaseFormModel, displayName, isRequired } from '../BaseForm';
import httpFacade from '../../http/httpFacade';
import Log from '../../helpers/log';
import noop from 'helpers/noop';
import ModalStore, { DialogActionType } from '../ModalStore';

class FeedbackForm extends BaseFormModel {
  @observable orderId: string = '';

  @observable
  @displayName('FEEDBACK')
  @isRequired('')
  feedback: string = '';

  @observable confirm: boolean = false;

  constructor(orderId) {
    super();
    this.orderId = orderId;
  }

  @computed
  get feedbackDTO() {
    return {
      orderId: this.orderId,
      feedback: this.feedback,
    };
  }

  @action.bound
  async submit(onFulfilled = noop) {
    try {
      await httpFacade.onlineOrders.fetchFeedback(this.feedbackDTO);
      this.confirm = true;
      onFulfilled();
    } catch (error) {
      ModalStore.closeModal(DialogActionType.close);
      Log.info(error);
    }
  }
}
export default FeedbackForm;
