import { action, observable, reaction } from 'mobx';

import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import { CopyMenuFromDayForm } from 'stores/Menu/MenuCopy/CopyMenuFromDayForm';
import { CopyMode } from 'stores/Menu/MenuCopy/types';
import { CopyMenuFromWeekForm } from 'stores/Menu/MenuCopy/CopyMenuFromWeekForm';
import { CopyMenuLineForm } from 'stores/Menu/MenuCopy/CopyMenuLineForm';
import { CopyMenuItemsForm } from 'stores/Menu/MenuCopy/CopyMenuItemsForm';

const copyMenuModeToForm: Record<CopyMode, any> = {
  [CopyMode.DAY]: CopyMenuFromDayForm,
  [CopyMode.WEEK]: CopyMenuFromWeekForm,
  [CopyMode.MENU_LINE]: CopyMenuLineForm,
  [CopyMode.MENU_ITEMS]: CopyMenuItemsForm,
};

type CopyMenuType =
  | CopyMenuFromDayForm
  | CopyMenuFromWeekForm
  | CopyMenuLineForm
  | CopyMenuItemsForm;

export class MenuPageStore {
  @observable isCopyMode = false;
  @observable copyMode: CopyMode | null = null;
  @observable copyMenu: CopyMenuType | null = null;
  @observable submitted = false;

  menuPlanner: MenuPlannerStore;

  constructor() {
    this.menuPlanner = new MenuPlannerStore();

    reaction(
      () => this.copyMode,
      () => {
        if (this.copyMode) {
          this.initCopyMenuForm(this.copyMode);
        }
      },
    );
  }

  @action
  initCopyMenuForm(copyMode: CopyMode) {
    this.copyMenu = new copyMenuModeToForm[copyMode](this.menuPlanner);
  }

  @action
  toggleCopyMode = () => {
    if (this.isCopyMode) {
      this.copyMode = null;
    }

    if (this.copyMode === null && !this.isCopyMode) {
      this.copyMode = CopyMode.DAY;
    }

    this.isCopyMode = !this.isCopyMode;
  };

  @action
  changeCopyMode = (mode: CopyMode) => {
    this.copyMode = mode;

    if (!this.isCopyMode) {
      this.toggleCopyMode();
    }
  };

  @action.bound
  async onCopyMenu() {
    if (!this.copyMenu) {
      return;
    }

    try {
      this.submitted = true;

      await httpFacade.menu.updateMenu(this.copyMenu.dataToCopy);
      await this.menuPlanner.fetchMenu();

      this.copyMenu.reset();
    } catch (error) {
      Log.warn(error);
    } finally {
      this.submitted = false;
    }
  }
}
