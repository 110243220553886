import { action, observable } from 'mobx';

import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';
import { IDataset } from '../Recognition/types';

class DatasetCopyStore {
  @observable targetDatasetId: string;
  @observable loading = false;
  @observable datasets: IDataset[] = [];

  sourceDatasetId: string;

  constructor(id: string, datasets: IDataset[]) {
    this.sourceDatasetId = id;
    this.datasets = datasets.filter(dataset => dataset.id !== id);
  }

  @action.bound
  async copy() {
    try {
      this.loading = true;

      await httpFacade.recognition.copyDataset(
        this.sourceDatasetId,
        this.targetDatasetId,
      );
    } catch (error) {
      Log.error(error);
    } finally {
      this.loading = false;
    }
  }
}

export default DatasetCopyStore;
