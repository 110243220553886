import React from 'react';
import { Column } from 'react-table';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import RootStore from 'stores/RootStore';

import style from './Campaign.module.scss';

import { withArrow } from 'components/Table/helpers';
import Controls from 'components/Table/Controls';
import Button from 'components/Button/Button';

import { getFormattedDate } from 'helpers/datetime';

const config = ({ onEdit, onDelete, onLinkCopy }): Column[] => [
  {
    Header: withArrow('title.campaigns.name'),
    accessor: 'title',
    resizable: true,
    minWidth: 250,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.campaigns.user.group'),
    accessor: 'userGroup.title',
    resizable: true,
    minWidth: 200,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.campaigns.enterprise'),
    accessor: 'userGroup.company.title',
    resizable: true,
    minWidth: 200,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.campaigns.max.part'),
    accessor: 'limit',
    resizable: false,
    minWidth: 180,
    Cell: row => <FormattedNumber value={row.value} />,
  },
  {
    Header: withArrow('title.campaigns.duration'),
    resizable: false,
    width: 180,
    Cell: row => {
      const fromTranslation = RootStore.localization.formatMessage(
        'title.from',
      );
      const fromDate = getFormattedDate(row.original.from);

      return row.original.to
        ? `${fromDate} - ${getFormattedDate(row.original.to)}`
        : `${fromTranslation} ${fromDate}`;
    },
  },
  {
    Header: withArrow('title.campaigns.registration.link'),
    accessor: 'id',
    resizable: false,
    width: 140,
    Cell: row => (
      <Button
        styleType="text"
        className={style.btn__copy}
        onClick={onLinkCopy(row.value)}
      >
        <FormattedMessage id="button.copy.link" />
      </Button>
    ),
  },
  {
    id: 'operations',
    resizable: false,
    minWidth: 64,
    className: 'operations',
    Cell: row => (
      <Controls
        onEdit={onEdit(row.original)}
        onDelete={onDelete(row.original.id)}
      />
    ),
    sortable: false,
  },
];

export default config;
