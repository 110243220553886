import http from '../http';
import URLS from '../urls';
import { api, arrayParamsSerializer } from '../helpers';

import {
  MLModel,
  ISample,
  IDataset,
  TaskAgentReportDto,
  InferenceAgentReportDto,
  TaskViewDto,
  CreateTaskReqParams,
  TaskAction,
  IDatasetSamples,
  DeployTaskParams,
  TaskParams,
  ServingTagsDto,
  MLModelWithDeployments,
  DeploymentDTO,
} from 'stores/Recognition/types';
import { DatasetTrain, IDatasetUpdateDTO, UpdateSampleDTO } from '../types';
import { IFeature } from 'stores/OLStore/types';

const mngrApi = url => api(url, 'ft-mngr');

export const recognitionApi = {
  autoLabelling(imageUrl: string, useContour = true) {
    return http.post<{}, { data: IFeature[] }>(
      mngrApi(URLS.recognition.autoLabelling),
      {},
      {
        params: { imageUrl, useContour },
      },
    );
  },

  fetchClusterInferenceAgents() {
    return http.get<InferenceAgentReportDto[]>(
      mngrApi(URLS.recognition.inferenceAgents),
    );
  },

  fetchClusterDeploymentAgents() {
    return http.get<ServingTagsDto[]>(mngrApi(URLS.recognition.agentsReports));
  },

  fetchClusterTaskAgents() {
    return http.get<TaskAgentReportDto[]>(mngrApi(URLS.recognition.taskAgents));
  },

  deletedDataset(
    id: string,
    data: {
      id: string;
      name: string;
      version: number;
    },
  ) {
    return http.delete(mngrApi(URLS.recognition.datasets), {
      replacePathParams: { id },
      data,
    });
  },

  deleteSamples(ids: string[]) {
    return http.put<{ ids: string[] }, {}>(
      mngrApi(URLS.recognition.bulkSamplesDeleting),
      {
        ids,
      },
    );
  },

  updateModel(id: string, version: string, description?: string) {
    return http.put<
      { id: string; version: string; description?: string },
      MLModelWithDeployments
    >(
      mngrApi(URLS.recognition.models),
      { id, description, version },
      {
        replacePathParams: { id },
      },
    );
  },

  fetchModels() {
    return http.get<MLModel[]>(mngrApi(URLS.recognition.models));
  },
  fetchModelsWithDeployments() {
    return http.get<MLModelWithDeployments[]>(
      mngrApi(URLS.recognition.models),
      {
        params: { showDeployments: true },
      },
    );
  },

  deleteModel(data: { id: string; version: string }) {
    return http.delete(mngrApi(URLS.recognition.models), {
      data,
      replacePathParams: {
        id: data.id,
      },
    });
  },

  fetchDatasets() {
    return http.get<IDataset[]>(mngrApi(URLS.recognition.datasets));
  },

  fetchDataset(id: string) {
    return http.get<IDataset>(mngrApi(URLS.recognition.datasets), {
      replacePathParams: { id },
    });
  },

  fetchDatasetSamples(params) {
    return http.get<IDatasetSamples>(
      mngrApi(URLS.recognition.samplesPageable),
      {
        paramsSerializer: arrayParamsSerializer,
        params,
      },
    );
  },

  updateDataset(data: IDatasetUpdateDTO) {
    return http.put<IDatasetUpdateDTO, IDataset>(
      mngrApi(URLS.recognition.datasets),
      data,
      {
        replacePathParams: { id: data.id },
      },
    );
  },

  uploadSample(data, datasetId) {
    return http.post<FormData, ISample>(
      mngrApi(URLS.recognition.uploadSample),
      data,
      {
        params: { datasetId },
      },
    );
  },

  deleteSample(id: string) {
    return http.delete(mngrApi(URLS.recognition.samples), {
      replacePathParams: { id },
    });
  },

  updateSample(id: string, dto: UpdateSampleDTO) {
    return http.put<UpdateSampleDTO, ISample>(
      mngrApi(URLS.recognition.samples),
      { ...dto },
      { replacePathParams: { id } },
    );
  },

  enableDefaultModel(data: {
    description: string;
    id: string;
    version: number;
  }) {
    return http.put(mngrApi(URLS.recognition.enableDefault), data, {
      replacePathParams: { id: data.id },
    });
  },

  enableLabelling(data: { description: string; id: string; version: number }) {
    return http.put(mngrApi(URLS.recognition.enableLabelling), data, {
      replacePathParams: { id: data.id },
    });
  },

  startDatasetTrain(data: DatasetTrain) {
    return http.post(mngrApi(URLS.recognition.startTrainJob), data);
  },

  copyDataset(sourceDatasetId: string, targetDatasetId: string) {
    return http.put(
      mngrApi(URLS.recognition.copyDataset),
      {},
      {
        replacePathParams: {
          sourceDatasetId,
        },
        params: {
          targetDatasetId,
        },
      },
    );
  },

  fetchTasks() {
    return http.get<TaskViewDto[]>(mngrApi(URLS.recognition.tasks));
  },

  createTask<T>(reqParams: CreateTaskReqParams<T>) {
    return http.post<CreateTaskReqParams<T>, { data: TaskViewDto }>(
      mngrApi(URLS.recognition.tasks),
      reqParams,
    );
  },

  fetchTask(id: string) {
    return http.get<TaskViewDto>(mngrApi(URLS.recognition.tasks), {
      replacePathParams: { id },
    });
  },

  updateTask(id: string, description?: string) {
    return http.put<{ description?: string }, TaskViewDto>(
      mngrApi(URLS.recognition.tasks),
      { description },
      {
        replacePathParams: { id },
      },
    );
  },

  deleteTask(id: string) {
    return http.delete(mngrApi(URLS.recognition.tasks), {
      replacePathParams: { id },
    });
  },

  actionTask(
    id: string,
    action: TaskAction,
    params: TaskParams | DeployTaskParams = {},
  ) {
    return http.put<{}, TaskViewDto>(
      mngrApi(URLS.recognition.taskAction),
      {
        params,
      },
      {
        replacePathParams: { id, action },
      },
    );
  },

  fetchQueuedTasks() {
    return http.get<TaskViewDto[]>(mngrApi(URLS.recognition.tasks));
  },

  enableDeployment(deploymentId: string) {
    return http.put<{}, DeploymentDTO>(
      mngrApi(URLS.recognition.enableDeployment),
      {},
      {
        replacePathParams: {
          id: deploymentId,
        },
      },
    );
  },

  disableDeployment(deploymentId: string) {
    return http.put<{}, DeploymentDTO>(
      mngrApi(URLS.recognition.disableDeployment),
      {},
      {
        replacePathParams: {
          id: deploymentId,
        },
      },
    );
  },

  deleteDeployment(deploymentId: string) {
    return http.delete<DeploymentDTO>(mngrApi(URLS.recognition.deployments), {
      replacePathParams: { id: deploymentId },
    });
  },

  fetchDeployments() {
    return http.get<DeploymentDTO[]>(mngrApi(URLS.recognition.deployments));
  },

  importDatasetArtifact(taskId: string) {
    return http.post<string, IDataset>(
      mngrApi(URLS.recognition.importDatasetArtifact),
      '"' + taskId + '"',
    );
  },
};
