import React, { Component } from 'react';
import ReactTable, { Column, SortedChangeFunction } from 'react-table';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { observer } from 'mobx-react';
// package for infinity scroll
// import {Waypoint} from 'react-waypoint';

import style from './Table.module.scss';

import classNames from 'classnames';

import Spinner from '../Spinner/Spinner';

interface Props {
  columns: Column[];
  data: any[];
  loading?: boolean;
  className?: string;
  multiSort?: boolean;
  getTrProps?: any;
  onResizedChange?: (newResized, event) => void;
  tableClassName?: string;
  defaultSorted?: Array<{ id: string; desc: boolean }>;
  noDataText?: string;
  subRowsKey?: string;
  onSortedChange?: SortedChangeFunction;
}

@observer
class Table extends Component<Props & WrappedComponentProps> {
  render() {
    const {
      intl,
      data = [],
      columns,
      loading,
      className,
      multiSort = false,
      getTrProps,
      onResizedChange,
      tableClassName,
      defaultSorted,
      noDataText = 'table.noDataText',
      subRowsKey,
      onSortedChange,
    } = this.props;

    return (
      <div className={classNames(style.wrapper__grid, className)}>
        {loading && <Spinner contain />}

        <ReactTable
          className={classNames(
            'app-table',
            {
              [style.table__empty]: !Boolean(data.length),
            },
            tableClassName,
          )}
          columns={columns}
          data={data}
          noDataText={intl.formatMessage({ id: noDataText })}
          showPagination={false}
          pageSize={data.length}
          minRows={0}
          multiSort={multiSort}
          getTrProps={getTrProps}
          onResizedChange={onResizedChange}
          defaultSorted={defaultSorted}
          subRowsKey={subRowsKey}
          collapseOnDataChange={false}
          onSortedChange={onSortedChange}
        />
      </div>
    );
  }
}

export default injectIntl(Table);
