import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from './DatasetModal.module.scss';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';

import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  name: string;
}

@observer
class DatasetModal extends Component<Props> {
  @observable name = this.props.name || '';

  @action
  onNameChange = name => {
    this.name = name;
  };

  onSubmit = () => {
    this.props.onSubmit!(this.name);
  };

  render() {
    return (
      <Modal
        {...this.props}
        className={style.modal__dataset}
        destroyOnClose={true}
        maskAnimation="fade"
        animation="zoom"
        visible
      >
        <div className={style.title}>
          <FormattedMessage id="title.editName" />
        </div>

        <div className={style.label}>
          <FormattedMessage id="NAME" />
        </div>

        <TextField
          value={this.name}
          className={style.control}
          onChange={this.onNameChange}
        />

        <div>
          <Button
            onClick={this.props.onClose}
            className={style.btn}
            styleType="ghost"
          >
            <FormattedMessage id="button.cancel" />
          </Button>

          <Button
            onClick={this.onSubmit}
            className={style.btn}
            disabled={!this.name}
            styleType="primary"
          >
            <FormattedMessage id="button.save" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default DatasetModal;
