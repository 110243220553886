import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';
import './style.scss';
import Spinner from 'components/Spinner/Spinner';
import SuccessBlock from './SuccessBlock/SuccessBlock';

import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import { ModalProps } from 'stores/ModalStore';
import { BasketMode } from '../../../stores/UserProfile/types';
import OrderList from './OrderList/OrderList';
import OrderDelivery from './OrderDelivery/OrderDelivery';
import TimeSlots from './TimeSlots/TimeSlots';

interface Props extends ModalProps {
  store?: UserProfileOrder;
}

@observer
class BasketModal extends Component<Props> {
  @observable loading = false;

  onClose = () => {
    this.props.store!.resetCurrentOrderItems();
    this.props.store?.changeBasketMode(BasketMode.orderList);
    this.props.onClose();
  };

  async componentDidMount() {
    this.props.store!.changeBasketMode(BasketMode.orderList);
    this.loading = true;
    await this.props.store!.previewOrder();

    this.loading = false;
  }

  render() {
    return (
      <Modal
        {...this.props}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        className={'basket__modal'}
        onClose={() => {
          this.props.store?.closeBasket();
          this.props.onClose();
        }}
        visible
      >
        {this.loading && <Spinner contain />}

        <h2 className={'basket__title'}>
          <FormattedMessage id="title.basket" />
        </h2>

        {this.props.store?.basketMode === BasketMode.orderList && (
          <OrderList onClose={this.onClose} store={this.props.store} />
        )}

        {this.props.store?.basketMode !== BasketMode.orderList &&
          (() => {
            switch (this.props.store?.basketMode) {
              case BasketMode.savedOrder:
                return (
                  <SuccessBlock
                    onClose={this.props.onClose}
                    store={this.props.store}
                  />
                );
              case BasketMode.delivery:
                return (
                  <OrderDelivery
                    onClose={this.props.onClose}
                    store={this.props.store}
                  />
                );
              case BasketMode.timeSlots:
                return <TimeSlots store={this.props.store} />;
              default:
                return (
                  <SuccessBlock
                    onClose={this.props.onClose}
                    store={this.props.store}
                  />
                );
            }
          })()}
      </Modal>
    );
  }
}

export default BasketModal;
