import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { trackWindowScroll } from 'react-lazy-load-image-component';

import Sample from './Sample';

import style from './Gallery.module.scss';

import { ISample } from 'stores/Recognition/types';
import Pagination from '../../../components/Pagination/Pagination';
import DatasetStore from '../../../stores/Recognition/DatasetStore';

interface Props {
  store: DatasetStore;
  gallery: ISample[];
  pageCount: number;
  page: number;
}

@inject('store')
@observer
class Gallery extends Component<Props> {
  onPageChange = (page: number) => this.props.store.fetchDatasetSamples(page);

  render() {
    const { store } = this.props;
    const { page, pageCount, gallery } = store;

    return (
      <section className={style.container}>
        <div className={style.gallery}>
          {gallery?.map?.(sample => (
            <Sample key={sample.id} sample={sample} />
          ))}
        </div>
        {gallery?.length > 0 && (
          <Pagination
            pageCount={pageCount}
            page={page}
            onPageClick={data => this.onPageChange(data)}
          />
        )}
      </section>
    );
  }
}

export default trackWindowScroll(Gallery);
