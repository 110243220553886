import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import style from '../OrderDelivery.module.scss';
import { bindFormControl } from '../../../../Form/FormControl/FormControl';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';

import OrderPickupDeliveryForm from '../../../../../stores/Forms/OrderDeliveryForm/OrderPickupDeliveryForm';
import Button from '../../../../Button/Button';
import { OrderDeliveryDTO } from '../../../../../stores/Forms/OrderDeliveryForm/types';
import TextArea from '../../../../Form/Fields/TextArea/TextAreaField';
import TextField from '../../../../Form/Fields/Text/TextField';
import { convertStrToBool } from '../../../../../helpers/stringToBoolean';

interface Props {
  userProfileOrderStore?: UserProfileOrder;
  onChangeBasketMode: () => void;
  onSubmit: (data: OrderDeliveryDTO) => void;
}

@observer
class PickupDeliveryForm extends Component<Props & WrappedComponentProps> {
  form = new OrderPickupDeliveryForm();
  formControl = bindFormControl(this.form);

  render() {
    const FormControl = this.formControl;
    const { intl, userProfileOrderStore } = this.props;
    const {
      pickupFeatureFields: fields,
      deliveryOptions,
    } = userProfileOrderStore!;
    const { email, phone, comment, isFormValid, isValidPickupForm } = this.form;

    return (
      <form>
        <div className={style.form__body}>
          {convertStrToBool(fields?.pointOfDelivery?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.pointOfDelivery?.required) && '*'}
                  <FormattedMessage id="POINT_OF_DELIVERY" />
                </span>
                <FormControl
                  name="pointOfDelivery"
                  render={propsField => (
                    <TextArea
                      {...propsField}
                      className={style.textarea}
                      placeholder={
                        deliveryOptions?.placeholder ||
                        intl.formatMessage({
                          id: 'modal.confirm.order.delivery.placeholder',
                        })
                      }
                      rows={4}
                      maxLength={200}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {convertStrToBool(fields?.email?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {(!fields?.email?.required ||
                    convertStrToBool(fields?.email?.required)) &&
                    '*'}
                  <FormattedMessage id="EMAIL" />
                </span>
                <FormControl
                  name="email"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={email}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.email.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {convertStrToBool(fields?.phone?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.phone?.required) && '*'}
                  <FormattedMessage id="PHONE" />
                </span>
                <FormControl
                  name="phone"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={phone}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.phone.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {convertStrToBool(fields?.comment?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.comment?.required) && '*'}
                  <FormattedMessage id="COMMENT" />
                </span>
                <FormControl
                  className={style.form__control}
                  name="comment"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={comment}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.comment.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className={style.controls}>
          <Button
            styleType="ghost"
            className={style.btn}
            onClick={this.props.onChangeBasketMode}
          >
            <FormattedMessage id="button.back" />
          </Button>

          <Button
            styleType="primary"
            className={style.btn}
            onClick={() => this.props.onSubmit(this.form.requestOrderData)}
            disabled={
              !isValidPickupForm ||
              !isFormValid ||
              userProfileOrderStore?.loading
            }
          >
            <FormattedMessage id="button.confirm" />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(PickupDeliveryForm);
