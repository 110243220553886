import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import style from './AdditionalSpecifications.module.scss';

import DishForm from 'stores/Forms/DishForm';

@inject('form')
@observer
class Specification extends Component<{ form?: DishForm }> {
  render() {
    const { form } = this.props;
    const { specs, itemSpecs } = form!;

    return specs.map(spec => (
      <div key={spec.id}>
        <h3 className={style.spec__title__group}>{spec.title}</h3>
        <ul className={style.spec__values}>
          {!spec?.values?.some(item => itemSpecs.includes(item.id)) && (
            <li>
              <span className={style.spec__title}>{'-'}</span>
            </li>
          )}
          {spec?.values?.map((item, index) => {
            if (itemSpecs.includes(item.id)) {
              return (
                <li key={item.id}>
                  <span className={style.spec__title}>{item.title || '-'}</span>
                  {spec.values!.length > 1 &&
                    index < spec.values!.length - 1 && (
                      <span className={style.devider}>|</span>
                    )}
                </li>
              );
            }
          }) || '-'}
        </ul>
      </div>
    ));
  }
}

export default Specification;
