export interface IFeature {
  type?: string;
  coordinates?: number[][];
  radius?: number;
  tag?: string;
  title?: string;
  subtag?: SubTag;
  confidence?: number;
}

export enum SubTag {
  FRONT = 'FRONT',
  BACK = 'BACK',
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum FEATURE_PROPERTIES {
  tag = 'tag',
  subtag = 'subtag',
  title = 'title',
  coordinates = 'coordinates',
  itemNumber = 'itemNumber',
  price = 'price',
}
