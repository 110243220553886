import { observer } from 'mobx-react';
import React, { Component } from 'react';
import classNames from 'classnames';

import style from '../OrderDelivery.module.scss';
import Button from 'components/Button/Button';
import { OrderLocation } from 'stores/OnlineOrders/type';
import OrderLocationsDeliveryForm from 'stores/Forms/OrderDeliveryForm/OrderLocationsDeliveryForm';

@observer
class Locations extends Component<{
  form?: OrderLocationsDeliveryForm;
  locations: OrderLocation[];
}> {
  selectLocation = (locationId: string) => () => {
    this.props.form!.setSelectedLocation(locationId);
  };

  render() {
    const { form, locations } = this.props;

    return (
      <>
        <ul className={style.locations}>
          {locations.map(location => (
            <li key={location.id}>
              <Button
                styleType="text"
                className={classNames(style.location, {
                  [style.location__selected]:
                    location.id === form?.selectedLocation,
                })}
                onClick={this.selectLocation(location.id)}
              >
                {location?.title}
              </Button>
            </li>
          ))}
        </ul>
      </>
    );
  }
}

export default Locations;
