import React, { Component, ReactNode } from 'react';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';
import classNames from 'classnames';

import style from './TimeRange.module.scss';

import { SHORT_TIME_FORMAT } from 'helpers/datetime';
import RootStore from 'stores/RootStore';
import { checkAndAutofillPartsOfTime } from './helpers';

export type TimeRangeType = [string | null, string | null];

interface Props {
  onChange: (value: TimeRangeType) => void;
  value: TimeRangeType;
  format?: string;
  locale?: string;
  disableClock?: boolean;
  clearIcon?: ReactNode | null;
  disabled?: boolean;
  invalid?: boolean;
  expanded?: boolean;
}

class TimeRange extends Component<Props> {
  timerangeRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.timerangeRef.current) {
      const timeInputs = this.timerangeRef.current.querySelectorAll(
        'input[type="number"]',
      );
      (Array.from(timeInputs) as HTMLInputElement[]).forEach(input => {
        /* reset max value for manual validation */
        input.max = '100';
      });
    }
  }
  onChange = value => {
    if (this.timerangeRef.current) {
      let time;
      const timeInputs = this.timerangeRef.current.querySelectorAll(
        'input[type="number"]',
      );
      time = checkAndAutofillPartsOfTime(
        Array.from(timeInputs) as HTMLInputElement[],
        value,
      );
      if (Array.isArray(time)) {
        this.props.onChange(time as TimeRangeType);
      }
    }
  };

  render() {
    const {
      onChange,
      value,
      invalid,
      format = SHORT_TIME_FORMAT,
      locale,
      disableClock = true,
      clearIcon = null,
      disabled,
      expanded,
      ...props
    } = this.props;

    return (
      <div className={style.timerange__wrapper} ref={this.timerangeRef}>
        <TimeRangePicker
          {...props}
          onChange={this.onChange}
          value={value}
          format={format}
          locale={locale || RootStore.localization.locale}
          disableClock={disableClock}
          clearIcon={clearIcon}
          rangeDivider={expanded ? '-' : ''}
          maxTime={null}
          disabled={disabled}
          className={classNames(
            invalid && 'react-timerange-picker--invalid',
            expanded && 'timerange__expended',
          )}
        />
      </div>
    );
  }
}

export default TimeRange;
