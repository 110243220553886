import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Table from 'components/Table/Table';
import WarningModal from 'components/Modals/Warning/WarningModal';
import config from './columnsConfig';
import DatasetCopyModal from 'components/Modals/DatasetCopy/DatasetCopyModal';

import DatasetsStore from 'stores/Recognition/DatasetsStore';
import ModalStore from 'stores/ModalStore';
import AppRouter from 'stores/AppRouter';

import { ROUTES } from 'routes/routes';
import SearchField from 'components/Form/Fields/Search/SearchField';
import style from './Datasets.module.scss';

@observer
class DatasetsPage extends Component {
  store = new DatasetsStore();

  async componentDidMount() {
    await this.store.fetchDatasets();
  }

  copyDataset = dataset => async () => {
    await ModalStore.showModal(DatasetCopyModal, {
      datasetId: dataset.id,
      datasets: this.store.datasets,
    });
  };

  updateDataset = id => () => {
    AppRouter.push(ROUTES.admin.dataset, { id });
  };

  deleteDataset = id => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });
    await this.store.deleteDataset(id);
  };

  onSearch = (value: string) => {
    this.store.searchValue = value;
  };

  render() {
    return (
      <div className={style.tab__content__container}>
        <SearchField
          className={style.search}
          onChange={this.onSearch}
          value={this.store.searchValue}
        />
        <Table
          data={this.store.filteredItems}
          columns={config({
            onDelete: this.deleteDataset,
            onEdit: this.updateDataset,
            onCopy: this.copyDataset,
          })}
          loading={this.store.loading}
          defaultSorted={[
            {
              id: 'createdDate',
              desc: true,
            },
          ]}
        />
      </div>
    );
  }
}

export default DatasetsPage;
