export const FORM_FIELD_USERNAME = 'username';
export const FORM_FIELD_PASSWORD = 'password';
export const FORM_FIELD_CLIENT_ID = 'client_id';
export const FORM_FIELD_GRANT_TYPE = 'grant_type';
export const FORM_FIELD_REFRESH_TOKEN = 'refresh_token';
export const FORM_FIELD_RESPONSE_TYPE = 'response_type';
export const FORM_FIELD_STATE = 'state';
export const FORM_FIELD_REDIRECT_URI = 'redirect_uri';
export const FORM_FIELD_CODE = 'code';

export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const GRANT_TYPE_PASSWORD = 'password';
export const RESPNOSE_TYPE_CODE = 'code';
export const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code';

export const CLIENT_ID_SIONUM = 'sionum';
export const CLIENT_SECRET_SIONUM = 'sionum';
