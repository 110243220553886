import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function hasMinLength(length: number, message = 'VM_HAS_MIN_LENGTH') {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        const isValid = !value || value.toString().length >= length;

        return isValid
          ? undefined
          : {
              message,
              data: {
                name,
                value,
                length,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
