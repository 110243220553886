import { CreateMenuLineDto } from 'stores/Menu/types';
import { MultiSelectOption } from 'components/MultiSelect/MultiSelectContainer';

export enum CopyMode {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MENU_LINE = 'MENU_LINE',
  MENU_ITEMS = 'MENU_ITEMS',
}

export interface ICopyMenu {
  copyMode: CopyMode;
  dataToCopy: CreateMenuLineDto[];
  isCopyDisabled: boolean;
  reset: () => void;
}

export type WeekDayOption = MultiSelectOption & { holiday: boolean };
