import { DateTime } from 'luxon';
import { observable, action, computed } from 'mobx';

import httpFacade from 'http/httpFacade';
import { getDate, getLocalDateTime } from 'helpers/datetime';
import Log from 'helpers/log';
import { sortByAccessor } from 'helpers/accessors';

import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import { DishGroupManagement } from 'stores/Menu/types';
import { PaymentType, RevenueReportReqParams } from 'stores/Report/types';
import RootStore from 'stores/RootStore';

class RevenueReportForm extends BaseFormModel {
  @observable
  @isRequired()
  period: [DateTime, DateTime | undefined] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];

  @observable
  @displayName('MENU')
  categoryId = '';

  @observable
  @displayName('PAYMENT_TYPE')
  paymentType: PaymentType | '' = '';

  @observable categories: DishGroupManagement[] = [];

  @observable paymentTypes: PaymentType[] = [];

  @observable loading = false;

  @computed
  get requestParams(): RevenueReportReqParams {
    const [startDate, endDate] = this.period;

    return {
      from: getDate(startDate.toJSDate()),
      to: getDate(endDate!.toJSDate()),
      ...(this.categoryId ? { dishGroupId: this.categoryId } : {}),
      ...(this.paymentType ? { paymentType: this.paymentType } : {}),
    };
  }

  @computed
  get categoriesValues() {
    return this.categories
      .slice()
      .sort(sortByAccessor({ accessor: 'title', desc: false }))
      .map(category => ({
        value: category.id,
        label: category.title,
      }));
  }

  @computed
  get paymentTypesValues() {
    return this.paymentTypes.slice().map(pt => ({
      value: pt.toString(),
      label: RootStore.localization.formatMessage(pt.toString()),
    }));
  }

  @action
  async init() {
    try {
      this.loading = true;

      const { data } = await httpFacade.menu.fetchCategories();

      this.categories = data;

      const paymentTypesData = await httpFacade.order.fetchPaymentTypes();

      this.paymentTypes = paymentTypesData.data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default RevenueReportForm;
