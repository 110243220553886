import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import classNames from 'classnames';

import style from './Planner.module.scss';

import ModalStore from 'stores/ModalStore';
import { PlannerLineModel } from 'stores/Menu/Models';
import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { CopyMode } from 'stores/Menu/MenuCopy/types';

import PlannerWeekday from './PlannerWeekday';
import PlannerLine from './PlannerLine';
import MenuModal from 'components/Modals/Menu/MenuModal';

@inject('menuPage')
@observer
class PlannerRow extends Component<{
  line: PlannerLineModel;
  menuPage?: MenuPageStore;
}> {
  openWeekMenu = date => async () => {
    const { line, menuPage } = this.props;

    menuPage!.menuPlanner.activeMenuLine = toJS(line);
    await ModalStore.showModal(MenuModal, {
      menu: menuPage!.menuPlanner,
      activeDate: date,
    });
  };

  render() {
    const { line, menuPage } = this.props;
    const { menuPlanner } = menuPage!;

    return (
      <div className={style.tr}>
        <PlannerLine
          line={line}
          disabled={!menuPlanner.canAddMenuLine || line.deleted}
        />
        {line.days.map(day => (
          <PlannerWeekday
            {...day}
            key={day.date}
            disabled={
              !Boolean(line.id) ||
              menuPlanner.currentDate > day.date ||
              line.deleted
            }
            onClick={this.openWeekMenu(day.date)}
            lineId={line.id}
          />
        ))}
        {menuPage?.isCopyMode &&
          menuPage.copyMenu?.copyMode === CopyMode.MENU_LINE && (
            <div
              className={classNames(
                style.selectionMask,
                style.selectionMaskMenuLine,
                {
                  [style.active]: line.id === menuPage.copyMenu.menuLineId,
                },
              )}
              onClick={menuPage.copyMenu.setMenuLineId(line.id)}
            />
          )}
      </div>
    );
  }
}

export default PlannerRow;
