import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { EUR } from 'helpers/currency';

import style from './RechargePayOne.module.scss';

import Spinner from 'components/Spinner/Spinner';
import Button from 'components/Button/Button';
import RadioField from 'components/Form/Fields/Radio/RadioField';
import { ModalLayout } from 'components/Layout/ModalLayout/ModalLayout';
import PaymentItem from './PaymentItem';

import RechargePayOneStore from 'stores/Recharge/RechargePayOneStore';
import { ModalProps } from 'stores/ModalStore';
import UserProfileStore from 'stores/UserProfile/UserProfileStore';
import {
  defaultRefillValues,
  PaymentType,
  paymentOptions,
} from 'stores/Recharge/types';
import RootStore from 'stores/RootStore';
import CurrencyInput from 'components/Form/Fields/Number/CurrencyInput';

export const rechargeLimits = {
  maxSumLength: 4,
};

interface Props extends ModalProps {
  profile?: UserProfileStore;
}

@observer
class RechargePayOneModal extends Component<Props> {
  store = new RechargePayOneStore();

  @observable isManualInputActive = false;

  @action
  toggleManualInput = () => {
    this.store.onChangeRefillValue(undefined);
    this.isManualInputActive = !this.isManualInputActive;
  };

  @action
  onDefaultValueChange = (value: string) => {
    if (this.isManualInputActive) {
      this.toggleManualInput();
    }
    this.store.onChangeRefillValue(Number(value));
  };

  async componentDidMount() {
    await this.store.fetchCardDepositInfo(this.props.profile!.id);
  }

  render() {
    const { profile } = this.props;
    const showInfoMessage =
      this.store.cardDepositInfo?.mandatory && !profile?.rfid;

    return (
      <ModalLayout
        className={style.modal}
        onClose={this.props.onClose}
        title="modal.recharge.title"
        controls={{
          submitBtnTitle: 'modal.recharge.button.add.balance',
          onClose: this.props.onClose,
          onSubmit: this.store.requestRefill,
          disabledSubmit: this.store.isOptionsValid,
        }}
      >
        {this.store.loading && <Spinner contain />}

        <div className={style.body}>
          <div className={style.refill__values}>
            {defaultRefillValues.map(value => (
              <RadioField
                key={value}
                value={value}
                label={<span>{`${value / 100} ${EUR}`}</span>}
                onChange={this.onDefaultValueChange}
                checked={value === this.store.refillValue}
                className={style.refill__value}
              />
            ))}
          </div>

          <div className={style.manualInput}>
            <Button
              styleType={this.isManualInputActive ? 'primary' : 'ghost'}
              className={classNames(style.btn, style.btn__other, {
                [style.btn__other__active]: this.isManualInputActive,
              })}
              onClick={this.toggleManualInput}
            >
              <FormattedMessage id="button.other" />
            </Button>

            <CurrencyInput
              value={this.store.refillValue}
              onChange={this.store.onChangeRefillValue}
              postfix={EUR}
              maskOptions={{ integerLimit: rechargeLimits.maxSumLength }}
              className={classNames(style.field, {
                [style.field__active]: this.isManualInputActive,
              })}
            />
          </div>

          <p className={style.text}>
            <FormattedMessage id="modal.recharge.topUp.note" />
          </p>

          <div className={style.methods__title}>
            <FormattedMessage id="modal.recharge.payment.method" />
          </div>

          <ul className={style.methods__list}>
            {Object.keys(PaymentType).map(type => {
              const paymentOption = paymentOptions[type];

              return (
                RootStore.config.paymentType[type]?.enabled && (
                  <PaymentItem
                    key={paymentOption.title}
                    option={paymentOption}
                    isActive={paymentOption.type === this.store.paymentType}
                    setPaymentType={this.store.setPaymentType}
                  />
                )
              );
            })}
          </ul>

          {showInfoMessage && (
            <div className={style.notification}>
              <FormattedMessage id="modal.recharge.notification.deposit" />
            </div>
          )}

          <a
            href={RootStore.config.problemsInstruction}
            className={style.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="modal.recharge.problem.instruction" />
          </a>
        </div>
      </ModalLayout>
    );
  }
}

export default RechargePayOneModal;
