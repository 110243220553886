import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from './AsideMenu.module.scss';

import { getRole, Roles } from 'helpers/roles';

import { ROUTES } from 'routes/routes';

import NavigationItem from './NavigationItem';

@observer
class Navigation extends Component {
  render() {
    return (
      <nav className={style.navigation}>
        <ul className={style.navigation__menu}>
          {getRole(Roles.accountant) && (
            <NavigationItem iconType="personIcon" to={ROUTES.employees}>
              <FormattedMessage id="asideMenu.item.employees.title" />
            </NavigationItem>
          )}

          {getRole(Roles.hostess) && (
            <>
              <NavigationItem iconType="personIcon" to={ROUTES.employees}>
                <FormattedMessage id="asideMenu.item.employees.title" />
              </NavigationItem>
            </>
          )}

          {getRole(Roles.service) && (
            <NavigationItem iconType="basket" to={ROUTES.service}>
              <FormattedMessage id="title.online.ordering" />
            </NavigationItem>
          )}

          {getRole(Roles.admin) && (
            <>
              <NavigationItem iconType="statistic" to={ROUTES.admin.report}>
                <FormattedMessage id="title.report" />
              </NavigationItem>

              <NavigationItem iconType="schedule" to={ROUTES.admin.menu}>
                <FormattedMessage id="title.menu" />
              </NavigationItem>

              <NavigationItem iconType="menu" to={ROUTES.admin.menuItems}>
                <FormattedMessage id="title.dishes" />
              </NavigationItem>

              <NavigationItem iconType="users" to={ROUTES.admin.staff}>
                <FormattedMessage id="title.users" />
              </NavigationItem>

              <NavigationItem iconType="discount" to={ROUTES.admin.discounts}>
                <FormattedMessage id="title.discounts" />
              </NavigationItem>

              <NavigationItem
                iconType="recognition"
                to={ROUTES.admin.recognition}
              >
                <FormattedMessage id="title.recognition" />
              </NavigationItem>
            </>
          )}

          {getRole(Roles.bayerRestrictedAdmin) && (
            <>
              <NavigationItem iconType="statistic" to={ROUTES.admin.report}>
                <FormattedMessage id="title.report" />
              </NavigationItem>

              <NavigationItem iconType="schedule" to={ROUTES.admin.menu}>
                <FormattedMessage id="title.menu" />
              </NavigationItem>

              <NavigationItem iconType="menu" to={ROUTES.admin.menuItems}>
                <FormattedMessage id="title.dishes" />
              </NavigationItem>

              <NavigationItem iconType="users" to={ROUTES.admin.staff}>
                <FormattedMessage id="title.users" />
              </NavigationItem>

              <NavigationItem iconType="discount" to={ROUTES.admin.discounts}>
                <FormattedMessage id="title.discounts" />
              </NavigationItem>

              <NavigationItem
                iconType="recognition"
                to={ROUTES.admin.recognition}
              >
                <FormattedMessage id="title.recognition" />
              </NavigationItem>
            </>
          )}

          {getRole(Roles.archiveAdmin) && (
            <NavigationItem iconType="archive" to={ROUTES.archiveAdmin.archive}>
              <FormattedMessage id="title.archive" />
            </NavigationItem>
          )}
          {getRole(Roles.cateringAdmin) && (
            <>
              <NavigationItem iconType="statistic" to={ROUTES.admin.report}>
                <FormattedMessage id="title.report" />
              </NavigationItem>

              <NavigationItem iconType="schedule" to={ROUTES.admin.menu}>
                <FormattedMessage id="title.menu" />
              </NavigationItem>

              <NavigationItem iconType="menu" to={ROUTES.admin.menuItems}>
                <FormattedMessage id="title.dishes" />
              </NavigationItem>

              <NavigationItem iconType="users" to={ROUTES.admin.staff}>
                <FormattedMessage id="title.users" />
              </NavigationItem>

              <NavigationItem iconType="discount" to={ROUTES.admin.discounts}>
                <FormattedMessage id="title.discounts" />
              </NavigationItem>
            </>
          )}
        </ul>
      </nav>
    );
  }
}

export default Navigation;
