import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import style from '../OrderDelivery.module.scss';
import { bindFormControl } from '../../../../Form/FormControl/FormControl';
import Locations from './Locations';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';

import OrderLocationsDeliveryForm from 'stores/Forms/OrderDeliveryForm/OrderLocationsDeliveryForm';
import { OrderDeliveryDTO } from '../../../../../stores/Forms/OrderDeliveryForm/types';
import Button from '../../../../Button/Button';
import TextArea from '../../../../Form/Fields/TextArea/TextAreaField';
import TextField from '../../../../Form/Fields/Text/TextField';
import { convertStrToBool } from '../../../../../helpers/stringToBoolean';

interface Props {
  userProfileOrderStore?: UserProfileOrder;
  onChangeBasketMode: () => void;
  onSubmit: (data: OrderDeliveryDTO) => void;
}

@observer
class LocationsDeliveryForm extends Component<Props & WrappedComponentProps> {
  form = new OrderLocationsDeliveryForm();
  formControl = bindFormControl(this.form);

  render() {
    const FormControl = this.formControl;
    const { intl, userProfileOrderStore } = this.props;
    const {
      isValidDeliveryCustomLocationsForm,
      isValidDeliveryServerLocationsForm,
      isFormValid,
      email,
      phone,
      comment,
    } = this.form;

    const {
      locationsFeatureFields: fields,
      isServerLocationsFeatureExist,
      deliveryOptions,
      locations,
      isCustomLocationsFeatureExist,
    } = userProfileOrderStore!;

    return (
      <form>
        <div className={style.form__body}>
          {isServerLocationsFeatureExist && (
            <Locations form={this.form} locations={locations} />
          )}
          {isCustomLocationsFeatureExist &&
            convertStrToBool(fields?.pointOfDelivery?.enabled) && (
              <div className={style.form__row}>
                <div className={style.form__col}>
                  <span className={style.label}>
                    {convertStrToBool(fields?.pointOfDelivery?.required) && '*'}
                    <FormattedMessage id="POINT_OF_DELIVERY" />
                  </span>
                  <FormControl
                    name="pointOfDelivery"
                    render={propsField => (
                      <TextArea
                        {...propsField}
                        className={style.textarea}
                        placeholder={
                          deliveryOptions?.placeholder ||
                          intl.formatMessage({
                            id: 'modal.confirm.order.delivery.placeholder',
                          })
                        }
                        rows={4}
                        maxLength={200}
                      />
                    )}
                  />
                </div>
              </div>
            )}

          {convertStrToBool(fields?.email?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.email?.required) && '*'}

                  <FormattedMessage id="EMAIL" />
                </span>
                <FormControl
                  name="email"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={email}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.email.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {convertStrToBool(fields?.phone?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.phone?.required) && '*'}
                  <FormattedMessage id="PHONE" />
                </span>
                <FormControl
                  name="phone"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={phone}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.phone.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {convertStrToBool(fields?.comment?.enabled) && (
            <div className={style.form__row}>
              <div className={style.form__col}>
                <span className={style.label}>
                  {convertStrToBool(fields?.comment?.required) && '*'}
                  <FormattedMessage id="COMMENT" />
                </span>
                <FormControl
                  className={style.form__control}
                  name="comment"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      value={comment}
                      className={style.text}
                      placeholder={intl.formatMessage({
                        id: 'modal.confirm.order.delivery.comment.placeholder',
                      })}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className={style.controls}>
          <Button
            styleType="ghost"
            className={style.btn}
            onClick={this.props.onChangeBasketMode}
          >
            <FormattedMessage id="button.back" />
          </Button>

          <Button
            styleType="primary"
            className={style.btn}
            onClick={() => this.props.onSubmit(this.form.requestOrderData)}
            disabled={
              (isServerLocationsFeatureExist &&
                !isValidDeliveryServerLocationsForm) ||
              (isCustomLocationsFeatureExist &&
                !isValidDeliveryCustomLocationsForm) ||
              !isFormValid ||
              userProfileOrderStore?.loading
            }
          >
            <FormattedMessage id="button.confirm" />
          </Button>
        </div>
      </form>
    );
  }
}

export default injectIntl(LocationsDeliveryForm);
