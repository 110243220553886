import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './Campaign.module.scss';

import { ModalProps } from 'stores/ModalStore';
import { Campaign } from 'stores/Staff/types';
import CampaignForm from 'stores/Forms/CampaignForm';

import SplitModalLayout from 'components/Layout/SplitModalLayout';
import FormSelect from '../Components/FormSelect/FormSelect';
import ModalForm from './ModalForm';

interface Props extends ModalProps {
  campaign?: Campaign;
}

@observer
class CampaignModal extends Component<Props> {
  form = new CampaignForm(this.props.campaign);

  async componentDidMount() {
    await this.form.init();
  }

  onGroupClick = group => {
    this.form.userGroup = group;
  };

  onSubmit = async event => {
    event.preventDefault();

    const onFulfilled = payload => this.props.onSubmit!(payload);

    if (this.form.id) {
      await this.form.updateCampaign(onFulfilled);
    } else {
      await this.form.createCampaign(onFulfilled);
    }
  };

  render() {
    return (
      <SplitModalLayout
        {...this.props}
        className={style.modal}
        loading={this.form.loading}
      >
        <ModalForm
          form={this.form}
          onSubmit={this.onSubmit}
          onClose={this.props.onClose}
        />

        <FormSelect
          title="modal.campaign.user.group"
          data={this.form.userGroups}
          activeLineID={this.form.userGroup?.id}
          onLineClick={this.onGroupClick}
        />
      </SplitModalLayout>
    );
  }
}

export default CampaignModal;
