import { DateTime } from 'luxon';
import { observable, computed } from 'mobx';

import { getDate, getLocalDateTime } from 'helpers/datetime';

import { BaseFormModel, isRequired } from 'stores/BaseForm';
import { RevenueReportReqParams } from 'stores/Report/types';

class StornoReportForm extends BaseFormModel {
  @observable
  @isRequired()
  period: [DateTime, DateTime | undefined] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];

  @computed
  get requestParams(): RevenueReportReqParams {
    const [startDate, endDate] = this.period;

    return {
      from: getDate(startDate.toJSDate()),
      to: getDate(endDate!.toJSDate()),
    };
  }
}

export default StornoReportForm;
