import * as History from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import qs from 'qs';
import { action } from 'mobx';

import { replaceUrlParams } from 'http/helpers';
import { getParams } from 'helpers/urls';

class AppRouter {
  browserHistory = History.createBrowserHistory();
  router = new RouterStore();
  synchronizedHistory = syncHistoryWithStore(this.browserHistory, this.router);

  push(url: string, data?: object) {
    const path = replaceUrlParams(url, data);

    this.router.push(path);
  }

  @action.bound
  goBack() {
    this.router.goBack();
  }

  currentRoute() {
    return window.location.href;
  }

  addParams(url: string, newParams: object): string {
    const [origin, paramsStr] = url.split('?');
    const params = { ...qs.parse(paramsStr), ...newParams };

    return origin + '?' + qs.stringify(params);
  }

  getSearchParams() {
    return getParams(this.router.location.search);
  }

  clearSearchParams() {
    this.router.history.replace(this.router.history.location.pathname);
  }
}

export default new AppRouter();
