import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import './style.scss';

import { ModalProps } from 'stores/ModalStore';
import { OrderModel } from 'stores/UserProfile/types';
import QRScannerStore from 'stores/Modals/QRScannerStore';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import QRScanner from 'components/Modals/OnlineOrderModal/QRScanner/QRScanner';
import OnlineOrder from 'components/Modals/OnlineOrderModal/OnlineOrder/OnlineOrder';

interface Props extends ModalProps {
  order?: OrderModel;
  onUpdateOrderStatus: (id: string) => void;
  activeDate?: DateTime;
  onPrintSticker?: (item: OrderModel) => void;
}

@observer
class OnlineOrderModal extends Component<Props> {
  store = new QRScannerStore(this.props.order, this.props.activeDate);

  markOrderDelivered = async () => {
    await this.store.markOrderDelivered(this.store.order.id, () =>
      this.props.onUpdateOrderStatus(this.store.order.id),
    );
  };

  render() {
    return (
      <Modal
        {...this.props}
        className={'onlineOrderModal__modal onlineOrderModal__modal__order'}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div className={'onlineOrderModal__title'}>
          <FormattedMessage id={'modal.online.orders.title'} />
        </div>

        {!this.store.order ? (
          <QRScanner
            onClose={this.props.onClose}
            scanning={this.store.scanning}
            scanQR={this.store.scanQR}
          />
        ) : (
          <div
            className={'onlineOrderModal__body onlineOrderModal__body__order'}
          >
            <OnlineOrder order={this.store.order} />
            <Icon
              type="print"
              className={'onlineOrderModal__btn_print'}
              onClick={() =>
                (this.props.onPrintSticker &&
                  this.props.onPrintSticker(this.store.order)) ||
                undefined
              }
            />

            {!this.store.isFeatureDays && (
              <div
                className={`onlineOrderModal__footer ${(this.store
                  .isOrderProcessingFinished &&
                  'onlineOrderModal__footer_green') ||
                  ''}`}
              >
                {this.store.isOrderProcessingFinished ? (
                  <Icon type={'check'} className={'onlineOrderModal__icon'} />
                ) : (
                  <>
                    <Button
                      styleType="ghost"
                      onClick={this.markOrderDelivered}
                      className={'onlineOrderModal__btn'}
                    >
                      <FormattedMessage id="button.delivered" />
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export default OnlineOrderModal;
