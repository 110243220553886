import URLS from 'http/urls';
import http from 'http/http';
import { api, arrayParamsSerializer, replaceUrlParams } from 'http/helpers';

import {
  CreateMenuLineDto,
  DishGroupManagement,
  ExtendedAvailable,
  IMenuLine,
  MenuLineCreateUpdateDto,
  MenuLineManagement,
  Specification,
} from 'stores/Menu/types';
import { DishGroupDTO } from '../types';

export const menuApi = {
  defineIsExtendedAvailable() {
    return http.get<ExtendedAvailable>(api(URLS.menu.isExtendedAvailable));
  },

  fetchBaseSpecifications() {
    return http.get<Specification[]>(api(URLS.menu.baseSpecifications));
  },

  fetchAdditionalSpecifications() {
    return http.get<Specification[]>(api(URLS.menu.additionalSpecifications));
  },

  fetchVats() {
    return http.get<Array<{ label: string; value: number }>>(
      api(URLS.finance.vats),
    );
  },

  fetchCategories() {
    return http.get<DishGroupManagement[]>(api(URLS.menu.dishGroups));
  },

  fetchCategory(id: string) {
    return http.get<DishGroupManagement>(api(URLS.menu.dishGroups), {
      replacePathParams: { id },
    });
  },

  deleteCategory(id: string) {
    return http.delete(api(URLS.menu.dishGroups), {
      replacePathParams: { id },
    });
  },

  createCategory(data: DishGroupDTO) {
    return http.post<DishGroupDTO, DishGroupManagement>(
      api(URLS.menu.dishGroups),
      data,
    );
  },

  updateCategory(id: string, data: DishGroupDTO) {
    return http.put<DishGroupDTO, DishGroupManagement>(
      api(URLS.menu.dishGroups),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  fetchMenuLines() {
    return http.get<IMenuLine[]>(api(URLS.menu.lines));
  },

  fetchMenuLine(id: string) {
    return http.get<IMenuLine>(api(URLS.menu.lines), {
      replacePathParams: { id },
    });
  },

  createMenuLine(data: MenuLineCreateUpdateDto) {
    return http.post<MenuLineCreateUpdateDto, IMenuLine>(
      api(URLS.menu.lines),
      data,
    );
  },

  deleteMenuLine(id: string) {
    return http.delete(api(URLS.menu.lines), {
      replacePathParams: { id },
    });
  },

  updateMenuLine(id: string, data: MenuLineCreateUpdateDto) {
    return http.put<MenuLineCreateUpdateDto, IMenuLine>(
      api(URLS.menu.lines),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  fetchPlannerMenu(from: string, to?: string) {
    return http.get<MenuLineManagement[]>(api(URLS.menu.menu), {
      replacePathParams: { from, to: to || from },
    });
  },

  fetchRecommendedPlannerdMenu(from: string, to?: string, params?: any) {
    return http.get<MenuLineManagement[]>(api(URLS.menu.menu), {
      replacePathParams: { from, to: to || from },
      paramsSerializer: arrayParamsSerializer,
      params,
    });
  },

  fetchMenuItemsCount(from: string, to?: string, params?: any) {
    return http.get<number>(api(URLS.menu.menuItemsCount), {
      replacePathParams: { from, to: to || from },
      paramsSerializer: arrayParamsSerializer,
      params,
    });
  },

  updateMenu(menu: CreateMenuLineDto[]) {
    return http.post(api(URLS.menu.update), menu);
  },

  deleteMenu(id: string, from: string, to?: string) {
    return http.delete(api(URLS.menu.delete), {
      replacePathParams: { id, from, to: to || from },
    });
  },

  moveMenuLine(data: {
    lineSource: string;
    lineDestination: string;
    from: string;
    to: string;
  }) {
    return http.put(api(URLS.menu.moveLine), { ...data });
  },

  changeNotVisibleKioskSelfCheckout(
    id: string,
    notVisibleKioskSelfCheckout: boolean,
  ) {
    return http.post(
      api(
        replaceUrlParams(URLS.menu.changeNotVisibleKioskSelfCheckout, { id }),
      ),
      {},
      {
        params: {
          notVisibleKioskSelfCheckout,
        },
      },
    );
  },
};
