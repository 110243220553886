import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ProfileMenuStore from '../../../../../stores/UserProfile/ProfileMenuStore';
import Aspect from './Aspect';

interface Props {
  menu: ProfileMenuStore;
  specName?: string;
  baseSpecification?: any;
}

@observer
class BaseSpecifications extends Component<Props> {
  handleCheckSpec = (id: string) =>
    this.props.menu.toggleSpec(id, this.props.specName);

  render() {
    const { menu, baseSpecification } = this.props;

    return baseSpecification?.values?.map(item => (
      <li key={item.id}>
        <Aspect
          aspect={item}
          isActive={menu?.filteredPreferences?.specifications?.some?.(
            specification => specification.id === item.id,
          )}
          onChange={this.handleCheckSpec}
        />
      </li>
    ));
  }
}

export default BaseSpecifications;
