import { FormattedMessage } from 'react-intl';
import React from 'react';
import classNames from 'classnames';

import style from './FormSteps.module.scss';

export interface IStep {
  label: string;
  step: number;
}

interface IStepsProps {
  steps: IStep[];
  activeStep: number;
  onClick?: (step: number) => void;
  isClickDisabled?: boolean;
}

export const Steps = ({
  steps,
  activeStep,
  onClick,
  isClickDisabled,
}: IStepsProps) => {
  const onClickCb = (step: number) => {
    if (onClick) {
      onClick(step);
    }
  };

  return (
    <div className={style.steps__list}>
      {steps.map(step => (
        <div
          className={classNames(
            style.step__item,
            step.step < activeStep && style.step__passed,
            step.step === activeStep && style.step__active,
            step.step > activeStep && style.step__unpassed,
          )}
          key={step.step}
        >
          <div className={style.step__border}>
            <div
              className={classNames(
                style.border__item,
                step.step <= activeStep
                  ? style.passed__border
                  : style.unpassed__border,
                step.step === 0 && style.hidden__border,
              )}
            />
            <div
              className={classNames(
                style.border__item,
                step.step < activeStep
                  ? style.passed__border
                  : style.unpassed__border,
                step.step === steps.length - 1 && style.hidden__border,
              )}
            />
          </div>

          <div
            className={classNames(
              style.step__item_label,
              isClickDisabled && style.click__disabled,
            )}
            onClick={() => onClickCb(step.step)}
          >
            <span className={style.step__dot} />
            <span className={style.step__label}>
              <FormattedMessage id={step.label} />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
