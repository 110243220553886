import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './StatisticItem.module.scss';

interface Props {
  title: string;
  value: number;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
}

@observer
class StatisticItem extends Component<Props> {
  render() {
    const { title, value, children, onClick, isActive, className } = this.props;

    return (
      <li>
        <div
          className={classNames(
            style.item,
            {
              [style.item__active]: isActive,
              [style.item__clickable]: onClick,
            },
            className,
          )}
          onClick={onClick}
        >
          <FormattedMessage id={title} defaultMessage={title} />
          <span>{value}</span>
        </div>

        {children}
      </li>
    );
  }
}

export default StatisticItem;
