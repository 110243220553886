import { observable, action, computed } from 'mobx';

import { isRequired, BaseFormModel, displayName } from 'stores/BaseForm';
import {
  TaskType,
  TaskViewDto,
  CreateTaskReqParams,
  ArchitectureType,
  MLModel,
} from 'stores/Recognition/types';
import AppRouter from 'stores/AppRouter';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

interface TaskParams {
  detectorId: string;
  classifierId: string;
}

class TaskCreateDetectorForm extends BaseFormModel {
  type: TaskType.CREATE_MODEL_DETECTOR_DVC;

  @observable
  @displayName('DESCRIPTION*')
  @isRequired()
  description: string;

  @observable
  @displayName('DETECTOR_MODEL')
  @isRequired()
  detectorId: string;

  @observable
  @displayName('CLASSIFICATOR_MODEL')
  @isRequired()
  classifierId: string;

  @observable loading = false;

  @observable tasks: TaskViewDto[] = [];
  @observable models: MLModel[] = [];

  @computed
  get modelsClassificationOptions() {
    return this.models
      .filter(
        model => model.architecture.type === ArchitectureType.CLASSIFICATION,
      )
      .map(it => ({
        value: it.id,
        label: it.description,
      }));
  }

  @computed
  get modelsDetectionOptions() {
    return this.models
      .filter(model => model.architecture.type === ArchitectureType.DETECTION)
      .map(it => ({
        value: it.id,
        label: it.description,
      }));
  }

  constructor(type: TaskType.CREATE_MODEL_DETECTOR_DVC) {
    super();

    this.type = type;
  }

  @computed
  get requestParams(): CreateTaskReqParams<TaskParams> {
    return {
      type: this.type,
      description: this.description,
      params: {
        classifierId: this.classifierId,
        detectorId: this.detectorId,
      },
    };
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [tasks, models] = await Promise.all([
        httpFacade.recognition.fetchTasks(),
        httpFacade.recognition.fetchModels(),
      ]);

      this.tasks = tasks.data;
      this.models = models.data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onCreate() {
    try {
      this.loading = true;

      await httpFacade.recognition.createTask(this.requestParams);

      AppRouter.goBack();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default TaskCreateDetectorForm;
