import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Desktop } from 'types/layout';

import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import { SwipeDirection } from 'stores/UserProfile/types';

import style from '../../OnlineOrder.module.scss';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';

@inject('store')
@observer
class MenuHeader extends Component<{ store?: UserProfileOrder }> {
  onArrowClick = (value: SwipeDirection) => () => {
    this.props.store!.swipeDay(value);
  };
  switchTab = () => this.props.store!.switchTab();

  render() {
    const { store } = this.props;

    return (
      <div className={style.menu__header}>
        <div className={style.menu__header_left}>
          <Desktop>
            <div className={style.header__day}>
              <div>{store?.deliveryDate.weekdayLong}</div>

              {store?.currentDate.toISODate() ===
                store?.deliveryDate.toISODate() && (
                <div className={style.label}>
                  <FormattedMessage id="TODAY" />
                </div>
              )}
            </div>
          </Desktop>
          <div className={style.tabs}>
            <p
              className={classNames(style.tabs__item, {
                [style.tabs__item_active]: this.props.store!.isTodayTabActive,
              })}
              onClick={this.switchTab}
            >
              <FormattedMessage id={'TODAY'} />
            </p>
            <p
              className={classNames(style.tabs__item, {
                [style.tabs__item_active]: !this.props.store!.isTodayTabActive,
              })}
              onClick={this.switchTab}
            >
              <FormattedMessage id={'STANDARD'} />
            </p>
          </div>
        </div>
        <div className={style.menu__header_right}>
          <Button
            styleType="text"
            onClick={this.onArrowClick('previous')}
            disabled={
              store!.deliveryDate.toISODate() <= store!.currentDate.toISODate()
            }
          >
            <Icon type="arrowLeft" />
          </Button>

          <Datepicker
            value={[store!.deliveryDate, undefined]}
            onChange={store!.changeDate}
            selectRange={false}
            showPeriods={false}
            minDate={store!.currentDate.toJSDate()}
            maxDate={store!.currentDate.plus({ month: 1 }).toJSDate()}
            styles={{
              calendar: style.date,
              calendarWrapper: style.datePicker,
            }}
          />

          <Button
            styleType="text"
            onClick={this.onArrowClick('next')}
            disabled={
              store!.deliveryDate.toISODate() >=
              store!.currentDate.plus({ month: 1 }).toISODate()
            }
          >
            <Icon type="arrowRight" />
          </Button>
        </div>
      </div>
    );
  }
}

export default MenuHeader;
