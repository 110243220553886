import React, { Component } from 'react';
import { observer } from 'mobx-react';

import ModalStore from 'stores/ModalStore';
import style from '../Recognition.module.scss';
import Table from 'components/Table/Table';
import WarningModal from 'components/Modals/Warning/WarningModal';
import config from './columnsConfig';
import DeploymentsStore from '../../../stores/Recognition/DeploymentsStore';
import DeploymentModelInfoModal from '../../../components/Modals/DeploymentModelInfo/DeploymentModelInfoModal';

@observer
class DeploymentsPage extends Component {
  store = new DeploymentsStore();

  async componentDidMount() {
    await this.store.fetchDeployments();
  }

  deleteModel = model => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });
    await this.store.deleteDeployment(model);
  };

  switchState = async (model, newState: boolean) =>
    newState
      ? this.store.enableDeployment(model)
      : this.store.disableDeployment(model);

  onShowModelInfo = model => async () => {
    ModalStore.showModal(DeploymentModelInfoModal, {
      model,
    });
  };

  render() {
    return (
      <Table
        data={this.store.deploymentsWithModels}
        columns={config({
          switchState: this.switchState,
          onDelete: this.deleteModel,
          onShowModelInfo: this.onShowModelInfo,
        })}
        subRowsKey={'models'}
        loading={this.store.loading}
        className={style.deploymentTable}
        defaultSorted={[
          {
            id: 'createdDate',
            desc: true,
          },
        ]}
      />
    );
  }
}

export default DeploymentsPage;
