import http from 'http/http';
import URLS from 'http/urls';
import { api } from 'http/helpers';
import { TransactionTypes } from 'types/general';
import { UpdateEmployeeDto } from '../types';

import {
  UpdateDishPreferencesDto,
  OnlineOrderMenuLine,
  OnlineOrderMenuStandard,
} from 'stores/UserProfile/types';
import { ProfileStatus } from 'stores/EmployeeProfile/types';
import { SpecificationItem } from 'stores/UserProfile/UserDishPreferences';

interface ProfileResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  nfc: boolean;
  rfid: boolean;
  deposit: boolean;
  refunded: boolean;
  status: ProfileStatus;
  externalReferenceId: string;
}

export interface TransactionItem {
  change: number;
  date: string;
  id: string;
  balance: number;
  transactionId: string;
  hasReceipt: boolean;
  externalPaymentIntentId: string;
  type: TransactionTypes;
}

interface EmployeeProfileApiMoneyReportGetResponse {
  balance: number;
  transactions: TransactionItem[];
}

export const profileApi = {
  fetchEmployeeByID(id: string) {
    return http.get<ProfileResponse>(api(URLS.employees.employees), {
      replacePathParams: { id },
    });
  },

  assignTags(userId: string, { rfid }: { rfid: string }) {
    return http.post<{}, unknown>(
      api(URLS.employees.assignTag),
      { rfid },
      { replacePathParams: { userId } },
    );
  },

  resetTags(userId: string) {
    return http.delete(api(URLS.employees.resetTag), {
      replacePathParams: { userId },
    });
  },

  fetchReport(id: string) {
    return http.get<EmployeeProfileApiMoneyReportGetResponse>(
      api(URLS.users.financeReport),
      {
        replacePathParams: { id },
      },
    );
  },

  blockProfile(id: string) {
    return http.post(
      api(URLS.employees.blockProfile),
      {},
      { replacePathParams: { id } },
    );
  },

  unblockProfile(id: string) {
    return http.post(
      api(URLS.employees.unblockProfile),
      {},
      { replacePathParams: { id } },
    );
  },

  requestRefund(id: string) {
    return http.post(
      api(URLS.employees.requestRefund),
      {},
      { replacePathParams: { id } },
    );
  },

  fetchDishPreference(id: string) {
    return http.get(api(URLS.employees.dishPreference), {
      replacePathParams: { id },
    });
  },

  updateDishPreference(id: string, data: any) {
    return http.put(api(URLS.employees.dishPreference), data, {
      replacePathParams: { id },
    });
  },

  updateContactInfo(id: string, data: UpdateEmployeeDto) {
    return http.put<UpdateEmployeeDto, any>(
      api(URLS.employees.employees),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  markRefund(id: string) {
    return http.post(
      api(URLS.employees.refund),
      {},
      { replacePathParams: { id } },
    );
  },

  unmarkRefund(id: string) {
    return http.post(
      api(URLS.employees.unmarkRefund),
      {},
      { replacePathParams: { id } },
    );
  },

  fetchMenuOnlineOrder(date: string) {
    return http.get<OnlineOrderMenuLine[]>(api(URLS.menu.menuOnlineOrder), {
      replacePathParams: { date },
    });
  },

  fetchMenuStandard() {
    return http.get<OnlineOrderMenuStandard[]>(api(URLS.menu.menuStandard));
  },

  fetchDishPreferences(id: string) {
    return http.get<{
      allergens: string[];
      specifications: SpecificationItem[];
    }>(api(URLS.employees.dishPreference), {
      replacePathParams: { id },
    });
  },

  updateDishPreferences(data: UpdateDishPreferencesDto, id: string) {
    return http.put(api(URLS.employees.dishPreference), data, {
      replacePathParams: {
        id,
      },
    });
  },
};
