import {
  SHORT_TIME_FORMAT,
  getFormattedDateTime,
  getDateTimeFromISO,
  getFormattedDate,
} from 'helpers/datetime';
import {
  DeliveryTimeSlot,
  DishPreferences,
  IMenuLine,
  MenuItem,
  OnlineOrderMenuLine,
  OnlineOrderMenuStandard,
  ProfileMenu,
} from './types';
import { DishItem, MenuLineManagement } from '../Menu/types';
import { sortByAccessor } from 'helpers/accessors';

export interface MenuLine extends IMenuLine {
  items: MenuItem[];
}

export function getMenuLines(
  menu: OnlineOrderMenuLine[],
  lines: IMenuLine[],
): MenuLine[] {
  return menu.map(line => {
    return {
      ...(lines.find(it => it.id === line.line) as IMenuLine),
      items: line.days[0].items
        .map(item => {
          if (!!item?.labels?.length) {
            item.labels.sort(
              sortByAccessor({ accessor: 'title', desc: false }),
            );
          }
          return item;
        })
        .map(it => ({
          ...it,
          amount: 0,
        })),
    };
  });
}

export function getOrderDate(date: string) {
  return `${getFormattedDate(date)} |
   ${getFormattedDateTime(date, SHORT_TIME_FORMAT)}`;
}

export function getTimeSlotPeriod(deliveryTimeSlot: DeliveryTimeSlot) {
  return `${getDateTimeFromISO(deliveryTimeSlot.start).toFormat(
    SHORT_TIME_FORMAT,
  )} -
   ${getDateTimeFromISO(deliveryTimeSlot.end).toFormat(SHORT_TIME_FORMAT)}`;
}

export const getPlannerMenuForCurrentDay = (
  plannerLines: ProfileMenu[],
  activeWeekDate: string,
) => {
  return plannerLines?.map?.(line => {
    const days = line?.days?.filter(day => day.date === activeWeekDate);
    return {
      days,
      id: line?.id,
      title: line?.title,
      prioritized: line?.prioritized,
    };
  });
};

export const getPlannerMenu = (
  menuLines: MenuLineManagement[],
  lines: IMenuLine[],
  period: string[],
): ProfileMenu[] => {
  return menuLines?.map?.(el => {
    const line = lines.find(item => item.id === el.line);

    return {
      id: line?.id,
      title: line?.title,
      prioritized: line?.prioritized,
      days: period.map(
        date => el.days.find(day => day.date === date) || { date, items: [] },
      ),
    };
  });
};

export const getFormattedFilteredPreferences = (
  preferences: DishPreferences,
) => {
  return {
    allergens: preferences?.allergens,
    specifications: preferences?.specifications?.map?.(item => item.id),
  };
};

export const filterCustomPreferences = (preferences, id) => {
  return preferences.includes(id)
    ? preferences.filter(item => item !== id)
    : [...preferences, id];
};

export const addDepositItemsToMenuItems = (
  menu: Array<OnlineOrderMenuStandard | MenuLine>,
  depositItems: DishItem[],
) => {
  return menu
    .map(group => group.items)
    .flat()
    .map(item => {
      if (item.depositItemId) {
        const depositItem = depositItems.find(
          el => el.id === item.depositItemId,
        );

        return {
          ...item,
          ...{
            depositTitle: depositItem?.title,
            depositPrice: depositItem?.price,
          },
        };
      }
      return { ...item };
    });
};

export const addDepositItemsToCollection = (
  menu: MenuItem[],
  depositItems: DishItem[],
) => {
  return menu.map(item => {
    if (item?.depositItemId) {
      const depositItem = depositItems.find(el => el.id === item.depositItemId);
      return {
        ...item,
        ...{
          depositTitle: depositItem?.title,
          depositPrice: depositItem?.price,
        },
      };
    }
    return { ...item };
  });
};

export const getSelectedMenuItems = (menuItems: MenuItem[]) => {
  return menuItems.filter(it => Boolean(it.amount));
};

export const sortMenu = (menu, sortGroupConfig, sortItemsConfig) => {
  return [...menu].sort(sortByAccessor(sortGroupConfig)).map(line => {
    return {
      ...line,
      items: [...line.items].sort(sortByAccessor(sortItemsConfig)),
    };
  });
};

export function createMenuCollection(menu) {
  const collection: MenuItem[] = [];
  if (menu?.length) {
    for (let i = 0; i < menu?.length; i++) {
      for (let j = 0; j < menu[i].items.length; j++) {
        const index = j * menu?.length + i;
        collection[index] = menu[i]?.items[j] ?? null;
      }
    }
  }

  return collection;
}

export function resetMenuItemsAmount(
  menu: Array<OnlineOrderMenuStandard | MenuLine>,
) {
  return menu.map(line => ({
    ...line,
    items: line.items.map(it => ({ ...it, amount: 0 })),
  }));
}

export function resetCollectionItemsAmount(menu: MenuItem[]) {
  return menu.map(item => ({
    ...item,
    amount: 0,
  }));
}
