import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './StornoReport.module.scss';

import Spinner from 'components/Spinner/Spinner';
import ReportTable from './ReportTable';
import StornoForm from './StornoForm';

import StornoReportStore from 'stores/Report/StornoReportStore';
import StornoReportForm from 'stores/Forms/Reports/StornoReportForm';

@observer
class StornoReportPage extends Component {
  store = new StornoReportStore();
  form = new StornoReportForm();

  async componentDidMount() {
    await this.store.getReport(this.form.requestParams);
  }

  render() {
    const { report } = this.store;

    return (
      <section className={style.main}>
        {this.store.loading && <Spinner contain />}

        <StornoForm form={this.form} store={this.store} />

        {report && <ReportTable report={report} />}
      </section>
    );
  }
}

export default StornoReportPage;
