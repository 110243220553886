import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { CopyMenuFromWeekForm } from 'stores/Menu/MenuCopy/CopyMenuFromWeekForm';
import RootStore from 'stores/RootStore';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';

import style from 'pages/MenuPlanner/CopyMenu/CopyMenu.module.scss';

interface IProps {
  form: CopyMenuFromWeekForm;
  onSubmit: () => void;
  disabled: boolean;
}

@observer
class CopyFromWeekComponent extends Component<IProps> {
  render() {
    const { form, onSubmit, disabled } = this.props;
    const WEEK_FORMAT = `'${RootStore.localization.formatMessage('WEEK')}' W`;

    return (
      <>
        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="menu.copy.label.from" />
          </div>
          <Datepicker
            value={form.periodFrom}
            onChange={form.changePeriodFrom}
            selectRange={false}
            showPeriods={false}
            dateFormat={WEEK_FORMAT}
            showWeekNumbers={true}
            icon={true}
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="menu.copy.label.toPeriod" />
          </div>
          <Datepicker
            value={form.periodTo}
            onChange={form.changePeriodTo}
            selectRange={true}
            showPeriods={false}
            dateFormat={WEEK_FORMAT}
            showWeekNumbers={true}
            icon={true}
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <Button
          onClick={onSubmit}
          className={style.btnCopy}
          disabled={form.isCopyDisabled || disabled}
        >
          <FormattedMessage id="button.copy" />
        </Button>
      </>
    );
  }
}

export { CopyFromWeekComponent };
