/* eslint-disable */
import { action } from 'mobx';

// tslint:disable-next-line:no-implicit-dependencies
import Worker from 'worker-loader!./SamplesLoader.worker';

import RootStore from '../RootStore';
import { popup } from '../Popup';

export interface UploadData {
  dataset: {
    id: string;
    name: string;
  };
  files: File[];
  accessToken: string | null;
}

export interface LoadedEvent {
  done: boolean;
  dataset: {
    id: string;
    name: string;
  };
}

class SampleLoaderService {
  private static onDatasetSamplesLoaded(event: { data: LoadedEvent }) {
    const { done, dataset } = event.data;

    if (done && dataset) {
      popup.notify(
        RootStore.localization.formatMessage('notification.file.load.success', {
          name: dataset.name,
        }),
        'SUCCESS',
      );
    }
  }

  private worker: Worker;

  @action.bound
  connect() {
    this.worker = new Worker();
    this.subscribe(SampleLoaderService.onDatasetSamplesLoaded);
  }

  uploadSamples(data: UploadData) {
    this.worker.postMessage({ ...data });
  }

  subscribe(callback) {
    this.worker.addEventListener('message', callback);
  }

  unsubscribe(callback) {
    this.worker.removeEventListener('message', callback);
  }

  disconnect() {
    this.worker?.terminate();
  }
}

export default SampleLoaderService;
