import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import style from './MenuConfiguration.module.scss';
import ProfileMenuStore from '../../../../stores/UserProfile/ProfileMenuStore';
import MenuSpecsList from './MenuSpecsList';
import Button from '../../../../components/Button/Button';

interface Props {
  menu: ProfileMenuStore;
  activeDate?: string;
}

@observer
class MenuConfiguration extends Component<Props> {
  resetFilters = () => {
    this.props.menu.resetFilters();
  };
  showItems = async () => {
    this.props.menu.showConfiguredMenuItems();
  };
  render() {
    const { menu } = this.props;

    return (
      <>
        <div className={style.specs__panel}>
          <div className={style.title__block}>
            <span className={style.title__filtering}>
              <FormattedMessage id="employeeProfile.menu.filter.title" />
              &nbsp;
            </span>
            {menu?.customFilters?.length > 0 && (
              <span className={style.title__reset} onClick={this.resetFilters}>
                <FormattedMessage id="employeeProfile.menu.reset.title" />
              </span>
            )}
          </div>
          <MenuSpecsList menu={menu} />
          <div className={style.btn__wrapper}>
            <Button onClick={this.showItems} className={style.btn}>
              <FormattedMessage
                id="title.show"
                values={{
                  value: `${menu.menuItemsCount}`,
                }}
              />
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default MenuConfiguration;
