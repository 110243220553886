import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import style from './NavigationTabs.module.scss';

interface Props {
  tabs: Array<{ to: string; title: string }>;
  className?: string;
}

const NavigationTabs = ({ tabs, className }: Props) => (
  <div className={classNames(style.tabs, className)}>
    {tabs.map(tab => (
      <NavLink
        className={style.tab}
        activeClassName={style.tab__active}
        isActive={(match, location) =>
          match && location && match.url === location.pathname
        }
        to={tab.to}
        key={tab.to}
      >
        <FormattedMessage id={tab.title} defaultMessage={tab.title} />
      </NavLink>
    ))}
  </div>
);

export default NavigationTabs;
