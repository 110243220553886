import React, { Component, ReactNode } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { getFormattedDate } from 'helpers/datetime';

import style from './TagsSelectModal.module.scss';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';
import SearchField from 'components/Form/Fields/Search/SearchField';
import RadioField from 'components/Form/Fields/Radio/RadioField';
import Spinner from 'components/Spinner/Spinner';

import { ModalProps } from 'stores/ModalStore';
import TagsSelectModalStore from 'stores/Modals/TagsSelectModalStore';

export interface ITag {
  id: string;
  title: string;
  noted?: boolean;
  date?: string;
  icon?: string;
}

export interface IGroupTag extends ITag {
  groupId?: string;
  isGroup?: boolean;
}

export interface EditModeActions<T extends ITag> {
  onDeleteTag: (id: string) => Promise<{ id: string } | undefined>;
  onCreateTag: (tagTitle: string) => Promise<{ tag: T } | undefined>;
}

export interface TagsSelectModalProps<T extends ITag> extends ModalProps {
  title: string;
  tags: T[];
  selectedTags: T[];
  isShowSelectAll?: boolean;
  isShowTags?: boolean;
  isShowSearchField?: boolean;
  inputTypes?: 'checkbox' | 'radio';
  editModeActions?: EditModeActions<T>;
  itemChild?: (tag: any) => ReactNode;
  withGroups?: boolean;
}

@observer
class TagsSelectModal<T extends ITag> extends Component<
  TagsSelectModalProps<T>
> {
  store = new TagsSelectModalStore(
    this.props.tags,
    this.props.selectedTags,
    this.props.editModeActions,
    this.props.withGroups,
  );

  @observable editMode = false;

  onSubmit = () => {
    this.props.onSubmit!(this.store.selectedTags);
  };

  @action
  toggleEditMode = () => {
    this.editMode = !this.editMode;
  };

  render() {
    const {
      title,
      isShowSelectAll,
      isShowTags = true,
      isShowSearchField,
      inputTypes = 'checkbox',
      onClose,
      editModeActions,
      itemChild,
      withGroups,
      ...props
    } = this.props;

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        onClose={onClose}
      >
        {this.store.loading && <Spinner contain />}

        <div className={style.modal__header}>
          <FormattedMessage id={title} />
        </div>

        {isShowTags && (
          <div className={style.tags}>
            {Boolean(this.store.selectedTags.length) ? (
              this.store.selectedTags.map(tag => (
                <div key={tag.id} className={style.tag}>
                  <span className={style.tag__name}>{tag.title}</span>

                  <Button
                    styleType="text"
                    onClick={this.store.onTagClick(tag)}
                    className={style.tag__btn}
                  >
                    <Icon type="close" />
                  </Button>
                </div>
              ))
            ) : (
              <div className={style.tags__text}>
                <FormattedMessage id="title.not.selected" />
              </div>
            )}
          </div>
        )}

        {isShowSearchField && (
          <div className={style.search__block}>
            <SearchField
              className={style.search__field}
              onChange={this.store.onSearch}
              value={this.store.searchValue}
              maxLength={20}
            >
              {editModeActions &&
                !this.store.filteredTags.length &&
                this.store.searchValue && (
                  <Button
                    styleType="text"
                    className={style.search__action}
                    onClick={this.store.onCreateTag}
                  >
                    <FormattedMessage id="modal.tags.create.tag" />
                  </Button>
                )}
            </SearchField>
          </div>
        )}

        {editModeActions && (
          <div
            className={classNames(style.editMode__controls, {
              [style.editMode__active]: this.editMode,
            })}
          >
            <FormattedMessage
              id={
                this.editMode
                  ? 'modal.tags.created.edit.mode'
                  : 'modal.tags.created'
              }
            />

            <Button
              styleType="text"
              className={style.editMode__btn}
              onClick={this.toggleEditMode}
            >
              <FormattedMessage
                id={
                  this.editMode
                    ? 'modal.tags.exit.edit.mode'
                    : 'modal.tags.edit'
                }
              />
            </Button>
          </div>
        )}

        <ul className={style.tags__list}>
          {Boolean(this.store.filteredTags.length) ? (
            <>
              {isShowSelectAll && (
                <li className={classNames(style.tags__item, style.tags__all)}>
                  <CheckboxField
                    className={{ wrapper: style.checkbox }}
                    checked={this.store.isSelectAllTags}
                    onChange={this.store.onSelectAllClick}
                    type="green"
                  >
                    <span className={style.tag__name}>
                      <FormattedMessage id="button.select.all" />
                    </span>
                  </CheckboxField>
                </li>
              )}

              {withGroups &&
                !this.store.searchValue &&
                this.store.filteredTags
                  ?.filter(item => (item as IGroupTag).isGroup)
                  .map((item, index) => {
                    const items = this.store.filteredTags?.filter(
                      it => (it as IGroupTag).groupId === item.id,
                    );
                    return (
                      <GroupItem
                        key={item.id + index}
                        store={this.store}
                        group={[item, ...items]}
                      />
                    );
                  })}

              {(!withGroups || this.store.searchValue) &&
                (inputTypes === 'radio'
                  ? this.store.filteredTags?.map(item => {
                      return (
                        <li className={style.radio__block} key={item.title}>
                          <RadioField
                            title={item.title}
                            name={item.id}
                            value={item.id}
                            onChange={this.store.onTagRadioClick}
                            checked={this.store.tagsIds.includes(item.id)}
                            radioButtonStyleType={'circle'}
                            className={style.radio__field}
                          />
                        </li>
                      );
                    })
                  : this.store.filteredTags?.map(it => (
                      <li
                        key={it.id}
                        className={classNames(
                          style.tags__item,
                          isShowSelectAll && style.tags__item__align,
                        )}
                      >
                        <CheckboxField
                          className={{ wrapper: style.checkbox }}
                          checked={this.store.tagsIds.includes(it.id)}
                          onChange={this.store.onTagClick(it)}
                          type="green"
                          disabled={this.editMode}
                        >
                          {it.icon && (
                            <img
                              src={it.icon}
                              alt="icon"
                              className={style.tag__icon}
                            />
                          )}
                          <span className={style.tag__name}>{it.title}</span>
                        </CheckboxField>

                        {editModeActions && (
                          <Button
                            styleType="text"
                            onClick={this.store.onDeleteTag(it.id)}
                            className={classNames(style.btn__delete, {
                              [style.editMode__active]: this.editMode,
                            })}
                          >
                            <Icon type="trash" />
                          </Button>
                        )}

                        {it.date && (
                          <span className={style.tag__time}>
                            {getFormattedDate(it.date)}
                          </span>
                        )}

                        {itemChild && itemChild(it)}
                      </li>
                    )))}
            </>
          ) : (
            <FormattedMessage id="modal.tags.not.found" />
          )}
        </ul>

        <div className={style.controls}>
          <Button onClick={onClose} styleType="ghost" className={style.btn}>
            <FormattedMessage id="button.cancel" />
          </Button>

          <Button onClick={this.onSubmit} className={style.btn}>
            <FormattedMessage id="button.done" />
          </Button>
        </div>
      </Modal>
    );
  }
}

interface IGroupItemProps {
  store: TagsSelectModalStore<IGroupTag>;
  group: IGroupTag[];
}

const GroupItem = observer(({ store, group }: IGroupItemProps) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const toggleOpened = () => {
    setIsOpened(!isOpened);
  };
  const selectedCount = store.selectedTags.filter(tag =>
    group.some(it => it.id === tag.id && !it.isGroup),
  ).length;

  return (
    <>
      {group.map(it => (
        <li
          key={it.id}
          className={classNames(
            style.tags__item,
            !isOpened &&
              !it.isGroup &&
              !store.searchValue &&
              style.tags__item__hidden,
            !it.isGroup && style.tags__item__align,
          )}
        >
          <CheckboxField
            className={{ wrapper: style.checkbox }}
            checked={store.tagsIds.includes(it.id)}
            onChange={store.onTagClick(it)}
            type="green"
          >
            <span className={style.tag__name}>{it.title}</span>
          </CheckboxField>
          {it.isGroup && (
            <div className={style.tags__group_icons}>
              {!!selectedCount && (
                <div className={style.tags__count}>{selectedCount}</div>
              )}
              <Icon
                onClick={() => toggleOpened()}
                type={isOpened ? 'arrowTop' : 'arrowBottom'}
              />
            </div>
          )}
        </li>
      ))}
    </>
  );
});

export default TagsSelectModal;
