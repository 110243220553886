import { StateCondition } from 'stores/Recognition/types';

import style from './Recognition.module.scss';

export function getStateColor(state: StateCondition) {
  switch (state) {
    case StateCondition.GOOD:
      return style.state__good;
    case StateCondition.RISQUE:
      return style.state__risque;
    default:
      return style.state__common;
  }
}
