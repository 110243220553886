import React from 'react';
import classNames from 'classnames';

import style from './Table.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

interface Props {
  onEdit?: () => void;
  onDelete?: () => void;
  classNames?: string;
}

const Controls = (props: Props) => {
  const onEdit = event => {
    event.stopPropagation();

    if (props.onEdit) {
      props.onEdit();
    }
  };

  const onDelete = event => {
    event.stopPropagation();

    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <div className={classNames(style.controls, props.classNames)}>
      {props.onEdit && (
        <Button styleType="text" className={style.control} onClick={onEdit}>
          <Icon type="edit" />
        </Button>
      )}

      {props.onDelete && (
        <Button styleType="text" className={style.control} onClick={onDelete}>
          <Icon type="trash" />
        </Button>
      )}
    </div>
  );
};

export default Controls;
