import noop from './noop';

type ConsoleFunc = (...args: unknown[]) => void;

const debugConfig = {
  // ws: false,
};

/* tslint:disable:no-console */
const Log = {
  error: (['production', 'test'].includes(process.env.NODE_ENV)
    ? noop
    : console.error) as ConsoleFunc,
  warn: (['production', 'test'].includes(process.env.NODE_ENV)
    ? noop
    : console.warn) as ConsoleFunc,
  info: (['production', 'test'].includes(process.env.NODE_ENV)
    ? noop
    : console.info) as ConsoleFunc,
  log: (process.env.NODE_ENV !== 'development'
    ? noop
    : console.log) as ConsoleFunc,
  debug: (type: keyof typeof debugConfig) =>
    process.env.SERVER !== 'production' && debugConfig[type]
      ? console.log
      : noop,
};

export default Log;
