import { action, computed, observable } from 'mobx';
import Map from 'ol/Map';
import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import { Vector as VectorLayer } from 'ol/layer';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { Vector as VectorSource } from 'ol/source';
import Draw from 'ol/interaction/Draw';
import {
  defaults as defaultInteractions,
  Modify,
  Select,
  Translate,
} from 'ol/interaction';
import { getCenter, getTopLeft, getTopRight } from 'ol/extent';
import { defaults as defaultControls } from 'ol/control';
import { singleClick, shiftKeyOnly } from 'ol/events/condition';
import Feature from 'ol/Feature';

import { uniqueId } from 'helpers/uniqueId';
import { getFeatureText } from './helpers';

import { FEATURE_PROPERTIES, SubTag } from './types';
import { MenuItem } from '../Recognition/types';

import { defaultStyle, errorStyle, selectedStyle } from './style';

import RootStore from '../RootStore';

const target = 'map';

class OLStore {
  @observable loading = true;
  @observable drawModeOn = false;
  @observable isImageBroken = false;
  @observable isSourceValid = false;
  @observable extent: number[] = [0, 0, 640, 360];

  map: Map;
  view: View;
  vectorSource: VectorSource;
  vectorLayer: VectorLayer;
  imageLayer: ImageLayer;
  draw: Draw | null;
  modify: Modify;
  select: Select;
  translate: Translate;
  image: Static;

  @observable shapeType: 'Polygon' | 'Circle' = 'Polygon';
  // not a plain object, cant be observable, only link changes detection
  @observable activeFeature: Feature | null;
  @observable features: Feature[] = [];
  @observable zoom = 1;
  @observable activeTag: string | null;
  @observable activeSubTag: SubTag | null;

  @observable projection = new Projection({
    units: 'pixels',
    extent: this.extent,
  });

  @computed
  get zoomPercentage(): string {
    return `${(this.zoom * 100).toFixed()}%`;
  }

  init(
    url: string,
    features: Feature[] = [],
    viewSize: { width: number; height: number },
  ) {
    this.extent = [0, 0, viewSize.width, viewSize.height];

    // order is important!
    this.addImageSource(url);
    this.addVectorSource();
    this.addInteractions();
    this.addFeatures(features);

    this.map = new Map({
      layers: [this.imageLayer, this.vectorLayer],
      target,
      view: new View({
        center: getCenter(this.extent),
        zoom: this.zoom,
        projection: this.projection,
      }),
      interactions: defaultInteractions().extend([this.select, this.translate]),
      controls: defaultControls({
        attribution: false,
        zoom: false,
        rotate: false,
      }),
    });
    this.map.addInteraction(this.modify);

    this.fitView();
  }

  @action.bound
  fitView() {
    this.map.getView().fit(this.extent);
    this.zoom = this.map.getView().getZoom();
  }

  @action.bound
  addImageSource(url: string) {
    this.image = new Static({
      url,
      imageExtent: this.extent,
      projection: this.projection,
    });

    this.image.on('imageloadend', () => {
      this.loading = false;
    });
    this.image.on('imageloaderror', () => {
      this.loading = false;
      this.isImageBroken = true;
    });
    this.image.on('imageloadstart', () => {
      this.loading = true;
    });

    if (this.imageLayer) {
      this.imageLayer.setSource(this.image);
    } else {
      this.imageLayer = new ImageLayer({
        source: this.image,
      });
    }
  }

  @action.bound
  addFeatures(features: Feature[] = []) {
    this.features = features;

    this.vectorSource?.clear();
    this.vectorSource?.addFeatures(features);
  }

  @action.bound
  toggleDrawFunction() {
    if (this.drawModeOn) {
      this.map.removeInteraction(this.draw);
      this.drawModeOn = false;
      this.select.setActive(true);
    } else {
      this.draw = new Draw({
        source: this.vectorSource,
        type: this.shapeType,
      });

      this.map.addInteraction(this.draw);
      this.drawModeOn = true;

      this.draw.on('drawstart', () => {
        this.select?.getFeatures().clear();
        this.select.setActive(false);

        this.activeFeature = null;
      });

      this.draw.on('drawend', event => {
        const { feature } = event;

        this.map.removeInteraction(this.draw);
        this.select.setActive(true);

        feature.setId(uniqueId());

        this.selectFeature(feature);
        this.features.push(feature);
        this.drawModeOn = false;
      });
    }
  }

  @action.bound
  selectFeature(feature: Feature) {
    this.select.getFeatures().clear();
    this.select.getFeatures().push(feature);
    this.select.changed();

    const tag = feature.get(FEATURE_PROPERTIES.tag);
    const subtag = feature.get(FEATURE_PROPERTIES.subtag);

    this.activeTag = tag;
    this.activeSubTag = subtag;
    this.activeFeature = feature;
  }

  @action.bound
  deleteActiveFeature() {
    if (this.activeFeature) {
      this.vectorSource.removeFeature(this.activeFeature);

      this.features = this.features.filter(
        feature => feature.getId() !== this.activeFeature.getId(),
      );
      this.activeFeature = null;
      this.activeTag = null;
      this.activeSubTag = null;
    }
  }

  @action.bound
  setFeatureTagAndTitle(item: MenuItem) {
    if (this.activeFeature) {
      this.activeFeature.set(FEATURE_PROPERTIES.tag, item.tag);
      this.activeFeature.set(FEATURE_PROPERTIES.title, item.title);
      this.activeFeature.set(FEATURE_PROPERTIES.price, item.price);
      this.activeFeature.set(FEATURE_PROPERTIES.itemNumber, item.itemNumber);
      this.activeFeature.changed();

      this.activeTag = item.tag;
    }
  }

  @action.bound
  setFeatureSubTag(subtag: SubTag | null) {
    if (this.activeFeature) {
      this.activeFeature.set(FEATURE_PROPERTIES.subtag, subtag);

      this.activeSubTag = subtag;

      this.activeFeature.changed();
    }
  }

  @action.bound
  resetData() {
    this.select?.getFeatures().remove(this.activeFeature);
    this.map.removeInteraction(this.draw);
    this.vectorSource?.clear();

    this.activeTag = null;
    this.activeSubTag = null;
    this.activeFeature = null;
    this.drawModeOn = false;
    this.features = [];
  }

  @action.bound
  updateData(imageFileLink: string, features: Feature[] = []) {
    this.resetData();
    this.addImageSource(imageFileLink);
    this.addFeatures(features);
    this.fitView();
  }

  @action.bound
  setZoom(value: number) {
    const zoom = this.map.getView().getZoom() + value;
    this.zoom = Math.max(zoom, 0);
    this.map.getView().setZoom(zoom);
  }

  private addVectorSource() {
    this.vectorSource = new VectorSource();
    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: feature => {
        const text = getFeatureText(feature);
        const featureExtent = feature.getGeometry().getExtent();
        const topLeft = this.map.getPixelFromCoordinate(
          getTopLeft(featureExtent),
        );

        const topRight = this.map.getPixelFromCoordinate(
          getTopRight(featureExtent),
        );
        const width = topRight[0] - topLeft[0];

        if (text) {
          defaultStyle.getText().setText(text);

          defaultStyle.getText().setRotation(width < 80 ? 30 : 0);
          return defaultStyle;
        } else {
          defaultStyle
            .getText()
            .setText(RootStore.localization.formatMessage('UNDEFINED_TAG'));

          return errorStyle;
        }
      },
      projection: this.projection,
      declutter: true,
    });

    this.vectorSource.on('change', () => {
      this.isSourceValid =
        !this.isImageBroken &&
        this.vectorSource.getFeatures().length &&
        this.vectorSource
          .getFeatures()
          .every(feature => Boolean(feature.get(FEATURE_PROPERTIES.tag)));
    });
  }

  private addInteractions() {
    this.modify = new Modify({ source: this.vectorSource });
    this.select = new Select({
      style: feature => {
        selectedStyle.getText().setText(getFeatureText(feature));

        return selectedStyle;
      },
      condition: event => singleClick(event) && !shiftKeyOnly(event),
    });
    this.translate = new Translate({
      features: this.select.getFeatures(),
    });

    this.select.on('select', event => {
      this.activeFeature = event.selected && event.selected[0];
      this.activeTag = this.activeFeature?.get(FEATURE_PROPERTIES.tag);
      this.activeSubTag = this.activeFeature?.get(FEATURE_PROPERTIES.subtag);
    });
  }
}

export default OLStore;
