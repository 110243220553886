import http from '../http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

export interface SelfRegGetResponse {
  active: boolean;
  invitationLetter: string;
}

export interface SelfRegPostRequest {
  nickname: string;
  email: string;
  firstName: string;
  lastName: string;
  campaignId: string;
}

export interface SelfRegPostResponse {
  QRCode: string;
}

export const RegistrationApi = {
  checkLimit(campaignId: string) {
    return http.get<SelfRegGetResponse>(api(URLS.registration), {
      params: { campaignId },
    });
  },

  register(data: SelfRegPostRequest) {
    return http.post<SelfRegPostRequest, SelfRegPostResponse>(
      api(URLS.registration),
      data,
    );
  },
};
