import { action, observable } from 'mobx';

import { TransactionTypes } from 'types/general';

import httpFacade from 'http/httpFacade';
import { TransactionItem } from 'http/Api/profile';

import { getRole, Roles } from 'helpers/roles';
import Log from 'helpers/log';

class ProfileReport {
  id: string;

  @observable balance = 0;
  @observable transactions: TransactionItem[] = [];

  @observable loading = false;

  constructor(id: string) {
    this.id = id;
  }

  @action
  async fetchReport() {
    try {
      this.loading = true;

      const response = await httpFacade.profile.fetchReport(this.id);
      const { balance, transactions } = response.data;

      this.balance = balance;
      this.transactions = getRole(Roles.accountant)
        ? transactions.filter(
            el =>
              el.type === TransactionTypes.STRIPE_REFILL ||
              el.type === TransactionTypes.PAYONE_REFILL ||
              el.type === TransactionTypes.BALANCE_MIGRATION,
          )
        : transactions;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default ProfileReport;
