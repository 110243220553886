import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'routes/routes';

import AppRouter from 'stores/AppRouter';
import ModalStore from 'stores/ModalStore';
import TasksStore from 'stores/Recognition/TasksStore';
import { TaskAction, TaskViewDto } from 'stores/Recognition/types';

import Button from 'components/Button/Button';
import DescriptionModal from 'components/Modals/Description/DescriptionModal';
import TaskInfoModal from 'components/Modals/TaskInfo/TaskInfoModal';
import WarningModal from 'components/Modals/Warning/WarningModal';
import Table from 'components/Table/Table';

import RootStore from 'stores/RootStore';

import config from './columnsConfig';

import style from './TasksPage.module.scss';

@observer
class TasksPage extends Component {
  store = new TasksStore();

  onNewTaskCreate = () => {
    AppRouter.push(ROUTES.admin.task);
  };

  deleteTask = (id: string) => async event => {
    event.stopPropagation();
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    await this.store.deleteTask(id);
  };

  updateTask = (task: TaskViewDto) => async event => {
    event.stopPropagation();
    const { payload } = await ModalStore.showModal(DescriptionModal, {
      title: (
        <FormattedMessage
          id="modal.description.title"
          values={{
            name: task.type,
          }}
        />
      ),
      label: 'title.comment',
      initialDescription: task.description,
    });

    await this.store.updateTask(task.id, payload);
  };

  onTaskAction = (task: TaskViewDto, action: TaskAction) => async event => {
    event.stopPropagation();
    switch (action) {
      case TaskAction.CREATE_TASK_CREATE_MODEL:
        const data = await ModalStore.showModal(DescriptionModal, {
          title: (
            <FormattedMessage
              id="modal.description.title"
              values={{
                name: task.type,
              }}
            />
          ),
          label: 'title.comment',
          initialDescription: task.description,
        });
        await this.store.taskAction(task.id, action, {
          modelDescription: data.payload,
        });
        break;
      case TaskAction.IMPORT_DATASET_ARTIFACT:
        await this.store.importDatasetArtifact(task.id);
        break;
      default:
        await this.store.taskAction(task.id, action);
    }
  };

  async componentDidMount() {
    await this.store.fetchTasks();

    RootStore.socket.subscribe(this.store.updateTasksAsync);
  }

  componentWillUnmount() {
    RootStore.socket.unsubscribe(this.store.updateTasksAsync);
  }

  onRowClick = (state, rowInfo) => {
    return {
      onClick: () => {
        ModalStore.showModal(TaskInfoModal, {
          task: rowInfo.original,
        });
      },
    };
  };

  render() {
    return (
      <>
        <Button className={style.btn__task} onClick={this.onNewTaskCreate}>
          <FormattedMessage id="button.newTask" />
        </Button>

        <Table
          getTrProps={this.onRowClick}
          data={this.store.tasks}
          columns={config({
            onUpdate: this.updateTask,
            onDelete: this.deleteTask,
            onTaskAction: this.onTaskAction,
          })}
          loading={this.store.loading}
          defaultSorted={[
            {
              id: 'createdDate',
              desc: true,
            },
          ]}
        />
      </>
    );
  }
}

export default TasksPage;
