import { action, observable } from 'mobx';

import { raceExToSuccess } from 'helpers/promise';

import httpFacade from 'http/httpFacade';

import { ROUTES } from 'routes/routes';

import AppRouter from 'stores/AppRouter';

import { ScanStatus } from './types';
import ModalStore from '../ModalStore';

class TagScanner {
  @observable status: ScanStatus = ScanStatus.LOADING;

  @observable canceled = false;

  @action.bound
  unsubscribe() {
    this.canceled = true;
  }

  @action.bound
  async scan() {
    try {
      this.status = ScanStatus.LOADING;

      const { rfid } = await raceExToSuccess({
        rfid: httpFacade.adapter.fetchTagFromRFID(),
      });

      this.status = ScanStatus.REDIRECTING;

      let employee;

      if (rfid) {
        employee = await httpFacade.employees.findEmployeeByRFID(rfid.data);
      }

      if (employee && !this.canceled) {
        ModalStore.closeModal();
        AppRouter.push(ROUTES.employeeProfile, employee.data);
      }
    } catch (error) {
      this.status = ScanStatus.ERROR;
    }
  }
}

export default TagScanner;
