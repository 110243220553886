import RootStore from 'stores/RootStore';
import { ROUTES } from '../routes/routes';

export enum Roles {
  admin = 'ROLE_ADMIN',
  hostess = 'ROLE_HOSTESS',
  employee = 'ROLE_EMPLOYEE',
  accountant = 'ROLE_ACCOUNTANT',
  archiveAdmin = 'ROLE_PRIMUS_ARCHIV_ADMIN',
  service = 'ROLE_SERVICE',
  cateringAdmin = 'ROLE_CATERING_ADMIN',
  bayerRestrictedAdmin = 'ROLE_BAYER_RESTRICTED_ADMIN',
}

export function getRole(role: Roles) {
  return RootStore.user.roles.includes(role);
}

export function getDefaultRouteByRole() {
  if (getRole(Roles.employee)) {
    return ROUTES.user.profile;
  } else if (getRole(Roles.hostess) || getRole(Roles.accountant)) {
    return ROUTES.employees;
  } else if (getRole(Roles.admin)) {
    return ROUTES.admin.report;
  } else if (getRole(Roles.service)) {
    return ROUTES.service;
  } else if (getRole(Roles.archiveAdmin)) {
    return ROUTES.archiveAdmin.archive;
  } else if (getRole(Roles.cateringAdmin)) {
    return ROUTES.admin.report;
  } else if (getRole(Roles.bayerRestrictedAdmin)) {
    return ROUTES.admin.report;
  } else {
    return ROUTES.user.profile;
  }
}
