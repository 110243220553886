import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Col.module.scss';

interface ColProps {
  sizes: ColumnSizes;
  className?: string;
  children?: any;
}

interface ColumnSizes {
  sm?: number;
  md?: number;
}

const Col: FC<ColProps> = ({ sizes, className, children }: ColProps) => {
  const classes = {};

  Object.keys(sizes).forEach(key => {
    const breakpoint = key[0].toUpperCase() + key.slice(1);
    const classCol = styles[`col${breakpoint}${sizes[key]}`];
    classes[classCol] = true;
  });

  return <div className={classNames(classes, className)}>{children}</div>;
};

export default Col;
