import classNames from 'classnames';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './TaskPage.module.scss';

import { ROUTES } from 'routes/routes';
import { TaskType } from 'stores/Recognition/types';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import TaskDetectorForm from './TaskForm/TaskDetectorForm';
import TaskClassifierForm from './TaskForm/TaskClassifierForm';
import TaskExtraClassifierForm from './TaskForm/TaskExtraClassifierForm';
import TaskEvalDetectorClassifierForm from './TaskForm/TaskEvalDetectorClassifierForm';
import TaskCreateDetectorForm from './TaskForm/TaskCreateDetectorForm';
import TaskEvalDetectorForm from './TaskForm/TaskEvalDetectorForm';
import RootStore from '../../../stores/RootStore';
import TaskDatasetGeneratorForm from './TaskForm/TaskDatasetGeneratorForm';

@observer
class TaskPage extends Component {
  @observable selectedTaskType: TaskType =
    TaskType.TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL;

  onSelectType = (type: TaskType) => () => {
    this.selectedTaskType = type;
  };

  render() {
    return (
      <main className={style.main}>
        <header className={style.header}>
          <Breadcrumbs
            links={[
              { to: ROUTES.admin.recognition, name: 'title.recognition' },
              { to: ROUTES.admin.tasks, name: 'title.tasks' },
              { name: RootStore.localization.formatMessage('title.newTask') },
            ]}
            className="admin-header--breadcrumbs"
          />
        </header>

        <div className={style.task}>
          <section className={style.task__types}>
            <h2 className={style.types__title}>
              <FormattedMessage id="title.type.task" />
            </h2>

            <ul className={style.types__list}>
              {Object.values(TaskType).map(type => (
                <li
                  key={type}
                  className={classNames(style.item, {
                    [style.item__active]: this.selectedTaskType === type,
                  })}
                  onClick={this.onSelectType(type)}
                >
                  <FormattedMessage id={type} />
                </li>
              ))}
            </ul>
          </section>

          {this.taskFormByType}
        </div>
      </main>
    );
  }

  @computed
  get taskFormByType() {
    switch (this.selectedTaskType) {
      case TaskType.TRAIN_DETECTOR_SSD:
      case TaskType.TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL:
      case TaskType.TRAIN_DETECTOR_TF_MRCNN:
        return (
          <TaskDetectorForm
            key={this.selectedTaskType}
            type={this.selectedTaskType}
          />
        );
      case TaskType.TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4:
        return <TaskClassifierForm type={this.selectedTaskType} />;
      case TaskType.TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4:
        return <TaskExtraClassifierForm type={this.selectedTaskType} />;
      case TaskType.EVAL_MODEL_DETECTOR_DVC:
        return <TaskEvalDetectorClassifierForm type={this.selectedTaskType} />;
      case TaskType.EVAL_MODEL_DETECTOR:
        return <TaskEvalDetectorForm type={this.selectedTaskType} />;
      case TaskType.CREATE_MODEL_DETECTOR_DVC:
        return <TaskCreateDetectorForm type={this.selectedTaskType} />;
      case TaskType.GENERATE_DATASET:
        return <TaskDatasetGeneratorForm type={this.selectedTaskType} />;
      default:
        return <div />;
    }
  }
}

export default TaskPage;
