import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './ModalLayout.module.scss';

import { ModalProps } from 'stores/ModalStore';

import Controls, {
  ModalControlsProps,
} from 'components/Modals/Controls/Controls';
import Spinner from 'components/Spinner/Spinner';

interface Props extends ModalProps {
  title: string;
  className?: string;
  loading?: boolean;
  controls: ModalControlsProps;
}

@observer
class ModalLayout extends Component<Props> {
  render() {
    const {
      onClose,
      title,
      className,
      children,
      loading,
      controls,
      ...props
    } = this.props;

    return (
      <Modal
        {...props}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        className={className}
        onClose={onClose}
      >
        {loading && <Spinner contain />}

        <h2 className={style.title}>
          <FormattedMessage id={title} />
        </h2>

        {children}

        <Controls {...controls} />
      </Modal>
    );
  }
}

export { ModalLayout };
