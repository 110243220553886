import axios from 'axios';

import {
  httpErrorHandler,
  internalServerErrorInterceptor,
  setAuthInterceptor,
  unauthorizedInterceptor,
  updateAuthInterceptor,
} from './interceptors';
import settings from 'settings';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  baseURL: settings.baseUrl,
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  cancelToken: source.token,
});

instance.interceptors.request.use(setAuthInterceptor);
instance.interceptors.response.use(undefined, updateAuthInterceptor);
instance.interceptors.response.use(undefined, unauthorizedInterceptor);
instance.interceptors.response.use(undefined, internalServerErrorInterceptor);
instance.interceptors.response.use(undefined, httpErrorHandler);

export { instance, source };
