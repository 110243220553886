import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from './AssignModal.module.scss';

import Button from 'components/Button/Button';
import TagWithText from 'components/Tag/withText';

import AssignTags from 'stores/Modals/AssignTags';

interface Props {
  store: AssignTags;
}

@observer
class Profile extends Component<Props> {
  render() {
    const { store } = this.props;

    return (
      <div className={style.profile}>
        <h3 className={style.profileTitle}>
          <FormattedMessage id="modal.assign.title" />
        </h3>

        <div className={style.userData}>
          <p className={style.userName}>{store.nickname}</p>
          <p className={style.userEmail}>{store.email}</p>
        </div>

        <Button
          className={style.btn}
          onClick={store.scanTag}
          disabled={store.loading}
        >
          <FormattedMessage id="modal.assign.profile.btn.scan" />
        </Button>

        <div className={style.tags}>
          <TagWithText type="rfid" tag={store.rfid} className={style.tag} />
        </div>

        <Button
          className={style.btn}
          onClick={store.assignTags}
          disabled={store.loading || !store.rfid.value}
        >
          <FormattedMessage id="modal.assign.profile.btn.assign" />
        </Button>
      </div>
    );
  }
}

export default Profile;
