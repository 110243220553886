import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './AssignModal.module.scss';

import Icon from 'components/Icon/Icon';

const Information = () => (
  <div className={style.information}>
    <div className={style.iconWrapper}>
      <Icon type="assignTags" />
    </div>

    <p className={style.infoText}>
      <FormattedMessage id="modal.assign.info.text.part1" />
    </p>
    <p className={style.infoText}>
      <FormattedMessage id="modal.assign.info.text.part2" />
    </p>
  </div>
);

export default Information;
