import URLS from 'http/urls';
import http from 'http/http';
import { api } from '../helpers';
import { Catering } from 'stores/Menu/types';

export const cateringApi = {
  fetchCatering() {
    return http.get<Catering>(api(URLS.catering.currentCatering));
  },

  fetchCaterings() {
    return http.get<Catering[]>(api(URLS.catering.caterings));
  },
};
