import React, { FC } from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from '../Fields.module.scss';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  invalid?: boolean;
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextField: FC<Props> = props => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange && props.onChange(event.target.value, event);

  const { invalid, ...rest } = props;

  return (
    <input
      {...rest}
      className={classNames(style.field, props.className, {
        [style.field__invalid]: invalid,
      })}
      onChange={onChange}
    />
  );
};

export default TextField;
