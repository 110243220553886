import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Icon from '../Icon/Icon';

export const withArrow = (id: string, className?: string) => (
  <div className="table-header">
    <div className={classNames('header-name', className)}>
      <FormattedMessage id={id} />
    </div>

    <Icon type="arrow" className="header-arrow" />
  </div>
);
