import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from '../Groups.module.scss';

import Spinner from 'components/Spinner/Spinner';
import Button from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import TimeRange from 'components/Form/Fields/TimeRange/TimeRange';

import { ModalProps } from 'stores/ModalStore';
import LineForm from 'stores/Forms/LineForm';

interface Props extends ModalProps {
  id?: string;
}

@observer
class MenuLineModal extends Component<Props> {
  form = new LineForm(this.props.id);
  formControl = bindFormControl(this.form);

  async componentDidMount() {
    if (this.props.id) {
      await this.form.fetchLine();
    }
  }

  onSubmit = async event => {
    event.preventDefault();

    const onFulfilled = payload => this.props.onSubmit!(payload);

    if (this.form.id) {
      await this.form.updateMenuLine(onFulfilled);
    } else {
      await this.form.createMenuLine(onFulfilled);
    }
  };

  render() {
    const FormControl = this.formControl;
    const { id, ...rest } = this.props;

    return (
      <Modal
        {...rest}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        className={style.modal}
        visible
      >
        {this.form.loading && <Spinner contain />}

        <h2 className={style.title}>
          <FormattedMessage
            id={this.form.id ? 'title.editLine' : 'title.newLine'}
          />
        </h2>

        <form name="line" className={style.form}>
          <FormControl
            name="title"
            className={style.control}
            render={props => <TextField {...props} className={style.model} />}
          />

          <div className={style.form__line}>
            <FormattedMessage id="title.online.ordering" />

            <FormControl
              name="onlineOrder"
              render={props => (
                <SwitchButton {...props} checked={this.form.onlineOrder} />
              )}
            />
          </div>

          <div className={style.form__line}>
            <FormattedMessage id="title.prioritized" />

            <FormControl
              name="prioritized"
              render={props => (
                <SwitchButton {...props} checked={this.form.prioritized} />
              )}
            />
          </div>

          <div className={style.form__section}>
            <h3 className={style.form__section__title}>
              <FormattedMessage id="modal.menuline.time.restriction" />
            </h3>

            <div className={style.form__line}>
              <FormControl
                name="timeOnlineOrder"
                render={props => (
                  <TimeRange {...props} disabled={!this.form.onlineOrder} />
                )}
              />
            </div>
          </div>
        </form>

        <div className={style.controls}>
          <Button
            className={style.btn}
            styleType="ghost"
            onClick={this.props.onClose}
          >
            <FormattedMessage id="button.cancel" />
          </Button>

          <Button
            className={style.btn}
            type="submit"
            onClick={this.onSubmit}
            disabled={!this.form.isFormValid}
          >
            <FormattedMessage
              id={this.form.id ? 'button.save' : 'button.create'}
            />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default MenuLineModal;
