import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classNames from 'classnames';

import 'react-lazy-load-image-component/src/effects/blur.css';
import style from './Gallery.module.scss';

import { ISample } from 'stores/Recognition/types';
import DatasetStore from 'stores/Recognition/DatasetStore';
import ModalStore from 'stores/ModalStore';

import DatasetSamplesModal from 'components/Modals/DatasetSamples/DatasetSamplesModal';
import WarningModal from 'components/Modals/Warning/WarningModal';
import Icon from 'components/Icon/Icon';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';

interface Props {
  sample: ISample;
  store?: DatasetStore;
}

@inject('store')
@observer
class Sample extends Component<Props> {
  onSampleDelete = id => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    await this.props.store!.deleteSample(id);
  };

  onSampleEdit = id => async () => {
    const { store } = this.props;
    const initialIndex =
      this.props.store!.filteredSamples.findIndex(el => el.id === id) || 0;

    await ModalStore.showModal(DatasetSamplesModal, {
      initialIndex,
      samples: store!.filteredSamples,
      menuItems: store!.menuItems,
    });

    store!.updateSamples();
  };

  onSampleCheck = id => () => {
    this.props.store!.toggleCheckedSample(id);
  };

  render() {
    const { sample, store } = this.props;
    const isChecked = store!.checkedSamples.includes(sample.id);
    return (
      <div
        className={classNames(style.sample, {
          [style.sample__checked]: isChecked,
        })}
      >
        <LazyLoadImage
          src={sample.thumbnail}
          alt={sample.name}
          wrapperClassName={style.image__wrapper}
          className={style.image}
          effect="blur"
          onClick={this.onSampleEdit(sample.id)}
        />

        {store!.multiMode ? (
          <CheckboxField
            className={{
              wrapper: style.sample__checkbox,
              icon: style.sample__checkbox__icon,
            }}
            type="green"
            checked={isChecked}
            onChange={this.onSampleCheck(sample.id)}
          />
        ) : (
          <Icon
            type="trash"
            className={style.icon__delete}
            onClick={this.onSampleDelete(sample.id)}
          />
        )}
      </div>
    );
  }
}

export default Sample;
