import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isTimeRange(
  required = {
    from: true,
    to: true,
  },
  message = 'VM_IS_TIME_RANGE',
) {
  const regEx = /^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/;
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        const isTimeValuesValid = obj[fieldName]?.every?.(
          time => !time || regEx.test(time),
        );
        const isRangeValid = ((): boolean => {
          const timeFrom = obj[fieldName][0];
          const timeTo = obj[fieldName][1];
          const validRange = timeFrom && timeTo && timeFrom < timeTo;

          if (required.from && required.to) {
            return validRange;
          } else if (required.from) {
            return timeTo ? validRange && !!timeFrom : !!timeFrom;
          } else if (required.to) {
            return timeFrom ? validRange && !!timeTo : !!timeTo;
          } else {
            return timeFrom && timeTo ? validRange : true;
          }
        })();
        const isValid = isTimeValuesValid && isRangeValid;

        return isValid
          ? undefined
          : {
              message: !isTimeValuesValid ? 'VM_IS_TIME' : message,
              data: {
                name,
                value,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
