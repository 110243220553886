import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './StaffList.module.scss';
import 'react-table/react-table.css';

import Button from 'components/Button/Button';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Icon from 'components/Icon/Icon';
import StaffModal from 'components/Modals/Staff/StaffModal';
import Table from 'components/Table/Table';

import config from './columnsConfig';

import StaffStore from 'stores/Staff/StaffStore';
import ModalStore from 'stores/ModalStore';

@observer
class StaffList extends Component {
  store = new StaffStore();

  async componentDidMount() {
    await this.store.fetchStaff();
  }

  openStaffModal = (id?) => async () => {
    const { payload } = await ModalStore.showModal(StaffModal, { id });

    this.store.updateStaff(payload);
  };

  deleteStaff = id => async () => {
    await this.store.deleteStaff(id);
  };

  onSearch = value => {
    this.store.searchValue = value;
  };

  render() {
    const { searchValue, filteredStaff, loading } = this.store;

    return (
      <div className={style.content}>
        <div className={style.row}>
          <Button className={style.btn} onClick={this.openStaffModal()}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.newUser" />
          </Button>

          <SearchField
            className={style.search}
            onChange={this.onSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={filteredStaff}
          columns={config({
            onEdit: this.openStaffModal,
            onDelete: this.deleteStaff,
          })}
          loading={loading}
        />
      </div>
    );
  }
}

export default StaffList;
