import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Tag.module.scss';

import Tag, { AppTag, TagProps, TagStatus } from './Tag';

interface Props extends TagProps {
  tag: AppTag;
}

const getTagText = (status: TagStatus, messageId: string) => {
  switch (status) {
    case TagStatus.available:
      return (
        <p className={classNames(style.tagText, style.tagText__available)}>
          <FormattedMessage id={messageId} />
        </p>
      );
    case TagStatus.error:
      return (
        <p className={classNames(style.tagText, style.tagText__error)}>
          <FormattedMessage id={messageId} />
        </p>
      );
    default:
      return (
        <p className={classNames(style.tagText)}>
          <FormattedMessage id={messageId} />
        </p>
      );
  }
};

const withText = Component => {
  return class extends React.Component<Props> {
    render() {
      const { status = TagStatus.default, messageId = '' } = this.props.tag;

      return (
        <div className="app-tag">
          <Component {...this.props} status={status} />

          {getTagText(status, messageId)}
        </div>
      );
    }
  };
};

export default withText(Tag);
