import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { IRevenueReportTotal } from 'stores/Report/types';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import style from './ReportSummary.module.scss';
import classNames from 'classnames';

@observer
class ReportSummary extends Component<{ values: IRevenueReportTotal }> {
  render() {
    const { values } = this.props;

    return (
      <div className={style.summary__wrapper}>
        <h2 className={style.summary__title}>
          <FormattedMessage id="title.summary" />
        </h2>

        <div className={style.summary__list}>
          <div className={style.summary__item}>
            <FormattedMessage id="summary.ordered.items" />

            <div className={style.item__value}>{values.amount}</div>

            <FormattedMessage id="summary.receiptsNumber" />

            <div className={style.item__value}>{values.receiptsNumber}</div>

            <FormattedMessage id="summary.selfCheckoutsNumber" />

            <div className={style.item__value}>
              {values.selfCheckoutsNumber}
            </div>
          </div>

          <div className={style.summary__item}>
            <FormattedMessage id="summary.brutto" />

            <div>
              <div
                className={classNames(style.item__value, style.value__total)}
              >
                <FormattedCurrency value={values.brutto} />
              </div>

              <div
                className={classNames(
                  style.item__value,
                  style.value__vatAmount,
                )}
              >
                {Object.entries(values.bruttoByPercentage).map(
                  ([vatPercent, amount]) => {
                    return (
                      <Fragment key={vatPercent}>
                        <div>
                          <FormattedCurrency
                            value={Number(vatPercent)}
                            withSymbol={false}
                          />
                          %
                        </div>

                        <span className={style.item__value__divider}>-</span>

                        <FormattedCurrency value={amount} />
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>

          <div className={style.summary__item}>
            <FormattedMessage id="summary.vat.amount" />

            <div>
              <div
                className={classNames(style.item__value, style.value__total)}
              >
                <FormattedCurrency value={values.vatAmount} />
              </div>

              <div
                className={classNames(
                  style.item__value,
                  style.value__vatAmount,
                )}
              >
                {Object.entries(values.vatByPercentage).map(
                  ([vatPercent, amount]) => {
                    return (
                      <Fragment key={vatPercent}>
                        <div>
                          <FormattedCurrency
                            value={Number(vatPercent)}
                            withSymbol={false}
                          />
                          %
                        </div>

                        <span className={style.item__value__divider}>-</span>

                        <FormattedCurrency value={amount} />
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>

          <div className={style.summary__item}>
            <FormattedMessage id="summary.netto" />

            <div>
              <div
                className={classNames(style.item__value, style.value__total)}
              >
                <FormattedCurrency value={values.netto} />
              </div>

              <div
                className={classNames(
                  style.item__value,
                  style.value__vatAmount,
                )}
              >
                {Object.entries(values.nettoByPercentage).map(
                  ([vatPercent, amount]) => {
                    return (
                      <Fragment key={vatPercent}>
                        <div>
                          <FormattedCurrency
                            value={Number(vatPercent)}
                            withSymbol={false}
                          />
                          %
                        </div>

                        <span className={style.item__value__divider}>-</span>

                        <FormattedCurrency value={amount} />
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          </div>

          <div className={style.summary__item}>
            <FormattedMessage id="summary.discountsAmount" />

            <div className={style.item__value}>
              {values.discounts.number}x |{' '}
              <FormattedCurrency value={values.discounts.amount} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportSummary;
