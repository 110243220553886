import React from 'react';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';
import Controls from 'components/Table/Controls';

const config = ({ onEdit, onDelete }): Column[] => [
  {
    Header: withArrow('title.name'),
    accessor: 'firstName',
    resizable: false,
    minWidth: 228,
  },
  {
    Header: withArrow('title.surname'),
    accessor: 'lastName',
    resizable: false,
    minWidth: 200,
  },
  {
    Header: withArrow('title.role'),
    accessor: 'roles[0].name',
    resizable: false,
    minWidth: 120,
  },
  {
    Header: withArrow('title.email'),
    accessor: 'email',
    resizable: false,
    minWidth: 200,
  },
  {
    id: 'operations',
    resizable: false,
    minWidth: 64,
    className: 'operations',
    Cell: row => (
      <Controls
        onEdit={onEdit(row.original.id)}
        onDelete={onDelete(row.original.id)}
      />
    ),
  },
];

export default config;
