import { observable } from 'mobx';

import { BaseFormModel } from '../BaseForm';

class DescriptionForm extends BaseFormModel {
  @observable
  description;

  constructor(initialDescription: string = '') {
    super();

    this.description = initialDescription;
  }
}

export default DescriptionForm;
