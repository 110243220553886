import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import style from './SubsidyModal.module.scss';

import ModalStore, { DialogActionType, ModalProps } from 'stores/ModalStore';
import { Discount } from 'stores/Subsidies/types';
import { DishGroupManagement } from 'stores/Menu/types';
import SubsidyModalStore from 'stores/Modals/SubsidyModalStore';

import TagsSelect from 'components/TagsSelect/TagsSelect';
import Button from 'components/Button/Button';
import TooltipComponent from 'components/Tooltip/Tooltip';
import SubsidyFormModal from './SubsidyFormModal';

interface Props extends ModalProps {
  discount?: Discount;
  userGroupsWithSubsidies: string[];
  cbSubmit: (payload: Discount) => void;
}

@observer
class SubsidyModal extends Component<Props> {
  store = new SubsidyModalStore(
    this.props.discount,
    this.props.userGroupsWithSubsidies,
  );

  async componentDidMount() {
    await this.store.init();
  }

  onSetStep = (symbol: string) => {
    this.store.setStep(symbol);
  };

  @computed
  get categoriesTags() {
    return this.store.categories.map(it => ({
      ...it,
      noted: it.itemsCount !== it.subsidizedItemsCount,
    }));
  }

  @computed
  get selectedCategoriesTags() {
    return this.store.selectedCategories.map(it => ({
      ...it,
      noted: it.itemsCount !== it.subsidizedItemsCount,
    }));
  }

  itemChildren = (tag: DishGroupManagement) => {
    return (
      tag?.subsidizedItemsCount >= 0 && (
        <>
          <div className={style.note}>
            <TooltipComponent
              content={
                <span>
                  <FormattedMessage id="modal.discount.hint" />
                </span>
              }
            >
              <span>{`${tag.subsidizedItemsCount}/${tag.itemsCount}`}</span>
            </TooltipComponent>
          </div>
        </>
      )
    );
  };

  render() {
    const { onClose, ...props } = this.props;
    if (this.store.loading) {
      return null;
    }

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={() => ModalStore.closeModal(DialogActionType.close)}
        visible
      >
        <h2 className={style.title}>
          <FormattedMessage
            id={
              !this.props.discount?.id ? 'title.new.subsidy' : 'title.subsidy'
            }
          />
        </h2>
        <>
          {this.store.step === 0 && (
            <>
              <div className={style.body}>
                {this.store.filteredUserGroups &&
                this.store.filteredUserGroups.length === 0 &&
                !this.store.id ? (
                  <div className={style.description}>
                    <FormattedMessage id={'modal.discount.noUserGruppeExist'} />
                  </div>
                ) : (
                  <>
                    <h3 className={style.description}>
                      <FormattedMessage id="title.new.subsidy.description" />
                    </h3>
                    <TagsSelect
                      title="title.discounts.user.group"
                      tags={this.store.filteredUserGroups}
                      selectedTags={
                        this.store.selectedUserGroup
                          ? [this.store.selectedUserGroup]
                          : []
                      }
                      onSubmit={this.store.selectUserGroup}
                      onRemoveTag={this.store.removeUserGroup}
                      isShowSearchField={true}
                      isShowTags={false}
                      inputTypes={'radio'}
                    />
                    <TagsSelect
                      title="title.dishCategory.optional"
                      tags={this.categoriesTags}
                      selectedTags={this.selectedCategoriesTags || []}
                      onSubmit={this.store.selectCategories}
                      onRemoveTag={this.store.removeCategories}
                      isShowSelectAll={true}
                      isShowSearchField={true}
                      isShowTags={false}
                      titleNotSelected={
                        !this.store.selectedCategories?.length && this.store.id
                          ? 'title.discounts.apply.to.allCategories'
                          : ''
                      }
                      className={{
                        selectedTag: style.selectedLabel,
                      }}
                      itemChild={this.itemChildren}
                    />
                    {this.store?.selectedCategories?.length > 0 &&
                      this.store?.selectedCategories?.some?.(
                        category =>
                          category?.subsidizedItemsCount !==
                          category?.itemsCount,
                      ) && (
                        <div className={style.info}>
                          <FormattedMessage
                            id="modal.discount.info.note"
                            tagName={'p'}
                          />
                          <FormattedMessage id="modal.discount.info.message" />
                        </div>
                      )}
                  </>
                )}
              </div>
              <div className={style.form__controls}>
                <Button
                  className={style.btn}
                  styleType="ghost"
                  onClick={onClose}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>

                <Button
                  className={style.btn}
                  onClick={() => this.onSetStep('+')}
                  disabled={!this.store?.selectedUserGroup}
                >
                  <FormattedMessage id={'button.set.amount'} />
                </Button>
              </div>
            </>
          )}

          {(this.store.step === 1 || this.store.step === 2) && (
            <SubsidyFormModal
              discount={this.props.discount}
              onSummary={() => this.onSetStep('+')}
              step={this.store.step}
              selectedCategories={this.store.selectedCategories}
              activeUserGroup={this.store.selectedUserGroup}
              onCancel={() => this.onSetStep('-')}
              onClose={onClose}
              cbSubmit={this.props.cbSubmit}
            />
          )}
        </>
      </Modal>
    );
  }
}

export default SubsidyModal;
