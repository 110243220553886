import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './Basket.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';
import BasketModal from 'components/Modals/Basket/BasketModal';

import ModalStore from 'stores/ModalStore';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';

interface Props {
  store?: UserProfileOrder;
}

@inject('store')
@observer
class Basket extends Component<Props> {
  openBasketModal = () => {
    ModalStore.showModal(BasketModal, {
      store: this.props.store,
    });
  };

  render() {
    const { store } = this.props;

    return (
      <div className={style.basket} onClick={this.openBasketModal}>
        <div className={style.icon__wrapper}>
          <Icon type="basket" className={style.basket__icon} />

          <FormattedMessage id="title.basket" />
        </div>

        <div className={style.basket__price}>
          <FormattedCurrency value={store!.totalPrice} />
        </div>
      </div>
    );
  }
}

export default Basket;
