import http from '../http';
import { api, arrayParamsSerializer } from 'http/helpers';
import URLS from 'http/urls';

import { Employee } from 'stores/Employees/types';

import { CurrencyValue } from 'types/general';

import {
  PageableListResponse,
  PageableListRequestParams,
  PaymentQR,
} from 'http/types';

interface EmployeesResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  nfc?: boolean;
  rfid?: boolean;
  refund?: boolean;
  balance?: CurrencyValue;
}

export interface CardDepositInfo {
  amount: number;
  mandatory: boolean;
}

export type EmployeesListResponse = PageableListResponse<Employee>;

export const employeesApi = {
  fetchQRPaymentData(id: string) {
    return http.put<{}, PaymentQR>(
      api(URLS.employees.paymentQR),
      {},
      { replacePathParams: { id } },
    );
  },

  fetchAccountantEmployees(params: PageableListRequestParams) {
    return http.get<EmployeesListResponse>(api(URLS.employees.accountant), {
      paramsSerializer: arrayParamsSerializer,
      params,
    });
  },

  fetchHREmployees(params: PageableListRequestParams) {
    return http.get<EmployeesListResponse>(api(URLS.employees.employees), {
      paramsSerializer: arrayParamsSerializer,
      params,
    });
  },

  findEmployeeByNFC(nfc: string) {
    return http.get<EmployeesResponse>(api(URLS.employees.NFC), {
      replacePathParams: { nfc },
    });
  },

  findEmployeeByRFID(rfid: string) {
    return http.get<EmployeesResponse>(api(URLS.employees.RFID), {
      replacePathParams: { rfid },
      skipHttpErrorHandler: true,
    });
  },

  fetchEmployeeByQR(qr: string) {
    return http.get<EmployeesResponse>(api(URLS.employees.QR), {
      replacePathParams: { qr },
    });
  },

  fetchCardDepositInfoByEmployeeId(id: string) {
    return http.get<CardDepositInfo>(api(URLS.employees.cardDeposit), {
      replacePathParams: { id },
    });
  },
};
