import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Campaign.module.scss';

import CampaignForm, { CAMPAIGN_LIMITS } from 'stores/Forms/CampaignForm';

import Button from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import NumberInput from 'components/Form/Fields/Number/NumberInput';

interface Props {
  form: CampaignForm;
  onClose: () => void;
  onSubmit: (event) => void;
}

@observer
class ModalForm extends Component<Props> {
  formControl = bindFormControl(this.props.form);

  render() {
    const { onSubmit, onClose, form } = this.props;
    const FormControl = this.formControl;

    return (
      <form className={style.form__section} onSubmit={onSubmit}>
        <h2 className={style.form__title}>
          <FormattedMessage id="modal.campaign.title" />
        </h2>

        <div className={style.form__body}>
          <div className={style.form__row}>
            <FormControl
              name="title"
              render={props => <TextField {...props} />}
            />
          </div>

          <div className={style.form__row}>
            <h3 className={style.form__label}>
              *<FormattedMessage id="modal.campaign.user.group" />
            </h3>

            <p className={style.form__value}>{form.userGroup?.title || '-'}</p>
          </div>

          <div className={classNames(style.form__row, style.row__duration)}>
            <h3 className={style.form__label}>
              <FormattedMessage id="modal.campaign.unlimited" />
            </h3>

            <FormControl
              name="unlimited"
              render={props => (
                <SwitchButton {...props} checked={form.unlimited} />
              )}
            />
          </div>

          <div className={style.form__row}>
            <h3 className={style.form__label}>
              <FormattedMessage id="modal.campaign.duration" />
            </h3>

            <FormControl
              name="period"
              render={props => (
                <Datepicker
                  {...props}
                  styles={{ calendar: style.datepicker }}
                  value={[form.startDate, form.endDate]}
                  selectRange={!form.unlimited}
                  showPeriods={false}
                  notEndDateContent={' - ...'}
                />
              )}
            />
          </div>

          <div className={style.form__row}>
            <FormControl
              name="limit"
              render={props => (
                <NumberInput
                  {...props}
                  className={style.participants}
                  max={CAMPAIGN_LIMITS.maxParticipants}
                />
              )}
            />
          </div>
        </div>

        <div className={style.form__controls}>
          <Button className={style.btn} styleType="ghost" onClick={onClose}>
            <FormattedMessage id="button.cancel" />
          </Button>

          <Button
            className={style.btn}
            styleType="primary"
            type="submit"
            disabled={!form.isFormValid}
          >
            <FormattedMessage id={form.id ? 'button.save' : 'button.create'} />
          </Button>
        </div>
      </form>
    );
  }
}

export default ModalForm;
