import React from 'react';
import style from './AdditionalInformation.module.scss';
import { FormattedMessage } from 'react-intl';
import Specifications from '../AdditionalSpecifications/AdditionalSpecifications';

export const AdditionalInformation: React.FC = () => {
  return (
    <section className={style.additional}>
      <div className={style.additional__wrapper}>
        <h2 className={style.additional__title}>
          <FormattedMessage id="form.dish.additional.information.title" />
        </h2>
      </div>
      <Specifications />
    </section>
  );

  // Ad block temporarily disabled

  // ) : (
  //   <section className={style.wrapper}>
  //     <div className={style.description__block}>
  //       <h2 className={style.main__title}>
  //         <FormattedMessage id="form.dish.additional.information.title" />
  //       </h2>
  //       <div className={style.description}>
  //         <FormattedMessage id="form.dish.additional.description" />
  //       </div>
  //     </div>
  //     <div className={style.subscription__block}>
  //       <h3 className={style.subscription__title}>
  //         <FormattedMessage id="form.dish.additional.subscription.title" />
  //       </h3>
  //       <h3 className={style.subscription__title__base}>
  //         <FormattedMessage id="form.dish.additional.subscription.base.plus" />
  //       </h3>
  //       {/*ToDo: Should be uncomment after BE realisation*/}
  //
  //       {/* <Button className={style.btn__details} styleType="primary">
  //         <FormattedMessage id={'form.dish.additional.button.details'} />
  //       </Button>*/}
  //     </div>
  //   </section>
  // );
};
