import { action, computed, observable, reaction } from 'mobx';

import httpFacade from 'http/httpFacade';
import {
  CreateUpdateMenuItemDto,
  MenuItemLabel,
} from 'http/Api/MenuItem/types';

import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isRequired,
} from 'stores/BaseForm';
import MenuItemLabelsStore from 'stores/MenuItems/MenuItemLabelsStore';
import RootStore from 'stores/RootStore';
import ModalStore from '../ModalStore';
import AppRouter from '../AppRouter';

import {
  AllergenType,
  DishGroupManagement,
  ExtendedAvailable,
  DishAspect,
  Specification,
  DishItem,
} from '../Menu/types';

import noop from 'helpers/noop';
import Log from 'helpers/log';
import { SortConfig } from 'helpers/types';
import { sortByAccessor } from 'helpers/accessors';

import WarningModal from 'components/Modals/Warning/WarningModal';
import { validVatRate } from '../Subsidies/types';
import UserStore from '../UserStore/UserStore';
import { Roles } from 'helpers/roles';
import { isAnySelected } from 'stores/BaseForm/validators/isAnySelected';

export const MENU_ITEM_LIMITS = {
  maxTitleLength: 200,
  maxDescriptionLength: 150,
  maxGR: 100,
  maxPercent: 100,
};

class DishForm extends BaseFormModel {
  @observable labelsManager = new MenuItemLabelsStore();

  @observable specs: Specification[] = [];
  @observable aspects: DishAspect[] = [];
  @observable allergens: AllergenType[] = [];
  @observable vats: Array<{ label: string; value: number }> = [];
  @observable depositItems: DishItem[] = [];
  @observable isExtendedAvailable: ExtendedAvailable = { enabled: false };

  @observable baseSpecifications: Specification[] = [];
  @observable itemSpecs: string[] = [];
  @observable itemAllergens: string[] = [];

  @observable
  @displayName('CATEGORY*')
  @isRequired('')
  category = '';

  @observable
  @displayName('DESCRIPTION')
  @displayName('')
  description = '';

  @observable
  @displayName('DISH_NAME*')
  @isRequired()
  @hasMaxLength(MENU_ITEM_LIMITS.maxTitleLength)
  title = '';

  @observable
  @displayName('PRICE*')
  @isRequired(undefined, 'price')
  price: number;

  @observable
  @displayName('vatRateWithHint')
  @isAnySelected()
  vatRates: number[] = [];

  @observable
  subsidized = false;

  @observable
  @displayName('DEPOSIT_ITEM')
  depositItemId = '';

  @observable
  @displayName('DISH_PREVIEW')
  image: FormData;

  @observable
  @displayName('KCAL')
  kcal = 0;

  @observable
  @displayName('SUGAR')
  sugar = 0;

  @observable
  @displayName('CARBS')
  carbs = 0;

  @observable
  @displayName('PROTEIN')
  protein = 0;

  @observable
  @displayName('FAT')
  fat = 0;

  @observable
  co2 = 0;

  @observable ingredients = {};

  @observable quickAccess = false;

  @observable labels: MenuItemLabel[] = [];

  @observable loading = false;

  @observable dishImageUrl: string = '';

  readonly id: string;
  @observable user = new UserStore();

  @observable notVisibleKioskSelfCheckout = false;

  @observable private _categories: DishGroupManagement[] = [];
  private defaultCategoriesSort: SortConfig = {
    accessor: 'label',
    desc: false,
  };

  private defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  @computed
  get categories() {
    return this._categories
      .map(it => ({
        label: it.title,
        value: it.id,
      }))
      .sort(sortByAccessor(this.defaultCategoriesSort));
  }

  @computed
  get isDepositItemsCategory() {
    return this._categories.some(
      category => category.id === this.category && category.forDepositItems,
    );
  }

  @computed
  get isDisabledSubsidyField() {
    return this.vatRates.includes(validVatRate);
  }

  @computed
  get sortedAllergens() {
    return [...this.allergens].sort(sortByAccessor(this.defaultSortByTitle));
  }

  @computed
  get depositItemsValues(): Array<{ label: string; value: string }> {
    return [
      {
        label: RootStore.localization.formatMessage('title.noDepositItem'),
        value: '',
      },
      ...this.depositItems.map(item => ({
        value: item.id || '',
        label: item.title,
      })),
    ];
  }

  @computed
  get dto(): CreateUpdateMenuItemDto {
    return {
      title: this.title,
      dishGroup: this.category,
      vatRates: this.vatRates,
      price: this.price,
      subsidized: this.subsidized,
      quickAccess: this.quickAccess,
      notVisibleKioskSelfCheckout: this.notVisibleKioskSelfCheckout,
      specification: this.itemSpecs,
      ingredients: {
        kcal: this.kcal,
        protein: this.protein,
        fat: this.fat,
        sugar: this.sugar,
        carbs: this.carbs,
      },
      co2: this.co2,
      description: this.description,
      allergens: this.itemAllergens,
      labels: this.labels.map(label => label.id),
      ...(this.depositItemId ? { depositItemId: this.depositItemId } : {}),
    };
  }

  constructor(id = '') {
    super();

    this.id = id;
    reaction(
      () => this.vatRates,
      () => {
        if (this.isDisabledSubsidyField) {
          this.subsidized = false;
        }
      },
    );
  }

  @action.bound
  async fetchCategories() {
    try {
      this.loading = true;

      ({ data: this._categories } = await httpFacade.menu.fetchCategories());
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      [
        { data: this._categories },
        { data: this.vats },
        { data: this.baseSpecifications },
        { data: this.allergens },
        { data: this.isExtendedAvailable },
        { data: this.depositItems },
      ] = await Promise.all([
        httpFacade.menu.fetchCategories(),
        httpFacade.menu.fetchVats(),
        httpFacade.menu.fetchBaseSpecifications(),
        httpFacade.menuItems.fetchAllergens(),
        httpFacade.menu.defineIsExtendedAvailable(),
        httpFacade.menuItems.fetchDepositItems(),
        this.labelsManager.init(),
      ]);
      if (this.id) {
        let menuItemData;
        let imageUrl;
        [{ data: imageUrl }, { data: menuItemData }] = await Promise.all([
          httpFacade.menuItems.getImage(this.id),
          httpFacade.menuItems.fetchMenuItem(this.id),
        ]);
        this.dishImageUrl = imageUrl;
        this.category = menuItemData.dishGroup;
        this.title = menuItemData.title;
        this.itemSpecs = [
          ...menuItemData.specification,
          ...menuItemData.healthAspects,
        ];
        this.itemAllergens = menuItemData.allergens.map(it => it.id);
        this.description = menuItemData.description ?? '';
        this.price = menuItemData.price;
        this.subsidized = menuItemData.subsidized ?? false;
        this.quickAccess = menuItemData.quickAccess ?? false;
        this.notVisibleKioskSelfCheckout =
          menuItemData.notVisibleKioskSelfCheckout ?? false;
        this.vatRates = menuItemData.vatRates;
        this.kcal = menuItemData.ingredients?.kcal ?? 0;
        this.sugar = menuItemData.ingredients?.sugar ?? 0;
        this.carbs = menuItemData.ingredients?.carbs ?? 0;
        this.protein = menuItemData.ingredients?.protein ?? 0;
        this.fat = menuItemData.ingredients?.fat ?? 0;
        this.co2 = menuItemData.co2 ?? 0;
        this.ingredients = menuItemData.ingredients ?? {};
        this.depositItemId = menuItemData.depositItemId ?? '';
        this.labels = menuItemData.labels;
      }
      if (this.isExtendedAvailable.enabled) {
        const { data } = await httpFacade.menu.fetchAdditionalSpecifications();
        this.specs = data;
      }
    } catch (error) {
      Log.info(error);
      AppRouter.goBack();
    } finally {
      this.loading = false;
    }
  }

  @computed
  get sortedLabels() {
    return [...this.labels].sort(sortByAccessor(this.defaultSortByTitle));
  }

  @action.bound
  async submit(onSuccess = noop) {
    try {
      if (this.id) {
        await ModalStore.showModal(WarningModal, {
          description: 'modal.warning.change.item',
          type: 'change',
        });

        this.loading = true;
        if (this.image && !this.dishImageUrl) {
          this.fetchImage(this.id);
        }
        if (
          RootStore.user.roles.length > 1 ||
          !RootStore.user.roles.includes(Roles.bayerRestrictedAdmin)
        ) {
          await httpFacade.menuItems.updateMenuItem(this.id, this.dto);
        }
      } else {
        this.loading = true;

        const response = await httpFacade.menuItems.createMenuItem(this.dto);
        const responseData = response.data as DishItem;
        if (responseData.id && this.image) {
          this.fetchImage(responseData.id);
        }
      }
      onSuccess();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchImage(id) {
    await fetch(`/api/hr/menuItems/${id}/images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.user.getAuthTokens().accessToken}`,
      },
      body: this.image,
    });
  }

  @action.bound
  async removeImage() {
    if (this.id && this.dishImageUrl) {
      await httpFacade.menuItems.deleteImage(this.id);
      this.dishImageUrl = '';
    }
  }

  @action.bound
  updateDishCategories(category: DishGroupManagement) {
    this._categories.push(category);
    this.category = category.id;
  }

  @action.bound
  toggleAllergen(type) {
    this.itemAllergens = this.itemAllergens.includes(type)
      ? this.itemAllergens.filter(it => it !== type)
      : [...this.itemAllergens, type];
  }

  @action.bound
  toggleSpec(id) {
    this.itemSpecs = this.itemSpecs.includes(id)
      ? this.itemSpecs.filter(it => it !== id)
      : [...this.itemSpecs, id];
  }

  @action.bound
  setLabels(data) {
    this.labels = data;
  }

  @action.bound
  unselectLabel(id?: string) {
    this.labels = id ? this.labels.filter(it => it.id !== id) : [];
  }

  @action.bound
  toggleVatRate(rate: number) {
    this.vatRates = this.vatRates.includes(rate)
      ? this.vatRates.filter(it => it !== rate)
      : [...this.vatRates, rate];
  }
}

export default DishForm;
