import { action, computed, observable } from 'mobx';
import { DateTime } from 'luxon';

import { getDateTimeWithLocale, getLocalDateTime } from 'helpers/datetime';

import { CopyMode, ICopyMenu } from 'stores/Menu/MenuCopy/types';
import { CreateMenuLineDto, MenuItem } from 'stores/Menu/types';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import { getDatesPeriod } from 'stores/Menu/MenuCopy/helpers';

export class CopyMenuFromWeekForm implements ICopyMenu {
  readonly copyMode = CopyMode.WEEK;

  @observable periodFrom: [DateTime, DateTime] = [
    getLocalDateTime().startOf('week'),
    getLocalDateTime().endOf('week'),
  ];
  @observable periodTo: [DateTime, DateTime] = [
    getLocalDateTime().startOf('week'),
    getLocalDateTime().endOf('week'),
  ];

  private menuPlanner: MenuPlannerStore;

  constructor(menuPlanner: MenuPlannerStore) {
    this.menuPlanner = menuPlanner;
  }

  @computed
  get copyOnPeriod(): string[] {
    const [periodStart, periodEnd] = this.periodTo;

    return getDatesPeriod(periodStart, periodEnd);
  }

  @action
  setPeriodFrom = (date: DateTime) => () => {
    this.periodFrom = [date.startOf('week'), date.endOf('week')];
  };

  @action.bound
  changePeriodFrom([date]: DateTime[]) {
    this.periodFrom = [date.startOf('week'), date.endOf('week')];
    this.menuPlanner.changeDate([date]);
  }

  @action.bound
  changePeriodTo([from, to]: DateTime[]) {
    this.periodTo = [from.startOf('week'), to.endOf('week')];
  }

  @computed
  get dataToCopy(): CreateMenuLineDto[] {
    return this.menuPlanner.plannerLines.map(plannerLine => {
      const itemsForWeek: {
        [key: number]: MenuItem[];
      } = plannerLine.days.reduce((acc, day, currentIndex) => {
        acc[currentIndex] = day.items;
        return acc;
      }, {});

      return {
        line: plannerLine.id,
        days: this.copyOnPeriod.map(date => ({
          date,
          items: itemsForWeek[getDateTimeWithLocale(date).weekday - 1].map(
            item => item.id,
          ),
        })),
      };
    });
  }

  @computed
  get isCopyDisabled(): boolean {
    return !this.dataToCopy.length;
  }

  @action.bound
  reset() {
    //
  }
}
