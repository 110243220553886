import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';

import { Mobile, Desktop } from 'types/layout';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import RootStore from 'stores/RootStore';
import { UserReport } from 'stores/UserProfile/UserReport';

import Tabs, { AppTab } from 'components/Tabs/Tabs';
import MenuLayout from './Menu/MenuLayout';
import Spinner from 'components/Spinner/Spinner';
import Basket from './Basket/Basket';
import Orders from './Orders';

import style from './OnlineOrder.module.scss';

@observer
class OnlineOrder extends Component<{ report: UserReport }> {
  store: UserProfileOrder = new UserProfileOrder(
    RootStore.user.id,
    this.props.report,
  );
  tabs: AppTab[] = [
    {
      component: <MenuLayout />,
      title: 'title.menu',
    },
    {
      component: <Orders />,
      title: 'title.orders.list',
    },
  ];

  async componentDidMount() {
    await this.store.init();
  }

  render() {
    return (
      <Provider store={this.store}>
        <main className={style.main}>
          {this.store.loading && <Spinner contain />}

          <Desktop>
            <MenuLayout />

            <Orders />
          </Desktop>

          <Mobile>
            <Tabs tabs={this.tabs} />

            {Boolean(this.store.orderMenu.length) && <Basket />}
          </Mobile>
        </main>
      </Provider>
    );
  }
}

export default OnlineOrder;
