import React from 'react';
import { Column } from 'react-table';
import style from '../Recognition.module.scss';
import { withArrow } from 'components/Table/helpers';
import Button from 'components/Button/Button';
import SwitchButton from '../../../components/Form/Fields/SwitchButton/SwitchButton';
import Icon from '../../../components/Icon/Icon';

const config = ({
  onDelete,
  switchState: onStateSwitch,
  onShowModelInfo,
}): Column[] => [
  {
    expander: true,
    width: 25,
    resizable: false,
    Expander: ({ isExpanded, ...rest }) => (
      <div>
        {rest.level === 0 ? (
          isExpanded ? (
            <Icon type={'arrowBottom'} />
          ) : (
            <Icon type={'arrowRight'} />
          )
        ) : (
          ''
        )}
      </div>
    ),
    className: style.expander,
  },
  {
    Header: withArrow('title.deployments'),
    resizable: true,
    minWidth: 200,
    Cell: row => {
      return row.level === 0 ? (
        <div className={style.name}>{row.original.name}</div>
      ) : (
        <div className={style.name} onClick={onShowModelInfo(row.original)}>
          {row.original.description}
        </div>
      );
    },
  },
  {
    Header: withArrow('title.models'),
    accessor: 'modelsCount',
    resizable: true,
    minWidth: 100,
    Cell: row => {
      return row.level === 0 ? (
        <div className={style.name}>{row.value}</div>
      ) : (
        ''
      );
    },
  },
  {
    Header: withArrow('title.state'),
    accessor: 'enabled',
    width: 160,
    resizable: false,
    Cell: row => {
      return row.level !== 0 ? (
        <SwitchButton
          onChange={(isChecked, e) => onStateSwitch(row.original, isChecked)}
          checked={row.value}
          className={style.control__switcher}
          isShowTitle
        />
      ) : (
        ''
      );
    },
  },
  {
    id: 'operations',
    resizable: false,
    width: 50,
    sortable: false,
    className: 'operations',
    Cell: row => {
      return row.level !== 0 ? (
        <Button
          styleType="text"
          key={row.original.id}
          className={style.action__icon}
          onClick={onDelete(row.original)}
        >
          <Icon type="trash" />
        </Button>
      ) : (
        ''
      );
    },
  },
];

export default config;
