import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import style from './AppLink.module.scss';

interface Props {
  to: string | object;
  className?: string;
  activeClass?: string;
  children?: React.ReactNode;
}

const AppLink = (props: Props) => {
  const { to, className, activeClass } = props;

  return (
    <NavLink
      to={to}
      activeClassName={classNames(activeClass)}
      className={classNames(style.appLink, className)}
    >
      {props.children}
    </NavLink>
  );
};

export default AppLink;
