import React, { Component } from 'react';
import { observer } from 'mobx-react';

import RevenueReportForm from 'stores/Forms/Reports/RevenueReportForm';
import ItemReportStore from 'stores/Report/ItemReportStore';

import style from './ItemReport.module.scss';
import Spinner from 'components/Spinner/Spinner';
import RevenueForm from '../RevenueReport/RevenueForm';
import ReportTable from './ReportTable';

@observer
class ItemReportPage extends Component {
  store = new ItemReportStore();
  form = new RevenueReportForm();

  async componentDidMount() {
    await Promise.all([
      this.form.init(),
      this.store.getReport(this.form.requestParams),
    ]);
  }

  render() {
    const { report } = this.store;

    return (
      <section className={style.main}>
        {(this.store.loading || this.form.loading) && <Spinner contain />}

        <RevenueForm form={this.form} store={this.store} />

        {report && (
          <ReportTable
            report={report}
            tableStyle={style.table__single}
            store={this.store}
          />
        )}
      </section>
    );
  }
}

export default ItemReportPage;
