import { action, computed, observable, runInAction } from 'mobx';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';

import AppRouter from 'stores/AppRouter';
import { BaseFormModel } from 'stores/BaseForm';
import UserDishPreferences from './UserDishPreferences';

class UserProfileStore extends BaseFormModel {
  id: string;
  @observable dishPreferences: UserDishPreferences;

  @observable email = '';
  @observable firstName = '';
  @observable lastName = '';
  @observable nickname = '';
  @observable rfid: boolean;
  @observable externalReferenceId: string;
  @observable loading = false;

  @computed
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(id: string) {
    super();

    this.id = id;
    this.dishPreferences = new UserDishPreferences(id);
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [employee] = await Promise.all([
        await httpFacade.profile.fetchEmployeeByID(this.id),
        await this.dishPreferences.init(),
      ]);

      runInAction(() => {
        this.firstName = employee.data.firstName;
        this.email = employee.data.email;
        this.lastName = employee.data.lastName;
        this.nickname = employee.data.nickname;
        this.rfid = employee.data.rfid;
        this.externalReferenceId = employee.data.externalReferenceId;
      });
    } catch (error) {
      AppRouter.goBack();
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateContactInfo({ firstName, lastName }) {
    try {
      this.loading = true;

      const { data: profile } = await httpFacade.profile.updateContactInfo(
        this.id,
        {
          firstName,
          lastName,
        },
      );

      this.lastName = profile.lastName;
      this.firstName = profile.firstName;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default UserProfileStore;
