import { computed, observable } from 'mobx';
import { BaseFormModel, hasMaxLength, isEmail } from '../../BaseForm';
import { isPhone } from '../../BaseForm/validators/isPhone';
import RootStore from '../../RootStore';
import { ckeckedIfRequiredFieldsFilled, defineRequiredFields } from './helpers';
import { OrderDeliveryDTO } from './types';

class OrderPickupDeliveryForm extends BaseFormModel {
  @observable
  pointOfDelivery = '';

  @observable
  @isEmail()
  @hasMaxLength(100)
  email = '';

  @observable
  @isPhone()
  @hasMaxLength(20)
  phone = '';

  @observable
  @hasMaxLength(100)
  comment = '';

  @computed
  get requestOrderData(): OrderDeliveryDTO {
    return {
      deliveryInfo: {
        email: this.email,
        phone: this.phone,
        comment: this.comment,
        pointOfDelivery: this.pointOfDelivery,
      },
    };
  }

  @computed
  get requiredPickupFeatureFields() {
    return defineRequiredFields(
      RootStore.config.feature?.deliveryPickup?.fields,
    );
  }

  @computed
  get isValidPickupForm() {
    return ckeckedIfRequiredFieldsFilled(
      this,
      this.requiredPickupFeatureFields,
    );
  }
}
export default OrderPickupDeliveryForm;
