import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { observable } from 'mobx';
import classNames from 'classnames';

import style from './Menu.module.scss';

import { ModalProps } from 'stores/ModalStore';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';

import DayItem from './DayItem';
import Weekdays from './Weekdays';
import Button from 'components/Button/Button';
import SplitModalLayout from 'components/Layout/SplitModalLayout';
import { MenuItemsSearch } from 'components/MenuItemsSearch/MenuItemsSearch';

interface Props extends ModalProps {
  menu: MenuPlannerStore;
  activeDate?: string;
}

@observer
class MenuModal extends Component<Props> {
  @observable fullMode = false;

  constructor(props) {
    super(props);

    this.props.menu.activeWeekDate = this.props.activeDate || '';
  }

  async componentDidMount() {
    if (!this.props.menu!.menuItems.length) {
      await this.props.menu.fetchMenuItems();
    }
  }

  switchMode = () => {
    this.fullMode = !this.fullMode;
  };

  onSubmit = async () => {
    await this.props.menu.updateMenuLine();

    this.props.onSubmit!();
  };

  onSearch = value => {
    this.props.menu.searchValue = value;
  };

  render() {
    const { menu, ...rest } = this.props;
    const {
      activeMenuLine,
      activeDay,
      categories,
      searchValue,
    } = this.props.menu;

    return (
      <Provider menu={menu}>
        <SplitModalLayout
          {...rest}
          className={classNames(style.modal, {
            [style.full__mode]: this.fullMode,
          })}
        >
          <div className={style.day__panel}>
            <h3 className={style.line__title}>{activeMenuLine.title}</h3>

            <Weekdays fullMode={this.fullMode} switchMode={this.switchMode} />

            {!this.fullMode && (
              <div className={style.day}>
                <p className={style.day__title}>
                  <FormattedMessage id="title.added" />
                </p>

                <ul>
                  {activeDay &&
                    activeDay.items.map(item => (
                      <DayItem item={item} key={item.id} />
                    ))}
                </ul>
              </div>
            )}

            <div
              className={classNames(style.btn__wrapper, {
                [style.full__mode]: this.fullMode,
              })}
            >
              <Button
                styleType="ghost"
                onClick={this.props.onClose}
                className={style.btn}
              >
                <FormattedMessage id="button.cancel" />
              </Button>

              <Button onClick={this.onSubmit} className={style.btn}>
                <FormattedMessage id="button.save" />
              </Button>
            </div>
          </div>

          <MenuItemsSearch
            onSearch={this.onSearch}
            searchValue={searchValue}
            categories={categories}
            isLoading={menu.loading}
            toggleItem={menu.toggleItem}
            selectedItemsIds={menu.selectedItems}
          />
        </SplitModalLayout>
      </Provider>
    );
  }
}

export default MenuModal;
