import http from 'http/http';
import { api, arrayParamsSerializer } from 'http/helpers';
import URLS from 'http/urls';
import { PageableListRequestParams, PageableListResponse } from 'http/types';

import {
  UserGroup,
  Staff,
  StaffRequestData,
  UserCompany,
  Campaign,
  CampaignDTO,
  UserGroupRequestData,
} from 'stores/Staff/types';
import { Discount, DiscountDTO } from 'stores/Subsidies/types';

import { Dictionary } from 'types/general';
import { SubsidyDto } from '../../stores/Forms/SubsidyFormStore';

export const staffApi = {
  fetchCampaigns() {
    return http.get<Campaign[]>(api(URLS.users.campaigns));
  },

  deleteCampaign(id: string) {
    return http.delete(api(URLS.users.campaigns), {
      replacePathParams: { id },
    });
  },

  createCampaign(data: CampaignDTO) {
    return http.post<CampaignDTO, Campaign>(api(URLS.users.campaigns), data);
  },

  updateCampaign(data: CampaignDTO) {
    return http.put(api(URLS.users.campaigns), data, {
      replacePathParams: {
        id: data.id,
      },
    });
  },

  fetchRoles() {
    return http.get<Dictionary[]>(api(URLS.users.roles));
  },

  fetchStaff(params?: PageableListRequestParams) {
    return http.get<PageableListResponse<Staff>>(api(URLS.users.staff), {
      paramsSerializer: arrayParamsSerializer,
      params,
    });
  },

  fetchStaffByID(id: string) {
    return http.get<Staff>(api(URLS.users.staff), {
      replacePathParams: { id },
    });
  },

  createStaff(data: StaffRequestData) {
    return http.post<StaffRequestData, Staff>(api(URLS.users.staff), data);
  },

  editStaff(id: string, data: StaffRequestData) {
    return http.put<StaffRequestData, Staff>(api(URLS.users.staff), data, {
      replacePathParams: { id },
    });
  },

  deleteStaff(id: string) {
    return http.delete(api(URLS.users.staff), {
      replacePathParams: { id },
    });
  },

  fetchUserGroups() {
    return http.get<PageableListResponse<UserGroup>>(api(URLS.users.groups));
  },

  createStaffGroup(data: UserGroupRequestData) {
    return http.post<UserGroupRequestData, UserGroup>(
      api(URLS.users.groups),
      data,
    );
  },

  updateStaffGroup(id: string, data: UserGroupRequestData) {
    return http.put<UserGroupRequestData, UserGroup>(
      api(URLS.users.groups),
      data,

      {
        replacePathParams: { id },
      },
    );
  },

  deleteStaffGroup(id: string) {
    return http.delete(api(URLS.users.groups), {
      replacePathParams: { id },
    });
  },

  fetchSubsidies() {
    return http.get<Discount[]>(api(URLS.finance.subsidies));
  },

  deleteSubsidy(id: string) {
    return http.delete(api(URLS.finance.subsidies), {
      replacePathParams: { id },
    });
  },
  createSubsidy(data: DiscountDTO) {
    return http.post<DiscountDTO, DiscountDTO>(
      api(URLS.finance.subsidies),
      data,
    );
  },
  updateSubsidy(id: string, data: DiscountDTO) {
    return http.put<DiscountDTO, DiscountDTO>(
      api(URLS.finance.subsidies),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  fetchBayerSubsidies() {
    return http.get<SubsidyDto[]>(api(URLS.finance.subsidiesBayer));
  },
  fetchBayerSubsidy(id: string) {
    return http.get<SubsidyDto>(api(URLS.finance.subsidiesBayer), {
      replacePathParams: { id },
    });
  },
  deleteBayerSubsidy(id: string) {
    return http.delete(api(URLS.finance.subsidiesBayer), {
      replacePathParams: { id },
    });
  },
  createBayerSubsidy(data: SubsidyDto) {
    return http.post<SubsidyDto, SubsidyDto>(
      api(URLS.finance.subsidiesBayer),
      data,
    );
  },
  updateBayerSubsidy(id: string, data: SubsidyDto) {
    return http.put<SubsidyDto, SubsidyDto>(
      api(URLS.finance.subsidiesBayer),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  fetchUserCompanies() {
    return http.get<PageableListResponse<UserCompany>>(
      api(URLS.users.companies),
    );
  },
};
