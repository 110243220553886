import { action, computed, observable } from 'mobx';

import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';
import { OrderModel } from '../UserProfile/types';
import ModalStore from '../ModalStore';
import { popup } from '../Popup';
import RootStore from '../RootStore';
import { DateTime } from 'luxon';
import { getLocalDateTime } from 'helpers/datetime';

class QRScannerStore {
  @observable order: OrderModel;

  @observable scanning: boolean = false;

  @observable loading = false;
  @observable currentDate: DateTime = getLocalDateTime();
  @observable activeDate: DateTime = getLocalDateTime();

  userId: string;

  constructor(onlineOrder?: OrderModel, date?: DateTime) {
    if (onlineOrder) {
      this.order = onlineOrder;
      if (date) {
        this.activeDate = date;
      }
    }
  }

  @computed
  get isFeatureDays() {
    return this.activeDate.toISODate() > this.currentDate.toISODate();
  }

  @action.bound
  async scanQR(result) {
    try {
      this.scanning = true;

      const qr = JSON.parse(result)?.qrCode;
      if (qr) {
        const {
          data,
        } = await httpFacade.onlineOrders.fetchSearchTodayOrderByQR(qr);
        this.order = data;
      } else {
        popup.notify(
          RootStore.localization.formatMessage(
            'error.business.order.itemNotFound',
            {},
          ),
        );
        ModalStore.closeModal();
      }
    } catch (error) {
      ModalStore.closeModal();
      Log.warn(error);
    } finally {
      this.scanning = false;
    }
  }

  @action.bound
  async markOrderDelivered(orderId: string, fullFilled: () => void) {
    try {
      await httpFacade.onlineOrders.fetchOrderProcessingFinished(orderId);
      fullFilled();
      this.order.orderProcessingFinished = true;
    } catch (error) {
      Log.warn(error);
    }
  }

  @computed
  get isOrderProcessingFinished() {
    return this.order.orderProcessingFinished;
  }
}

export default QRScannerStore;
