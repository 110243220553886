import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import style from './ServerError.module.scss';

import Icon from 'components/Icon/Icon';
import AppLogo from 'components/AppLogo/AppLogo';

import { ROUTES } from 'routes/routes';

import RootStore from 'stores/RootStore';

@observer
class ServerError extends Component {
  render() {
    return (
      <div className={style.content}>
        <div className={style.logo__wrapper}>
          <AppLogo type="header" styles={{ image: style.logo }} />
        </div>

        <div className={style.contentRow}>
          <div className={style.description}>
            <h2 className={style.title}>500</h2>

            <p className={style.explanation}>
              <FormattedMessage id="error.500.sorry" />,<br />
              <FormattedMessage id="error.500.explanation" />
            </p>

            <p className={style.solution}>
              <FormattedMessage
                id="error.500.solution"
                values={{
                  home: (
                    <Link
                      to={ROUTES.root}
                      className={style.link}
                      onClick={RootStore.resetErrors}
                    >
                      <FormattedMessage id="error.500.home" />
                    </Link>
                  ),
                }}
              />
            </p>
          </div>

          <div className={style.dishWrapper}>
            <Icon type="service" className={style.icon} />
          </div>
        </div>
      </div>
    );
  }
}

export default ServerError;
