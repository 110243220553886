import { IFeature } from '../OLStore/types';
import { ISample, MenuItem, Tag2Samples } from './types';

import RootStore from '../RootStore';

export function createGallerySample(sample: ISample, menuItems?: MenuItem[]) {
  return {
    ...sample,
    thumbnail: sample.thumbnailFormat + sample.thumbnailBytes,
    labelling: {
      ...sample.labelling,
      data: menuItems
        ? sample.labelling.data.map(feature =>
            mapMenuItemToLabel(menuItems, feature),
          )
        : [],
    },
  };
}

export function generateDatasetLabels(
  samples: Tag2Samples[],
  menuItems: MenuItem[],
) {
  const labels = new Map<string, { amount: number; title: string }>();
  samples
    ?.map(sampleLabel => mapMenuItemToLabel(menuItems, sampleLabel))
    .forEach(label => {
      labels.set(label.tag!, {
        amount: labels.has(label.tag!)
          ? (labels.get(label.tag!)?.amount || 0) + label.samplesAmount
          : label.samplesAmount,
        title: label.title || RootStore.localization.formatMessage('UNDEFINED'),
      });
    });

  return [...labels].map(([key, value]) => ({
    tag: key,
    amount: value.amount,
    title: value.title,
  }));
}

export function mapMenuItemToLabel(
  menuItems: MenuItem[],
  label: IFeature | any,
) {
  const item = menuItems?.find?.(menuItem => menuItem.tag === label.tag);

  return {
    ...label,
    tag: item?.tag,
    title: item?.title,
  };
}

export const generateUnknownLabels = (
  samples: Tag2Samples[],
  menuItems: MenuItem[],
) => {
  const unknownLabels: string[] = [];
  samples.forEach(label => {
    if (menuItems.every(menuItem => label.tag !== menuItem.tag)) {
      unknownLabels.push(label.tag);
    }
  });
  return unknownLabels;
};
