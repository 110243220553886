export enum TypesOfDelivery {
  pickup = 'PICKUP',
  delivery = 'DELIVERY',
}

export interface OrderDeliveryDTO {
  deliveryInfo?: {
    email?: string;
    phone?: string;
    comment?: string;
    pointOfDelivery?: string;
  };
  selectedLocation?: string;
}

export const deliveryTypes: TypesOfDelivery[] = [
  TypesOfDelivery.pickup,
  TypesOfDelivery.delivery,
];
