import React, { FC } from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from './SwitchButton.module.scss';
import { FormattedMessage } from 'react-intl';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (v: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  isMiddleState?: boolean;
  isShowTitle?: boolean;
  className?: string;
}

const SwitchButton: FC<Props> = props => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange && props.onChange(event.target.checked, event);

  const {
    checked,
    name,
    isMiddleState,
    className,
    disabled,
    isShowTitle = false,
  } = props;

  return (
    <label
      className={classNames(
        style.track,
        {
          [style.track__checked]: checked,
        },
        className,
        disabled && style.track__disabled,
      )}
    >
      <input
        type="checkbox"
        checked={checked}
        className={style.input}
        onChange={onChange}
        disabled={disabled}
        id={name}
      />

      <span
        className={classNames(
          style.thumb,
          isMiddleState && style.thumb__middle,
        )}
      />
      {isShowTitle && (
        <span
          className={classNames(style.title, { [style.title__off]: !checked })}
        >
          <FormattedMessage id={checked ? 'ON' : 'OFF'} />
        </span>
      )}
    </label>
  );
};

export default SwitchButton;
