import { action, observable } from 'mobx';

import Log from 'helpers/log';
import {
  getFormattedDateTimeFromMillis,
  QR_LIVE_TIME_FORMAT,
} from 'helpers/datetime';

import QRStore from '../QRStore';

import httpFacade from 'http/httpFacade';

class QRPaymentStore {
  @observable code: string;
  @observable lifetime: number;

  @observable loading = false;

  userId: string;

  private qr = new QRStore();

  constructor(userId: string) {
    this.userId = userId;
  }

  @action.bound
  async generateQRCode() {
    try {
      this.loading = true;

      const { data } = await httpFacade.employees.fetchQRPaymentData(
        this.userId,
      );

      this.lifetime = Number(
        getFormattedDateTimeFromMillis(data.timeToLive, QR_LIVE_TIME_FORMAT),
      );
      this.code = await this.qr.generateQR(JSON.stringify(data));
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default QRPaymentStore;
