import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import Log from 'helpers/log';

import './style.scss';

import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';

import FileCompressor from 'stores/Services/FileCompressor';
import { action } from 'mobx';

const MAX_FILE_SIZE = 2500000;

interface Props {
  onChange: (files: any) => void;
  className?: string;
  onRemove?: () => void;
  file: any;
}

@observer
class FileUploader extends Component<Props> {
  onDrop = files => {
    if (!files.length) {
      return;
    }
    const img = files[0];
    const onChange = this.props.onChange;
    const formData = new FormData();

    if (img.size / 1024 > 150) {
      FileCompressor.compress(
        img,
        result => formData.append('image', result, img.name),
        error => Log.error(error),
      );
    } else {
      formData.append('image', img);
    }
    onChange(formData);
  };

  render() {
    const { file } = this.props;
    return (
      <Dropzone
        onDrop={this.onDrop}
        accept={['.jpg', '.jpeg', '.png']}
        multiple={false}
        maxSize={MAX_FILE_SIZE}
      >
        {({
          getRootProps,
          getInputProps,
          acceptedFiles,
          isDragActive,
          rejectedFiles,
        }) => {
          const isFileSizeError =
            !!rejectedFiles.length && rejectedFiles[0].size > MAX_FILE_SIZE;
          return (
            <div
              {...getRootProps()}
              className={`fileUploader__wrapper ${isDragActive &&
                'fileUploader__wrapper_active'} ${!!acceptedFiles.length &&
                'fileUploader__wrapper_uploaded'} ${!acceptedFiles.length &&
                file &&
                'fileUploader__wrapper_noBorder'}`}
            >
              <input {...getInputProps()} />

              <div
                className={`fileUploader__info ${!!acceptedFiles.length &&
                  'fileUploader__info_uploaded'} ${!acceptedFiles.length &&
                  file &&
                  'fileUploader__info_fromServer'}`}
              >
                <div
                  className={`fileUploader__preview ${isDragActive &&
                    'fileUploader__preview_loading'}`}
                >
                  {!!acceptedFiles.length || file ? (
                    <img
                      src={
                        !!acceptedFiles.length
                          ? URL.createObjectURL(acceptedFiles[0])
                          : file
                      }
                      alt={'dish preview'}
                      className={'fileUploader__preview__image'}
                    />
                  ) : isDragActive ? (
                    <Spinner
                      className={{
                        wrap: 'fileUploader__spinner_wrap',
                        spinner: 'fileUploader__spinner',
                        inner: 'fileUploader__spinner_inner',
                      }}
                    />
                  ) : (
                    <Icon
                      type="imageEmpty"
                      className={'fileUploader__noImage'}
                    />
                  )}
                </div>

                <div className={'fileUploader__description'}>
                  {!!acceptedFiles.length ? (
                    this.renderFilePreview(acceptedFiles[0], acceptedFiles)
                  ) : file ? (
                    this.renderFilePreview(file, acceptedFiles)
                  ) : (
                    <>
                      <div className={`fileUploader__description__button`}>
                        <FormattedMessage
                          id="fileUploader.description"
                          values={{
                            browse: (
                              <span className={'fileUploader__button'}>
                                <FormattedMessage id="fileUploader.browse" />
                              </span>
                            ),
                          }}
                        />
                      </div>
                      <div
                        className={`fileUploader__limit ${isFileSizeError &&
                          'fileUploader__error'}`}
                      >
                        <FormattedMessage
                          id={
                            isFileSizeError
                              ? 'fileUploader.sizeError'
                              : 'fileUploader.limit'
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }

  @action
  renderFilePreview(file, uploadedFiles) {
    const onRemoveFile = e => {
      uploadedFiles.splice(0, uploadedFiles.length);
      this.props.onChange(uploadedFiles[0]);
      if (this.props.onRemove) {
        this.props.onRemove();
      }
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div className={'fileLoader__files_uploaded'}>
        <div>
          {file?.name && (
            <>
              <FormattedMessage
                id="fileUploader.image.title"
                values={{
                  fileName: file?.name,
                }}
              />
              <p className={'fileUploader__description__image_size'}>
                {(file?.size / 1024).toFixed(2)} kb
              </p>
            </>
          )}
        </div>
        <button
          onClick={onRemoveFile}
          className={'fileUploader__button_remove'}
        >
          <Icon type={'close'} />
        </button>
      </div>
    );
  }
}

export default FileUploader;
