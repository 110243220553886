import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from './AsideMenu.module.scss';

import Button from 'components/Button/Button';
import EmployeeScanModal from 'components/Modals/Scanners/EmployeeScanModal';
import TagScanModal from 'components/Modals/Scanners/TagScanModal';

import ModalStore from 'stores/ModalStore';

const onEmployeeScan = async () => {
  await ModalStore.showModal(EmployeeScanModal);
};

const onTagScan = async () => {
  await ModalStore.showModal(TagScanModal);
};

@observer
class Controls extends Component {
  render() {
    return (
      <div className={style.controls}>
        <Button className={style.btn} onClick={onTagScan}>
          <FormattedMessage id="button.scan.tag" defaultMessage="Scan Tag" />
        </Button>

        <Button className={style.btn} onClick={onEmployeeScan}>
          <FormattedMessage id="button.scan.qr" defaultMessage="Scan QR-code" />
        </Button>
      </div>
    );
  }
}

export default Controls;
