import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import ModalStore from 'stores/ModalStore';
import { IMenuLine } from './types';

import WarningModal from 'components/Modals/Warning/WarningModal';

class LinesStore {
  @observable data: IMenuLine[] = [];
  @observable searchValue = '';

  @observable loading = false;

  @computed
  get filteredItems() {
    return this.data.filter(el =>
      this.searchValue
        ? el.title.toLowerCase().includes(this.searchValue.toLocaleLowerCase())
        : el,
    );
  }

  @action.bound
  async fetchLines() {
    try {
      this.loading = true;

      const { data } = await httpFacade.menu.fetchMenuLines();

      this.data = data.filter(line => !line.deleted);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteLine(id: string) {
    try {
      await ModalStore.showModal(WarningModal, {
        title: 'modal.warning.title.delete',
        description: 'modal.warning.delete.item',
      });

      this.loading = true;

      await httpFacade.menu.deleteMenuLine(id);

      this.data = this.data.filter(line => line.id !== id);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateLines(payload: IMenuLine) {
    const line = this.data.find(item => item.id === payload.id);
    if (line?.prioritized || payload.prioritized) {
      this.data = this.data.map(it => {
        return {
          ...it,
          prioritized: false,
        };
      });
    }

    this.data = line
      ? (this.data = this.data.map(item =>
          item.id === payload.id ? payload : item,
        ))
      : (this.data = [...this.data, payload]);
  }
}

export default LinesStore;
