import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from './DescriptionModal.module.scss';

import TextArea from 'components/Form/Fields/TextArea/TextAreaField';
import Button from 'components/Button/Button';
import { bindFormControl } from 'components/Form/FormControl/FormControl';

import { ModalProps } from 'stores/ModalStore';
import DescriptionForm from 'stores/Forms/DescriptionForm';

interface Props extends ModalProps {
  title?: React.ReactElement;
  description?: React.ReactElement;
  label?: string;
  initialDescription?: string;
}

@observer
class DescriptionModal extends Component<Props> {
  form = new DescriptionForm(this.props.initialDescription);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();

    this.props.onSubmit!(this.form.description);
  };

  render() {
    const { title, description, label, ...rest } = this.props;
    const FormControl = this.formControl;

    return (
      <Modal
        {...rest}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        className={style.modal}
        visible
      >
        <form name="comment-form" onSubmit={this.onSubmit}>
          {title && <h3 className={style.title}>{title}</h3>}

          {description && (
            <div className={style.description}>{description}</div>
          )}

          <div className={style.field__comment}>
            {label && (
              <label htmlFor="description" className={style.field__label}>
                <FormattedMessage id={label} />
              </label>
            )}

            <FormControl
              className={style.form__control}
              name="description"
              render={props => <TextArea {...props} maxLength={150} />}
            />
          </div>

          <div className={style.btn__wrapper}>
            <Button
              styleType="ghost"
              onClick={this.props.onClose}
              className={style.btn}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button type="submit" styleType="primary" className={style.btn}>
              <FormattedMessage
                id={
                  this.props.initialDescription
                    ? 'button.save'
                    : 'button.create'
                }
              />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default DescriptionModal;
