import { observable } from 'mobx';

import { BaseFormModel, isRequired, displayName, isEmail } from '../BaseForm';

import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';

export enum RecoveryFormStatus {
  loading = 'loading',
  pending = 'pending',
  success = 'success',
}

class RecoveryForm extends BaseFormModel {
  @observable
  @displayName('EMAIL')
  @isRequired()
  @isEmail()
  email = '';

  @observable message = '';
  @observable status = RecoveryFormStatus.pending;

  async send() {
    Log.info(`send recovery email: ${this.email}`);

    if (this.validate()) {
      try {
        const data = { email: this.email };

        this.message = '';
        this.status = RecoveryFormStatus.loading;

        await httpFacade.user.passwordRecovery(data);

        this.status = RecoveryFormStatus.success;
      } catch (error) {
        this.message = 'error.form.incorrect';
        this.status = RecoveryFormStatus.pending;
      }
    }
  }
}

export default RecoveryForm;
