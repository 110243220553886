import { IStep } from './FormSteps';

export const subsidySteps: IStep[] = [
  {
    label: 'subsidy.step.subsidy',
    step: 0,
  },
  {
    label: 'subsidy.step.group.settings',
    step: 1,
  },
  {
    label: 'subsidy.step.inclusions.exclusions',
    step: 2,
  },
  {
    label: 'subsidy.step.discount.amount',
    step: 3,
  },
  {
    label: 'subsidy.step.rules',
    step: 4,
  },
  {
    label: 'subsidy.step.daily.limits',
    step: 5,
  },
];
