import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getFormattedDate } from 'helpers/datetime';

import { FormattedMessage } from 'react-intl';

import { getOrderDate, getTimeSlotPeriod } from 'stores/UserProfile/helpers';
import { OrderModel } from 'stores/UserProfile/types';

import style from './OnlineOrder.module.scss';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import OrderItemsList from 'components/OrderItemsList/OrderItemsList';

@observer
class OnlineOrder extends Component<{
  order: OrderModel;
}> {
  render() {
    const {
      deliveryTimeSlot,
      deliveryInfo,
      deliveryLocation,
      items,
      queueNumber,
      deliveryDate,
      finalPrice,
      createdDate,
    } = this.props.order;

    return (
      <div className={style.order}>
        <div className={style.order__line}>
          <div className={style.order__info}>
            <p className={style.order__number}>{queueNumber}</p>
            <div className={style.order__info__line}>
              {getOrderDate(createdDate)}
            </div>

            {deliveryTimeSlot && (
              <div className={style.order__info__line}>
                <FormattedMessage
                  id="user.orders.delivery.period"
                  values={{ period: getTimeSlotPeriod(deliveryTimeSlot) }}
                />
              </div>
            )}

            <div className={style.order__info__line}>
              <FormattedMessage
                id="user.orders.delivery.on"
                values={{
                  date: getFormattedDate(deliveryDate),
                }}
              />
            </div>

            <div className={style.order__info__line}>
              {deliveryLocation?.title || deliveryInfo?.pointOfDelivery ? (
                <>
                  <FormattedMessage id="user.orders.delivery.label" />
                  &nbsp;
                  {deliveryLocation?.title || deliveryInfo?.pointOfDelivery}
                </>
              ) : (
                <FormattedMessage id="user.orders.delivery.pickup.label" />
              )}
            </div>

            {deliveryInfo && (
              <>
                {deliveryInfo?.email && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.email.label" />
                    &nbsp;
                    {deliveryInfo.email}
                  </div>
                )}
                {deliveryInfo?.phone && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.phone.label" />
                    &nbsp;
                    {deliveryInfo.phone}
                  </div>
                )}
                {deliveryInfo?.comment && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.comment.label" />
                    &nbsp;
                    {deliveryInfo.comment}
                  </div>
                )}
              </>
            )}
          </div>

          <span className={style.price}>
            <FormattedCurrency value={finalPrice} />
          </span>
        </div>

        <OrderItemsList
          items={items}
          styles={{ list: style.order__items, item: style.order__item }}
          recent={false}
        />
      </div>
    );
  }
}

export default OnlineOrder;
