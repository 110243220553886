import { AxiosError } from 'axios';
import qs from 'qs';

import { ResponseErrMessage, ResponseError } from './types';

import Log from 'helpers/log';

export function api(url: string, type: 'hr' | 'ft-mngr' = 'hr'): string {
  return `/api/${type}${url}`;
}

export function wss(url: string, accessToken?: string | null): string {
  const path = `wss://${window.location.host}${url}`;

  return accessToken ? `${path}?accessToken=${accessToken}` : path;
}

export function replaceUrlParams(url: string, params = {}): string {
  return url.replace(/:(\w+)/gi, (str, bracket) => {
    if (bracket in params) {
      return params[bracket] || '';
    } else {
      Log.info("url param doesn't exist");

      return '';
    }
  });
}

export function arrayParamsSerializer(requestParams: any): string {
  return qs.stringify(requestParams, { indices: false, arrayFormat: 'comma' });
}

export const isAuthTokenExpired = (errors: ResponseError[]): boolean =>
  errors.some(err => err.message === ResponseErrMessage.AUTH_TOKEN_EXPIRED_ERR);

export function getHttpError(error: AxiosError): { id: string; params?: any } {
  const { response } = error;

  switch (response?.status) {
    case 403:
      return {
        id: 'error.403.text',
      };
    case 408 || error.code === 'ECONNABORTED':
      return {
        id: 'error.connection.timeout',
      };
    case 400:
      return {
        id: 'error.400',
      };
    case 429:
      return {
        id: 'error.server.is.busy',
      };
    default:
      return {
        id:
          (response?.data?.errors && response?.data?.errors[0].message) ||
          'error.network',
      };
  }
}
