import { action, observable } from 'mobx';

import QRHub from 'stores/QRHub';
import AppRouter from 'stores/AppRouter';
import ModalStore from '../ModalStore';

import httpFacade from 'http/httpFacade';

import { ROUTES } from 'routes/routes';

import { ScanStatus } from './types';

class QRScanner {
  @observable status: ScanStatus = ScanStatus.LOADING;

  @observable canceled = false;

  qr = new QRHub();

  @action.bound
  async unsubscribe() {
    this.canceled = true;

    await this.qr.disconnect();
  }

  @action.bound
  async scanEmployee() {
    try {
      this.status = ScanStatus.LOADING;

      const connection = this.qr.create();

      connection.on('BarcodeScanned', async (device: string, code: string) => {
        this.status = ScanStatus.REDIRECTING;

        await this.qr.disconnect();

        const { data } = await httpFacade.employees.fetchEmployeeByQR(code);

        if (!this.canceled) {
          ModalStore.closeModal();
          AppRouter.push(ROUTES.employeeProfile, { id: data.id });
        }
      });

      await connection.start();
    } catch (error) {
      this.status = ScanStatus.ERROR;
    }
  }

  @action.bound
  async scan(callback: (device: string, code: string) => void) {
    try {
      this.status = ScanStatus.LOADING;

      const connection = this.qr.create();

      connection.on('BarcodeScanned', callback);

      await connection.start();
    } catch (error) {
      this.status = ScanStatus.ERROR;
    }
  }
}

export default QRScanner;
