import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router';

import MenuLines from './MenuLines/MenuLines';
import Planner from './Planner/Planner';
import NavigationTabs from 'components/NavigationTabs/NavigationTabs';

import { ROUTES } from 'routes/routes';

import style from './MenuPage.module.scss';

@observer
class MenuPage extends Component {
  render() {
    return (
      <div className={style.content__tabs}>
        <NavigationTabs
          tabs={[
            { to: ROUTES.admin.menu, title: 'title.menu' },
            { to: ROUTES.admin.menuLines, title: 'title.menuLines' },
          ]}
        />

        <Switch>
          <Route path={ROUTES.admin.menu} component={Planner} exact />
          <Route path={ROUTES.admin.menuLines} component={MenuLines} exact />
        </Switch>
      </div>
    );
  }
}

export default MenuPage;
