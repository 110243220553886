import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import style from './TaskForm.module.scss';

import Controls from './Controls';
import TaskInfo from './TaskInfo';
import TextField from 'components/Form/Fields/Text/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Spinner from 'components/Spinner/Spinner';
import TagsSelect from 'components/TagsSelect/TagsSelect';

import TaskForm from 'stores/Forms/TasksForms/TaskEvalDetectorForm';
import { TaskType } from 'stores/Recognition/types';
import SelectField from '../../../../components/Form/Fields/Select/SelectField';

@observer
class TaskEvalDetectorForm extends Component<{
  type: TaskType.EVAL_MODEL_DETECTOR;
}> {
  form: TaskForm = new TaskForm(this.props.type);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onCreate();
  };

  async componentDidMount() {
    await this.form.init();
  }

  render() {
    const FormControl = this.formControl;

    return (
      <form className={style.task__form} onSubmit={this.onSubmit}>
        {this.form.loading && <Spinner contain />}

        <TaskInfo info={this.props.type} />

        <div className={style.form__body}>
          <TagsSelect
            title="title.dataset.required"
            tags={this.form.datasetTags}
            selectedTags={this.form.selectedDatasets}
            onSubmit={this.form.setDatasets}
            onRemoveTag={this.form.removeDataset}
          />

          <FormControl
            name="detectorId"
            className={classNames(style.control, style.control__option)}
            render={props => (
              <SelectField
                {...props}
                options={this.form.modelsDetectionOptions}
              />
            )}
          />

          <FormControl
            name="description"
            className={classNames(style.control, style.control__description)}
            render={props => <TextField {...props} />}
          />
        </div>

        <Controls disabled={!this.form.isParamsValid} />
      </form>
    );
  }
}

export default TaskEvalDetectorForm;
