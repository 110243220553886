import React, { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import ReactPaginate from 'react-paginate';

import style from './Pagination.module.scss';

import { FormattedMessage } from 'react-intl';

interface Props {
  pageCount?: number;
  page?: number;
  onPageClick: (page: any) => void;
  className?: string;
}

@observer
class Pagination extends Component<Props> {
  handlePageClick = data => this.props.onPageClick(data.selected);

  render() {
    const { pageCount, page, className } = this.props;
    return (
      <ReactPaginate
        previousLabel={<FormattedMessage id="button.previous" />}
        nextLabel={<FormattedMessage id="button.next" />}
        breakLabel={'...'}
        breakClassName={classNames(style.page, style.break)}
        pageCount={pageCount}
        forcePage={page}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={this.handlePageClick}
        containerClassName={classNames(style.pagination, className)}
        subContainerClassName={classNames(style.pages, style.pagination)}
        activeClassName={style.page__active}
        pageClassName={style.page}
        pageLinkClassName={style.page__link}
        previousClassName={classNames(style.page, style.button)}
        previousLinkClassName={style.button__link}
        nextLinkClassName={style.button__link}
        nextClassName={classNames(style.page, style.button)}
      />
    );
  }
}

export default Pagination;
