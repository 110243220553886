import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { ISample, ILabel, IDataset } from './types';

import Log from 'helpers/log';
import { sortByAccessor } from 'helpers/accessors';
import {
  createGallerySample,
  generateDatasetLabels,
  generateUnknownLabels,
} from './helpers';

import RootStore from '../RootStore';
import { MenuItem } from '../Menu/types';
import { DEFAULT_PAGE } from '../constants';

export const MISSING__LABELS = 'MISSING__LABELS';
const DEFAULT_SAMPLES_PAGE_SIZE = 48;

class DatasetStore {
  @observable dataset: IDataset;
  @observable menuItems: MenuItem[] = [];
  @observable filters: string[] = [];
  @observable samples: ISample[] = [];
  @observable checkedSamples: string[] = [];
  @observable pageCount: number;
  @observable size = DEFAULT_SAMPLES_PAGE_SIZE;
  @observable page = DEFAULT_PAGE;
  @observable totalSamples: number;
  @observable unknownLabels: string[] = [];
  @observable isNoTags: boolean = false;
  @observable filterParam: string[] = [];

  @observable loading = false;
  @observable multiMode = false;

  private readonly id: string;
  private defaultSort = { accessor: 'title', desc: false };

  @computed
  get labels(): ILabel[] {
    return generateDatasetLabels(
      this.dataset?.statistic?.tag2Samples,
      this.menuItems,
    ).sort(sortByAccessor(this.defaultSort));
  }

  @computed
  get labelsAmount(): number {
    if (!this.labels.length) {
      return 0;
    }
    return this.labels.some(label => !label.tag)
      ? this.labels.length - 1
      : this.labels.length;
  }

  @computed
  get gallery() {
    return this.samples;
  }

  @computed
  get filteredSamples() {
    return this.filters.length
      ? this.gallery.filter(
          ({ labelling }) =>
            labelling.data.some(label => this.filters.includes(label.tag!)) ||
            Boolean(
              !Boolean(labelling.data.length) &&
                this.filters.includes(MISSING__LABELS),
            ),
        )
      : this.gallery;
  }

  @computed
  get missingLabels() {
    return (
      this.dataset?.statistic?.sample2tags.filter(
        item => !Boolean(item?.tagsAmount),
      ).length || 0
    );
  }

  @computed
  get unknownTags() {
    return this.labels?.filter?.(label => !label.tag)[0]?.amount || 0;
  }

  constructor(id: string) {
    this.id = id;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [dataset, samples, menuItems] = await Promise.all([
        httpFacade.recognition.fetchDataset(this.id),
        httpFacade.recognition.fetchDatasetSamples({
          datasetId: this.id,
          size: this.size,
          page: this.page,
        }),
        httpFacade.menuItems.fetchMenuItems(),
      ]);

      ({ data: this.dataset } = dataset);
      this.menuItems = menuItems?.data;
      const {
        content,
        totalPages,
        number: currentPage,
        size,
        totalElements,
      } = samples.data;

      this.samples = content?.map?.(sample =>
        createGallerySample(sample, this.menuItems),
      );

      this.pageCount = totalPages;
      this.page = currentPage;
      this.size = size;
      this.totalSamples = totalElements;
      this.unknownLabels = generateUnknownLabels(
        this.dataset.statistic.tag2Samples,
        this.menuItems,
      );
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async changeDatasetName(name: string) {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.updateDataset({
        id: this.id,
        version: this.dataset.version,
        name,
      });

      this.dataset = { ...data };
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  toggleFilter(tag: string) {
    const isMissingLabels = tag === MISSING__LABELS;

    if (isMissingLabels) {
      this.isNoTags = !this.isNoTags;
      this.filters = this.filters.includes(tag) ? [] : [tag];
      this.filterParam = [];
    } else {
      this.isNoTags = false;
      this.filters = this.filters?.filter?.(item => item !== MISSING__LABELS);

      if (tag === undefined) {
        this.filters = this.filters.includes(tag) ? [] : [tag];

        this.filterParam = this.filters?.length ? this.unknownLabels : [];
      } else {
        this.filters = this.filters.includes(tag)
          ? this.filters.filter(filter => filter !== tag)
          : [...this.filters.filter(Boolean), tag];
        this.filterParam = this.filters;
      }
    }

    this.fetchDatasetSamples(0, this.filterParam);
  }

  @action.bound
  async uploadSamples(files) {
    try {
      const { user, sampleLoaderService } = RootStore;
      const { accessToken } = user.getAuthTokens();

      sampleLoaderService.uploadSamples({
        dataset: {
          id: this.id,
          name: this.dataset.name,
        },
        accessToken,
        files,
      });
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async deleteSample(id: string) {
    try {
      this.loading = true;

      await httpFacade.recognition.deleteSample(id);

      this.samples = this.samples.filter(sample => sample.id !== id);
      const selectedPage =
        !this.samples.length && !!this.page ? this.page - 1 : this.page;
      await this.fetchDataset(this.id);
      await this.fetchDatasetSamples(selectedPage);
    } catch (error) {
      Log.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  switchMultiMode() {
    this.multiMode = !this.multiMode;
    this.checkedSamples = this.multiMode ? this.checkedSamples : [];
  }

  @action.bound
  toggleCheckedSample(checkedId: string) {
    this.checkedSamples = this.checkedSamples.includes(checkedId)
      ? this.checkedSamples.filter(id => id !== checkedId)
      : [...this.checkedSamples, checkedId];
  }

  @action.bound
  toggleCheckedAllSample() {
    this.checkedSamples = this.samples.map(sample => sample.id);
  }

  @action.bound
  resetCheckedSamples() {
    this.checkedSamples = [];
  }

  @action.bound
  async updateSamples() {
    await this.fetchDataset(this.id);
    await this.fetchDatasetSamples();
  }

  @action.bound
  async multiSampleDelete() {
    try {
      this.loading = true;

      await httpFacade.recognition.deleteSamples(this.checkedSamples);

      const selectedPage =
        this.samples.length === this.checkedSamples.length
          ? this.page - 1
          : this.page;
      await this.fetchDataset(this.id);
      await this.fetchDatasetSamples(selectedPage);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateSamplesAsync(event) {
    if (event.data.dataset?.id === this.id && event.data.done) {
      const selectedPage =
        this.samples.length > this.size ? this.page + 1 : this.page;

      await this.fetchDataset(this.id);

      return this.fetchDatasetSamples(selectedPage);
    }
  }

  @action
  async fetchDataset(id: string) {
    this.loading = true;
    try {
      const { data } = await httpFacade.recognition.fetchDataset(id);
      this.dataset = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action
  async fetchDatasetSamples(selectedPage?: number, tags?: string[]) {
    this.loading = true;
    this.multiMode = false;
    this.checkedSamples = [];

    try {
      const { data } = await httpFacade.recognition.fetchDatasetSamples({
        datasetId: this.id,
        size: this.size,
        page: selectedPage !== undefined ? selectedPage : this.page,
        tags: tags || this.filterParam,
        notags: this.isNoTags,
      });
      const {
        content,
        totalPages,
        number: currentPage,
        size,
        totalElements,
      } = data;

      this.samples = content.map(sample =>
        createGallerySample(sample, this.menuItems),
      );
      this.pageCount = totalPages;
      this.page = currentPage;
      this.size = size;
      this.totalSamples = totalElements;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default DatasetStore;
