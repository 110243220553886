import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { EUR } from 'helpers/currency';
import { getTitleWithPeriod } from './helpers';

import style from './SubsidyModal.module.scss';

import Button from 'components/Button/Button';
import RadioField from 'components/Form/Fields/Radio/RadioField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import CurrencyInput from 'components/Form/Fields/Number/CurrencyInput';
import NumberInput from 'components/Form/Fields/Number/NumberInput';
import SubsidyCheckList from './SubsidyCheckList';

import { UserGroup } from 'stores/Staff/types';
import SubsidyForm, { DISCOUNT_LIMITS } from 'stores/Forms/SubsidyForm';
import { Discount, DiscountPeriod } from 'stores/Subsidies/types';
import { DishGroupManagement } from 'stores/Menu/types';
import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  discount?: Discount;
  activeUserGroup?: UserGroup;
  selectedCategories: DishGroupManagement[];
  onSummary: () => void;
  step: number;
  onCancel: () => void;
  cbSubmit: (payload: Discount) => void;
}

@observer
class SubsidyFormModal extends Component<Props> {
  form = new SubsidyForm(
    this.props.activeUserGroup,
    this.props.selectedCategories,
    this.props.discount,
  );
  formControl = bindFormControl(this.form);

  onSubmit = async e => {
    e.preventDefault();
    let requestData;
    const onFulfilled = payload => {
      requestData = payload;
      if (payload) {
        this.props.onClose!();
      }
    };
    await this.form.onSubmit(onFulfilled);
    if (requestData) {
      this.props.cbSubmit(requestData);
    }
  };

  render() {
    const { onCancel, step } = this.props;
    const FormControl = this.formControl;

    return (
      <>
        <div className={style.body}>
          <>
            {
              {
                1: (
                  <>
                    <form
                      id="subsidy-form"
                      name="subsidy-form"
                      className={style.form}
                    >
                      <div className={style.form__row}>
                        <FormControl
                          name="price"
                          render={fieldProps => (
                            <CurrencyInput
                              {...fieldProps}
                              postfix={EUR}
                              className={style.field__amount}
                            />
                          )}
                        />
                        {!this.form.otherLimits && (
                          <FormControl
                            name="defaultLimit"
                            render={() => (
                              <h4 className={style.field__defaultLimit}>
                                <FormattedMessage
                                  id={getTitleWithPeriod(
                                    this.form.defaultPeriod,
                                  )}
                                  values={{
                                    limit: this.form.defaultLimit,
                                  }}
                                />
                              </h4>
                            )}
                          />
                        )}
                      </div>

                      <div className={style.form__row}>
                        <FormControl
                          name="otherLimits"
                          className={style.field__otherLimits}
                          render={btnProps => (
                            <SwitchButton
                              {...btnProps}
                              checked={this.form.otherLimits}
                            />
                          )}
                        />
                      </div>

                      <div
                        className={classNames(
                          style.form__row,
                          style.form__row__limits,
                          !this.form.otherLimits && style.form__row__disabled,
                        )}
                      >
                        <div>
                          <span className={style.frequency}>
                            <FormattedMessage id="FREQUENCY" />
                          </span>
                          <ul className={style.periods}>
                            {Object.values(DiscountPeriod).map(it => (
                              <li className={style.period} key={it}>
                                <FormControl
                                  name="period"
                                  render={fieldProps => (
                                    <RadioField
                                      {...fieldProps}
                                      title={it}
                                      name={it}
                                      value={it}
                                      checked={this.form.period === it}
                                      radioButtonStyleType={'circle'}
                                      className={style.radio}
                                    />
                                  )}
                                  className={style.radio}
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                        <FormControl
                          name="limit"
                          errorClass={style.limit__error}
                          render={fieldProps => (
                            <NumberInput
                              {...fieldProps}
                              postfix={'Mal'}
                              max={DISCOUNT_LIMITS.maxLimit}
                              disabled={!this.form.otherLimits}
                              className={style.field__limit}
                            />
                          )}
                        />
                      </div>

                      <div className={style.form__row}>
                        <FormControl
                          name="confirmationMode"
                          className={style.field__confirmationMode}
                          render={btnProps => (
                            <SwitchButton
                              {...btnProps}
                              checked={this.form.confirmationMode}
                              className={style.switchButton}
                            />
                          )}
                        />
                      </div>
                    </form>
                  </>
                ),
                2: (
                  <SubsidyCheckList
                    form={this.form}
                    userGroup={this.props.activeUserGroup}
                    dishCategories={this.props.selectedCategories}
                  />
                ),
              }[step]
            }
          </>
        </div>
        <div className={style.form__controls}>
          <Button className={style.btn} styleType="ghost" onClick={onCancel}>
            <FormattedMessage id="button.back" />
          </Button>
          {step === 1 ? (
            <Button
              className={style.btn}
              disabled={!this.form.price || !this.form.isFormValid}
              onClick={this.props.onSummary}
            >
              <FormattedMessage id={'button.summary'} />
            </Button>
          ) : (
            <Button
              form="subsidy-form"
              type="submit"
              className={style.btn}
              onClick={this.onSubmit}
            >
              <FormattedMessage
                id={this.form.id ? 'button.save' : 'button.create'}
              />
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default SubsidyFormModal;
