import { action, computed, observable, runInAction } from 'mobx';

import Log from 'helpers/log';
import { getRole, Roles } from 'helpers/roles';
import httpFacade from 'http/httpFacade';
import { CardDepositInfo } from 'http/Api/employees';
import { ProfileStatus } from './types';

import AssignModal from 'components/Modals/Assign/AssignModal';
import { AppTag, TagMessage, TagStatus } from 'components/Tag/Tag';

import ProfileReport from './ProfileReport';
import ModalStore from 'stores/ModalStore';
import AppRouter from 'stores/AppRouter';
import { SocketDataType, TransactionData } from 'stores/Socket/types';
import RootStore from 'stores/RootStore';
import { popup } from 'stores/Popup';

class ProfileStore {
  id: string;

  @observable
  report: ProfileReport;

  @observable email = '';
  @observable firstName = '';
  @observable lastName = '';
  @observable nickname = '';

  @observable nfc: AppTag = {
    value: '',
    messageId: TagMessage.notAssigned,
    status: TagStatus.default,
  };

  @observable rfid: AppTag = {
    value: '',
    messageId: TagMessage.notAssigned,
    status: TagStatus.default,
  };

  @observable externalReferenceId: string;
  @observable profileStatus: ProfileStatus;
  @observable message: string | undefined;

  @observable loading = false;
  @observable refunded = false;
  @observable cardDepositInfo: CardDepositInfo = {
    amount: 0,
    mandatory: false,
  };

  @computed
  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get canBlockOrder(): boolean {
    return this.profileStatus === ProfileStatus.inactive;
  }

  @computed
  get isTagsAvailable(): boolean {
    return (
      this.nfc.status === TagStatus.available ||
      this.rfid.status === TagStatus.available
    );
  }

  @computed
  get canAttachTag(): boolean {
    return (
      this.report.balance >= this.cardDepositInfo.amount &&
      this.profileStatus !== ProfileStatus.inactive &&
      this.rfid.status !== TagStatus.available
    );
  }

  constructor(id: string) {
    this.id = id;
    this.report = new ProfileReport(id);
  }

  @action.bound
  async toggleRefund() {
    try {
      if (this.refunded) {
        await httpFacade.profile.unmarkRefund(this.id);
      } else {
        await httpFacade.profile.markRefund(this.id);
      }

      this.refunded = !this.refunded;
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [employee] = await Promise.all([
        httpFacade.profile.fetchEmployeeByID(this.id),
        this.report.fetchReport(),
      ]);

      if (getRole(Roles.hostess)) {
        await this.initDepositInfo();
      }

      runInAction(() => {
        this.firstName = employee.data.firstName;
        this.email = employee.data.email;
        this.lastName = employee.data.lastName;
        this.nickname = employee.data.nickname;
        this.refunded = employee.data.refunded;
        this.externalReferenceId = employee.data.externalReferenceId;

        if (employee.data.nfc) {
          this.nfc.status = TagStatus.available;
          this.nfc.messageId = TagMessage.available;
        }

        if (employee.data.rfid) {
          this.rfid.status = TagStatus.available;
          this.rfid.messageId = TagMessage.available;
        }

        this.profileStatus = employee.data.status;
      });
    } catch (error) {
      AppRouter.goBack();
    } finally {
      this.loading = false;
    }
  }

  @action
  async initDepositInfo() {
    try {
      this.loading = true;

      const {
        data,
      } = await httpFacade.employees.fetchCardDepositInfoByEmployeeId(this.id);

      this.cardDepositInfo = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async blockProfile() {
    try {
      this.loading = true;

      await httpFacade.profile.blockProfile(this.id);

      this.profileStatus = ProfileStatus.inactive;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async unblockProfile() {
    try {
      this.loading = true;

      await httpFacade.profile.unblockProfile(this.id);

      this.profileStatus = ProfileStatus.active;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async attachTag() {
    try {
      await ModalStore.showModal(AssignModal, {
        userId: this.id,
      });
      await this.init();
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async resetTokenAssignment() {
    try {
      await httpFacade.profile.resetTags(this.id);

      this.rfid.status = TagStatus.default;
      this.rfid.messageId = TagMessage.notAssigned;

      this.nfc.status = TagStatus.default;
      this.nfc.messageId = TagMessage.notAssigned;

      await this.init();
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async removeTags() {
    try {
      await httpFacade.profile.resetTags(this.id);

      this.rfid.status = TagStatus.default;
      this.rfid.messageId = TagMessage.notAssigned;

      this.nfc.status = TagStatus.default;
      this.nfc.messageId = TagMessage.notAssigned;

      await httpFacade.payment.returnDeposit(this.id, {
        overdraft: false,
        tag: true,
      });
      await this.init();
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async updateBalanceAsync(event) {
    const data: TransactionData = JSON.parse(event.data);

    if (
      data?.type === SocketDataType.NEW_TRANSACTION &&
      data?.payload.source === 'EXTERNAL'
    ) {
      popup.notify(
        RootStore.localization.formatMessage('notification.balance.update'),
        'SUCCESS',
      );

      await this.report.fetchReport();
    }
  }
}

export default ProfileStore;
