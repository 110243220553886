import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';

import style from './AssignModal.module.scss';

import Information from './Information';
import Profile from './Profile';
import Spinner from 'components/Spinner/Spinner';

import AssignTags from 'stores/Modals/AssignTags';
import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  userId: string;
}

@observer
class AssignModal extends Component<Props> {
  store = new AssignTags(this.props.userId);

  async componentDidMount() {
    await this.store.init();
  }

  render() {
    const { userId, ...otherProps } = this.props;

    return (
      <Modal
        {...otherProps}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        {this.store.loading && <Spinner contain />}

        <div className={style.content}>
          <Information />
          <Profile store={this.store} />
        </div>
      </Modal>
    );
  }
}

export default AssignModal;
