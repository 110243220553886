import React from 'react';

import Spinner from 'components/Spinner/Spinner';

import style from './Spinner.module.scss';

const TableSpinner = ({ loading }): JSX.Element | null =>
  Boolean(loading) ? (
    <Spinner className={{ wrap: style.tableSpinner }} />
  ) : null;

export default TableSpinner;
