import React, { Component } from 'react';
import classNames from 'classnames';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { observer } from 'mobx-react';

import style from '../Fields.module.scss';

import { Omit } from 'helpers/types';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  invalid?: boolean;
  onChange: (v: string, event: any) => void;
  showLimit?: boolean;
  rows?: number;
}

@observer
class TextArea extends Component<Props & WrappedComponentProps> {
  textarea = React.createRef<HTMLTextAreaElement>();

  onChange = event => {
    const { target } = event;
    event.target.style.height = 'auto';
    event.target.style.height = `${target.scrollHeight}px`;

    this.props.onChange(event.target.value, event);
  };

  onKeyDown = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  componentDidMount() {
    const { current } = this.textarea;
    const { scrollHeight } = current!;

    current!.style.height = 'auto';
    current!.style.height = `${scrollHeight}px`;
  }

  render() {
    const { className, invalid, showLimit = false, rows, ...rest } = this.props;
    const value = String(rest.value);

    return (
      <div className={classNames(style.textarea)}>
        <textarea
          {...rest}
          rows={rows || 1}
          className={classNames(style.field, className, {
            [style.field__invalid]: invalid,
          })}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          ref={this.textarea}
        />

        {showLimit && (
          <span
            className={classNames(style.area__limit, {
              [style.value__max]: value.length === rest.maxLength,
            })}
          >
            {value.length} / {rest.maxLength}
          </span>
        )}
      </div>
    );
  }
}

export default injectIntl(TextArea);
