import React from 'react';
import { FormattedMessage } from 'react-intl';
import style from './NoContentBlock.module.scss';
import Icon, { icons } from '../Icon/Icon';
import classNames from 'classnames';

interface Props {
  text: string;
  icon?: keyof typeof icons;
  className?: string;
}

const NoContentBlock: React.FC<Props> = ({ text, icon, className }) => (
  <div className={classNames(style.wrapper, className)}>
    {icon && <Icon type={icon} className={style.icon} />}
    <FormattedMessage id={text} />
  </div>
);

export default NoContentBlock;
