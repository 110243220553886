import Modal from 'rc-dialog';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalProps } from 'stores/ModalStore';
import { TaskViewDto } from 'stores/Recognition/types';

import style from './TaskInfoModal.module.scss';

interface Props extends ModalProps {
  task: TaskViewDto;
}

class TaskInfoModal extends Component<Props> {
  render() {
    const { task, ...props } = this.props;
    const { cfg: config, artifacts, metrics } = task;

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <h2 className={style.modal__title}>
          <FormattedMessage id="title.task" />
        </h2>

        <div className={style.param__wrapper}>
          <div className={style.row}>
            <FormattedMessage id="title.id" values={{ id: task.id }} />
          </div>
        </div>

        <div className={style.param__wrapper}>
          <h3 className={style.title}>
            <FormattedMessage id="title.config" />
          </h3>

          <div className={style.params}>
            {Boolean(config) &&
              Object.entries(config).map(it => (
                <p key={it[0]} className={style.params__item}>
                  {it[0]} - {it[1] instanceof Array ? it[1].join(', ') : it[1]}
                </p>
              ))}
          </div>
        </div>

        <div className={style.param__wrapper}>
          <h3 className={style.title}>
            <FormattedMessage id="title.metrics" />
          </h3>

          <div className={style.params}>
            {Boolean(metrics) &&
              Object.entries(metrics).map(it => (
                <p key={it[0]} className={style.params__item}>
                  {it[0]} - {it[1]}{' '}
                </p>
              ))}
          </div>
        </div>

        <div className={style.param__wrapper}>
          <h3 className={style.title}>
            <FormattedMessage id="title.artifacts" />
          </h3>

          <div className={style.params}>
            {Boolean(artifacts) &&
              Object.entries(artifacts).map(it => (
                <a
                  key={it[1]}
                  href={it[1]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={style.link}
                >
                  {it[0]}
                </a>
              ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default TaskInfoModal;
