import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import style from './Fileloader.module.scss';

import DatasetStore from 'stores/Recognition/DatasetStore';

interface Props {
  store?: DatasetStore;
}

@inject('store')
@observer
class Fileloader extends Component<Props> {
  onDrop = async files => {
    await this.props.store!.uploadSamples(files);
  };

  render() {
    return (
      <Dropzone onDrop={this.onDrop} accept={['.jpg', '.jpeg']}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={style.dropzone}>
            <input {...getInputProps()} />

            <p className={style.dropzone__title}>
              <FormattedMessage id="description.dropzone.title" />
            </p>

            <p className={style.dropzone__description}>
              <FormattedMessage
                id="description.dropzone.description"
                values={{
                  here: (
                    <span className={style.here}>
                      <FormattedMessage id="description.dropzone.description.here" />
                    </span>
                  ),
                }}
              />
            </p>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default Fileloader;
