import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import { TransactionItem } from 'http/Api/profile';
import { getRole, Roles } from 'helpers/roles';
import { getTransactionDescription } from '../helpers';
import {
  getFormattedDate,
  getFormattedDateTime,
  SHORT_TIME_FORMAT,
} from 'helpers/datetime';

import style from './Transaction.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import ReceiptModal from 'components/Modals/Receipt/ReceiptModal';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import ModalStore from 'stores/ModalStore';

function getTransactionDate(date: string) {
  return `${getFormattedDate(date)} | 
    ${getFormattedDateTime(date, SHORT_TIME_FORMAT)}`;
}

interface Props extends TransactionItem {
  userId: string;
}

@observer
class Transaction extends Component<Props> {
  transactionID = React.createRef<HTMLSpanElement>();

  @action.bound
  onCopy() {
    const transactionID = this.transactionID.current;
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(transactionID!);

    selection?.removeAllRanges();
    selection?.addRange(range);

    document.execCommand('copy');
  }

  @action.bound
  getReceipt() {
    const { id, userId } = this.props;

    ModalStore.showModal(ReceiptModal, {
      transactionId: id,
      userId,
    });
  }

  render() {
    const {
      change,
      date,
      externalPaymentIntentId,
      hasReceipt,
      type,
    } = this.props;
    const isAccountant = getRole(Roles.accountant);

    return (
      <li className={style.content}>
        <div className={style.transaction__wrapper}>
          <header className={style.header}>
            <div className={style.transaction__title}>
              <FormattedMessage id={getTransactionDescription(type)} />
            </div>

            <div className={style.date}>{getTransactionDate(date)}</div>
          </header>

          <div
            className={classNames(
              style.amount,
              change > 0 ? style.amount__positive : style.amount__negative,
            )}
          >
            {change > 0 ? '+' : ''}
            <FormattedCurrency
              value={change}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
          </div>

          {hasReceipt && (
            <Button
              styleType="text"
              onClick={this.getReceipt}
              className={style.btn__receipt}
            >
              <Icon type="receipt" />
            </Button>
          )}
        </div>

        {isAccountant && (
          <div className={style.wrapper__id}>
            <div className={style.transaction__id}>
              ID:
              <span className={style.id} ref={this.transactionID}>
                {externalPaymentIntentId}
              </span>
            </div>

            <Button
              styleType="text"
              className={style.btn__copy}
              onClick={this.onCopy}
            >
              <FormattedMessage id="button.copy.id" />
            </Button>
          </div>
        )}
      </li>
    );
  }
}

export default Transaction;
