import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './FormSelect.module.scss';

import Button from 'components/Button/Button';

export interface FormListData {
  id: string;
  title: string;
  itemNumber?: string;
  disabled?: boolean;
}

interface Props {
  title: string;
  data: FormListData[];
  onLineClick: (value?) => void;
  activeLineID?: string;
  className?: string;
  withNone?: boolean;
}

@observer
class FormSelect extends Component<Props> {
  onLineClick = (item?) => () => {
    this.props.onLineClick(item);
  };

  render() {
    const { title, data, activeLineID, className, withNone } = this.props;

    return (
      <section className={classNames(style.select__section, className)}>
        <h2 className={style.select__title}>
          <FormattedMessage id={title} />
        </h2>

        <ul className={style.items}>
          {withNone && (
            <li>
              <Button
                className={classNames(style.item, {
                  [style.item__active]: !Boolean(activeLineID),
                })}
                styleType="text"
                onClick={this.onLineClick()}
              >
                <FormattedMessage id="title.none" />
              </Button>
            </li>
          )}

          {data.map(it => (
            <li key={it.id}>
              <Button
                className={classNames(style.item, {
                  [style.item__active]: activeLineID === it.id,
                })}
                disabled={it.disabled}
                styleType="text"
                onClick={this.onLineClick(it)}
              >
                <span className={style.item__name}>{it.title}</span>

                {it.itemNumber && (
                  <span className={style.item__code}>{it.itemNumber}</span>
                )}
              </Button>
            </li>
          ))}
        </ul>
      </section>
    );
  }
}

export default FormSelect;
