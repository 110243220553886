import WarningModal from 'components/Modals/Warning/WarningModal';
import React from 'react';
import style from './ConsentModal.module.scss';
import { observer } from 'mobx-react';

@observer
class ConsentModal extends WarningModal {
  render(): JSX.Element {
    const props = this.props;

    return <WarningModal className={style.modal} {...props} />;
  }
}

export default ConsentModal;
