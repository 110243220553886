import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { RouteChildrenProps } from 'react-router';

import UserList from './StaffList/StaffList';

import style from './StaffPage.module.scss';

type Props = RouteChildrenProps<{
  id: string;
}>;

@observer
class StaffPage extends Component<Props> {
  render() {
    return (
      <main className={style.content__tabs}>
        <UserList />
      </main>
    );
  }
}

export default StaffPage;
