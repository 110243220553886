import { TransactionTypes } from 'types/general';

export function getTransactionDescription(type: TransactionTypes) {
  switch (type) {
    case TransactionTypes.ORDER:
      return 'transaction.title.order';
    case TransactionTypes.DEPOSIT:
      return 'transaction.title.deposit';
    case TransactionTypes.UNDEPOSIT:
      return 'transaction.title.undeposit';
    case TransactionTypes.STRIPE_REFILL:
      return 'transaction.title.stripe.refill';
    case TransactionTypes.STRIPE_REFUND:
      return 'transaction.title.stripe.refund';
    case TransactionTypes.OVERDRAFT:
      return 'transaction.title.overdraft';
    case TransactionTypes.UNOVERDRAFT:
      return 'transaction.title.unoverdraft';
    case TransactionTypes.ORDER_STORNO:
      return 'transaction.title.storno';
    case TransactionTypes.BALANCE_MIGRATION:
      return 'transaction.title.migration';
    case TransactionTypes.PAYONE_REFILL:
      return 'transaction.title.payone.refill';
    case TransactionTypes.PAYONE_REFUND:
      return 'transaction.title.payone.refund';
    case TransactionTypes.DEPOSIT_ITEMS_REFUND:
      return 'transaction.title.depositItems.refund';
    default:
      return 'transaction.title.default';
  }
}
