import React from 'react';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import style from './SubsidyReport.module.scss';
import { FormattedMessage } from 'react-intl';

const config = (): Column[] => [
  {
    Header: withArrow('title.userGroups'),
    accessor: 'title',
    resizable: false,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.report.subsidy.type'),
    accessor: 'encouragementType',
    resizable: false,
    width: 99,
    Cell: row =>
      row.value === 'SUBSIDY' ? (
        <FormattedMessage id={'title.report.subsidy.type.subsidy'} />
      ) : (
        <FormattedMessage id={'title.report.subsidy.type.surcharge'} />
      ),
  },
  {
    Header: withArrow('title.report.subsidized.orders'),
    accessor: 'amount',
    resizable: false,
    width: 187,
  },
  {
    Header: withArrow('title.report.subsidy.value'),
    accessor: 'value',
    resizable: false,
    width: 137,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.total'),
    accessor: 'total',
    resizable: false,
    width: 137,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
];

export default config;
