import { action } from 'mobx';

import { wss } from 'http/helpers';
import Log from 'helpers/log';
import { getRole, Roles } from 'helpers/roles';
import { TokensResponse } from 'http/Api/User/types';

import RootStore from '../RootStore';
import { Tokens } from '../UserStore/types';

class Socket {
  private socket: WebSocket;
  private timeout;
  private errorsCount: number = 0;

  @action.bound
  connect(
    getAuthTokens: () => Tokens,
    refreshTokens: (refreshToken: string) => Promise<TokensResponse>,
  ) {
    const isAdmin = getRole(Roles.admin);
    const { notification } = RootStore.config;
    const { accessToken, refreshToken } = getAuthTokens();

    this.socket = new WebSocket(
      wss(isAdmin ? notification.mngr : notification.hr, accessToken),
    );

    this.socket.onerror = event => {
      if (refreshToken !== null) {
        this.errorsCount += 1;
      }
      Log.info(event);
    };
    this.socket.onclose = event => {
      if (event.wasClean) {
        Log.info('notification socket was closed');
      } else {
        Log.info(event);
        if (this.errorsCount > 1) {
          if (refreshToken !== null) {
            refreshTokens(refreshToken)
              .then(data => {
                this.errorsCount = 0;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(
                  () => this.connect(getAuthTokens, refreshTokens),
                  5000,
                );
              })
              .catch(error => {
                if (
                  error.response?.status === 400 &&
                  error.response?.data?.error === 'invalid_grant'
                ) {
                  return;
                }
                this.errorsCount = 0;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(
                  () => this.connect(getAuthTokens, refreshTokens),
                  5000,
                );
              });
          }
        } else {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(
            () => this.connect(getAuthTokens, refreshTokens),
            5000,
          );
        }
      }
    };
  }

  subscribe(callback) {
    this.socket?.addEventListener('message', callback);
  }

  unsubscribe(callback) {
    this.socket?.removeEventListener('message', callback);
  }

  disconnect() {
    this.socket?.close();
    clearTimeout(this.timeout);
  }
}

export default Socket;
