import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { observable } from 'mobx';

import style from './Planner.module.scss';

import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { PlannerLineModel } from 'stores/Menu/Models';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

interface Props {
  line: PlannerLineModel;
  menuPage?: MenuPageStore;
  disabled?: boolean;
}

@inject('menuPage')
@observer
class PlannerLine extends Component<Props> {
  @observable open: boolean;

  cellRef = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);

    this.open = !Boolean(this.props.line.id);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutsideHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutsideHandler);
  }

  clickOutsideHandler = event => {
    const { current } = this.cellRef;

    if (current && !current.contains(event.target)) {
      this.open = false;
      const { line, menuPage } = this.props;
      if (!line.id) {
        menuPage!.menuPlanner.deleteMenuLine(line.id);
      }
    }
  };

  toggle = () => {
    this.open = !this.open;
  };

  onSelect = value => async () => {
    const { line, menuPage } = this.props;
    const { menuPlanner } = menuPage!;

    if (value.id && line.id && line.days.some(day => day.items.length)) {
      await menuPlanner!.moveMenuLine(line.id, value.id);
    }

    ({ id: line.id, title: line.title } = value);
  };

  deleteMenuLine = async () => {
    const { line, menuPage } = this.props;

    menuPage!.menuPlanner.deleteMenuLine(line.id);
  };

  render() {
    const { line, menuPage, disabled } = this.props;
    const { menuPlanner } = menuPage!;

    return (
      <div
        className={classNames(style.td, style.line__cell, {
          [style.cell__disabled]: disabled,
        })}
        ref={this.cellRef}
      >
        <div className={style.line__wrapper}>
          <span className={style.line__title}>{line.title}</span>

          <div className={style.line__controls}>
            <Button
              styleType="text"
              disabled={
                !menuPlanner!.availableLines.length || menuPage?.isCopyMode
              }
              onClick={this.toggle}
              className={style.planner__btn}
            >
              <Icon type="edit" />
            </Button>

            <Button
              styleType="text"
              onClick={this.deleteMenuLine}
              className={style.planner__btn}
              disabled={menuPage?.isCopyMode}
            >
              <Icon type="trash" />
            </Button>
          </div>
        </div>

        {this.open && (
          <DropdownMenu
            options={menuPlanner!.availableLines.map(option => ({
              value: option,
              onSelect: this.onSelect(option),
              label: option.title,
            }))}
          />
        )}
      </div>
    );
  }
}

export default PlannerLine;
