import { action, computed, observable } from 'mobx';
import { BaseFormModel, isRequired } from '../BaseForm';
import AppRouter from '../AppRouter';
import Log from '../../helpers/log';
import { UserGroup } from '../Staff/types';
import httpFacade from '../../http/httpFacade';
import noop from '../../helpers/noop';
import { TimeRangeType } from '../../components/Form/Fields/TimeRange/TimeRange';
import RootStore from '../RootStore';
import { DishGroupManagement, MenuItem } from '../Menu/types';
import { SortConfig } from '../../helpers/types';
import { sortByAccessor } from '../../helpers/accessors';
import { IGroupTag } from '../../components/Modals/TagsSelectModal/TagsSelectModal';

export enum SubsidyType {
  SUBSIDY = 'SUBSIDY',
  DISCOUNT = 'DISCOUNT',
  SURCHARGE = 'SURCHARGE',
}

export enum ValidityInterval {
  UNLIMITED = 'UNLIMITED',
  DAILY = 'DAILY',
}

export enum DiscountAmount {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
}

export interface SubsidyDto {
  id?: string;
  title: string;
  type: SubsidyType;
  userGroupId: string | null;
  bayerCardSubsidyLevel: string | null;
  includeMenuItemIds: string[];
  excludeMenuItemIds: string[];
  includeDishGroupIds: string[];
  excludeDishGroupIds: string[];
  absoluteAmountAmount: number | null;
  absoluteAmountCurrency: string | null;
  percentageAmount: number | null;
  minimumConsumptionAmountAmount: number | null;
  minimumConsumptionAmountCurrency: string | null;
  entireReductionAmountAvailable: boolean;
  availableFromTime: string | null;
  availableToTime: string | null;
  noVat: boolean;
  paidAmountLimited: boolean;
  validityInterval: ValidityInterval;
}

class SubsidyForm extends BaseFormModel {
  @observable
  @isRequired()
  title = '';

  @observable
  type: SubsidyType = SubsidyType.SUBSIDY;

  @observable
  discountAmount: DiscountAmount | undefined = undefined;

  @observable
  amount: number | undefined;

  @observable
  userGroupId;

  @observable
  useCardNumber = false;

  @observable
  bayerCardSubsidyLevel = '';

  @observable
  noVat = true;

  @observable
  paidAmountLimited = true;

  @observable
  entireReductionAmountAvailable = false;

  @observable
  minimumConsumption = false;

  @observable
  minimumConsumptionAmountAmount: number | undefined;

  @observable loading = false;
  readonly id: string;

  @observable
  availableTime: TimeRangeType = [null, null];

  @observable
  validityInterval: ValidityInterval = ValidityInterval.UNLIMITED;

  @observable
  includeItems: IGroupTag[] = [];

  @observable
  excludeItems: IGroupTag[] = [];

  @observable private _userGroups: UserGroup[] = [];
  @observable private _menuItems: MenuItem[] = [];
  @observable private _dishGroups: DishGroupManagement[] = [];
  @observable private _menuItemsMap: Map<string, MenuItem[]> = new Map();

  private defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  constructor(id = '') {
    super();
    this.id = id;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [groups, menuItems, dishGroups] = await Promise.all([
        httpFacade.staff.fetchUserGroups(),
        httpFacade.menuItems.fetchMenuItems(),
        httpFacade.menu.fetchCategories(),
      ]);
      this._userGroups = groups.data.content;
      this._menuItems = menuItems.data;
      this._dishGroups = dishGroups.data.sort(
        sortByAccessor(this.defaultSortByTitle),
      );
      this._menuItems.forEach(item => {
        const items = this._menuItemsMap.get(item.dishGroup.id) || [];
        this._menuItemsMap.set(item.dishGroup.id, [...items, item]);
      });

      if (this.id) {
        const [{ data: subsidy }] = await Promise.all([
          httpFacade.staff.fetchBayerSubsidy(this.id),
        ]);
        this.title = subsidy.title;
        this.type = subsidy.type;
        if (subsidy.absoluteAmountAmount !== null) {
          this.discountAmount = DiscountAmount.AMOUNT;
          this.amount = subsidy.absoluteAmountAmount;
        } else if (subsidy.percentageAmount !== null) {
          this.discountAmount = DiscountAmount.PERCENT;
          this.amount = subsidy.percentageAmount;
        }
        this.userGroupId = subsidy.userGroupId;
        this.useCardNumber = !!subsidy.bayerCardSubsidyLevel;
        this.bayerCardSubsidyLevel = subsidy.bayerCardSubsidyLevel || '';
        this.noVat = subsidy.noVat;
        this.paidAmountLimited = subsidy.paidAmountLimited;
        this.entireReductionAmountAvailable =
          subsidy.entireReductionAmountAvailable;
        this.minimumConsumption =
          subsidy.minimumConsumptionAmountAmount !== null;
        this.minimumConsumptionAmountAmount =
          subsidy.minimumConsumptionAmountAmount !== null
            ? subsidy.minimumConsumptionAmountAmount
            : undefined;
        this.availableTime = [
          subsidy.availableFromTime
            ? subsidy.availableFromTime.slice(0, 5)
            : null,
          subsidy.availableToTime ? subsidy.availableToTime.slice(0, 5) : null,
        ];
        this.validityInterval = subsidy.validityInterval;
        this.includeItems = this.menuItems.filter(
          item =>
            subsidy.includeMenuItemIds.some(it => it === item.id) ||
            subsidy.includeDishGroupIds.some(it => it === item.id),
        );
        this.excludeItems = this.menuItems.filter(
          item =>
            subsidy.excludeMenuItemIds.some(it => it === item.id) ||
            subsidy.excludeDishGroupIds.some(it => it === item.id),
        );
      }
    } catch (error) {
      Log.info(error);
      AppRouter.goBack();
    } finally {
      this.loading = false;
    }
  }

  @computed
  get userGroups() {
    return this._userGroups.map(it => ({
      label: it.title,
      value: it.id,
    }));
  }

  @computed
  get validityIntervals() {
    return Object.values(ValidityInterval).map(item => ({
      label: RootStore.localization.formatMessage(`INTERVAL.${item}`),
      value: item,
    }));
  }

  @computed
  get menuItems(): IGroupTag[] {
    const menuTags: IGroupTag[] = [];
    this._dishGroups.forEach(dish => {
      const menuItems =
        this._menuItemsMap
          .get(dish.id)
          ?.slice()
          .sort(sortByAccessor(this.defaultSortByTitle))
          .map(item => {
            return {
              id: item.id,
              title: item.title,
              groupId: dish.id,
            };
          }) || [];

      menuTags.push(
        { id: dish.id, title: dish.title, isGroup: true },
        ...menuItems,
      );
    });
    return menuTags;
  }

  @action.bound
  selectIncludeItems(includeItems: IGroupTag[]) {
    this.includeItems = [...includeItems];
  }

  @action.bound
  removeIncludeItems(id?: string) {
    const isGroup = this.includeItems.find(item => item.id === id)?.isGroup;
    this.includeItems = id
      ? this.includeItems?.filter(
          item => item.id !== id && (isGroup ? item.groupId !== id : true),
        )
      : [];
  }

  @action.bound
  selectExcludeItems(excludeItems: IGroupTag[]) {
    this.excludeItems = [...excludeItems];
  }

  @action.bound
  removeExcludeItems(id?: string) {
    const isGroup = this.excludeItems.find(item => item.id === id)?.isGroup;
    this.excludeItems = id
      ? this.excludeItems?.filter(
          item => item.id !== id && (isGroup ? item.groupId !== id : true),
        )
      : [];
  }

  @computed
  get dto(): SubsidyDto {
    return {
      title: this.title,
      type: this.type,
      userGroupId: this.userGroupId || null,
      bayerCardSubsidyLevel: this.useCardNumber
        ? this.bayerCardSubsidyLevel
        : null,
      absoluteAmountAmount:
        this.discountAmount === DiscountAmount.AMOUNT &&
        this.amount !== undefined
          ? this.amount
          : null,
      absoluteAmountCurrency:
        this.discountAmount === DiscountAmount.AMOUNT &&
        this.amount !== undefined
          ? 'EUR'
          : null,
      percentageAmount:
        this.discountAmount === DiscountAmount.PERCENT &&
        this.amount !== undefined
          ? this.amount
          : null,
      noVat: this.noVat,
      paidAmountLimited: this.paidAmountLimited,
      entireReductionAmountAvailable: this.entireReductionAmountAvailable,
      minimumConsumptionAmountAmount:
        this.minimumConsumption &&
        this.minimumConsumptionAmountAmount !== undefined
          ? this.minimumConsumptionAmountAmount
          : null,
      minimumConsumptionAmountCurrency:
        this.minimumConsumption &&
        this.minimumConsumptionAmountAmount !== undefined
          ? 'EUR'
          : null,
      availableFromTime: this.availableTime[0]
        ? this.availableTime[0] + ':00'
        : null,
      availableToTime: this.availableTime[1]
        ? this.availableTime[1] + ':00'
        : null,
      validityInterval: this.validityInterval,
      includeMenuItemIds: this.includeItems
        .filter(item => !item.isGroup)
        .map(item => item.id),
      excludeMenuItemIds: this.excludeItems
        .filter(item => !item.isGroup)
        .map(item => item.id),
      includeDishGroupIds: this.includeItems
        .filter(item => item.isGroup)
        .map(item => item.id),
      excludeDishGroupIds: this.excludeItems
        .filter(item => item.isGroup)
        .map(item => item.id),
    };
  }

  @action.bound
  async onSubmit(onFulfilled = noop) {
    if (this.validate()) {
      try {
        let response;
        if (this.id) {
          this.loading = true;
          response = await httpFacade.staff.updateBayerSubsidy(
            this.id,
            this.dto,
          );
        } else {
          this.loading = true;
          response = await httpFacade.staff.createBayerSubsidy(this.dto);
        }
        onFulfilled(response.data);
      } catch (error) {
        Log.info(error);
      } finally {
        this.loading = false;
      }
    }
  }
}

export default SubsidyForm;
