import React, { Component } from 'react';
import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './Tabs.module.scss';

import Button from '../Button/Button';

import { uniqueId } from 'helpers/uniqueId';

export interface AppTab {
  title: React.ReactElement | string;
  component: React.ReactElement | string;
}

interface Props {
  tabs: AppTab[];
  tabListClass?: string;
  tabClass?: string;
  persistent?: boolean;
}

@observer
class Tabs extends Component<Props> {
  @observable activeIndex = 0;

  onTabClick = index => () => {
    this.activeIndex = index;
  };

  render() {
    const { tabs, tabListClass, tabClass, persistent } = this.props;

    return (
      <>
        <ul className={classNames(style.tab__list, tabListClass)}>
          {tabs.map((tab, index) => (
            <li key={uniqueId()}>
              <Button
                styleType="text"
                className={classNames(
                  style.btn__tab,
                  tabClass,
                  this.activeIndex === index && [style.tab__active],
                )}
                onClick={this.onTabClick(index)}
              >
                {typeof tab.title === 'string' ? (
                  <FormattedMessage id={tab.title} defaultMessage={tab.title} />
                ) : (
                  tab.title
                )}
              </Button>
            </li>
          ))}
        </ul>

        {persistent
          ? tabs.map((el, index) => (
              <div
                key={index}
                className={classNames(style.tab__content, {
                  [style.content__show]: index === this.activeIndex,
                })}
              >
                {el.component}
              </div>
            ))
          : tabs[this.activeIndex].component}
      </>
    );
  }
}

export default Tabs;
