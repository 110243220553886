import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import style from './CategoryModal.module.scss';

import { ModalProps } from 'stores/ModalStore';
import CategoryForm, { FIELDS_LIMITS } from 'stores/Forms/CategoryForm';
import { DishGroupManagement } from 'stores/Menu/types';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import Controls from '../Controls/Controls';

interface Props extends ModalProps {
  category?: DishGroupManagement;
}

@observer
class CategoryModal extends Component<Props> {
  form = new CategoryForm(this.props.category);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();

    await this.form.submit(payload => this.props.onSubmit!(payload));
  };

  render() {
    const FormControl = this.formControl;

    return (
      <Modal
        {...this.props}
        className={style.modal__category}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        {this.form.loading && <Spinner fullscreen />}

        <h2 className={style.title}>
          <FormattedMessage
            id={
              this.props.category ? 'title.editCategory' : 'title.newCategory'
            }
          />
        </h2>

        <form onSubmit={this.onSubmit}>
          <div className={style.form__body}>
            <FormControl
              name="title"
              className={style.form__row}
              render={props => <TextField {...props} />}
            />

            <div className={style.form__row}>
              <FormControl
                name="itemNumber"
                className={style.control__number}
                render={props => (
                  <TextField
                    {...props}
                    size={FIELDS_LIMITS.itemNumberLength}
                    maxLength={FIELDS_LIMITS.itemNumberLength}
                    placeholder="000"
                    value={this.form.itemNumber}
                  />
                )}
              />

              <p className={style.warning}>
                <Icon type="warning" className={style.icon} />
                <FormattedMessage id="warning.articleNumber" />
              </p>
            </div>

            <div className={style.form__row}>
              <FormattedMessage id="DEPOSIT_ITEM" />

              <FormControl
                name="forDepositItems"
                render={props => (
                  <SwitchButton
                    {...props}
                    checked={this.form.forDepositItems}
                  />
                )}
              />
            </div>
            <div className={style.form__row}>
              <FormattedMessage id="STANDART_ARTICLES" />

              <FormControl
                name="standardMenuItems"
                render={props => (
                  <SwitchButton
                    {...props}
                    checked={this.form.standardMenuItems}
                  />
                )}
              />
            </div>
            <div className={style.form__row}>
              <FormattedMessage id="form.dish.not.visible.kiosk.self.checkout" />

              <FormControl
                name="notVisibleKioskSelfCheckout"
                render={props => (
                  <SwitchButton
                    {...props}
                    checked={this.form.notVisibleKioskSelfCheckout}
                  />
                )}
              />
            </div>
          </div>

          <Controls
            onClose={this.props.onClose}
            disabledSubmit={!this.form.isFormValid}
            submitBtnTitle={
              this.props.category ? 'button.save' : 'button.create'
            }
          />
        </form>
      </Modal>
    );
  }
}

export default CategoryModal;
