import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Spinner from 'components/Spinner/Spinner';
import Table from 'components/Table/Table';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import SubsidyForm from './SubsidyForm';

import SubsidyReportForm from 'stores/Forms/Reports/SubsidyReportForm';
import SubsidyReportStore from 'stores/Report/SubsidyReportStore';

import config from './columnsConfig';

import style from './SubsidyReport.module.scss';

@observer
class SubsidyReportPage extends Component {
  store = new SubsidyReportStore();
  form = new SubsidyReportForm();

  async componentDidMount() {
    await Promise.all([
      this.form.init(),
      this.store.getReport(this.form.requestParams),
    ]);
  }

  render() {
    return (
      <section className={style.main}>
        {this.form.loading && <Spinner contain />}

        <SubsidyForm form={this.form} store={this.store} />

        {this.store.report && (
          <>
            {this.store.report.companyTitle && (
              <>
                <h3 className={style.table__title}>
                  {this.store.report.companyTitle}
                </h3>

                <div className={style.total__row}>
                  <div className={style.total__value}>
                    <FormattedMessage id="title.total" />
                  </div>

                  <div className={style.total__value}>
                    {this.store.report.amount}
                  </div>

                  <div className={style.total__value}>
                    <FormattedCurrency
                      value={this.store.report.total}
                      withSymbol={false}
                    />
                  </div>
                </div>
              </>
            )}
            <Table
              data={this.store.report.discounts}
              columns={config()}
              loading={this.store.loading}
            />
          </>
        )}
      </section>
    );
  }
}

export default SubsidyReportPage;
