import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { CopyMenuFromDayForm } from 'stores/Menu/MenuCopy/CopyMenuFromDayForm';
import { weekDayOptions } from 'stores/Menu/MenuCopy/helpers';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import { MultiSelectField } from 'components/MultiSelect/MultiSelectField';
import { MultiSelectOptions } from 'components/MultiSelect/MultiSelectOptions';
import { MultiSelectContainer } from 'components/MultiSelect/MultiSelectContainer';
import Button from 'components/Button/Button';
import style from 'pages/MenuPlanner/CopyMenu/CopyMenu.module.scss';

interface IProps {
  form: CopyMenuFromDayForm;
  onSubmit: () => void;
  disabled: boolean;
}

@observer
class CopyFromDayComponent extends Component<IProps> {
  render() {
    const { form, onSubmit, disabled } = this.props;
    const additionalOptions = [
      {
        id: 'menu.copy.everyDay',
        label: 'menu.copy.everyDay',
        onClick: form.clearSelectedOptions,
      },
      {
        id: 'menu.copy.selectWeekdays',
        label: 'menu.copy.selectWeekdays',
        onClick: form.selectWeekdays,
      },
    ];

    return (
      <>
        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="menu.copy.label.from" />
          </div>
          <Datepicker
            value={[form.from, undefined]}
            onChange={form.changeDateFrom}
            selectRange={false}
            showPeriods={false}
            icon={true}
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="menu.copy.label.toPeriod" />
          </div>
          <Datepicker
            value={form.period}
            onChange={form.changePeriod}
            selectRange={true}
            showPeriods={false}
            icon={true}
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="menu.copy.label.copy" />
          </div>
          <MultiSelectContainer
            context={{
              options: weekDayOptions,
              additionalOptions,
              selectedOptionIds: form.selectedOptionIds,
              toggleOption: form.toggleOption,
            }}
          >
            <MultiSelectField
              className={classNames(style.multiSelect, style.multiSelectField)}
            />
            <MultiSelectOptions />
          </MultiSelectContainer>
        </div>

        <Button
          onClick={onSubmit}
          className={style.btnCopy}
          disabled={form.isCopyDisabled || disabled}
        >
          <FormattedMessage id="button.copy" />
        </Button>
      </>
    );
  }
}

export { CopyFromDayComponent };
