import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { MultiSelectContext } from 'components/MultiSelect/MultiSelectContainer';
import { Popover } from 'components/MultiSelect/Popover/Popover';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';

import style from './MultiSelect.module.scss';

export const MultiSelectOptions = observer(() => {
  const context = useContext(MultiSelectContext);

  if (!context || !context.isOpened) {
    return null;
  }

  return (
    <Popover refElement={context.refElement} onClose={context.toggleOptions}>
      <div className={style.options}>
        {!!context.additionalOptions?.length &&
          context.additionalOptions.map(option => (
            <div
              key={option.id}
              className={classNames(style.option, style.additionalOption)}
              onClick={option.onClick}
            >
              <FormattedMessage
                id={option.label}
                defaultMessage={option.label}
              />
            </div>
          ))}
        {context.options.map(option => (
          <div key={option.id} className={style.option}>
            <CheckboxField
              type="green"
              checked={context.selectedOptionIds.includes(option.id)}
              onChange={() => context.toggleOption(option.id)}
              className={{
                wrapper: style.checkboxLabel,
                icon: style.checkboxIcon,
              }}
            >
              <FormattedMessage
                id={option.label}
                defaultMessage={option.label}
              />
            </CheckboxField>
          </div>
        ))}
      </div>
    </Popover>
  );
});
