import { action, computed, observable } from 'mobx';
import { SortingRule } from 'react-table';

import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';

import WarningModal from 'components/Modals/Warning/WarningModal';
import { UserGroup } from './types';
import ModalStore from 'stores/ModalStore';

class StaffGroupStore {
  @observable data: UserGroup[] = [];
  @observable sorted: SortingRule[];

  @observable pageCount: number;
  @observable searchValue = '';

  @observable loading = true;

  @computed
  get filteredData() {
    return this.searchValue
      ? this.data.filter(item =>
          item.title
            .toLocaleLowerCase()
            .includes(this.searchValue.toLocaleLowerCase()),
        )
      : this.data;
  }

  @action
  async fetchStaffGroups() {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.fetchUserGroups();

      this.data = data.content;
    } catch (error) {
      Log.error(error);
    }

    this.loading = false;
  }

  @action.bound
  async deleteStaffGroup(id: string) {
    try {
      await ModalStore.showModal(WarningModal, {
        title: 'modal.warning.title.delete',
        description: 'modal.warning.delete.group',
      });

      this.loading = true;

      await httpFacade.staff.deleteStaffGroup(id);

      this.data = this.data.filter(group => group.id !== id);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateStaffGroup(payload: UserGroup) {
    const group = this.data.find(item => item.id === payload.id);

    this.data = group
      ? this.data.map(item => (item.id === payload.id ? payload : item))
      : [...this.data, payload];
  }
}

export default StaffGroupStore;
