import { action, observable } from 'mobx';
import { SortingRule } from 'react-table';
import debounce from 'debounce';

import { getRole, Roles } from 'helpers/roles';
import { mapSortingRulesToValues } from './helpers';

import httpFacade from 'http/httpFacade';
import { PageableListRequestParams } from 'http/types';

import AppRouter from 'stores/AppRouter';

import { Employee } from './types';

import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_THROTTLE_MS,
} from '../constants';

class EmployeesStore {
  @observable data: Employee[] = [];
  @observable sorted: SortingRule[];
  @observable pageCount: number;
  @observable size = DEFAULT_PAGE_SIZE;
  @observable page = DEFAULT_PAGE;
  @observable searchTerm = '';

  @observable loading = true;

  fetch = getRole(Roles.accountant)
    ? httpFacade.employees.fetchAccountantEmployees
    : httpFacade.employees.fetchHREmployees;

  onSearch = debounce<() => void>(this.fetchEmployees, DEFAULT_THROTTLE_MS);

  @action
  async fetchEmployees(params?: PageableListRequestParams) {
    this.loading = true;

    try {
      const { data } = await this.fetch({
        ...(this.sorted !== undefined
          ? { sort: mapSortingRulesToValues(this.sorted) }
          : {}),
        ...(this.size !== undefined ? { size: this.size } : {}),
        ...(this.page !== undefined ? { page: this.page } : {}),
        ...(this.searchTerm ? { q: this.searchTerm } : {}),
        ...params,
      });
      const { content, totalPages } = data;

      // tie on numberOfElements if necessary, but not on size
      this.data = content;
      this.pageCount = totalPages;
    } catch (error) {
      AppRouter.goBack();
    }

    this.loading = false;
  }

  @action.bound
  async changeSort(newSorted: SortingRule[]) {
    this.sorted = newSorted;
    await this.fetchEmployees();
  }

  @action.bound
  async changeSize(size) {
    await this.fetchEmployees({ size });
  }

  @action.bound
  async changePageCurrent(page) {
    await this.fetchEmployees({ page });
  }

  @action.bound
  changeFilter(value) {
    this.searchTerm = value;
    this.onSearch();
  }
}

export default EmployeesStore;
