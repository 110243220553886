import { action, computed, observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { CardDepositInfo } from 'http/Api/employees';
import { PaymentType, RefillRequestDto } from 'stores/Recharge/types';
import AppRouter from 'stores/AppRouter';

class RechargePayOneStore {
  @observable refillValue: number | undefined;
  @observable paymentType: PaymentType;
  @observable cardDepositInfo: CardDepositInfo | undefined;

  @observable loading = false;

  @computed
  get requestRefillDto(): RefillRequestDto {
    return {
      amount: Number(this.refillValue),
      paymentType: this.paymentType,
      successUrl: AppRouter.currentRoute(),
      failOrCancelUrl: AppRouter.currentRoute(),
    };
  }

  @computed
  get isOptionsValid() {
    return !Boolean(this.refillValue && this.paymentType);
  }

  @action
  setPaymentType = type => {
    this.paymentType = type;
  };

  @action
  onChangeRefillValue = (value: number | undefined) => {
    this.refillValue = value;
  };

  @action.bound
  async requestRefill() {
    try {
      this.loading = true;
      const { data } = await httpFacade.payment.requestRefill(
        this.requestRefillDto,
      );

      window.open(data.uri, '_blank');
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchCardDepositInfo(userId: string) {
    try {
      this.loading = true;

      const {
        data,
      } = await httpFacade.employees.fetchCardDepositInfoByEmployeeId(userId);

      this.cardDepositInfo = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default RechargePayOneStore;
