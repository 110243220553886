import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './FeedbackModal.module.scss';

import { getFormattedDate } from 'helpers/datetime';
import { DeliveryTimeSlot, OrderModel } from 'stores/UserProfile/types';
import { getOrderDate, getTimeSlotPeriod } from 'stores/UserProfile/helpers';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

interface Props {
  order: OrderModel;
  deliveryTimeSlot?: DeliveryTimeSlot;
}

@observer
class OrderInfo extends Component<Props> {
  @observable expanded: boolean = false;

  toggleOrder = () => {
    this.expanded = !this.expanded;
  };

  render() {
    const { order, deliveryTimeSlot } = this.props;

    return (
      <div className={style.order}>
        <div className={style.order__line} onClick={this.toggleOrder}>
          <Button styleType="text" className={style.btn__arrow}>
            <Icon
              type={this.expanded ? 'arrowBottom' : 'arrowRight'}
              className={style.icon}
            />
          </Button>

          <div className={style.order__info}>
            <div className={style.order__number}>{order.queueNumber}</div>

            <div className={style.order__info__line}>
              {getOrderDate(order.createdDate)}
            </div>
            {deliveryTimeSlot && (
              <div className={style.order__info__line}>
                <FormattedMessage
                  id="user.orders.delivery.period"
                  values={{ period: getTimeSlotPeriod(deliveryTimeSlot) }}
                />
              </div>
            )}
            <div className={style.order__info__line}>
              <FormattedMessage
                id="user.orders.delivery.on"
                values={{
                  date: getFormattedDate(order.deliveryDate),
                }}
              />
            </div>
          </div>
          <span className={style.price}>
            <FormattedCurrency value={order?.finalPrice || 0} />
          </span>
        </div>

        <ul
          key={order.id}
          className={classNames(style.order__items, {
            [style.order__items__expanded]: this.expanded,
          })}
        >
          {order.items.map(item => (
            <li key={item.tag} className={style.order__item}>
              <span className={style.item__amount}>{item.amount}x</span>
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default OrderInfo;
