import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Omit } from 'helpers/types';

import style from '../Fields.module.scss';

const CENTS = 100;

function toFixedNumber(num, digits, base?) {
  const pow = Math.pow(base || 10, digits);
  return Math.round(num * pow) / pow;
}

function formattedValue(value: number, withZeroCents?: boolean) {
  const showZeroCents = withZeroCents && value % 100 === 0;

  return showZeroCents
    ? `${String(value / CENTS).replace('.', ',')},00`
    : String(value / CENTS).replace('.', ',');
}

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 2, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  guide: true,
};

interface Props extends Omit<MaskedInputProps, 'onChange' | 'mask'> {
  postfix?: string;
  placeholder?: string;
  className?: string;
  maskOptions?: object;
  onChange?: (v: number | undefined) => void;
  invalid?: boolean;
  value: number | undefined;
}

@observer
class CurrencyInput extends React.Component<Props> {
  @observable showValue = '';

  @action
  setShowValue(value: number | undefined, withZeroCents?: boolean) {
    this.showValue =
      value !== undefined ? formattedValue(value, withZeroCents) : '';
  }

  @action
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue =
      event.target.value !== ''
        ? toFixedNumber(Number(event.target.value.replace(',', '.')) * CENTS, 2)
        : undefined;

    this.setShowValue(parsedValue);

    if (this.props.onChange) {
      this.props.onChange(parsedValue);
    }
  };

  @action
  onBlur = () => {
    this.setShowValue(this.props.value, true);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setShowValue(this.props.value);
    }
  }

  componentDidMount() {
    this.setShowValue(this.props.value, true);
  }

  render() {
    const { maskOptions, ...props } = this.props;

    const currencyMask = createNumberMask({
      ...defaultMaskOptions,
      ...maskOptions,
    });

    const { className, placeholder, postfix, invalid, value, ...rest } = props;

    return (
      <div
        className={classNames(className, style.number__wrapper)}
        data-postfix={postfix}
      >
        <MaskedInput
          {...rest}
          mask={currencyMask}
          onChange={this.onChange}
          onBlur={this.onBlur}
          className={classNames(style.field, {
            [style.field__postfix]: postfix,
            [style.field__invalid]: invalid,
          })}
          placeholder={placeholder || '0,00'}
          type="text"
          value={this.showValue}
        />
      </div>
    );
  }
}

export default CurrencyInput;
