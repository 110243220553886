const timePlaceholder = '00';
const timeDivider = ':';

function fillTimeWithNull(time) {
  return time < 10 ? 0 : '';
}

export function checkAndAutofillPartsOfTime(
  inputsArray: HTMLInputElement[],
  value,
) {
  let time = value;

  if (inputsArray[0].value && !inputsArray[1].value) {
    time[0] = `${fillTimeWithNull(inputsArray[0].value)}${
      inputsArray[0].value
    }${timeDivider}${timePlaceholder}`;
  }

  if (inputsArray[2].value && !inputsArray[3].value) {
    time[1] = `${fillTimeWithNull(inputsArray[2])}${
      inputsArray[2].value
    }${timeDivider}${timePlaceholder}`;
  }

  if (!inputsArray[2].value && inputsArray[3].value && !Array.isArray(time)) {
    const part1 =
      !inputsArray[0].value && !inputsArray[1].value
        ? null
        : `${fillTimeWithNull(inputsArray[0].value)}${
            inputsArray[0].value
          }${timeDivider}${fillTimeWithNull(inputsArray[1].value)}${
            inputsArray[1].value
          }`;
    time = [
      part1,
      `${timePlaceholder}${timeDivider}${fillTimeWithNull(
        inputsArray[3].value,
      )}${inputsArray[3].value}`,
    ];
  }

  if (!inputsArray[0].value && inputsArray[1].value && !Array.isArray(time)) {
    const part2 =
      !inputsArray[2].value && !inputsArray[3].value
        ? null
        : `${fillTimeWithNull(inputsArray[2].value)}${
            inputsArray[2].value
          }${timeDivider}${fillTimeWithNull(inputsArray[3].value)}${
            inputsArray[3].value
          }`;
    time = [
      `${timePlaceholder}${timeDivider}${fillTimeWithNull(
        inputsArray[1].value,
      )}${inputsArray[1].value}`,
      part2,
    ];
  }

  return time;
}
