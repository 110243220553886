import React from 'react';
import { Column } from 'react-table';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from '../Recognition.module.scss';

import { withArrow } from 'components/Table/helpers';

import { DATE_TIME_FORMAT, getFormattedDateTime } from 'helpers/datetime';

const config = (): Column[] => [
  {
    Header: withArrow('title.agents.name'),
    accessor: 'name',
    resizable: true,
    minWidth: 400,
    Cell: row => <span className={style.name}>{row.value}</span>,
  },
  {
    Header: withArrow('title.tasks.running'),
    accessor: 'statistics',
    resizable: false,
    width: 120,
    Cell: row => (
      <span className={style.name}>{row.value.runningTasksCount}</span>
    ),
  },
  {
    Header: withArrow('title.tasks.started'),
    accessor: 'statistics',
    resizable: false,
    width: 120,
    Cell: row => (
      <span className={style.name}>
        {row.value.startedTasksCount ||
          row.value.successfulDetectionsCount ||
          0}
      </span>
    ),
  },
  {
    Header: withArrow('title.tasks.failed'),
    accessor: 'statistics',
    resizable: false,
    minWidth: 185,
    Cell: row => (
      <span className={style.name}>
        {row.value.failedTasksCount || row.value.failedDetectionsCount || 0}
      </span>
    ),
  },
  {
    Header: withArrow('title.tasks.status'),
    accessor: 'healthStatus',
    resizable: false,
    width: 185,
    Cell: row =>
      row.value ? (
        <span className={classNames(style.state, style.state__common)}>
          <FormattedMessage id={row.value} defaultMessage={row.value} />
        </span>
      ) : null,
  },
  {
    Header: withArrow('title.startedAt'),
    accessor: 'startedAt',
    resizable: false,
    width: 160,
    Cell: row => getFormattedDateTime(row.value, DATE_TIME_FORMAT),
  },
];

export default config;
