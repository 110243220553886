import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { IDataset } from './types';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../constants';

import Log from 'helpers/log';
import { SortConfig } from 'helpers/types';
import { sortByAccessor } from 'helpers/accessors';

class DatasetsStore {
  @observable datasets: IDataset[] = [];

  @observable pageCount: number;
  @observable size = DEFAULT_PAGE_SIZE;
  @observable page = DEFAULT_PAGE;
  @observable searchValue = '';

  @observable loading = false;

  private defaultSort: SortConfig = {
    accessor: 'lastModifiedDate',
    desc: true,
  };

  @action.bound
  async fetchDatasets() {
    this.loading = true;

    try {
      const { data } = await httpFacade.recognition.fetchDatasets();

      this.datasets = data.sort(sortByAccessor(this.defaultSort));
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteDataset(id: string) {
    try {
      const dto = this.datasets.find(dataset => dataset.id === id);

      if (dto) {
        this.loading = true;

        await httpFacade.recognition.deletedDataset(id, {
          id: dto!.id,
          version: dto!.version,
          name: dto!.name,
        });

        this.datasets = this.datasets.filter(dataset => dataset.id !== id);
      }
    } finally {
      this.loading = false;
    }
  }

  @computed
  get filteredItems() {
    return this.datasets.filter(el =>
      this.searchValue.trim()
        ? el.name
            .toLowerCase()
            .includes(this.searchValue.trim().toLocaleLowerCase())
        : el,
    );
  }
}

export default DatasetsStore;
