import { ITag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { sortByAccessor } from 'helpers/accessors';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { action, computed, observable } from 'mobx';
import AppRouter from 'stores/AppRouter';
import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import {
  CreateTaskReqParams,
  IDataset,
  TaskType,
} from 'stores/Recognition/types';

export const OptionsLimits = {
  amount: 1,
  minObjectsPerImageMin: 1,
  maxObjectsPerImageMin: 1,
};

interface TaskParams {
  datasetIds: string[];
  bgDatasetIds: string[];
  amount: number;
  minObjectsPerImage: number;
  maxObjectsPerImage: number;
  minVisiblePart: number;
  maxObjectsPerClassStored: number;
  contextStrategyProb: number;
  contextStrategyNumAngles: number;
  contextStrategyMaxRepetitions: number;
  addLightingProb: number;
  globalAugmentProb: number;
  globalAugmentMin: number;
  globalAugmentMax: number;
  globalAugmentAdditiveGaussianNoiseScale: number;
  globalAugmentGrayscaleLowerRate: number;
  globalAugmentGrayscaleUpperRate: number;
  globalAugmentChangeColorTemperatureLowerValue: number;
  globalAugmentChangeColorTemperatureUpperValue: number;
  globalAugmentGaussianBlurLowerRate: number;
  globalAugmentGaussianBlurUpperRate: number;
  instanceAugmentProb: number;
  instanceAugmentMin: number;
  instanceAugmentMax: number;
  instanceAugmentChannelShuffleProb: number;
  instanceAugmentMotionBlurFilterSize: number;
  instanceAugmentGrayscaleLowerRate: number;
  instanceAugmentGrayscaleUpperRate: number;
  instanceAugmentEnhanceSharpnessLowerRate: number;
  instanceAugmentEnhanceSharpnessUpperRate: number;
  instanceAugmentGammaContrastLowerRate: number;
  instanceAugmentGammaContrastUpperRate: number;
  instanceAugmentAddLowerValue: number;
  instanceAugmentAddUpperValue: number;
  instanceAugmentAddPerChannelProb: number;
}

class TaskDatasetGeneratorForm extends BaseFormModel {
  static readonly DEFAULT_PARAMS: TaskParams = {
    datasetIds: [''],
    bgDatasetIds: [''],
    amount: 50,
    minObjectsPerImage: 3,
    maxObjectsPerImage: 5,
    minVisiblePart: 1.0,
    maxObjectsPerClassStored: 100,
    contextStrategyProb: 0.5,
    contextStrategyNumAngles: 36,
    contextStrategyMaxRepetitions: 5,
    addLightingProb: 0.0,
    globalAugmentProb: 0.5,
    globalAugmentMin: 1,
    globalAugmentMax: -1,
    globalAugmentAdditiveGaussianNoiseScale: 0.0,
    globalAugmentGrayscaleLowerRate: 0.0,
    globalAugmentGrayscaleUpperRate: 0.0,
    globalAugmentChangeColorTemperatureLowerValue: 3500,
    globalAugmentChangeColorTemperatureUpperValue: 6500,
    globalAugmentGaussianBlurLowerRate: 0.0,
    globalAugmentGaussianBlurUpperRate: 0.0,
    instanceAugmentProb: 0.5,
    instanceAugmentMin: 1,
    instanceAugmentMax: -1,
    instanceAugmentChannelShuffleProb: 0.3,
    instanceAugmentMotionBlurFilterSize: 5,
    instanceAugmentGrayscaleLowerRate: 0.0,
    instanceAugmentGrayscaleUpperRate: 0.0,
    instanceAugmentEnhanceSharpnessLowerRate: 0.8,
    instanceAugmentEnhanceSharpnessUpperRate: 2.0,
    instanceAugmentGammaContrastLowerRate: 0.4,
    instanceAugmentGammaContrastUpperRate: 0.8,
    instanceAugmentAddLowerValue: -20,
    instanceAugmentAddUpperValue: 20,
    instanceAugmentAddPerChannelProb: 0.5,
  };

  type: TaskType.GENERATE_DATASET;

  @observable
  @displayName('DESCRIPTION*')
  @isRequired()
  description: string;

  @observable
  selectedDatasets: ITag[] = [];

  @observable
  selectedBgDatasets: ITag[] = [];

  @observable
  @displayName('AMOUNT*')
  @isRequired()
  amount: number = 50;

  @observable
  @isRequired()
  @displayName('MIN_OBJECT_PER_IMAGE*')
  minObjectsPerImage: number = 3;

  @observable
  @isRequired()
  @displayName('MAX_OBJECT_PER_IMAGE*')
  maxObjectsPerImage: number = 5;

  @observable
  loading: boolean = false;

  @observable
  datasets: IDataset[] = [];

  @computed
  get datasetTags(): ITag[] {
    return this.datasets
      .map(dataset => ({
        id: dataset.id,
        title: dataset.name,
        date: dataset.createdDate,
      }))
      .sort(sortByAccessor({ desc: true, accessor: 'date' }));
  }

  constructor(type: TaskType.GENERATE_DATASET) {
    super();

    this.type = type;
  }

  @computed
  get datasetIds(): string[] {
    return this.selectedDatasets.map(dataset => dataset.id);
  }

  @computed
  get bgDatasetIds(): string[] {
    return this.selectedBgDatasets.map(dataset => dataset.id);
  }

  @computed
  get requestParams(): CreateTaskReqParams<TaskParams> {
    const params = TaskDatasetGeneratorForm.DEFAULT_PARAMS;
    params.datasetIds = this.datasetIds;
    params.bgDatasetIds = this.bgDatasetIds;
    params.amount = Number(this.amount);
    params.minObjectsPerImage = Number(this.minObjectsPerImage);
    params.maxObjectsPerImage = Number(this.maxObjectsPerImage);
    return {
      type: this.type,
      description: this.description,
      params,
    };
  }

  @action
  async init() {
    try {
      this.loading = true;

      const datasets = await httpFacade.recognition.fetchDatasets();

      this.datasets = datasets.data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onCreate() {
    try {
      this.loading = true;

      await httpFacade.recognition.createTask(this.requestParams);

      AppRouter.goBack();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setDatasets(tags: ITag[]) {
    this.selectedDatasets = tags;
  }

  @action.bound
  removeDataset(id?: string) {
    this.selectedDatasets = id
      ? this.selectedDatasets.filter(it => it.id !== id)
      : [];
  }

  @action.bound
  setBgDatasets(tags: ITag[]) {
    this.selectedBgDatasets = tags;
  }

  @action.bound
  removeBgDataset(id?: string) {
    this.selectedBgDatasets = id
      ? this.selectedBgDatasets.filter(it => it.id !== id)
      : [];
  }
}

export default TaskDatasetGeneratorForm;
