import { DateTime } from 'luxon';
import { action, computed, observable, reaction } from 'mobx';

import httpFacade from 'http/httpFacade';

import { AllergenType, Catering, IMenuLine, MenuItem, Periods } from './types';
import { PlannerLineModel } from './Models';

import Log from 'helpers/log';
import { groupByCategories } from './helpers';
import { sortByAccessor } from 'helpers/accessors';
import { getDateTimeFromISO, getLocalDateTime } from 'helpers/datetime';

// week
const PERIOD = 7;
export type SwipeDirection = 'previous' | 'next';

class MenuPlannerStore {
  @observable date = getLocalDateTime();
  @observable currentDate = this.date.toISODate();
  @observable activeWeekDate = '';
  @observable searchValue: string = '';

  @observable plannerLines: PlannerLineModel[] = [];
  @observable menuLines: IMenuLine[] = [];
  @observable menuItems: MenuItem[] = [];
  @observable allergens: AllergenType[] = [];
  @observable activeMenuLine: PlannerLineModel;
  @observable catering: Catering;

  @observable loading = false;

  @observable private sortConfig = { accessor: 'title', desc: false };

  @computed
  get weekStartDate() {
    return this.date.startOf(Periods.week);
  }

  @computed
  get weekEndDate() {
    return this.date.endOf(Periods.week);
  }

  @computed
  get weekNumber() {
    return this.date.weekNumber;
  }

  @computed
  get categories() {
    const regExp = new RegExp(this.searchValue, 'i');
    const filteredMenuItems = this.menuItems.filter(item =>
      this.searchValue ? regExp.test(item.title) : item,
    );

    return groupByCategories(filteredMenuItems).sort(
      sortByAccessor(this.sortConfig),
    );
  }

  @computed
  get canAddMenuLine(): boolean {
    return this.currentDate <= this.period.slice(-1)[0];
  }

  @computed
  get availableLines() {
    return this.menuLines.filter(
      mLine =>
        !mLine.deleted &&
        !this.plannerLines.find(pLine => pLine.id === mLine.id),
    );
  }

  @computed
  get activeDay() {
    return this.activeMenuLine.days.find(
      day => day.date === this.activeWeekDate,
    );
  }

  @computed
  get selectedItems(): string[] {
    return this.activeDay ? this.activeDay.items.map(item => item.id) : [];
  }

  @computed
  get period(): string[] {
    const period: string[] = [];

    for (
      let day = this.weekStartDate;
      day.weekday <= PERIOD && period.length < PERIOD;
      day = day.plus({ day: 1 })
    ) {
      period.push(day.toISODate());
    }

    return period;
  }

  @computed
  get availableWeekDays(): string[] {
    return this.period.filter(period => period >= this.currentDate);
  }

  constructor() {
    reaction(
      () => this.date,
      async () => {
        await this.fetchMenu();
      },
    );
  }

  @action.bound
  async init() {
    try {
      [{ data: this.allergens }, { data: this.catering }] = await Promise.all([
        await httpFacade.menuItems.fetchAllergens(),
        httpFacade.catering.fetchCatering(),
      ]);
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  swipeWeek(value: SwipeDirection) {
    this.date =
      value === 'previous'
        ? this.date.minus({ week: 1 })
        : this.date.plus({ week: 1 });
  }

  @action.bound
  toggleItem(item: MenuItem) {
    if (this.activeDay) {
      if (this.activeDay.items.some(value => value.id === item.id)) {
        this.activeDay.items = this.activeDay.items.filter(
          value => value.id !== item.id,
        );
      } else {
        this.activeDay.items.push(item);
      }
    }
  }

  @action.bound
  addLine() {
    const line = new PlannerLineModel();

    line.days.push(...this.period.map(date => ({ date, items: [] })));

    this.plannerLines = [line, ...this.plannerLines];
  }

  @action.bound
  changeDate([date]: DateTime[]) {
    this.date = date;
  }

  @action.bound
  nextWeekDay() {
    // last day of the with according to DateTime
    const last = this.availableWeekDays.slice(-1)[0];
    const activeWeekDay = getDateTimeFromISO(this.activeWeekDate);

    this.activeWeekDate =
      activeWeekDay.toISODate() !== last
        ? activeWeekDay.plus({ day: 1 }).toISODate()
        : this.activeWeekDate;
  }

  @action.bound
  previousWeekDay() {
    // first day of the with according to DateTime
    const first = this.availableWeekDays[0];
    const activeWeekDay = getDateTimeFromISO(this.activeWeekDate);

    this.activeWeekDate =
      activeWeekDay.toISODate() !== first
        ? activeWeekDay.minus({ day: 1 }).toISODate()
        : this.activeWeekDate;
  }

  @action.bound
  async fetchMenu() {
    try {
      this.loading = true;

      const [menu, lines] = await Promise.all([
        httpFacade.menu.fetchPlannerMenu(
          this.weekStartDate.toISODate(),
          this.weekEndDate.toISODate(),
        ),
        await httpFacade.menu.fetchMenuLines(),
      ]);

      this.menuLines = lines.data;
      this.plannerLines = menu.data.map(el => {
        const line = this.menuLines.find(item => item.id === el.line) || {
          title: '',
          deleted: true,
        };

        return {
          id: el.line,
          title: line.title,
          days: this.period.map(
            date =>
              el.days.find(day => day.date === date) || { date, items: [] },
          ),
          deleted: line.deleted,
        };
      });

      this.loading = false;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteMenuLine(id?: string) {
    try {
      this.loading = true;

      if (id) {
        await httpFacade.menu.deleteMenu(
          id,
          this.weekStartDate.toISODate(),
          this.weekEndDate.toISODate(),
        );

        this.plannerLines = this.plannerLines.filter(line => line.id !== id);
      } else {
        // delete new empty line
        this.plannerLines = this.plannerLines.filter(line => Boolean(line.id));
      }
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateMenuLine() {
    try {
      this.loading = true;

      const data = [
        {
          line: this.activeMenuLine.id,
          days: this.activeMenuLine.days.map(day => ({
            date: day.date,
            items: day.items.map(item => item.id),
          })),
        },
      ];

      await httpFacade.menu.updateMenu(data);

      this.plannerLines = this.plannerLines.map(line =>
        line.id === this.activeMenuLine.id ? this.activeMenuLine : line,
      );
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchMenuItems() {
    try {
      this.loading = true;

      const { data } = await httpFacade.menuItems.fetchMenuItems();
      this.menuItems = data?.sort?.(sortByAccessor(this.sortConfig));
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async moveMenuLine(
    lineSource: string,
    lineDestination: string,
    from?: string,
    to?: string,
  ) {
    try {
      this.loading = true;

      const data = {
        lineSource,
        lineDestination,
        from: from || this.weekStartDate.toISODate(),
        to: to || this.weekEndDate.toISODate(),
      };

      await httpFacade.menu.moveMenuLine(data);
    } finally {
      this.loading = false;
    }
  }
}

export default MenuPlannerStore;
