import http from 'http/http';

import RootStore from 'stores/RootStore';

type FetchTagGetResponse = string;

export const adapterApi = {
  fetchTagFromRFID() {
    return http.get<FetchTagGetResponse>(RootStore.config.rfid, {
      skipParamReplace: true,
      skipHttpErrorHandler: true,
    });
  },
};
