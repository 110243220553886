import React from 'react';
import { FormattedMessage } from 'react-intl';

import style from './Controls.module.scss';

import Button from 'components/Button/Button';

export interface ModalControlsProps {
  onClose: () => void;
  onSubmit?: () => void;
  disabledSubmit?: boolean;
  submitBtnTitle: string;
}

const Controls = ({
  onClose,
  onSubmit,
  disabledSubmit = false,
  submitBtnTitle,
}: ModalControlsProps) => {
  return (
    <div className={style.controls}>
      <Button className={style.btn} styleType="ghost" onClick={onClose}>
        <FormattedMessage id="button.cancel" />
      </Button>

      <Button
        className={style.btn}
        type="submit"
        disabled={disabledSubmit}
        onClick={onSubmit}
      >
        <FormattedMessage id={submitBtnTitle} />
      </Button>
    </div>
  );
};

export default Controls;
