import Modal from 'rc-dialog';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalProps } from 'stores/ModalStore';

import style from './NewDeploymentModal.module.scss';
import { MLModelWithDeployments } from '../../../stores/Recognition/types';
import classNames from 'classnames';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import TextArea from '../../Form/Fields/TextArea/TextAreaField';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import NewDeploymentForm, {
  NewDeploymentFormStates,
} from '../../../stores/Forms/NewDeploymentForm';
import { observer } from 'mobx-react';

interface Props extends ModalProps {
  model: MLModelWithDeployments;
}

@observer
class NewDeploymentModal extends Component<Props> {
  form = new NewDeploymentForm(this.props.model);
  formControl = bindFormControl(this.form);

  async componentDidMount() {
    await this.form.fetchServingTags();
  }

  onSubmit = () => this.form.onSubmit();

  onChangeModalState = async (state: string) => {
    this.form.changeFormState(state);
  };

  onCloseModal = () => {
    if (this.form.formState === NewDeploymentFormStates.SELECT_SERVING_TAG) {
      this.onChangeModalState(NewDeploymentFormStates.DEFAULT);
    } else {
      this.props.onClose();
    }
  };

  render() {
    const FormControl = this.formControl;

    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onCloseModal}
        visible
      >
        {this.form.formState === NewDeploymentFormStates.DEFAULT && (
          <>
            <div className={style.header}>
              <FormattedMessage id="title.new.deployment" tagName={'h2'} />
            </div>
            <form onSubmit={this.onSubmit} className={style.form}>
              <div className={style.body}>
                <div className={style.content}>
                  <div className={style.column}>
                    <span className={style.label}>
                      <FormattedMessage id="title.model" />
                    </span>
                    <span className={style.text}>
                      {this.props.model.architecture.name}
                    </span>
                  </div>

                  <div className={style.column}>
                    <span className={style.label}>
                      <FormattedMessage id="title.serving.tag" />
                    </span>
                    {this.form.selectedServingTag && (
                      <span className={style.text}>
                        {this.form.selectedServingTag}
                      </span>
                    )}
                    <div className={classNames(style.row, style.row__button)}>
                      <span className={style.label}>
                        <FormattedMessage id="title.select" />
                      </span>
                      <div className={style.btn__arrow__block}>
                        <Button
                          styleType="text"
                          onClick={() =>
                            this.onChangeModalState(
                              NewDeploymentFormStates.SELECT_SERVING_TAG,
                            )
                          }
                          className={style.btn__arrow}
                        >
                          <Icon type={'arrowRight'} />
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={style.column}>
                    <span className={style.label}>
                      <FormattedMessage id="title.description" />
                    </span>
                    <FormControl
                      name="description"
                      render={props => (
                        <TextArea
                          {...props}
                          maxLength={150}
                          className={style.textarea}
                          rows={9}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={style.footer}>
                <Button
                  styleType="ghost"
                  onClick={this.props.onClose}
                  className={style.btn}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>

                <Button
                  type="submit"
                  styleType="primary"
                  className={style.btn}
                  disabled={!this.form.selectedServingTag}
                >
                  <FormattedMessage id={'button.deploy'} />
                </Button>
              </div>
            </form>
          </>
        )}

        {this.form.formState === NewDeploymentFormStates.SELECT_SERVING_TAG && (
          <>
            <div className={classNames(style.header, style.header__arrow)}>
              <Button
                styleType="text"
                onClick={() =>
                  this.form.changeFormState(NewDeploymentFormStates.DEFAULT)
                }
                className={style.btn__arrow}
              >
                <Icon type={'arrowLeft'} />
              </Button>
              <FormattedMessage id="title.deployments" tagName={'h2'} />
            </div>
            <ul className={style.body}>
              {this.form.servingTags?.map?.(servingTag => (
                <li key={servingTag.id}>
                  <Button
                    styleType="text"
                    className={classNames(style.servingTag, {
                      [style.servingTag__anable]: !servingTag.disabled,
                    })}
                    disabled={servingTag.disabled}
                    onClick={() => {
                      this.form.selectServingTag(servingTag.title);
                      this.form.changeFormState(
                        NewDeploymentFormStates.DEFAULT,
                      );
                    }}
                  >
                    {servingTag?.title}
                  </Button>
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal>
    );
  }
}

export default NewDeploymentModal;
