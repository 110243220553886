import { observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { Catering } from 'stores/Menu/types';

class HelpModalStore {
  @observable catering: Catering;
  @observable loading = false;

  async fetchCatering() {
    try {
      this.loading = true;

      const { data } = await httpFacade.catering.fetchCatering();
      this.catering = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default HelpModalStore;
