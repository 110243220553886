import { action, observable, reaction } from 'mobx';
import httpFacade from 'http/httpFacade';
import {
  BaseFormModel,
  isRequired,
  displayName,
  hasMaxLength,
  isRegEx,
  isSameAs,
  isNotSameAs,
} from '../BaseForm';
import noop from '../../helpers/noop';
import ModalStore, { DialogActionType } from '../ModalStore';
import Log from '../../helpers/log';
import { popup } from '../Popup';
import RootStore from '../RootStore';

export interface ChangePasswordDTO {
  oldPassword: string;
  newPassword: string;
}

class ChangePasswordForm extends BaseFormModel {
  @observable
  @displayName('PREVIOUS_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  previousPassword = '';

  @observable
  @displayName('NEW_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  @isRegEx(
    /(?=.*[A-ZÄÖÜ])(?=.*[a-zäöü])(?=.*[0-9])(?=.{8,})/,
    'VM_IS_REGEX_PWD',
  )
  @isNotSameAs('previousPassword', 'VM_IS_NOT_SAME_AS_PASSWORD')
  newPassword = '';

  @observable
  @displayName('CONFIRM_NEW_PASSWORD')
  @isRequired()
  @hasMaxLength(25)
  @isSameAs('newPassword', 'VM_IS_SAME_AS_PWD')
  confirmNewPassword = '';

  @observable readonly userId: string;

  @observable isValidCharactersNumber: boolean;
  @observable isValidUppercaseAndLowercase: boolean;
  @observable isValidHaveNumber: boolean;

  constructor(userId: string) {
    super();
    this.userId = userId;
    this.newPassword = '';

    reaction(
      () => this.newPassword,
      () => {
        this.isValidHaveNumber = /(?=.*[0-9])/.test(this.newPassword);
        this.isValidUppercaseAndLowercase = /(?=.*[A-ZÄÖÜ])(?=.*[a-zäöü])/.test(
          this.newPassword,
        );
        this.isValidCharactersNumber = /(?=.{8,})/.test(this.newPassword);
      },
    );
  }

  @action.bound
  async submit(fullFilled = noop) {
    if (this.validate()) {
      try {
        const requestData: ChangePasswordDTO = {
          oldPassword: this.previousPassword,
          newPassword: this.newPassword,
        };
        const response = await httpFacade.user.changePassword(
          this.userId,
          requestData,
        );
        if (response.status === 200) {
          popup.notify(
            RootStore.localization.formatMessage(
              'notification.password.save.success',
            ),
            'SUCCESS',
          );
        }
        fullFilled(response.data);
      } catch (error) {
        Log.warn(error);
        ModalStore.closeModal(DialogActionType.cancel);
      }
    }
  }
}

export default ChangePasswordForm;
