import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './CategoryPanel.module.scss';

import ModalStore from 'stores/ModalStore';
import CategoriesStore from 'stores/Menu/CategoriesStore';

import CategoryModal from 'components/Modals/Category/CategoryModal';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import WarningModal from 'components/Modals/Warning/WarningModal';

interface Props {
  store: CategoriesStore;
}

@observer
class CategoryPanel extends Component<Props> {
  onClick = (id?) => () => {
    this.props.store.changeCategory(id);
  };

  onCategoryEdit = category => async () => {
    const { payload } = await ModalStore.showModal(CategoryModal, { category });
    this.props.store.updateCategories(payload);
  };

  onCategoryDelete = id => async () => {
    await this.props.store.deleteCategory(id);
  };

  onCategoryKioskSelfCheckoutVisibilityChange = category => async () => {
    await ModalStore.showModal(WarningModal, {
      title: category.notVisibleKioskSelfCheckout
        ? 'modal.warning.title.confirm.display'
        : 'modal.warning.title.confirm.hide',
      description: category.notVisibleKioskSelfCheckout
        ? 'modal.warning.confirm.display'
        : 'modal.warning.confirm.hide',
      type: 'change',
    });
    await this.props.store.changeCategoryKioskSelfCheckoutVisibility(category);
  };

  render() {
    const { store } = this.props;
    const empty = !Boolean(store.categories.length);

    return (
      <div className={style.panel}>
        <div className={style.panel__header}>
          <FormattedMessage id="title.category" />

          {store.activeCategoryID && (
            <Button styleType="text" onClick={this.onClick()}>
              <FormattedMessage id="title.showAllDishes" />
            </Button>
          )}
        </div>

        <ul
          className={classNames({
            [style.list__empty]: empty,
          })}
        >
          {empty ? (
            <pre className={style.wrapper__empty}>
              <Icon type="plate" className={style.icon__plate} />
              <FormattedMessage id="category.empty" />
            </pre>
          ) : (
            store.categories.map(category => (
              <li
                className={classNames(style.category, {
                  [style.category__active]:
                    category.id === store.activeCategoryID,
                })}
                key={category.id}
                onClick={this.onClick(category.id)}
              >
                <div className={style.category__titleWrapper}>
                  <span className={style.category__title}>
                    {category.title}
                  </span>

                  {category.standardMenuItems && (
                    <span className={style.category__standardArticlesTitle}>
                      <FormattedMessage id="category.standard.articles" />
                    </span>
                  )}
                </div>

                <span className={style.code}>{category.itemNumber}</span>

                <div className={style.controls}>
                  <Button
                    styleType="text"
                    className={style.control}
                    onClick={this.onCategoryKioskSelfCheckoutVisibilityChange(
                      category,
                    )}
                  >
                    <Icon
                      type={
                        category.notVisibleKioskSelfCheckout
                          ? 'iconHide'
                          : 'iconShow'
                      }
                    />
                  </Button>
                  <Button
                    styleType="text"
                    className={style.control}
                    onClick={this.onCategoryEdit(category)}
                  >
                    <Icon type="edit" />
                  </Button>
                  <Button
                    styleType="text"
                    className={style.control}
                    onClick={this.onCategoryDelete(category.id)}
                  >
                    <Icon type="trash" />
                  </Button>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    );
  }
}

export default CategoryPanel;
