import React from 'react';
import Icon from 'components/Icon/Icon';
import { FormattedMessage } from 'react-intl';

function BrowserInfo() {
  return (
    <section className={'browserInfo'}>
      <div className={'browserInfo__text'}>
        <FormattedMessage id="page.login.browser.info" />
      </div>

      <div className={'browserInfo__icons'}>
        <Icon type="safari" className="browserInfo__icon" />

        <Icon type="chrome" className="browserInfo__icon" />
      </div>
    </section>
  );
}

export default BrowserInfo;
