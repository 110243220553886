import { observable, action } from 'mobx';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/routes';

import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';

import './style.scss';

import AppLogo from 'components/AppLogo/AppLogo';
import Icon from 'components/Icon/Icon';
import HelpModal from 'components/Modals/Help/HelpModal';
import Button from 'components/Button/Button';
import ActionSelect from 'components/ActionSelect/ActionSelect';
import { getRole, Roles } from '../../helpers/roles';

@observer
class MenuMobile extends Component<{ showHelp?: boolean }> {
  @observable showMenu = false;
  options: Array<{ action: () => void; title: string }> = [];

  constructor(props) {
    super(props);

    this.options = this.props.showHelp
      ? [
          {
            action: this.onHelpHandler,
            title: 'button.impressum',
          },
          {
            action: RootStore.user.logout,
            title: 'button.logout',
          },
        ]
      : [
          {
            action: RootStore.user.logout,
            title: 'button.logout',
          },
        ];
  }

  @action
  toggleMenu = () => {
    this.showMenu = !this.showMenu;
  };

  onHelpHandler = async () => {
    await ModalStore.showModal(HelpModal);
  };

  render() {
    return (
      <>
        <header className={'menuMobile__header'}>
          <Button
            styleType="text"
            onClick={this.toggleMenu}
            className={'menuMobile__header__btn__icon'}
          >
            <Icon type={this.showMenu ? 'close' : 'hamburger'} />
          </Button>
          <AppLogo
            type="header"
            styles={{
              link: 'menuMobile__header__logo__link',
              image: 'menuMobile__header__logo',
            }}
          />

          {!this.showMenu && (
            <ActionSelect
              styles={{
                buttonStyle: 'menuMobile__header__btn__select',
                listStyle: 'menuMobile__header__actions__list',
              }}
              options={this.options}
              icon="more"
            />
          )}
        </header>

        <div
          className={`menuMobile__header__menu ${this.showMenu &&
            'menuMobile__header__menu_show'}`}
        >
          <div className={'menuMobile__header__links'}>
            {getRole(Roles.service) && (
              <NavLink
                className={'menuMobile__header__link'}
                activeClassName={'menuMobile__header__link_active'}
                to={ROUTES.service}
                onClick={this.toggleMenu}
                exact
              >
                <FormattedMessage id="title.online.ordering" />
              </NavLink>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MenuMobile;
