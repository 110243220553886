import { action, computed, observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { Discount } from './types';
import { DishGroupManagement } from '../Menu/types';
import { sortByAccessor } from '../../helpers/accessors';
import { SubsidyDto } from '../Forms/SubsidyFormStore';
import RootStore from '../RootStore';
import { UserGroup } from '../Staff/types';

class SubsidiesStore {
  @observable loading = false;
  @observable searchValue = '';
  @observable subsidies: Discount[] = [];
  @observable bayerSubsidies: SubsidyDto[] = [];
  @observable categories: DishGroupManagement[] = [];
  @observable userGroups: UserGroup[] = [];
  @observable isBayerSpecific = false;
  @observable private sortConfig = { accessor: 'title', desc: false };

  @computed
  get filteredData() {
    const regExp = new RegExp(this.searchValue, 'i');

    if (this.isBayerSpecific) {
      return this.bayerSubsidies
        .filter(item => (this.searchValue ? regExp.test(item.title) : item))
        .sort(sortByAccessor(this.sortConfig))
        .map(item => ({
          ...item,
          userGroup: this.userGroups.find(
            group => group.id === item.userGroupId,
          )?.title,
        }));
    }
    return this.subsidies
      .filter(item =>
        this.searchValue ? regExp.test(item.details.userGroup.title) : item,
      )
      ?.map?.(subsidy => {
        return {
          ...subsidy,
          allDishGroups: this.categories,
          ...subsidy.details,
          dishGroups:
            subsidy.details?.dishGroups &&
            subsidy.details?.dishGroups?.length > 0
              ? [...subsidy.details?.dishGroups]
                  ?.sort(sortByAccessor(this.sortConfig))
                  .map(it => it.title)
                  .join(', ')
              : '',
        };
      });
  }

  @computed
  get activeUserGroupsWithSubsidies(): string[] {
    return this.subsidies.map(item => item.details.userGroup.id);
  }

  @action.bound
  async init() {
    try {
      this.loading = true;
      this.isBayerSpecific = RootStore.config.feature.subsidies?.bayerSpecific;

      if (this.isBayerSpecific) {
        const [groups, bayerSubsidies] = await Promise.all([
          httpFacade.staff.fetchUserGroups(),
          httpFacade.staff.fetchBayerSubsidies(),
        ]);
        this.bayerSubsidies = bayerSubsidies.data;
        this.userGroups = groups.data.content;
      } else {
        const [subsidies, categories] = await Promise.all([
          httpFacade.staff.fetchSubsidies(),
          httpFacade.menu.fetchCategories(),
        ]);
        this.subsidies = subsidies.data;
        this.categories = categories.data;
      }
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateSubsidy(data: Discount) {
    this.subsidies = this.subsidies.map(item =>
      item.id === data.id ? data : item,
    );
  }

  @action.bound
  addSubsidy(data: Discount) {
    this.subsidies = [...this.subsidies, data];
  }

  @action.bound
  async deleteSubsidy(id: string) {
    try {
      this.loading = true;
      if (this.isBayerSpecific) {
        await httpFacade.staff.deleteBayerSubsidy(id);
        this.bayerSubsidies = this.bayerSubsidies.filter(
          item => item.id !== id,
        );
      } else {
        await httpFacade.staff.deleteSubsidy(id);
        this.subsidies = this.subsidies.filter(item => item.id !== id);
      }
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default SubsidiesStore;
