import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import style from './Breadcrumbs.module.scss';

import { FormattedMessage } from 'react-intl';

interface Props {
  links: Array<{ to?: string; name?: string }>;
  className?: string;
  type?: 'admin' | 'general';
}

const Breadcrumbs: FC<Props> = ({ links, className, type = 'admin' }) => (
  <div
    className={classNames(
      style.breadcrumbs,
      {
        [style.breadcrumbs__admin]: type === 'admin',
      },
      className,
    )}
  >
    {links.map((link, index) =>
      link.to ? (
        <Link className="link link__to" to={link.to} key={index}>
          <FormattedMessage id={link.name} defaultMessage={link.name} />
        </Link>
      ) : (
        <span className="link" key={index}>
          {link.name ?? ''}
        </span>
      ),
    )}
  </div>
);

export default Breadcrumbs;
