import Modal from 'rc-dialog';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ModalProps } from 'stores/ModalStore';

import style from './DeploymentModelInfoModal.module.scss';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { DeploymentModel } from '../../../stores/Recognition/types';

enum DeploymentModelInfoModalState {
  DEFAULT = 'default',
  CONFIG = 'config',
}

interface Props extends ModalProps {
  model: DeploymentModel;
}

@observer
class DeploymentModelInfoModal extends Component<Props> {
  @observable modalState: string = DeploymentModelInfoModalState.DEFAULT;

  @action
  onChangeModalState = state => {
    this.modalState = state;
  };

  render() {
    const { model, ...props } = this.props;

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        {this.modalState === DeploymentModelInfoModalState.DEFAULT && (
          <>
            <div className={style.header}>
              <FormattedMessage id="title.deployment.info" tagName={'h2'} />
            </div>
            <div className={style.body}>
              <div className={style.content}>
                <div className={classNames(style.row, style.row_wide)}>
                  <span className={style.name}>
                    <FormattedMessage
                      id="title.model.name"
                      values={{ modelName: model.name }}
                    />
                  </span>
                </div>
                <div className={style.column}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.description" />
                  </span>
                  <span className={style.description}>
                    {model?.description ?? ''}
                  </span>
                </div>

                <div className={style.row}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.type" />
                  </span>
                  <span className={style.text}>{model?.type}</span>
                </div>
                <div className={style.row}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.ID" />
                  </span>
                  <span className={style.text}>{model.id}</span>
                </div>
                <div className={style.row}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.created" />
                  </span>
                  <span className={style.text}>{model.createdDate}</span>
                </div>
                <div className={style.row}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.updated" />
                  </span>
                  <span className={style.text}>{model.lastModifiedDate}</span>
                </div>
                <div className={classNames(style.row, style.row_narrow)}>
                  <span className={style.label__light}>
                    <FormattedMessage id="title.result" />
                  </span>
                  {model?.result?.successful ? (
                    <span className={style.success}>
                      <FormattedMessage id="title.successful" />
                    </span>
                  ) : (
                    <span className={style.error}>
                      <FormattedMessage id="title.error" />
                    </span>
                  )}
                </div>
                {!model.result!.successful && (
                  <span className={style.error}>{model.result!.errMsg}</span>
                )}

                <div className={classNames(style.row, style.row__button)}>
                  <span className={style.text}>
                    <FormattedMessage id="title.config" />
                  </span>

                  <div className={style.btn__arrow__block}>
                    <Button
                      styleType="text"
                      onClick={() =>
                        this.onChangeModalState(
                          DeploymentModelInfoModalState.CONFIG,
                        )
                      }
                      className={style.btn__arrow}
                    >
                      <Icon type={'arrowRight'} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.modalState === DeploymentModelInfoModalState.CONFIG && (
          <>
            <div className={classNames(style.header, style.header__arrow)}>
              <Button
                styleType="text"
                onClick={() =>
                  this.onChangeModalState(DeploymentModelInfoModalState.DEFAULT)
                }
                className={style.btn__arrow}
              >
                <Icon type={'arrowLeft'} />
              </Button>
              <FormattedMessage id="title.config" tagName={'h2'} />
            </div>
            <div className={style.body}>
              <div
                className={classNames(style.content, style.content_scrolled)}
              >
                {Object.keys(model?.cfg)?.map?.((title, index) => (
                  <div key={index} className={style.configInfo}>
                    <span>{`${title}: `}</span>
                    <span>{JSON.stringify(model?.cfg?.[title])}</span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default DeploymentModelInfoModal;
