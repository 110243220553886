export function copyToClipboard(text: string) {
  const el = document.createElement('textarea');
  el.value = text;
  el.style.position = 'fixed';
  el.style.visibility = 'none';

  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  el.remove();
}
