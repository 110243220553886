import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './DatasetSamples.module.scss';

import CategoryItem from './Category/CategoryItem';

import DatasetSamplesStore from 'stores/Modals/DatasetSamplesStore';

@observer
class LastUsed extends Component<{ store: DatasetSamplesStore }> {
  render() {
    const { store } = this.props;

    return (
      <ul className={style.last__used}>
        {store.lastUsedCategory.items.map(item => (
          <li key={item.tag}>
            <CategoryItem item={item} />
          </li>
        ))}
      </ul>
    );
  }
}

export default LastUsed;
