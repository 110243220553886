import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import style from './MenuCard.module.scss';
import { MenuItem } from 'stores/UserProfile/types';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import AmountCounter from 'components/AmountCounter/AmountCounter';
import Icon from '../../../../components/Icon/Icon';
import ModalStore from '../../../../stores/ModalStore';
import MenuCardrModal from '../../../../components/Modals/MenuCard/MenuCardModal';
import UserProfileOrder from '../../../../stores/UserProfile/UserProfileOrder';

@inject('store')
@observer
class MenuCard extends Component<{
  menuItem: MenuItem;
  editable?: boolean;
  className?: string;
  store?: UserProfileOrder;
}> {
  handleOpenMenuCard = async () => {
    await ModalStore.showModal(MenuCardrModal, {
      menuItem: this.props.menuItem,
    });
  };

  async componentDidMount() {
    if (this.props.menuItem?.id && !this.props.menuItem?.isFetchedImage) {
      await this.props.store!.fetchItemImage(this.props.menuItem.id);
    }
  }

  render() {
    const { menuItem, editable, className } = this.props;

    return (
      <div
        className={classNames(className, style.item)}
        key={menuItem.id}
        onClick={this.handleOpenMenuCard}
      >
        {menuItem?.image ? (
          <img src={menuItem.image} alt="" className={style.item__image} />
        ) : (
          <div
            className={classNames(style.item__image, style.item__image_empty)}
          >
            <Icon type="imageEmpty" className={style.icon__imageEmpty} />
          </div>
        )}

        <div className={style.info__wrap}>
          <div className={style.item__additional}>
            {!!menuItem?.labels?.length &&
              menuItem.labels.map((tag, index) => (
                <React.Fragment key={tag.id}>
                  <span>
                    {tag.title}
                    {index !== menuItem.labels!.length - 1 && <>{','}&nbsp;</>}
                  </span>
                </React.Fragment>
              ))}
          </div>
          <div className={style.info}>
            <div className={style.item__title}>{menuItem.title}</div>

            <div className={style.item__data}>
              <div className={style.item__price}>
                <FormattedCurrency value={menuItem.price} />
              </div>

              <ul className={style.item__allergens}>
                {menuItem?.allergens?.map?.((it, index) => (
                  <React.Fragment key={it.id}>
                    <li>
                      <img
                        className={style.allergen__icon}
                        src={it.icon}
                        alt="allergen"
                      />
                      {index === 5 && menuItem.allergens.length > 6 && (
                        <span className={style.dots}> ...</span>
                      )}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {editable && (
          <AmountCounter
            item={menuItem}
            className={style.item__amountCounter}
          />
        )}
      </div>
    );
  }
}

export default MenuCard;
