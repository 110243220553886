import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import style from './MenuRow.module.scss';

import MenuCard from '../MenuCard/MenuCard';

import { ProfileMenu } from 'stores/UserProfile/types';

@observer
class MenuRow extends Component<{
  line: ProfileMenu;
}> {
  render() {
    const { line } = this.props;
    return (
      <div className={style.content}>
        <span
          className={classNames(style.title, {
            [style.title__prioritized]: line.prioritized,
          })}
        >
          {line.title}
        </span>

        <div
          className={classNames(style.items, {
            [style.items__prioritized]: line.prioritized,
          })}
        >
          {line?.days?.map?.(day =>
            day.items.map(item => <MenuCard menuItem={item} key={item.id} />),
          )}
        </div>
      </div>
    );
  }
}

export default MenuRow;
