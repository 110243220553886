import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import style from './Category.module.scss';

import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';

import DatasetSamplesStore from 'stores/Modals/DatasetSamplesStore';
import { MenuItem } from 'stores/Recognition/types';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

@inject('store')
@observer
class CategoryItem extends Component<{
  item: MenuItem;
  store?: DatasetSamplesStore;
}> {
  onChange = item => () => {
    this.props.store?.ol.setFeatureTagAndTitle(item);
    this.props.store?.ol.setFeatureSubTag(item.subtag);
  };

  render() {
    const { store, item } = this.props;
    const { ol } = store!;

    return (
      <CheckboxField
        checked={ol.activeTag === item.tag}
        onChange={this.onChange(item)}
        className={{ wrapper: style.submenu__item }}
        type="green"
      >
        {item.title}{' '}
        {typeof item.price !== 'undefined' && (
          <>
            &nbsp; <FormattedCurrency value={item.price} withWrap={false} />
          </>
        )}
      </CheckboxField>
    );
  }
}

export default CategoryItem;
