export const defaultRefillValues = [1000, 2000, 5000];

export interface PaymentOption {
  title: string;
  type: PaymentType;
  icon: string;
}

export enum PaymentType {
  creditCard = 'CREDIT_CARD',
  eWalletPayment = 'E_WALLET_PAYMENT',
  onlineBankTransfer = 'ONLINE_BANK_TRANSFER',
  maestro = 'MAESTRO',
}

export const paymentOptions: Record<keyof typeof PaymentType, PaymentOption> = {
  creditCard: {
    title: 'payment.option.bank.card',
    type: PaymentType.creditCard,
    icon: 'iconCreditCard',
  },
  maestro: {
    title: 'payment.option.maestro',
    type: PaymentType.maestro,
    icon: 'iconMaestro',
  },
  onlineBankTransfer: {
    title: 'payment.option.sofort',
    type: PaymentType.onlineBankTransfer,
    icon: 'iconSofort',
  },
  eWalletPayment: {
    title: 'payment.option.paypal',
    type: PaymentType.eWalletPayment,
    icon: 'iconPayPal',
  },
};

export interface RefillRequestDto {
  amount: number;
  paymentType: PaymentType;
  successUrl: string;
  failOrCancelUrl: string;
}
