import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './ImagesCarousel.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Fallback from 'components/Fallback/Fallback';
import Spinner from 'components/Spinner/Spinner';

import ArchiveImageViewStore, {
  STEP,
} from 'stores/Modals/ArchiveImageViewStore';
import { IArchiveImage } from 'stores/Forms/Archive/types';

@observer
class ImagesCarousel extends Component<{ images: IArchiveImage[] }> {
  panel = React.createRef<HTMLElement>();
  store = new ArchiveImageViewStore(this.props.images);

  async componentDidMount() {
    if (this.store.images.length) {
      await this.store.fetchImage();
    }
    this.panel?.current?.focus();
  }

  changeImage = index => () => {
    this.store?.changeImage(index);
  };

  onPanelKeyDown = async event => {
    if (this.store.images.length <= 1) {
      return;
    }

    switch (event.key) {
      case 'ArrowLeft':
        await this.store!.changeImage(-STEP);
        break;
      case 'ArrowRight':
        await this.store!.changeImage(STEP);
        break;
    }
  };

  render() {
    const { images } = this.store;
    const showControls = images.length > 1;

    return (
      <section
        onKeyDown={this.onPanelKeyDown}
        className={style.main}
        ref={this.panel}
        tabIndex={0}
      >
        {showControls && (
          <Button
            title="&larr;"
            styleType="text"
            className={style.btn__arrow}
            onClick={this.changeImage(-STEP)}
            disabled={this.store?.isFirst}
          >
            <Icon type="arrowLeft" />
          </Button>
        )}

        <div className={style.image__wrapper}>
          {this.store.loading ? (
            <Spinner fullscreen />
          ) : (
            <>
              {!this.store.activeImage ? (
                <Fallback className={style.fallback} />
              ) : (
                <img
                  alt="foto"
                  src={this.store.activeImage}
                  className={style.image}
                />
              )}
            </>
          )}
        </div>

        {showControls && (
          <Button
            title="&rarr;"
            styleType="text"
            className={style.btn__arrow}
            onClick={this.changeImage(STEP)}
            disabled={this.store?.isLast}
          >
            <Icon type="arrowRight" />
          </Button>
        )}
      </section>
    );
  }
}

export default ImagesCarousel;
