import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import style from './StornoReport.module.scss';

const config = (): Column[] => [
  {
    Header: withArrow('ID'),
    accessor: 'itemNumber',
    resizable: false,
    width: 80,
  },
  {
    Header: withArrow('title.report.name'),
    accessor: 'title',
    resizable: true,
    width: 320,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.report.count'),
    accessor: 'amount',
    resizable: true,
    width: 140,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.report.price'),
    accessor: 'price',
    resizable: false,
    width: 100,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.brutto'),
    accessor: 'brutto',
    resizable: false,
    width: 170,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.vat'),
    accessor: 'vat',
    resizable: false,
    width: 100,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.vatAmount'),
    accessor: 'vatAmount',
    resizable: false,
    width: 150,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.netto'),
    accessor: 'netto',
    resizable: false,
    width: 150,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.report.reason'),
    accessor: 'reason',
    resizable: false,
    width: 250,
    Cell: row => (
      <div className={style.cell__text}>
        <FormattedMessage id={row.value} />
      </div>
    ),
  },
];

export default config;
