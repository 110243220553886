import { AxiosError, AxiosRequestConfig } from 'axios';
import { IFeature } from 'stores/OLStore/types';
import { DishItem } from 'stores/Menu/types';

export enum ResponseErrMessage {
  AUTH_TOKEN_EXPIRED_ERR = 'error.business.security.authToken.expired',
  REFRESH_TOKEN_EXPIRED_ERR = 'error.business.refreshToken.expired',
}

export interface RequestConfig extends AxiosRequestConfig {
  replacePathParams?: object;
  skipParamReplace?: boolean;
  skipHttpErrorHandler?: boolean;
}

export interface ResponseErrorConfig extends AxiosError {
  config: AxiosRequestConfig & RequestConfig;
}

export interface ResponseError {
  message: ResponseErrMessage;
  params: string[];
}

export interface UpdateEmployeeDto {
  firstName: string;
  lastName: string;
}

export interface ResponseSortParams {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface PageableListResponse<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    size: number;
    paged: boolean;
    sort: ResponseSortParams;
    unpaged: boolean;
  };
  size: number;
  sort: ResponseSortParams;
  totalElements: number;
  totalPages: number;
}

export interface PageableListRequestParams {
  sort?: string[];
  size?: number;
  page?: number;
  q?: string;
}

export interface IDatasetUpdateDTO {
  id: string;
  name: string;
  version: number;
}

export interface ModelUpdateDTO {
  id: string;
  version: number;
}

export interface UpdateSampleDTO {
  data: IFeature[];
  size: {
    width: number;
    height: number;
  };
}

export interface DatasetTrain {
  architectureId?: string;
  config: {
    duration?: number;
    stepsNumber?: number;
    remapLabels?: boolean;
  };
  datasetId: string;
  description?: string;
}

export interface PaymentQR {
  code: string;
  timeToLive: number;
}

export interface DishItemDTO extends Omit<DishItem, 'allergens' | 'labels'> {
  allergens: string[];
  labels: string[];
}

export interface FeedbackDTO {
  orderId: string;
  feedback: string;
}

export interface DishGroupDTO {
  title: string;
  itemNumber: string;
  forDepositItems: boolean;
  standardMenuItems: boolean;
  notVisibleKioskSelfCheckout: boolean;
}
