import { observer } from 'mobx-react';
import React, { Component } from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import style from './OrderDelivery.module.scss';

import { BasketMode, DeliveryOptions } from 'stores/UserProfile/types';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import classNames from 'classnames';
import { OrderDeliveryDTO } from 'stores/Forms/OrderDeliveryForm/types';
import Delivery from './components/Delivery';

interface Props extends WrappedComponentProps {
  store?: UserProfileOrder;
  onClose: () => void;
}

@observer
class OrderDelivery extends Component<Props> {
  onConfirm = async (deliveryData: OrderDeliveryDTO) => {
    await this.props.store!.saveOrder(deliveryData);
  };

  render() {
    const { store, intl } = this.props;
    const deliveryOptions = store?.deliveryOptions as DeliveryOptions;

    return (
      <>
        <div className={style.body}>
          <div className={classNames(style.text__block, style.text)}>
            {(deliveryOptions?.text &&
              deliveryOptions?.text
                ?.split?.('\n')
                ?.map((item, index) => <p key={index}>{item}</p>)) || (
              <>
                <FormattedMessage
                  id="modal.confirm.order.delivery.text1"
                  tagName={'p'}
                />
                &nbsp;
                <FormattedMessage
                  id="modal.confirm.order.delivery.text2"
                  tagName={'p'}
                />
                &nbsp;
                <FormattedMessage
                  id="modal.confirm.order.delivery.text3"
                  tagName={'p'}
                />
              </>
            )}
          </div>

          <p
            className={classNames(
              style.text__block,
              style.text,
              style.text__details,
            )}
          >
            {deliveryOptions?.details ||
              intl.formatMessage({
                id: 'modal.confirm.order.delivery.details',
              })}
          </p>

          <Delivery
            userProfileOrderStore={store}
            onSubmit={this.onConfirm}
            onChangeBasketMode={() =>
              this.props.store!.changeBasketMode(
                this.props.store?.isTimeSlotsFeatureExist
                  ? BasketMode.timeSlots
                  : BasketMode.orderList,
              )
            }
          />
        </div>
      </>
    );
  }
}

export default injectIntl(OrderDelivery);
