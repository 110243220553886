import style from './ArchivePage.module.scss';
import { OrderState } from '../../stores/Forms/Archive/types';

export function getPrimusStateColor(orderState: OrderState) {
  switch (orderState) {
    case OrderState.DRAFT:
      return style.state__draft;
    case OrderState.DELIVERED:
      return style.state__delivered;
    case OrderState.CLOSED_DELIVERED:
      return style.state__common;
    case OrderState.CLOSED_DRAFT:
      return style.state__common;
    case OrderState.CLOSED:
      return style.state__common;
    default:
      return style.state__common;
  }
}
