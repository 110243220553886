import React, { Component } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import style from './SelectField.module.scss';
import RootStore from '../../../../stores/RootStore';

interface Option<T> {
  value: T;
  label: string | JSX.Element;
}

interface Props<T> {
  className?: string;
  invalid?: boolean;
  classNamePrefix?: string;
  value: T;
  onChange: (
    v: T | undefined | null,
    option: Option<T> | undefined | null,
  ) => void;
  options: Array<Option<T>>;
  disabled?: boolean;
  placeholder?: string;
  components?: any;
}

class SelectField<T = any> extends Component<Props<T>> {
  value = () => {
    return (
      this.props.options.find(option => option.value === this.props.value) ??
      null
    );
  };

  onChange = (v: Option<T> | undefined | null) => {
    this.props.onChange(v && v.value, v);
  };

  isOptionDisabled = option => !!option.disabled;

  render() {
    const {
      value,
      onChange,
      disabled,
      className,
      invalid,
      components,
      placeholder,
      ...props
    } = this.props;

    return (
      <ReactSelect
        {...props}
        classNamePrefix="select"
        className={classNames(className, style.select, {
          [style.select__invalid]: invalid,
        })}
        styles={{
          menu: (provided, _state) => ({
            ...provided,
            zIndex: 15,
          }),
          control: (provided, _state) => ({
            ...provided,
          }),
        }}
        isOptionDisabled={this.isOptionDisabled}
        isDisabled={disabled}
        onChange={this.onChange}
        components={{ IndicatorSeparator: () => null, ...components }}
        value={this.value()}
        placeholder={
          placeholder ||
          RootStore.localization.formatMessage('select.placeholder')
        }
      />
    );
  }
}

export default SelectField;
