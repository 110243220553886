import { FEATURE_PROPERTIES, IFeature } from './types';
import { ISize, IStretching } from '../Recognition/types';

// import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';

import { uniqueId } from 'helpers/uniqueId';

const MAX_TITLE_LENGTH = 30;

export function sliceTitle(title: string) {
  return title.length > MAX_TITLE_LENGTH
    ? `${title.slice(0, MAX_TITLE_LENGTH)}...`
    : title;
}

export function getStretching(from: ISize, to: ISize): IStretching {
  return {
    diffX: to.width / from.width,
    diffY: to.height / from.height,
  };
}

export function convertDataToFeature(
  feature: IFeature,
  viewHeight: number,
): IFeature {
  return {
    ...feature,
    coordinates: feature?.coordinates?.map(point => [
      point[0],
      revertYCoordinate(point[1], viewHeight),
    ]),
  };
}

export function revertYCoordinate(value: number, viewHeight: number): number {
  return viewHeight - value;
}

export function featureFabric(features: IFeature[] = []) {
  return features.map(feature => {
    const shape = new Feature({
      geometry: new Polygon([feature.coordinates]),
    });

    shape.set(FEATURE_PROPERTIES.tag, feature.tag);
    shape.set(FEATURE_PROPERTIES.title, feature.title);
    shape.set(FEATURE_PROPERTIES.subtag, feature.subtag);

    shape.setId(uniqueId());

    return shape;
  });
}

export function convertFeatureToData(feature: Feature, viewHeight: number) {
  const type = feature.getGeometry().getType();
  const coordinates = {
    coordinates: feature
      .getGeometry()
      .getCoordinates()
      .flat()
      .map(point => [point[0], revertYCoordinate(point[1], viewHeight)]),
  };

  // Circe geometry
  // radius: feature.getRadius()
  // coordinates: feature.getCenter()
  const tag = feature.get(FEATURE_PROPERTIES.tag);
  const subtag = feature.get(FEATURE_PROPERTIES.subtag) || null;

  return {
    type,
    tag,
    subtag,
    ...coordinates,
  };
}

export function getFeatureText(feature: Feature) {
  const title = sliceTitle(feature.get(FEATURE_PROPERTIES.title) || '');
  const subtag = feature.get(FEATURE_PROPERTIES.subtag);

  return subtag ? `${title} \n ${subtag}` : title;
}
