import React, { Component } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import style from './Label.module.scss';

import { ILabel } from 'stores/Recognition/types';
import DatasetStore from 'stores/Recognition/DatasetStore';

interface Props {
  label: ILabel;
  store?: DatasetStore;
}

@inject('store')
@observer
class Label extends Component<Props> {
  toggleFilter = filter => () => {
    this.props.store!.toggleFilter(filter);
  };

  render() {
    const { label, store } = this.props;

    return (
      <li
        className={classNames(style.label, {
          [style.label__active]: store!.filters.includes(label.tag),
        })}
        onClick={this.toggleFilter(label.tag)}
      >
        <span className={style.label__name}>{label.title}</span>
        <span className={style.label__value}>{label.amount}</span>
      </li>
    );
  }
}

export default Label;
