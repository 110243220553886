import React from 'react';
import { Column } from 'react-table';

import style from './Categories.module.scss';

import { withArrow } from 'components/Table/helpers';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Button from 'components/Button/Button';
import { FormattedMessage } from 'react-intl';
import SwitchButton from '../../components/Form/Fields/SwitchButton/SwitchButton';
import { validVatRate } from '../../stores/Subsidies/types';
import { isAllMenuItemsSubsidized, isMiddleStateOfToggle } from './helpers';
import Icon from 'components/Icon/Icon';
import { sortByAccessor } from 'helpers/accessors';

export const config = ({
  activeCategory,
  onEdit,
  onDelete,
  onDescriptionUpdate,
  onSwitchCategory,
  onSwitchMenuItem,
  items,
  onKioskSelfCheckoutVisibilityChange,
}): Column[] => {
  const columns = [
    {
      Header: withArrow('title.artNumber'),
      accessor: 'itemNumber',
      resizable: false,
      maxWidth: 90,
    },
    {
      Header: withArrow('title.product.name'),
      accessor: 'title',
      resizable: true,
      minWidth: 180,
      className: style.product__name,
      Cell: row => <span className={style.cell__text}>{row.value}</span>,
    },
    {
      Header: withArrow('title.description'),
      accessor: 'description',
      resizable: true,
      minWidth: 180,
      className: style.product__description,
      Cell: row => (
        <Button
          styleType="text"
          onClick={onDescriptionUpdate(row.original)}
          className={style.btn__description}
        >
          {row.value}
        </Button>
      ),
    },
    {
      Header: withArrow('title.priceBrutto'),
      accessor: 'price',
      resizable: false,
      width: 120,
      maxWidth: 140,
      headerClassName: style.product__priceBrutto,
      Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
    },
    {
      Header: withArrow('title.vatRate'),
      accessor: 'vatRates',
      resizable: false,
      maxWidth: 100,
      Cell: row => (
        <>
          {row.original.vatRates
            .sort(
              sortByAccessor({
                accessor: (a: number): number => a,
                desc: true,
              }),
            )
            .map(rate => (
              <FormattedCurrency value={rate} withSymbol={false} key={rate} />
            ))}
        </>
      ),
    },
  ];

  const toggleColumn = {
    Header: () => (
      <div className={style.header__switch}>
        <FormattedMessage id={'title.discounts.subsidized'} />
        <SwitchButton
          onChange={(isChecked, e) =>
            onSwitchCategory(
              isMiddleStateOfToggle(items) ? !isChecked : isChecked,
            )
          }
          checked={isAllMenuItemsSubsidized(items)}
          isMiddleState={isMiddleStateOfToggle(items)}
        />
      </div>
    ),
    accessor: activeCategory ? 'subsidized' : undefined,
    resizable: true,
    minWidth: 150,
    sortable: false,
    Cell: row => (
      <SwitchButton
        onChange={(isChecked, e) => onSwitchMenuItem(row.original, isChecked)}
        checked={row.value}
        disabled={!row.original.vatRates.includes(validVatRate)}
      />
    ),
  };

  const operationsColumn = {
    id: 'operation',
    resizable: false,
    width: 112,
    className: 'operations',
    Cell: row => (
      <div className={style.controls}>
        <Button
          styleType="text"
          className={style.control}
          onClick={onKioskSelfCheckoutVisibilityChange(row.original)}
        >
          <Icon
            type={
              row.original.notVisibleKioskSelfCheckout ? 'iconHide' : 'iconShow'
            }
          />
        </Button>
        <Button
          styleType="text"
          className={style.control}
          onClick={onEdit(row.original)}
        >
          <Icon type="edit" />
        </Button>
        <Button
          styleType="text"
          className={style.control}
          onClick={onDelete(row.original.id)}
        >
          <Icon type="trash" />
        </Button>
      </div>
    ),
    sortable: false,
  };

  return !activeCategory
    ? [...columns, operationsColumn]
    : [...columns, toggleColumn, operationsColumn];
};
