import React from 'react';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from './Checkbox.module.scss';

interface CheckboxProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'className'
  > {
  type: 'red' | 'green';
  onChange?: (v: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: {
    wrapper?: string;
    input?: string;
    icon?: string;
  };
  withTick?: boolean;
}

function getClassNameByType(type) {
  switch (type) {
    case 'green':
      return style.svg__green;
    case 'red':
      return style.svg__red;
    default:
      return '';
  }
}

const CheckboxField = (props: CheckboxProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange && props.onChange(event.target.checked, event);

  const {
    checked,
    disabled,
    className,
    children,
    type,
    withTick,
    ...rest
  } = props;

  return (
    <label
      className={classNames(style.checkbox, className?.wrapper, {
        [style.checkbox__disabled]: disabled,
      })}
    >
      <input
        {...rest}
        checked={checked}
        disabled={disabled}
        type="checkbox"
        className={classNames(style.model, className?.input)}
        onChange={onChange}
      />

      <svg
        viewBox="0 0 24 24"
        className={classNames(
          style.svg,
          getClassNameByType(type),
          className?.icon,
        )}
      >
        {withTick && (
          <polyline points="20 6 9 17 4 12" className={style.tick} />
        )}
      </svg>

      {children}
    </label>
  );
};

export default CheckboxField;
