export interface Employee {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  nfc: boolean;
  rfid: boolean;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}
