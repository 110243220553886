import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { AppTag, TagMessage, TagStatus } from 'components/Tag/Tag';

import ModalStore, { DialogActionType } from '../ModalStore';

import Log from 'helpers/log';

class AssignTags {
  @observable id: string;
  @observable email: string;
  @observable nickname: string;
  @observable haveTags: boolean;

  @observable rfid: AppTag = {
    value: '',
    messageId: TagMessage.notAssigned,
    status: TagStatus.default,
  };

  @observable loading = false;

  constructor(id) {
    this.id = id;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const { data: employee } = await httpFacade.profile.fetchEmployeeByID(
        this.id,
      );

      this.email = employee.email;
      this.nickname = employee.nickname;
      this.haveTags = employee.rfid;

      if (employee.rfid) {
        this.rfid.status = TagStatus.available;
        this.rfid.messageId = TagMessage.available;
      }
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async assignTags() {
    try {
      this.loading = true;

      await httpFacade.profile.assignTags(this.id, {
        rfid: this.rfid.value,
      });

      if (!this.haveTags) {
        await httpFacade.payment.refillDeposit(this.id, {
          overdraft: false,
          tag: true,
        });
      }

      ModalStore.closeModal(DialogActionType.submit);
    } catch (error) {
      Log.warn(error);
    }

    this.loading = false;
  }

  @action
  async scanRFID() {
    try {
      const { data } = await httpFacade.adapter.fetchTagFromRFID();

      this.rfid.value = data;
      this.rfid.status = TagStatus.available;
      this.rfid.messageId = TagMessage.available;
    } catch (error) {
      this.rfid.value = '';
      this.rfid.status = TagStatus.error;

      if (error) {
        switch (error.formStatus) {
          case 404:
            this.rfid.messageId = TagMessage.notFound;
            break;

          case 409:
            this.rfid.messageId = TagMessage.manyTagsFound;
            break;

          default:
            this.rfid.messageId = TagMessage.hardwareError;
        }
      } else {
        this.rfid.messageId = TagMessage.hardwareError;
      }
    }
  }

  @action.bound
  async scanTag() {
    this.loading = true;

    try {
      await this.scanRFID();
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default AssignTags;
