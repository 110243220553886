import { action, computed, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';

import { getDate, getLocalDateTime } from 'helpers/datetime';
import Log from 'helpers/log';
import {
  addDepositItemsToCollection,
  addDepositItemsToMenuItems,
  createMenuCollection,
  getMenuLines,
  getSelectedMenuItems,
  MenuLine,
  resetCollectionItemsAmount,
  resetMenuItemsAmount,
  sortMenu,
} from './helpers';
import httpFacade from 'http/httpFacade';
import {
  BasketMode,
  DeliveryTimeSlot,
  MenuItem,
  OnlineOrderMenuStandard,
  OnlineOrderPreviewDto,
  OnlineOrderPreviewRequest,
  OnlineOrderRequest,
  Order,
  OrderItem,
  OrderModel,
  SwipeDirection,
} from './types';

import QRStore from 'stores/QRStore';
import RootStore from 'stores/RootStore';
import { LogService } from 'stores/Services/LogService';
import ModalStore, { DialogActionType } from '../ModalStore';
import { DishItem } from '../Menu/types';
import { UserReport } from './UserReport';
import { popup } from '../Popup';
import { OrderLocation } from '../OnlineOrders/type';
import { SortConfig } from 'helpers/types';
import { checkIsMobileOrTablet } from '../helpers';
import {
  OrderDeliveryDTO,
  TypesOfDelivery,
} from '../Forms/OrderDeliveryForm/types';
import ConsumptionConditionModal from 'components/Modals/ConsumptionCondition/ConsumptionConditionModal';

export enum UserProfileOrderStatusError {
  NOT_INOUGH_MONEY = 'notInoughMoney',
}

const REDUCED_VAT = 700;
class UserProfileOrder {
  userId: string;

  @observable deliveryDate = getLocalDateTime().setLocale(
    RootStore.localization.locale,
  );
  @observable currentDate = getLocalDateTime().setLocale(
    RootStore.localization.locale,
  );
  @observable startOrderDate = getLocalDateTime().setLocale(
    RootStore.localization.locale,
  );
  @observable menu: MenuLine[] = [];
  @observable menuStandard: OnlineOrderMenuStandard[] = [];

  @observable timeSlots: DeliveryTimeSlot[] = [];

  @observable selectedTimeSlot: DeliveryTimeSlot | null;
  @observable pointOfDelivery: string = '';
  @observable basketMode: BasketMode = BasketMode.orderList;
  @observable depositItems: DishItem[] = [];
  @observable report: UserReport;

  @observable orders: OrderModel[] = [];
  @observable order: Order;
  @observable orderPreview: OnlineOrderPreviewDto = {
    finalPrice: 0,
    originalPrice: 0,
    discount: 0,
  };
  @observable locations: OrderLocation[] = [];

  @observable isTodayTabActive: boolean = true;

  @observable loading = false;

  @observable collectionStandardMenu: MenuItem[] = [];
  @observable collectionMenu: MenuItem[] = [];

  @observable
  typeOfDelivery: string;

  @observable qrCode: string;
  private qr = new QRStore();
  private onCreditAllowSum: number =
    +RootStore.config.payment.negativeBalanceLimit.amount || 0;

  private defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };
  private defaultSortByDishGroupTitle: SortConfig = {
    accessor: 'dishGroupTitle',
    desc: false,
  };

  constructor(userId: string, report: UserReport) {
    this.userId = userId;
    this.report = report;

    reaction(
      () => this.deliveryDate,
      () => {
        if (!this.isExitDateOffsetForOrders) {
          this.fetchMenu();
        }
        this.isTodayTabActive = true;
      },
    );
    reaction(
      () => this.basketMode,
      mode => {
        if (mode === BasketMode.orderList) {
          this.selectedTimeSlot = null;
        }
        this.typeOfDelivery = this.deliveryTypes[0];
      },
    );
  }

  @computed
  get isMobileOrTablet() {
    return checkIsMobileOrTablet();
  }

  @computed
  get deliveryTypes(): TypesOfDelivery[] {
    const types: TypesOfDelivery[] = [];
    if (this.isPickupFeatureExist) {
      types.push(TypesOfDelivery.pickup);
    }

    if (
      this.isServerLocationsFeatureExist ||
      this.isCustomLocationsFeatureExist
    ) {
      types.push(TypesOfDelivery.delivery);
    }
    return types;
  }

  @computed
  get isDelivery() {
    return this.typeOfDelivery === TypesOfDelivery.delivery;
  }

  @computed
  get orderMenu() {
    const standardMenuItems = getSelectedMenuItems(
      this.isMobileOrTablet
        ? addDepositItemsToMenuItems(this.menuStandard, this.depositItems)
        : addDepositItemsToCollection(
            this.collectionStandardMenu,
            this.depositItems,
          ),
    );
    const menuItems = getSelectedMenuItems(
      this.isMobileOrTablet
        ? addDepositItemsToMenuItems(this.menu, this.depositItems)
        : addDepositItemsToCollection(this.collectionMenu, this.depositItems),
    );

    return [...menuItems, ...standardMenuItems];
  }

  @computed
  get totalPrice() {
    return this.orderMenu.reduce<number>(
      (acc, it) =>
        acc +
        it.price * (it.amount || 0) +
        (it?.depositPrice || 0) * (it.amount || 0),
      0,
    );
  }

  @computed
  get isTimeSlotsFeatureExist() {
    return !!RootStore.config.feature?.deliveryTimeSlots?.enabled;
  }

  @computed
  get isPickupFeatureExist() {
    return !!RootStore.config.feature?.deliveryPickup?.enabled;
  }

  @computed
  get isDeliveryFeatureExist() {
    return !!RootStore.config.feature?.delivery?.enabled;
  }

  @computed
  get isServerLocationsFeatureExist() {
    return !!RootStore.config.feature?.deliveryServerLocations?.enabled;
  }

  @computed
  get isCustomLocationsFeatureExist() {
    return !!RootStore.config.feature?.deliveryCustomLocations?.enabled;
  }

  @computed
  get customLocationsFeatureFields() {
    return RootStore.config.feature?.deliveryCustomLocations?.fields;
  }

  @computed
  get serverLocationsFeatureFields() {
    return RootStore.config.feature?.deliveryServerLocations?.fields;
  }

  @computed
  get locationsFeatureFields() {
    return this.isCustomLocationsFeatureExist
      ? this.customLocationsFeatureFields
      : this.serverLocationsFeatureFields;
  }

  @computed
  get pickupFeatureFields() {
    return RootStore.config.feature?.deliveryPickup?.fields;
  }

  @computed
  get deliveryOptions() {
    return RootStore.config.feature?.deliveryOptions;
  }

  @computed
  get isExitDateOffsetForOrders() {
    return this.startOrderDate.toISODate() > this.deliveryDate.toISODate();
  }

  @computed
  get isShowTodayMenu() {
    return !!this.sortedMenu?.length && this.isTodayTabActive;
  }

  @computed
  get isShowStandardMenu() {
    return !!this.sortedStandardMenu?.length && !this.isTodayTabActive;
  }

  @computed
  get isShowNoContentBlock() {
    return (
      ((!this.menu?.length && this.isTodayTabActive) ||
        (!this.menuStandard?.length && !this.isTodayTabActive)) &&
      !this.isExitDateOffsetForOrders
    );
  }

  @computed
  get sortedStandardMenu() {
    return sortMenu(
      this.menuStandard,
      this.defaultSortByDishGroupTitle,
      this.defaultSortByTitle,
    );
  }

  @computed
  get sortedMenu() {
    const menuSorted = sortMenu(
      this.menu,
      this.defaultSortByTitle,
      this.defaultSortByTitle,
    );
    let index;
    const isExistPrioritizedMenuLine = menuSorted.some((line, i) => {
      if (line.prioritized) {
        index = i;
        return true;
      }
      return false;
    });

    if (isExistPrioritizedMenuLine && index) {
      const element = menuSorted[index];
      menuSorted.splice(index, 1);
      menuSorted.splice(0, 0, element);
      return menuSorted;
    } else {
      return menuSorted;
    }
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const dateISO = this.deliveryDate.toISODate();
      const [
        menu,
        menuStandard,
        lines,
        orders,
        depositItems,
        locations,
      ] = await Promise.all([
        httpFacade.profile.fetchMenuOnlineOrder(dateISO),
        httpFacade.profile.fetchMenuStandard(),
        httpFacade.menu.fetchMenuLines(),
        httpFacade.onlineOrders.fetchEmployeeOnlineOrders(this.userId),
        httpFacade.menuItems.fetchDepositItems(),
        httpFacade.onlineOrders.fetchOrderLocations(),
      ]);

      this.menu = this.isExitDateOffsetForOrders
        ? []
        : getMenuLines(menu?.data, lines?.data);

      this.menuStandard = menuStandard?.data?.reduce<OnlineOrderMenuStandard[]>(
        (acc, item) => {
          return [
            ...acc,
            {
              ...item,
              items: item.items.map(it => ({
                ...it,
                amount: 0,
              })),
            },
          ];
        },
        [],
      );

      this.collectionMenu = createMenuCollection(this.sortedMenu);
      this.collectionStandardMenu = createMenuCollection(
        this.sortedStandardMenu,
      );

      this.locations = locations.data;
      this.orders = orders.data;
      this.depositItems = depositItems.data;

      const startOrderDateOffset = RootStore.config.order.startDate.daysOffset;

      this.startOrderDate = this.currentDate.plus({
        day: Number(startOrderDateOffset),
      });
    } catch (error) {
      this.menu = [];

      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  changeDate([date]: DateTime[]) {
    this.deliveryDate = date;
  }

  @action.bound
  resetAllFields() {
    this.pointOfDelivery = '';
    this.selectedTimeSlot = null;
  }

  @action.bound
  async fetchMenu() {
    try {
      this.loading = true;
      this.collectionMenu = [];
      const dateISO = this.deliveryDate.toISODate();
      const [menu, lines] = await Promise.all([
        httpFacade.profile.fetchMenuOnlineOrder(dateISO),
        httpFacade.menu.fetchMenuLines(),
      ]);
      this.menu = getMenuLines(menu.data, lines.data);
      this.collectionMenu = createMenuCollection(this.sortedMenu);
    } catch (error) {
      this.menu = [];
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchItemImage(id: string) {
    try {
      const { data } = await httpFacade.menuItems.getImage(id);

      if (this.isTodayTabActive && !!this.collectionMenu?.length) {
        this.collectionMenu = this.collectionMenu?.map(item => {
          if (item?.id === id) {
            item.image = data;
            item.isFetchedImage = true;
          }
          return item;
        });
      } else if (!!this.collectionStandardMenu?.length) {
        this.collectionStandardMenu = this.collectionStandardMenu?.map(item => {
          if (item?.id === id) {
            item.image = data;
            item.isFetchedImage = true;
          }
          return item;
        });
      }
    } catch (error) {
      Log.info(error);
    }
  }

  @action
  switchTab() {
    this.isTodayTabActive = !this.isTodayTabActive;
  }

  @action
  changeDelivery(value: string) {
    this.pointOfDelivery = value;
  }

  @action
  setSelectedTimeSlot(slot: DeliveryTimeSlot) {
    this.selectedTimeSlot = slot;
  }

  @action.bound
  swipeDay(value: SwipeDirection) {
    this.deliveryDate =
      value === 'previous'
        ? this.deliveryDate.minus({ day: 1 })
        : this.deliveryDate.plus({ day: 1 });
  }

  @action.bound
  async fetchTimeSlots() {
    try {
      this.loading = true;
      const { data } = await httpFacade.onlineOrders.fetchTimeSlots(
        getDate(this.deliveryDate.toJSDate()),
      );

      this.timeSlots = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onConfirmOrderListStep() {
    if (!this.isTimeSlotsFeatureExist) {
      this.isDeliveryFeatureExist
        ? this.changeBasketMode(BasketMode.delivery)
        : this.saveOrder();
    } else {
      await this.fetchTimeSlots();
      this.changeBasketMode(BasketMode.timeSlots);
    }
  }

  @action.bound
  onConfirmTimeSlotsStep() {
    this.isDeliveryFeatureExist
      ? this.changeBasketMode(BasketMode.delivery)
      : this.saveOrder();
  }

  @action.bound
  async getOrders() {
    try {
      this.loading = true;

      const { data } = await httpFacade.onlineOrders.fetchEmployeeOnlineOrders(
        this.userId,
      );

      this.orders = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  isError(error: any): error is Error {
    return 'message' in error;
  }

  @action.bound
  async saveOrder(deliveryData?: OrderDeliveryDTO) {
    try {
      this.loading = true;

      if (
        this.report.balance + this.onCreditAllowSum <
        this.orderPreview.finalPrice
      ) {
        throw new Error(UserProfileOrderStatusError.NOT_INOUGH_MONEY);
      }

      let consumptionCondition: string | null = null;
      if (
        deliveryData?.deliveryInfo ||
        deliveryData?.selectedLocation ||
        this.orderMenu.some(
          mi => mi.vatRates.length === 1 && mi.vatRates[0] === REDUCED_VAT,
        )
      ) {
        consumptionCondition = 'TAKE_AWAY';
      } else {
        consumptionCondition =
          RootStore.config.online?.buyer?.consumption?.condition?.default;
        if (
          typeof consumptionCondition === 'undefined' ||
          consumptionCondition === null
        ) {
          await ModalStore.showModal(ConsumptionConditionModal, {
            onConsumptionConditionSelected: (condition: string): void => {
              consumptionCondition = condition;
            },
            onReject: (): void => {
              this.loading = false;
            },
          });
        }
      }

      const data: OnlineOrderRequest = {
        deliveryTimeSlotId: this.selectedTimeSlot?.id ?? undefined,
        items: this.getOrderItems(),
        paymentMethod: {
          type: 'EMPLOYEE_PAYMENT',
          employeeId: this.userId,
        },
        deliveryDate: getDate(this.deliveryDate.toJSDate()),
        deliveryLocationId: deliveryData?.selectedLocation,
        consumptionCondition,
      };
      if (
        deliveryData?.selectedLocation &&
        deliveryData &&
        !deliveryData?.deliveryInfo?.pointOfDelivery
      ) {
        deliveryData.deliveryInfo!.pointOfDelivery =
          deliveryData.selectedLocation;
      }

      const { data: response } = await httpFacade.onlineOrders.saveOnlineOrder({
        ...data,
        ...deliveryData,
      });
      this.order = response;

      this.resetCurrentOrderItems();
      await this.getOrders();
      this.changeBasketMode(BasketMode.savedOrder);
    } catch (error) {
      if (
        this.isError(error) &&
        error.message === UserProfileOrderStatusError.NOT_INOUGH_MONEY
      ) {
        const errorMessage = RootStore.localization.formatMessage(
          'error.business.order.cantPaid',
        );
        const errorMessageId = 'error.business.order.cantPaid';
        const errorData = {
          errorMessage,
          errorMessageId,
        };

        LogService.error(errorData);
        popup.notify(errorMessage);
      }
      ModalStore.closeModal(DialogActionType.cancel);
      this.resetCurrentOrderItems();
      this.changeBasketMode(BasketMode.orderList);
      Log.info(error);
    } finally {
      this.loading = false;
      this.resetAllFields();
    }
  }

  @action
  changeBasketMode(mode: BasketMode) {
    this.basketMode = mode;
  }

  @action.bound
  async previewOrder() {
    try {
      const requestData: OnlineOrderPreviewRequest = {
        items: this.getOrderItems(),
        paymentMethod: {
          type: 'EMPLOYEE_PAYMENT',
          employeeId: this.userId,
        },
        deliveryDate: getDate(this.deliveryDate.toJSDate()),
        // does not influence on persisting state
        consumptionCondition: 'IN_PLACE',
      };

      const { data } = await httpFacade.onlineOrders.previewOnlineOrder(
        requestData,
      );
      this.orderPreview = data;
    } catch (error) {
      this.orderPreview = { discount: 0, finalPrice: 0, originalPrice: 0 };

      Log.info(error);
    }
  }

  @action
  changeDeliveryType(type) {
    this.typeOfDelivery = type;
  }

  getOrderItems() {
    return this.orderMenu.reduce<OrderItem[]>(
      (acc, product) =>
        product.amount
          ? [...acc, { amount: product.amount, tag: product.tag }]
          : acc,
      [],
    );
  }

  @action.bound
  closeBasket() {
    this.resetAllFields();
    this.changeBasketMode(BasketMode.orderList);
  }

  @action.bound
  resetCurrentOrderItems() {
    this.menu = resetMenuItemsAmount(this.menu) as MenuLine[];
    this.menuStandard = resetMenuItemsAmount(
      this.menuStandard,
    ) as OnlineOrderMenuStandard[];
    this.collectionMenu = resetCollectionItemsAmount(this.collectionMenu);
    this.collectionStandardMenu = resetCollectionItemsAmount(
      this.collectionStandardMenu,
    );
  }

  @action.bound
  async generateQRCode(data: string) {
    try {
      this.qrCode = await this.qr.generateQR(data || '');
    } catch (error) {
      Log.info(error);
    }
  }
}

export default UserProfileOrder;
