import http from 'http/http';
import URLS from 'http/urls';
import { api } from 'http/helpers';
import * as constants from './constants';
import qs from 'qs';

import {
  TokensResponse,
  UserAuthFormData,
  AuthorizationResponse,
} from './types';
import { ChangePasswordDTO } from '../../../stores/Forms/ChangePasswordForm';

export const userApi = {
  async login(data: UserAuthFormData) {
    const stateLenth = 64;
    const state = this.state(stateLenth);
    const redirectUri =
      window.location.protocol +
      '//' +
      (window.location.hostname === 'localhost'
        ? '127.0.0.1:' + window.location.port
        : window.location.host) +
      '/cb';

    const authorizationData = {};
    authorizationData[constants.FORM_FIELD_RESPONSE_TYPE] =
      constants.RESPNOSE_TYPE_CODE;
    authorizationData[constants.FORM_FIELD_CLIENT_ID] =
      constants.CLIENT_ID_SIONUM;
    authorizationData[constants.FORM_FIELD_STATE] = state;
    authorizationData[constants.FORM_FIELD_REDIRECT_URI] = redirectUri;

    return http
      .get(api(URLS.oAuthAuthorization), {
        params: authorizationData,
        skipHttpErrorHandler: true,
        headers: {
          accept: '*/*',
        },
      })
      .then(async response => {
        if (response.headers['content-type'] === 'text/html;charset=UTF-8') {
          const html = response.data as string;
          if (
            html.indexOf('id="username"') >= 0 &&
            html.indexOf('id="password"')
          ) {
            const loginData = {};
            loginData[constants.FORM_FIELD_USERNAME] = data.username;
            loginData[constants.FORM_FIELD_PASSWORD] = data.password;
            return http
              .post(api(URLS.login), qs.stringify(loginData), {
                skipHttpErrorHandler: true,
                headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                },
              })
              .then(async loginResponse => {
                if (
                  loginResponse.headers['content-type'] ===
                  'text/html;charset=UTF-8'
                ) {
                  const loginHtml = loginResponse.data as string;
                  if (loginHtml.indexOf('alert-danger') >= 0) {
                    throw new Error('login failed');
                  }
                } else if (
                  loginResponse.headers['content-type'] === 'application/json'
                ) {
                  return this.getAccessToken(loginResponse, redirectUri);
                }
              });
          }
        } else if (response.headers['content-type'] === 'application/json') {
          return this.getAccessToken(response, redirectUri);
        }
      });
  },

  state(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersNumber = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersNumber));
    }

    return result;
  },

  refreshToken(refreshToken: string) {
    const tokenData = {};
    tokenData[constants.FORM_FIELD_GRANT_TYPE] =
      constants.GRANT_TYPE_REFRESH_TOKEN;
    tokenData[constants.FORM_FIELD_REFRESH_TOKEN] = refreshToken;

    return http.post<string, TokensResponse>(
      api(URLS.authToken),
      qs.stringify(tokenData),
      {
        skipHttpErrorHandler: true,
        auth: {
          username: constants.CLIENT_ID_SIONUM,
          password: constants.CLIENT_SECRET_SIONUM,
        },
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  passwordRecovery(data) {
    return http.post(api(URLS.users.passwordRecovery), data);
  },

  changePassword(userId: string, data: ChangePasswordDTO) {
    return http.post<ChangePasswordDTO, unknown>(
      api(URLS.users.changePassword),
      data,
      {
        replacePathParams: { id: userId },
      },
    );
  },

  getAccessToken(response, redirectUri: string) {
    const authorizationResponse: AuthorizationResponse = response.data as AuthorizationResponse;
    const tokenData = {};
    tokenData[constants.FORM_FIELD_GRANT_TYPE] =
      constants.GRANT_TYPE_AUTHORIZATION_CODE;
    tokenData[constants.FORM_FIELD_CODE] = authorizationResponse.code;
    tokenData[constants.FORM_FIELD_REDIRECT_URI] = redirectUri;
    tokenData[constants.FORM_FIELD_CLIENT_ID] = constants.CLIENT_ID_SIONUM;
    return http.post<any, TokensResponse>(
      api(URLS.authToken),
      qs.stringify(tokenData),
      {
        skipHttpErrorHandler: true,
        auth: {
          username: constants.CLIENT_ID_SIONUM,
          password: constants.CLIENT_SECRET_SIONUM,
        },
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },
};
