import React, { Component } from 'react';
import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';
import style from './HomeScreenOffering.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { checkIsMobileOrTablet } from 'stores/helpers';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Icon from 'components/Icon/Icon';
import ConsentModal from './ConsentModal';

const CONSENT = 'consent';
const DO_NOT_SHOW = 'dontShowAddToHomeScreen';

@observer
class HomeScreenOffering extends Component {
  safari: boolean;
  chrome: boolean;
  @observable
  visible: boolean = false;

  componentDidMount(): void {
    if (!checkIsMobileOrTablet()) {
      return;
    }

    if (localStorage.getItem(CONSENT) === null) {
      ModalStore.showModal(ConsentModal, {
        description: React.createElement(
          'span',
          {},
          RootStore.localization.formatMessage('consent.description', {
            host: window.location.hostname,
          }),
        ),
        cancelBtnText: 'button.no.consent',
        submitBtnText: 'button.consent',
        useCancel: true,
        useAutofocus: false,
        type: 'change',
        closeable: false,
      })
        .then(() => {
          localStorage.setItem(CONSENT, 'true');
          this.showOffering();
        })
        .catch(() => {
          localStorage.setItem(CONSENT, 'false');
        });
    } else if (localStorage.getItem(CONSENT) === 'true') {
      if (localStorage.getItem(DO_NOT_SHOW) !== 'true') {
        this.showOffering();
      }
    }
  }

  render(): React.ReactNode {
    return (
      <>
        {this.visible && (
          <div>
            <div className={classNames(style.layer, style.show)} />
            <div className={style.container}>
              <div className={style.title}>
                <FormattedMessage id="home.screen.popup.title" />
              </div>
              <div className={style.close} onClick={this.closeOffering}>
                <FormattedMessage id="button.close" />
              </div>
              <div className={style.instructions}>
                <FormattedMessage id="home.screen.popup.description" />
              </div>
              <ul className={style.instruction_steps}>
                {this.safari && (
                  <>
                    <li>
                      <Icon type="shareIOS" />
                      <FormattedMessage id="home.screen.safari.step1" />
                    </li>
                    <li>
                      <Icon type="plusIOS" />
                      <FormattedMessage id="home.screen.safari.step2" />
                    </li>
                  </>
                )}
                {this.chrome && (
                  <>
                    <li>
                      <Icon type="chromeMenu" />
                      <FormattedMessage id="home.screen.chrome.step1" />
                    </li>
                    <li>
                      <Icon type="installMobile" />
                      <FormattedMessage id="home.screen.chrome.step2" />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }

  private isChrome(): boolean {
    return (
      navigator.userAgent.indexOf('Chrome/') >= 0 &&
      navigator.userAgent.indexOf(' Edg') < 0
    );
  }

  private isSafari(): boolean {
    return (
      navigator.userAgent.indexOf('Safari/') >= 0 &&
      navigator.userAgent.indexOf('Chrome/') < 0 &&
      navigator.userAgent.indexOf('Chromium/') < 0
    );
  }

  private showOffering() {
    this.safari = this.isSafari();
    this.chrome = this.isChrome();
    this.visible = true;
  }

  private hideOffering() {
    this.visible = false;
  }

  @action.bound
  private closeOffering() {
    this.hideOffering();
    localStorage.setItem(DO_NOT_SHOW, 'true');
  }
}

export default HomeScreenOffering;
