import React from 'react';
import { Column } from 'react-table';
import { FormattedMessage } from 'react-intl';

import style from './ArchivePage.module.scss';

import { getFormattedDate } from 'helpers/datetime';
import { withArrow } from 'components/Table/helpers';
import Button from 'components/Button/Button';
import classNames from 'classnames';
import RootStore from '../../stores/RootStore';
import { getPrimusStateColor } from './helpers';

const config = ({ onOpenImages }): Column[] => [
  {
    Header: withArrow('title.date'),
    accessor: 'createdDate',
    resizable: false,
    width: 120,
    Cell: row => getFormattedDate(row.value),
  },
  {
    Header: withArrow('title.meal.type'),
    accessor: 'type',
    resizable: false,
    Cell: row => (
      <FormattedMessage
        id={`archive.meal.${row.value.toString().toLowerCase()}`}
      />
    ),
  },
  {
    Header: withArrow('title.station'),
    accessor: 'station',
    resizable: false,
  },
  {
    Header: withArrow('title.room'),
    accessor: 'room',
    resizable: false,
  },
  {
    Header: withArrow('title.patientId'),
    accessor: 'patientId',
    resizable: false,
  },
  {
    Header: withArrow('title.referenceNumber'),
    accessor: 'referenceNumber',
    resizable: false,
  },
  {
    Header: withArrow('title.state'),
    accessor: 'state',
    resizable: false,
    Cell: row => (
      <span className={classNames(style.state, getPrimusStateColor(row.value))}>
        {RootStore.localization.formatMessage(row.value)}
      </span>
    ),
  },
  {
    Header: withArrow('title.image'),
    resizable: false,
    width: 120,
    Cell: row => (
      <Button
        styleType="text"
        onClick={onOpenImages(row.original.id)}
        className={style.button__view}
      >
        <FormattedMessage id="button.view" />
      </Button>
    ),
  },
];

export default config;
