import { action, computed, observable } from 'mobx';

import { IArchiveImage } from '../Forms/Archive/types';
import httpFacade from '../../http/httpFacade';
import Log from '../../helpers/log';

export const STEP = 1;

class ArchiveImageViewStore {
  @observable images: IArchiveImage[];

  @observable loading = false;
  @observable activeImage: string = '';

  @observable private activeIndex: number = 0;

  constructor(images) {
    this.images = images;
  }

  @computed
  get isLast() {
    return this.activeIndex === this.images.length - 1;
  }

  @computed
  get isFirst() {
    return this.activeIndex === 0;
  }

  @action.bound
  async fetchImage() {
    try {
      this.loading = true;
      const { data } = await httpFacade.archive.fetchImage(
        this.images[this.activeIndex].id,
      );

      this.activeImage = data.content;
    } catch (error) {
      Log.error(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async changeImage(value = 1) {
    this.activeIndex =
      value > 0
        ? Math.max(this.activeIndex + value, 0)
        : Math.min(this.activeIndex + value, this.images.length - 1);
    await this.fetchImage();
  }
}

export default ArchiveImageViewStore;
