import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router';

import style from './ReportPage.module.scss';

import { ROUTES } from 'routes/routes';

import NavigationTabs from 'components/NavigationTabs/NavigationTabs';

import RevenueReportPage from './RevenueReport/RevenueReportPage';
import StornoReportPage from './StornoReport/StornoReportPage';
import SubsidyReportPage from './SubsidyReport/SubsidyReportPage';
import ItemReportPage from './ItemReport/ItemRepotPage';

@observer
class ReportPage extends Component {
  render() {
    return (
      <main className={style.content__tabs}>
        <NavigationTabs
          className={style.tabs}
          tabs={[
            { to: ROUTES.admin.revenue, title: 'title.revenue' },
            { to: ROUTES.admin.subsidy, title: 'title.subsidy' },
            { to: ROUTES.admin.storno, title: 'title.storno' },
            { to: ROUTES.admin.item, title: 'title.best.worst.sold.item' },
          ]}
        />

        <Switch>
          <Route
            path={ROUTES.admin.revenue}
            component={RevenueReportPage}
            exact
          />

          <Route
            path={ROUTES.admin.subsidy}
            component={SubsidyReportPage}
            exact
          />

          <Route
            path={ROUTES.admin.storno}
            component={StornoReportPage}
            exact
          />

          <Route path={ROUTES.admin.item} component={ItemReportPage} exact />

          <Redirect to={ROUTES.admin.revenue} />
        </Switch>
      </main>
    );
  }
}

export default ReportPage;
