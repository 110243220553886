import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { CopyMenuFromWeekForm } from 'stores/Menu/MenuCopy/CopyMenuFromWeekForm';
import { CopyMenuFromDayForm } from 'stores/Menu/MenuCopy/CopyMenuFromDayForm';
import { CopyMenuLineForm } from 'stores/Menu/MenuCopy/CopyMenuLineForm';
import { CopyMenuItemsForm } from 'stores/Menu/MenuCopy/CopyMenuItemsForm';
import { CopyMode } from 'stores/Menu/MenuCopy/types';

import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import RadioField from 'components/Form/Fields/Radio/RadioField';
import { CopyFromDayComponent } from 'pages/MenuPlanner/CopyMenu/CopyFromDayComponent';
import { CopyFromWeekComponent } from 'pages/MenuPlanner/CopyMenu/CopyFromWeekComponent';
import { CopyMenuLineComponent } from 'pages/MenuPlanner/CopyMenu/CopyMenuLineComponent';
import { CopyMenuItemsComponent } from 'pages/MenuPlanner/CopyMenu/CopyMenuItemsComponent';

import style from './CopyMenu.module.scss';

interface IProps {
  store: MenuPageStore;
}

@inject('store')
@observer
class CopyMenu extends Component<IProps> {
  savedMode: CopyMode | null = null;

  renderFormByMode() {
    const { store } = this.props;

    if (!store.copyMenu && !this.savedMode) {
      return null;
    }

    switch (store.copyMode ?? this.savedMode) {
      case CopyMode.DAY:
        return (
          <CopyFromDayComponent
            form={store.copyMenu as CopyMenuFromDayForm}
            onSubmit={store.onCopyMenu}
            disabled={store.submitted}
          />
        );
      case CopyMode.WEEK:
        return (
          <CopyFromWeekComponent
            form={store.copyMenu as CopyMenuFromWeekForm}
            onSubmit={store.onCopyMenu}
            disabled={store.submitted}
          />
        );
      case CopyMode.MENU_LINE:
        return (
          <CopyMenuLineComponent
            form={store.copyMenu as CopyMenuLineForm}
            onSubmit={store.onCopyMenu}
            disabled={store.submitted}
          />
        );
      case CopyMode.MENU_ITEMS:
        return (
          <CopyMenuItemsComponent
            form={store.copyMenu as CopyMenuItemsForm}
            onSubmit={store.onCopyMenu}
            disabled={store.submitted}
          />
        );
      default:
        return '';
    }
  }

  componentDidUpdate(prevProps: IProps) {
    // save mode on update in order to transition copy panel closing
    this.savedMode = prevProps.store.copyMode;
  }

  render() {
    const { store } = this.props;

    return (
      <div className={style.section}>
        <div className={style.header}>
          <h2 className={style.title}>
            <FormattedMessage id="menu.copy.title" />
          </h2>
          <div className={style.modeSwitcher}>
            {Object.values(CopyMode).map(mode => (
              <RadioField
                key={mode}
                name="copyMode"
                value={mode}
                title={mode}
                checked={store.copyMode === mode}
                onChange={() => store.changeCopyMode(mode)}
                className={style.modeRadioButton}
              />
            ))}
          </div>
          <SwitchButton
            checked={store.isCopyMode}
            onChange={store.toggleCopyMode}
          />
        </div>
        <div
          className={classNames(style.form, {
            [style.formMenuItems]: store.copyMode === CopyMode.MENU_ITEMS,
          })}
          aria-expanded={store.isCopyMode}
        >
          <div
            className={classNames(style.formContent, {
              [style.formContentColumn]: store.copyMode === CopyMode.MENU_ITEMS,
            })}
          >
            {this.renderFormByMode()}
          </div>
        </div>
      </div>
    );
  }
}

export { CopyMenu };
