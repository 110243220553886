const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob);

export function downloadCsvBlob({ fileName, csvBlob, csvBlobURL }) {
  if (isIE11) {
    window.navigator.msSaveOrOpenBlob(csvBlob, `${fileName}.csv`);
  } else {
    const anchor = document.createElement('a');

    document.body.appendChild(anchor);

    anchor.href = csvBlobURL;
    anchor.download = fileName;

    anchor.click();
    anchor.remove();
  }
}
