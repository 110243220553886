import { DateTime } from 'luxon';
import { observable, action, computed } from 'mobx';

import httpFacade from 'http/httpFacade';

import { getDate, getLocalDateTime } from 'helpers/datetime';
import Log from 'helpers/log';

import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import { UserCompany, UserGroup } from 'stores/Staff/types';
import { SubsidyReportReqParams } from 'stores/Report/types';
import RootStore from 'stores/RootStore';

class SubsidyReportForm extends BaseFormModel {
  @observable
  @isRequired()
  period: [DateTime, DateTime | undefined] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];

  @observable
  @displayName('title.enterprise')
  companyId = '';

  @observable
  @displayName('title.userGroups')
  userGroupId = '';

  @observable
  exportType = '';

  @observable companies: UserCompany[] = [];
  @observable userGroups: UserGroup[] = [];

  @observable loading = false;

  @computed
  get requestParams(): SubsidyReportReqParams {
    const [startDate, endDate] = this.period;

    return {
      from: getDate(startDate.toJSDate()),
      to: getDate(endDate!.toJSDate()),
      ...(this.companyId ? { userCompanyId: this.companyId } : {}),
      ...(this.userGroupId ? { userGroupId: this.userGroupId } : {}),
    };
  }

  @computed
  get userGroupsValues() {
    return this.userGroups.map(userGroup => ({
      value: userGroup.id,
      label: userGroup.title,
    }));
  }

  @computed
  get companiesValues() {
    return this.companies.map(company => ({
      value: company.id,
      label: company.title,
    }));
  }

  @action
  async init() {
    try {
      this.loading = true;

      if (RootStore.config.feature.subsidies?.bayerSpecific) {
        this.userGroups = (
          await httpFacade.staff.fetchBayerSubsidies()
        ).data.map(subsidy => ({
          id: typeof subsidy.id !== 'undefined' ? subsidy.id : '',
          title: subsidy.title,
        }));
      } else {
        const [companies, groups] = await Promise.all([
          httpFacade.staff.fetchUserCompanies(),
          httpFacade.staff.fetchUserGroups(),
        ]);
        this.companies = companies.data.content;
        this.userGroups = groups.data.content;
      }
    } catch (error) {
      Log.info(error.message);
    } finally {
      this.loading = false;
    }
  }
}

export default SubsidyReportForm;
