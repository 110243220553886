import { action, observable } from 'mobx';

const DEFAULT_INTERVAL = 60000;

export class IntervalFetch {
  @observable active: boolean = false;
  @observable request: () => Promise<any>;
  interval: number;
  timerId: ReturnType<typeof setTimeout>;

  constructor(interval: number = DEFAULT_INTERVAL) {
    this.interval = interval;
  }

  @action
  setRequest = request => {
    this.request = request;
  };

  @action
  fetch = async () => {
    try {
      await this.request();
    } finally {
      if (this.active) {
        this.timerId = setTimeout(this.fetch, this.interval);
      }
    }
  };

  @action
  start = async () => {
    this.active = true;
    await this.fetch();
  };

  @action
  stop = () => {
    this.active = false;
    clearTimeout(this.timerId);
  };
}
