import http from 'http/http';
import URLS from 'http/urls';
import { api } from 'http/helpers';
import { RefillRequestDto } from 'stores/Recharge/types';

interface UserDepositDTO {
  comment?: string;
  overdraft: boolean;
  tag: boolean;
}

export const paymentApi = {
  refillDeposit(userId: string, data: UserDepositDTO) {
    return http.post<UserDepositDTO, unknown>(api(URLS.users.deposit), data, {
      replacePathParams: { id: userId },
    });
  },

  returnDeposit(userId: string, data: UserDepositDTO) {
    return http.delete(api(URLS.users.deposit), {
      data,
      replacePathParams: { id: userId },
    });
  },

  requestRefill(refillData: RefillRequestDto) {
    return http.post<RefillRequestDto, { uri: string }>(
      api(URLS.payment.request),
      refillData,
    );
  },
};
