import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { DeploymentDTO, DeploymentModel } from './types';

import Log from 'helpers/log';
import { sortByAccessor } from '../../helpers/accessors';

interface ServingTagsWithModels {
  name: string;
  modelsCount: number;
  models: DeploymentModel[];
}

class DeploymentsStore {
  @observable loading = false;

  @observable deployments: DeploymentDTO[] = [];
  @observable private sortConfig = { accessor: 'description', desc: true };

  @computed
  get deploymentsWithModels() {
    const servingTags = [
      ...new Set(
        this.deployments?.map?.(deployment => deployment?.cfg?.servingTag),
      ),
    ];
    const servingTagsWithModels: ServingTagsWithModels[] = [];

    servingTags.forEach(servingTag => {
      const models: DeploymentModel[] = [];
      this.deployments.forEach(deployment => {
        if (deployment.cfg.servingTag === servingTag) {
          models.push({
            ...deployment.model,
            result: { ...deployment.result },
            createdDate: deployment.createdDate,
            enabled: deployment.enabled,
            description: deployment.description,
            lastModifiedDate: deployment.lastModifiedDate,
            deploymentId: deployment.id,
            type: deployment.type,
            cfg: { ...deployment.cfg },
          });
        }
      });
      servingTagsWithModels.push({
        name: servingTag,
        modelsCount: models.length,
        models: [...models]?.sort(sortByAccessor(this.sortConfig)),
      });
    });

    return servingTagsWithModels;
  }

  @action.bound
  async fetchDeployments() {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.fetchDeployments();
      this.deployments = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async enableDeployment(model: DeploymentModel) {
    try {
      const { data } = await httpFacade.recognition.enableDeployment(
        model.deploymentId,
      );
      if (data?.result?.successful) {
        this.deployments = this.deployments.map(deployment => {
          return deployment.id === model.deploymentId
            ? { ...deployment, ...{ enabled: true } }
            : deployment;
        });
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async disableDeployment(model: DeploymentModel) {
    try {
      const { data } = await httpFacade.recognition.disableDeployment(
        model.deploymentId,
      );
      if (data?.result?.successful) {
        this.deployments = this.deployments.map(deployment => {
          return deployment.id === model.deploymentId
            ? { ...deployment, ...{ enabled: false } }
            : deployment;
        });
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action.bound
  async deleteDeployment(model: DeploymentModel) {
    try {
      const { data } = await httpFacade.recognition.deleteDeployment(
        model.deploymentId,
      );
      if (data?.result?.successful) {
        this.deployments = this.deployments.filter(
          deployment => deployment.id !== model.deploymentId,
        );
      }
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default DeploymentsStore;
