import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from 'components/Modals/BalanceMigration/BalanceMigrationModal.module.scss';

import { ModalProps } from 'stores/ModalStore';
import BalanceMigrationForm, {
  refillLimits,
} from 'stores/Forms/BalanceMigrationForm';

import { bindFormControl } from 'components/Form/FormControl/FormControl';

import Controls from 'components/Modals/Controls/Controls';
import CurrencyInput from 'components/Form/Fields/Number/CurrencyInput';
import { EUR } from 'helpers/currency';

interface Props extends ModalProps {
  user: {
    id: string;
    name: string;
  };
}

@observer
class BalanceMigrationModal extends Component<Props> {
  form = new BalanceMigrationForm(this.props.user);
  FormControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();

    await this.form.submit(this.props.onSubmit);
  };

  render() {
    const { onClose } = this.props;
    const { FormControl } = this;

    return (
      <Modal
        {...this.props}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        visible
      >
        <form
          onSubmit={this.onSubmit}
          className={style.form}
          name="refill-form"
        >
          <h3 className={style.form__title}>
            <FormattedMessage id="modal.balance.title" />
          </h3>

          <div className={style.form__body}>
            <div className={style.description}>
              <FormattedMessage
                id="modal.balance.description"
                values={{
                  name: this.form.user.name,
                }}
              />
            </div>

            <FormControl
              name="amount"
              render={props => (
                <CurrencyInput
                  {...props}
                  maskOptions={{ integerLimit: refillLimits.maxAmountLength }}
                  postfix={EUR}
                />
              )}
            />
          </div>

          <Controls
            onClose={onClose}
            submitBtnTitle="modal.balance.save"
            disabledSubmit={!this.form.isFormValid}
          />
        </form>
      </Modal>
    );
  }
}

export default BalanceMigrationModal;
