import React, { Component } from 'react';

import style from './ProgressBar.module.scss';

interface Props {
  value: number;
}

class ProgressBar extends Component<Props> {
  render() {
    const { value } = this.props;
    const width = { width: `${value}%` };

    return (
      <>
        <div className={style.progress}>
          <div className={style.progressBar} role="progressbar" style={width} />
        </div>

        <span className={style.value}>{value}%</span>
      </>
    );
  }
}

export default ProgressBar;
