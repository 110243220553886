import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './MenuRecommendation.module.scss';

import MenuCard from '../MenuCard/MenuCard';

import ProfileMenuStore from 'stores/UserProfile/ProfileMenuStore';

@observer
class MenuRecommendation extends Component<{
  menu: ProfileMenuStore;
}> {
  render() {
    const { menu } = this.props;

    return (
      <div className={style.content}>
        <span className={style.title__recommendation}>
          <FormattedMessage id="employeeProfile.menu.recommends.title" />
        </span>

        <div className={style.items}>
          {menu?.recommendedMenuForCurrentDay?.length > 0 ? (
            menu.recommendedMenuForCurrentDay?.map(line => {
              return line.days.map(day => {
                return day.items.map(item => (
                  <MenuCard
                    menuItem={item}
                    key={item.id}
                    className={style.card__recommendation}
                  />
                ));
              });
            })
          ) : (
            <span className={style.message__noResults}>
              <FormattedMessage id={'employeeProfile.menu.noResultsMessage'} />
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default MenuRecommendation;
