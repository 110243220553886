import React, { Component } from 'react';
import { observer } from 'mobx-react';
import style from './SubsidyModal.module.scss';
import { FormattedMessage } from 'react-intl';
import SubsidyForm from '../../../stores/Forms/SubsidyForm';
import { UserGroup } from '../../../stores/Staff/types';
import { DishGroupManagement } from '../../../stores/Menu/types';
import FormattedCurrency from '../../FormattedCurrency/FormattedCurrency';
import { getTitleWithPeriod } from './helpers';

interface Props {
  form: SubsidyForm;
  userGroup?: UserGroup;
  dishCategories: DishGroupManagement[];
}

@observer
class SubsidyCheckList extends Component<Props> {
  render() {
    const { form, userGroup = { title: '' }, dishCategories } = this.props;

    return (
      <>
        <div className={style.amount__description}>
          <FormattedMessage
            id="modal.discount.summary.price"
            values={{
              mode: form.confirmationMode ? (
                <FormattedMessage id="modal.discount.mode.manual" />
              ) : (
                ''
              ),
              value: (
                <span className={style.price}>
                  <FormattedCurrency value={form.price} />
                </span>
              ),
            }}
            tagName={'p'}
          />
        </div>

        <div className={style.row}>
          <span className={style.label}>
            <FormattedMessage id="modal.discount.label.userGroup" />
          </span>
          <div className={style.values}>{userGroup.title}</div>
        </div>

        <div className={style.row}>
          <span className={style.label}>
            <FormattedMessage id="modal.discount.label.dishCategory" />
          </span>
          <div className={style.categories}>
            {!!dishCategories.length ? (
              dishCategories?.map((category, index) => (
                <span key={category.id} className={style.values}>{`${
                  category.title
                }${
                  index >= 0 && index !== dishCategories.length - 1 ? ', ' : ''
                }`}</span>
              ))
            ) : (
              <span className={style.values}>
                <FormattedMessage id="title.discounts.apply.to.allCategories" />
              </span>
            )}
          </div>
        </div>

        <div className={style.row}>
          <span className={style.amount__description}>
            <FormattedMessage id="USAGE_LIMITS" />
          </span>
          <p className={style.values}>
            <FormattedMessage
              id={getTitleWithPeriod(
                form.otherLimits ? form.period : form.defaultPeriod,
              )}
              values={{
                limit: form.otherLimits ? form.limit : form.defaultLimit,
              }}
            />
          </p>
        </div>
      </>
    );
  }
}

export default SubsidyCheckList;
