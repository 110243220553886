import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import { ModalProps } from 'stores/ModalStore';
import QRPaymentStore from 'stores/Modals/QRPaymentStore';

import Spinner from 'components/Spinner/Spinner';
import Button from 'components/Button/Button';

interface Props extends ModalProps {
  userId: string;
}

@observer
class QRPaymentModal extends Component<Props> {
  store = new QRPaymentStore(this.props.userId);

  async componentDidMount() {
    await this.store.generateQRCode();
  }

  render() {
    const { code, loading, lifetime, generateQRCode } = this.store;
    const { onClose } = this.props;

    return loading ? (
      <Spinner fullscreen />
    ) : (
      <Modal
        {...this.props}
        className={'qrPayment__modal'}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <h3 className={'qrPayment__title'}>
          <FormattedMessage id="modal.qr.payment.title" />
        </h3>

        <div className={'qrPayment__code_wrapper'}>
          {code ? (
            <img src={code} alt="qr-code" className={'qrPayment__code'} />
          ) : (
            <p className={'qrPayment__error_text'}>
              <FormattedMessage id="modal.qr.payment.error" />
            </p>
          )}
        </div>

        {code && (
          <p className={'qrPayment__time_wrapper'}>
            <FormattedMessage
              id="modal.qr.payment.time"
              values={{
                mm: lifetime,
              }}
            />
          </p>
        )}

        <div className={'qrPayment__controls'}>
          {code ? (
            <Button
              className={'qrPayment__btn_close'}
              styleType="ghost"
              onClick={onClose}
            >
              <FormattedMessage id="button.close" />
            </Button>
          ) : (
            <Button
              className={'qrPayment__btn_generate'}
              styleType="ghost"
              onClick={generateQRCode}
            >
              <FormattedMessage id="button.generate.qr.again" />
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

export default QRPaymentModal;
