import { action, observable, reaction } from 'mobx';

import httpFacade from 'http/httpFacade';

import { MLModelWithDeployments } from './types';

import { SortConfig } from 'helpers/types';
import { sortByAccessor } from 'helpers/accessors';
import Log from 'helpers/log';

class ModelsStore {
  @observable models: MLModelWithDeployments[] = [];
  @observable showedModel: MLModelWithDeployments;

  @observable loading = false;

  private defaultSort: SortConfig = { accessor: 'createdDate', desc: true };

  constructor() {
    reaction(
      () => this.models,
      () => {
        this.showedModel = this.models.filter(
          model => model.id === this.showedModel?.id,
        )[0];
      },
    );
  }

  @action.bound
  async fetchModels() {
    this.loading = true;

    try {
      const {
        data,
      } = await httpFacade.recognition.fetchModelsWithDeployments();

      this.models = data.slice().sort(sortByAccessor(this.defaultSort));
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async enableLabelingModel(model: MLModelWithDeployments) {
    try {
      this.loading = true;

      await httpFacade.recognition.enableLabelling(model);

      this.models = this.models.map(it => ({
        ...it,
        labelling: Boolean(it.id === model.id),
      }));
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async enableDefaultModel(model: MLModelWithDeployments) {
    try {
      this.loading = true;

      await httpFacade.recognition.enableDefaultModel(model);

      this.models = this.models.map(it => ({
        ...it,
        inferenceDefault: Boolean(it.id === model.id),
      }));
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteModel(data: { id: string; version: string }) {
    try {
      this.loading = true;

      await httpFacade.recognition.deleteModel(data);

      this.models = this.models.filter(model => model.id !== data.id);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateModel(id: string, version: string, description?: string) {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.updateModel(
        id,
        version,
        description,
      );

      this.models = this.models.map(model => (model.id === id ? data : model));
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteDeployment(deploymentId: string) {
    try {
      const { data } = await httpFacade.recognition.deleteDeployment(
        deploymentId,
      );
      if (data?.result?.successful) {
        this.models = this.models.map(model => {
          if (model.id === data.model.id) {
            // @ts-ignore
            model.deployments.data = [
              ...model?.deployments?.data?.filter?.(
                deployment => deployment.id !== deploymentId,
              ),
            ];
          }
          return model;
        });
      }
    } catch (error) {
      Log.warn(error);
    }
  }

  @action
  setShowedModel(id: string) {
    this.showedModel = this.models.filter(model => model.id === id)[0];
  }
  @action.bound
  async enableDeployment(deploymentId: string, isEnable: boolean) {
    try {
      this.models = this.models.map(model => {
        model.deployments?.data?.map(deployment => {
          if (deployment.id === deploymentId) {
            deployment.enabled = isEnable;
            model.deployments.active = isEnable
              ? model.deployments.active + 1
              : model.deployments.active - 1;
          }
          return deployment;
        });
        return model;
      });
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default ModelsStore;
