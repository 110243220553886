import { observable, action, computed } from 'mobx';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

import { BaseFormModel, isRequired, displayName } from 'stores/BaseForm';
import {
  TaskType,
  CreateTaskReqParams,
  IDataset,
} from 'stores/Recognition/types';
import AppRouter from 'stores/AppRouter';
import { ITag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { sortByAccessor } from 'helpers/accessors';

export const OptionsLimits = {
  stepsNumberMin: 0,
  stepsNumberMax: 1000000,
  stepsNumberStep: 5000,
  durationMin: 0,
  durationMax: 24 * 60,
  durationStep: 15,
};

interface TaskParams {
  datasetIds: string[];
  durationMs?: number;
  stepsNumber?: number;
  remapLabels?: boolean;
}

class TaskDetectorForm extends BaseFormModel {
  type:
    | TaskType.TRAIN_DETECTOR_SSD
    | TaskType.TRAIN_DETECTOR_TF_MRCNN
    | TaskType.TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL;

  @observable
  selectedDatasets: ITag[] = [];

  @observable
  @displayName('DESCRIPTION*')
  @isRequired()
  description: string;

  @observable
  @isRequired()
  stepsNumber = 0;

  @observable
  @isRequired()
  duration = 0;

  @observable
  remapLabels = false;

  @observable loading = false;

  @observable datasets: IDataset[] = [];

  @computed
  get durationMs() {
    return this.duration * 60000;
  }

  @computed
  get datasetIds() {
    return this.selectedDatasets.map(dataset => dataset.id);
  }

  @computed
  get datasetTags(): ITag[] {
    return this.datasets
      .map(dataset => ({
        id: dataset.id,
        title: dataset.name,
        date: dataset.createdDate,
      }))
      .sort(sortByAccessor({ desc: true, accessor: 'date' }));
  }

  constructor(
    type:
      | TaskType.TRAIN_DETECTOR_SSD
      | TaskType.TRAIN_DETECTOR_TF_MRCNN
      | TaskType.TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL,
  ) {
    super();

    this.type = type;
  }

  @computed
  get isParamsValid() {
    return (
      Boolean(this.selectedDatasets.length) &&
      this.description &&
      (this.stepsNumber > 0 || this.durationMs > 0)
    );
  }

  @computed
  get requestParams(): CreateTaskReqParams<TaskParams> {
    return {
      type: this.type,
      description: this.description,
      params: {
        datasetIds: this.datasetIds,
        remapLabels: this.remapLabels,
        ...(this.durationMs > 0 && { durationMs: this.durationMs }),
        ...(this.stepsNumber > 0 && { stepsNumber: this.stepsNumber }),
      },
    };
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.fetchDatasets();

      this.datasets = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onCreate() {
    try {
      this.loading = true;

      await httpFacade.recognition.createTask(this.requestParams);

      AppRouter.goBack();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setDatasets(tags: ITag[]) {
    this.selectedDatasets = tags;
  }

  @action.bound
  removeDataset(id?: string) {
    this.selectedDatasets = id
      ? this.selectedDatasets.filter(it => it.id !== id)
      : [];
  }
}

export default TaskDetectorForm;
