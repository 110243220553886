import { action, computed, observable } from 'mobx';
import { BaseFormModel, hasMaxLength, isEmail } from '../../BaseForm';
import { isPhone } from '../../BaseForm/validators/isPhone';
import RootStore from '../../RootStore';
import { ckeckedIfRequiredFieldsFilled, defineRequiredFields } from './helpers';
import { OrderDeliveryDTO } from './types';

class OrderLocationsDeliveryForm extends BaseFormModel {
  @observable
  pointOfDelivery = '';

  @observable
  typeOfDelivery: string;

  @observable
  @isEmail()
  @hasMaxLength(100)
  email = '';

  @observable
  @isPhone()
  @hasMaxLength(20)
  phone = '';

  @observable
  @hasMaxLength(100)
  comment = '';

  @observable selectedLocation: string;

  @action
  setSelectedLocation(location: string) {
    this.selectedLocation = location;
  }

  @computed
  get requestOrderData(): OrderDeliveryDTO {
    return {
      deliveryInfo: {
        email: this.email,
        phone: this.phone,
        comment: this.comment,
        pointOfDelivery: this.pointOfDelivery,
      },
      selectedLocation: this.selectedLocation,
    };
  }

  @computed
  get requiredCustomLocationsFeatureFields() {
    return defineRequiredFields(
      RootStore.config.feature?.deliveryCustomLocations?.fields,
    );
  }
  @computed
  get requiredServerLocationsFeatureFields() {
    return defineRequiredFields(
      RootStore.config.feature?.deliveryServerLocations?.fields,
    );
  }
  @computed
  get isValidDeliveryServerLocationsForm() {
    return (
      this.selectedLocation &&
      ckeckedIfRequiredFieldsFilled(
        this,
        this.requiredServerLocationsFeatureFields,
      )
    );
  }

  @computed
  get isValidDeliveryCustomLocationsForm() {
    return ckeckedIfRequiredFieldsFilled(
      this,
      this.requiredCustomLocationsFeatureFields,
    );
  }
}
export default OrderLocationsDeliveryForm;
