import { observable, action } from 'mobx';

import httpFacade from 'http/httpFacade';

import Log from 'helpers/log';
import { showDate } from 'helpers/datetime';
import { downloadPdfBlob } from 'helpers/pdf';
import { downloadCsvBlob } from 'helpers/csv';

import RootStore from '../RootStore';

import { RevenueReport, RevenueReportReqParams, SortProperty } from './types';
import IReportStore from './IReportStore';

class RevenueReportStore implements IReportStore {
  @observable report: RevenueReport;
  @observable loading = false;

  @action.bound
  async getReport(params: RevenueReportReqParams) {
    try {
      this.loading = true;

      const { data } = await httpFacade.order.fetchRevenueReport(params);

      this.report = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getPdfReport(params: RevenueReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.revenue.pdf',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchRevenueReportPdf(params);
      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], { type: 'application/pdf' });

      downloadPdfBlob({ fileName, pdfBlob, pdfBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async getCsvReport(params: RevenueReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.revenue.csv',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchRevenueReportCsv(params);
      const csvBlobURL = URL.createObjectURL(data);
      const csvBlob = new Blob([data], { type: 'text/csv' });

      downloadCsvBlob({ fileName, csvBlob, csvBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }

  changeSortProperty(sortProperty: SortProperty | null) {
    //
  }
}

export default RevenueReportStore;
