import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isNotSameAs(compareName: string, msg?: string) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];
        const compareValue = obj[compareName];

        const isValid = value !== compareValue;
        return isValid
          ? undefined
          : {
              message: msg || 'VM_IS_NOT_SAME_AS',
              data: {
                name,
                value,
                compareName,
              },
            };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
