import { DateTime } from 'luxon';
import RootStore from 'stores/RootStore';

export type ISOTimeString = string;
export type ISODateString = string; // yyyy-MM-dd
export type ISODateTimeString = string;

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const TIME_FORMAT = 'HH:mm:ss';
export const SHORT_TIME_FORMAT = 'HH:mm';
export const QR_LIVE_TIME_FORMAT = 'mm';
export const DATE_FORMAT_ORDERS = 'MMMM d, EEEE';

export enum QUICK_TIME_PERIODS {
  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_YEAR = 'CURRENT_YEAR',
}

/* ToDo: Temporary solution! Should be deleted after BE fix.*/
const addUTCSuffixToISOString = (date: string) => `${date}Z`;

export function showDate(date: string) {
  const [year, month, day] = date.split(/[-TZ:.]/);

  return `${day}.${month}.${year}`;
}

export function getDate(date: Date) {
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export function getDuration(startTime: string, endTime: string) {
  return DateTime.fromISO(endTime)
    .diff(DateTime.fromISO(startTime))
    .toFormat('hh:mm:ss');
}

export function getLocalDateTime() {
  return DateTime.local();
}

export function getDateTimeWithLocale(
  date: string,
  locale: string = RootStore.localization.locale,
) {
  return DateTime.fromISO(date).setLocale(locale);
}

export function getDateTimeFromISO(date: string) {
  return DateTime.fromISO(date);
}

export function getFormattedDateTime(
  datetime: ISODateTimeString | ISOTimeString,
  format: string,
) {
  return DateTime.fromISO(addUTCSuffixToISOString(datetime)).toFormat(format);
}

export function getFormattedDate(
  date: ISODateString,
  format: string = DATE_FORMAT,
) {
  return DateTime.fromISO(date).toFormat(format);
}

export function getUTCTime(time: string, resultFormat: string = TIME_FORMAT) {
  return DateTime.fromISO(time)
    .toUTC()
    .toFormat(resultFormat);
}

export function getDateTimeFromJSDate(
  date: Date,
  locale: string = RootStore.localization.locale,
) {
  return DateTime.fromJSDate(date).setLocale(locale);
}

export function getFormattedDateTimeFromMillis(ms: number, format: string) {
  return DateTime.fromMillis(ms).toFormat(format);
}

export function getFormattedTime(
  time: ISOTimeString,
  format: string = TIME_FORMAT,
) {
  return DateTime.fromISO(time).toFormat(format);
}
