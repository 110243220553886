import {
  DATE_TIME_FORMAT,
  getDuration,
  getFormattedDateTime,
} from 'helpers/datetime';
import React, { ReactElement } from 'react';
import { Column } from 'react-table';

import { TaskAction } from 'stores/Recognition/types';

import Button from 'components/Button/Button';
import { withArrow } from 'components/Table/helpers';
import Icon from 'components/Icon/Icon';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { TaskStatus } from './TaskStatus/TaskStatus';

import style from '../Recognition.module.scss';
import { FormattedMessage } from 'react-intl';
import FloatMenu from '../../../components/FloatMenu/FloatMenu';

const config = ({ onUpdate, onDelete, onTaskAction }): Column[] => [
  {
    Header: withArrow('title.type'),
    accessor: 'type',
    resizable: true,
    minWidth: 320,
    Cell: row => <span className={style.name}>{row.value}</span>,
  },
  {
    Header: withArrow('title.comment'),
    accessor: 'description',
    resizable: true,
    minWidth: 180,
    Cell: row => (
      <Button
        styleType="text"
        onClick={onUpdate(row.original)}
        className={style.btn__description}
      >
        {row.value}
      </Button>
    ),
  },
  {
    Header: withArrow('title.dateRange'),
    accessor: 'createdDate',
    resizable: false,
    width: 160,
    Cell: row => getFormattedDateTime(row.value, DATE_TIME_FORMAT),
  },
  {
    Header: withArrow('title.duration'),
    resizable: false,
    width: 100,
    Cell: row =>
      row.original.startedAt && row.original.finishedAt
        ? getDuration(row.original.startedAt, row.original.finishedAt)
        : '',
  },
  {
    Header: withArrow('title.progress'),
    accessor: 'metrics.progress',
    resizable: false,
    width: 140,
    Cell: row =>
      row.value ? (
        <ProgressBar key={row.original.id} value={Number(row.value)} />
      ) : (
        ''
      ),
  },
  {
    Header: withArrow('title.state'),
    accessor: 'state',
    resizable: false,
    width: 180,
    Cell: row => <TaskStatus state={row.value} />,
  },
  {
    id: 'operations',
    resizable: false,
    sortable: false,
    width: 50,
    className: 'operations',
    Cell: row => {
      if (
        row.original.wfAllowedActions.includes(
          TaskAction.CREATE_TASK_CREATE_MODEL,
        )
      ) {
        const options: ReactElement[] = [
          <Button
            styleType="text"
            className={style.action}
            onClick={onDelete(row.original.id)}
            key={row.original.id}
          >
            <FormattedMessage id="button.delete" />
          </Button>,
        ];

        if (
          row.original.wfAllowedActions.includes(
            TaskAction.CREATE_TASK_CREATE_MODEL,
          )
        ) {
          options.unshift(
            <Button
              styleType="text"
              className={style.action}
              key={row.original.id}
              onClick={onTaskAction(
                row.original,
                TaskAction.CREATE_TASK_CREATE_MODEL,
              )}
            >
              <FormattedMessage id="button.create.model" />
            </Button>,
          );
        }

        return <FloatMenu options={options} />;
      } else if (
        row.original.wfAllowedActions.includes(
          TaskAction.IMPORT_DATASET_ARTIFACT,
        )
      ) {
        const options: ReactElement[] = [
          <Button
            styleType="text"
            className={style.action}
            onClick={onDelete(row.original.id)}
            key={row.original.id}
          >
            <FormattedMessage id="button.delete" />
          </Button>,
        ];
        options.unshift(
          <Button
            styleType="text"
            className={style.action}
            key={row.original.id}
            onClick={onTaskAction(
              row.original,
              TaskAction.IMPORT_DATASET_ARTIFACT,
            )}
          >
            <FormattedMessage id="button.generate.dataset" />
          </Button>,
        );
        return <FloatMenu options={options} />;
      } else {
        return (
          <>
            {row.original.wfAllowedActions.includes(TaskAction.CANCEl) && (
              <Button
                styleType="text"
                className={style.action__icon}
                onClick={onTaskAction(row.original, TaskAction.CANCEl)}
              >
                <Icon type="cancel" />
              </Button>
            )}
            <Button
              styleType="text"
              className={style.action__icon}
              onClick={onDelete(row.original.id)}
            >
              <Icon type="trash" />
            </Button>
          </>
        );
      }
    },
  },
];

export default config;
