import React from 'react';
import { Column } from 'react-table';

import style from '../Subsidies.module.scss';

import { withArrow } from 'components/Table/helpers';
import Controls from 'components/Table/Controls';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import { getSubsidesetItemsAmount } from './helpers';
import { FormattedMessage } from 'react-intl';

const config = ({ onEdit, onDelete }): Column[] => [
  {
    Header: withArrow('title.discounts.user.group'),
    accessor: 'details.userGroup.title',
    minWidth: 200,
    Cell: row => <span className={style.name}>{row.value}</span>,
  },
  {
    Header: withArrow('title.discounts.categories'),
    accessor: 'dishGroups',
    minWidth: 235,
    Cell: row => {
      return (
        <span className={style.name}>
          {row.value || <FormattedMessage id={'title.all'} />}
        </span>
      );
    },
  },
  {
    Header: withArrow('title.discounts.amount'),
    accessor: 'details.price',
    resizable: false,
    maxWidth: 200,
    Cell: row => <FormattedCurrency value={row.value} withSymbol={false} />,
  },
  {
    Header: withArrow('title.discounts.limits'),
    accessor: 'details.limit',
    resizable: false,
    minWidth: 150,
    maxWidth: 200,
    Cell: row => `${row.value}x`,
  },
  {
    Header: withArrow('title.discounts.updates'),
    accessor: 'details.period',
    resizable: false,
    minWidth: 170,
    maxWidth: 200,
    Cell: row => <FormattedMessage id={row.value} defaultMessage={row.value} />,
  },
  {
    Header: withArrow('title.discounts.subsidized'),
    resizable: true,
    width: 185,
    Cell: row =>
      getSubsidesetItemsAmount(
        row.original.details.dishGroups,
        row.original.allDishGroups,
      ),
  },
  {
    id: 'operation',
    resizable: false,
    width: 72,
    className: 'operations',
    Cell: row => (
      <Controls
        onEdit={onEdit(row.original)}
        onDelete={onDelete(row.original.id)}
      />
    ),
    sortable: false,
  },
];

export const bayerConfig = ({ onEdit, onDelete }): Column[] => [
  {
    Header: withArrow('subsidy.table.name'),
    accessor: 'title',
    minWidth: 300,
    Cell: row => <span className={style.name}>{row.value}</span>,
  },
  {
    Header: withArrow('subsidy.table.type'),
    accessor: 'type',
    minWidth: 300,
    Cell: row => {
      return (
        <span className={style.name}>
          <FormattedMessage id={row.value} />
        </span>
      );
    },
  },
  {
    Header: withArrow('subsidy.table.user.group'),
    accessor: 'userGroup',
    minWidth: 300,
    Cell: row => <span className={style.name}>{row.value}</span>,
  },
  {
    id: 'operation',
    resizable: false,
    width: 72,
    className: 'operations',
    Cell: row => (
      <Controls
        onEdit={onEdit(row.original)}
        onDelete={onDelete(row.original.id)}
      />
    ),
    sortable: false,
  },
];

export default config;
