import {
  DishItem,
  DishGroupManagement,
  MenuManagementDate,
} from '../Menu/types';
import { MenuItemLabel } from 'http/Api/MenuItem/types';
import { ISODateString, ISODateTimeString } from 'helpers/datetime';
import { OrderDeliveryDTO } from '../Forms/OrderDeliveryForm/types';

export type SwipeDirection = 'previous' | 'next';

export interface UpdateDishPreferencesDto {
  allergens: string[];
  specifications: string[];
}

export enum Periods {
  week = 'week',
}

export interface AllergenType {
  id: string;
  title: string;
  icon?: string;
}

export interface DishPreferences {
  allergens: string[];
  specifications: any[];
}

export interface IMenuLine {
  id: string;
  title: string;
  deleted: boolean;
  onlineOrder: boolean;
  prioritized: boolean;
}

export interface MenuItem extends Omit<DishItem, 'dishGroup'> {
  price: number;
  currency?: string;
  id: string;
  tag: string;
  title: string;
  type: string;
  itemNumber: string;
  depositItemId?: string;
  depositTitle?: string;
  depositPrice?: number;
  subsidized: boolean;
  vatRates: number[];
  dishGroup: DishGroupManagement;
  amount?: number;
  description?: string;
  image?: string;
  isFetchedImage?: boolean;
  labels: MenuItemLabel[];
  requiresConsumptionCondition: boolean;
}

export interface OnlineOrderMenuLine {
  line: string;
  lineTitle: string;
  days: WeeklyMenuLine[];
}

export interface OnlineOrderMenuStandard {
  dishGroupId: string;
  dishGroupTitle: string;
  items: MenuItem[];
}

export interface WeeklyMenuLine {
  date: string;
  items: MenuItem[];
}

export interface DeliveryTimeSlot {
  id: string;
  start: ISODateTimeString;
  end: ISODateTimeString;
  blocked: boolean;
  available: boolean;
}

export interface OrderItem {
  amount: number;
  tag: string;
}

export interface Order {
  cateringStatus: CateringStatus;
  date: string;
  id: string;
  items: OrderItem[];
  qrCode: string;
}

export enum CateringStatus {
  OPEN = 'OPEN',
  IN_PROCESS = 'IN_PROCESS',
  READY = 'READY',
  DELIVERED = 'DELIVERED',
}

export interface OnlineOrderPreviewDto {
  discount: number;
  finalPrice: number;
  originalPrice: number;
}

export interface OrderModel {
  id: string;
  queueNumber: number;
  createdDate: ISODateTimeString;
  deliveryDate: ISODateString;
  items: OrderItemModel[];
  cateringStatus: CateringStatus;
  deliveryTimeSlot?: DeliveryTimeSlot;
  qrCode: string;
  deliveryLocation: { id?: string; title?: string };
  deliveryInfo?: DeliveryInfo;
  finalPrice: number;
  onFeedback: boolean;
  nickname?: string;
  orderProcessingFinished: boolean;
  recentOrder?: boolean;
  consumptionCondition?: ConsumptionCondition | null;
}

interface DeliveryInfo {
  email?: string;
  phone?: string;
  comment?: string;
  pointOfDelivery?: string;
  tableName?: string;
}

export interface OrderItemModel extends OrderItem {
  title: string;
}

export interface OnlineOrderPreviewRequest {
  paymentMethod: EmployeePaymentMethod;
  items: OrderItem[];
  deliveryDate: string;
  consumptionCondition?: string | null;
}

export interface OnlineOrderRequest extends OrderDeliveryDTO {
  paymentMethod: EmployeePaymentMethod;
  deliveryDate: string;
  deliveryTimeSlotId?: string;
  items: OrderItem[];
  pointOfDelivery?: string;
  deliveryLocationId?: string;
  consumptionCondition?: string | null;
}

export interface MenuLineDTO {
  days: AvailableDateMenuLine[];
  line: string;
  lineTitle: string;
}

export interface AvailableDateMenuLine {
  date: string;
  items: MenuItem[];
}

export interface ProfileMenu {
  id?: string;
  title?: string;
  prioritized?: boolean;
  days: MenuManagementDate[];
  line?: string;
}
interface DeliveryField {
  enabled: boolean;
  required: boolean;
}

export interface DeliveryFormFields {
  pointOfDelivery: DeliveryField;
  email: DeliveryField;
  phone: DeliveryField;
  town: DeliveryField;
  street: DeliveryField;
  homeNumber: DeliveryField;
  comment: DeliveryField;
}

export interface DeliveryOptions {
  text: string;
  details: string;
  placeholder: string;
  fields: DeliveryFormFields;
}

export enum BasketMode {
  orderList = 'orderList',
  timeSlots = 'timeSlots',
  delivery = 'delivery',
  savedOrder = 'savedOrder',
}

export interface EmployeePaymentMethod {
  type: string;
  employeeId: string;
}

export enum ConsumptionCondition {
  TAKE_AWAY = 'TAKE_AWAY',
  IN_PLACE = 'IN_PLACE',
}
