import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { TaskAgentReportDto, InferenceAgentReportDto } from './types';

import Log from 'helpers/log';

class ClusterAgentsStore {
  @observable loading = false;

  @observable taskAgents: TaskAgentReportDto[] = [];
  @observable inferenceAgents: InferenceAgentReportDto[] = [];

  @action.bound
  async fetchClusterAgents() {
    try {
      this.loading = true;

      [
        { data: this.taskAgents },
        { data: this.inferenceAgents },
      ] = await Promise.all([
        httpFacade.recognition.fetchClusterTaskAgents(),
        httpFacade.recognition.fetchClusterInferenceAgents(),
      ]);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default ClusterAgentsStore;
