import {
  BaseFormModel,
  displayName,
  isRequired,
  hasMaxLength,
  isNumberMoreOrEqual,
} from '../BaseForm';
import { action, computed, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';

import { Campaign, CampaignDTO, UserGroup } from '../Staff/types';

import httpFacade from 'http/httpFacade';

import noop from 'helpers/noop';
import { getDateTimeFromISO, getLocalDateTime } from 'helpers/datetime';

export const CAMPAIGN_LIMITS = {
  maxTitleLength: 100,
  maxParticipants: 99999,
};

class CampaignForm extends BaseFormModel {
  @observable
  @displayName('modal.campaign.name')
  @isRequired()
  @hasMaxLength(CAMPAIGN_LIMITS.maxTitleLength)
  title = '';

  @observable
  @isRequired()
  userGroup: UserGroup;

  @observable
  @displayName('modal.campaign.participants')
  @isRequired()
  @isNumberMoreOrEqual(1)
  limit = 1;

  @observable
  period: [DateTime, DateTime | undefined] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];

  startDate = getLocalDateTime();
  endDate: DateTime | undefined = getLocalDateTime();

  @observable unlimited = false;
  @observable loading = false;

  @observable userGroups: UserGroup[] = [];

  @observable readonly id: string;

  @computed
  get dto(): CampaignDTO {
    return {
      from: this.startDate.toISODate(),
      to: this.unlimited ? undefined : this.endDate?.toISODate(),
      id: this.id,
      limit: this.limit,
      title: this.title,
      userGroup: {
        id: this.userGroup.id!,
      },
    };
  }

  constructor(campaign?: Campaign) {
    super();

    if (campaign) {
      this.title = campaign.title;
      this.userGroup = campaign.userGroup;
      this.limit = campaign.limit;
      this.unlimited = !Boolean(campaign.to);
      this.id = campaign.id;
      this.startDate = getDateTimeFromISO(campaign.from);
      this.endDate = campaign.to ? getDateTimeFromISO(campaign.to) : undefined;
    }

    reaction(
      () => this.unlimited,
      unlimited => {
        this.endDate = unlimited ? undefined : this.startDate;
      },
    );

    reaction(
      () => this.period,
      period => {
        const [startDate, endDate] = period;
        this.startDate = startDate;
        this.endDate = endDate;
      },
    );
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.fetchUserGroups();

      this.userGroups = data.content;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateCampaign(onFulfilled = noop) {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.updateCampaign(this.dto);

      onFulfilled(data);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async createCampaign(onFulfilled = noop) {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.createCampaign(this.dto);

      onFulfilled(data);
    } finally {
      this.loading = false;
    }
  }
}

export default CampaignForm;
