import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from './Categories.module.scss';

import DescriptionModal from 'components/Modals/Description/DescriptionModal';
import Button from 'components/Button/Button';
import SearchField from 'components/Form/Fields/Search/SearchField';
import CategoryPanel from './CategoryPanel/CategoryPanel';
import CategoryModal from 'components/Modals/Category/CategoryModal';
import Table from 'components/Table/Table';

import CategoriesStore from 'stores/Menu/CategoriesStore';
import ModalStore from 'stores/ModalStore';
import AppRouter from 'stores/AppRouter';
import { MenuItem } from 'stores/Menu/types';

import { config } from './columnsConfig';

import { ROUTES } from 'routes/routes';
import Pagination from 'components/Pagination/Pagination';
import WarningModal from 'components/Modals/Warning/WarningModal';

@observer
class CategoriesPage extends Component {
  store = new CategoriesStore();

  async componentDidMount() {
    await this.store.init();
  }

  onCategoryCreate = async () => {
    const { payload } = await ModalStore.showModal(CategoryModal);

    this.store.updateCategories(payload);
  };

  onMenuItemEdit = menuItem => async () => {
    AppRouter.push(ROUTES.admin.menuItem, {
      id: menuItem.id,
    });
  };

  onDescriptionUpdate = (menuItem: MenuItem) => async () => {
    const { payload } = await ModalStore.showModal(DescriptionModal, {
      title: (
        <FormattedMessage
          id="modal.description.title"
          values={{
            name: menuItem.title,
          }}
        />
      ),
      label: 'title.description',
      initialDescription: menuItem.description || '',
    });

    await this.store.updateMenuItemDescription(menuItem.id, payload);
  };

  onSearch = value => {
    this.store.searchMenuItems(value);
  };

  onMenuItemDelete = id => async () => {
    await this.store.deleteMenuItem(id);
  };

  onMenuItemCreate = async () => {
    AppRouter.push(ROUTES.admin.menuItem);
  };

  onChangePage = (page: number) => this.store.changePage(page);

  onMenuItemSubsidisedChange = async (
    menuItem: MenuItem,
    isChecked: boolean,
  ) => {
    await this.store.changeSubsidisedMenuItem(menuItem.id, isChecked);
  };
  onCategorySubsidisedChange = async isChecked => {
    await this.store.changeSubsidisedCategory(isChecked);
  };

  onMenuItemKioskSelfCheckoutVisibilityChange = menuItem => async () => {
    await ModalStore.showModal(WarningModal, {
      title: menuItem.notVisibleKioskSelfCheckout
        ? 'modal.warning.title.confirm.display'
        : 'modal.warning.title.confirm.hide',
      description: menuItem.notVisibleKioskSelfCheckout
        ? 'modal.warning.confirm.display'
        : 'modal.warning.confirm.hide',
      type: 'change',
    });
    await this.store.changeMenuItemKioskSelfCheckoutVisibility(menuItem);
  };

  render() {
    const empty = !Boolean(this.store.categories.length);
    const { page, pageCount } = this.store;

    return (
      <main className={style.main}>
        <div className={style.controls}>
          <Button className={style.btn} onClick={this.onCategoryCreate}>
            <FormattedMessage id="button.newCategory" />
          </Button>

          <Button
            className={style.btn}
            disabled={empty}
            onClick={this.onMenuItemCreate}
          >
            <FormattedMessage id="button.newDish" />
          </Button>
        </div>

        <SearchField
          className={style.search}
          onChange={this.onSearch}
          value={this.store.searchValue}
        />

        <CategoryPanel store={this.store} />

        <Table
          className={style.products}
          data={this.store.filteredItems}
          columns={config({
            activeCategory: this.store.activeCategory,
            items: this.store.items,
            onEdit: this.onMenuItemEdit,
            onDelete: this.onMenuItemDelete,
            onDescriptionUpdate: this.onDescriptionUpdate,
            onSwitchMenuItem: this.onMenuItemSubsidisedChange,
            onSwitchCategory: this.onCategorySubsidisedChange,
            onKioskSelfCheckoutVisibilityChange: this
              .onMenuItemKioskSelfCheckoutVisibilityChange,
          })}
          loading={this.store.loading}
        />

        {this.store.filteredItems?.length > 0 && (
          <Pagination
            className={style.pagination}
            pageCount={pageCount}
            page={page}
            onPageClick={data => this.onChangePage(data)}
          />
        )}
      </main>
    );
  }
}

export default CategoriesPage;
