import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { TaskType } from 'stores/Recognition/types';
import TaskForm from 'stores/Forms/TasksForms/TaskCreateDetectorForm';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Spinner from 'components/Spinner/Spinner';
import SelectField from 'components/Form/Fields/Select/SelectField';
import TextField from 'components/Form/Fields/Text/TextField';
import Controls from './Controls';
import TaskInfo from './TaskInfo';

import style from './TaskForm.module.scss';

@observer
class TaskCreateDetectorForm extends Component<{
  type: TaskType.CREATE_MODEL_DETECTOR_DVC;
}> {
  form: TaskForm = new TaskForm(this.props.type);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onCreate();
  };

  async componentDidMount() {
    await this.form.init();
  }

  render() {
    const FormControl = this.formControl;

    return (
      <form className={style.task__form} onSubmit={this.onSubmit}>
        {this.form.loading && <Spinner contain />}

        <TaskInfo info={this.props.type} />

        <div className={style.form__body}>
          <div className={style.form__options}>
            <FormControl
              name="detectorId"
              className={classNames(style.control, style.control__option)}
              render={props => (
                <SelectField
                  {...props}
                  options={this.form.modelsDetectionOptions}
                />
              )}
            />

            <FormControl
              name="classifierId"
              className={classNames(style.control, style.control__option)}
              render={props => (
                <SelectField
                  {...props}
                  options={this.form.modelsClassificationOptions}
                />
              )}
            />
          </div>

          <FormControl
            name="description"
            className={style.control}
            render={props => <TextField {...props} />}
          />
        </div>

        <Controls disabled={!this.form.isFormValid} />
      </form>
    );
  }
}

export default TaskCreateDetectorForm;
