import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

export interface ILodData {
  errorMessage: string;
  errorMessageId?: string;
  statusCode?: number;
  url?: string;
}

export class LogService {
  static error(data: ILodData) {
    try {
      const message = JSON.stringify(data);

      httpFacade.logger.sendError({ message });
    } catch (error) {
      Log.warn(error);
    }
  }
}
