import React from 'react';
import { Column } from 'react-table';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './MenuLines.module.scss';

import { withArrow } from 'components/Table/helpers';
import Controls from 'components/Table/Controls';
import { getFormattedTime, SHORT_TIME_FORMAT } from 'helpers/datetime';

const config = ({ onEdit, onDelete }): Column[] => [
  {
    Header: withArrow('title.groupName'),
    accessor: 'title',
    resizable: true,
  },
  {
    Header: withArrow('title.order.time'),
    resizable: false,
    maxWidth: 170,
    Cell: row => {
      const timeFrom =
        row.original.orderTimeFrom &&
        getFormattedTime(row.original.orderTimeFrom, SHORT_TIME_FORMAT);
      const timeTo =
        row.original.orderTimeTo &&
        getFormattedTime(row.original.orderTimeTo, SHORT_TIME_FORMAT);
      return <span>{getTimeRange(timeFrom, timeTo)}</span>;
    },
  },
  {
    Header: withArrow('title.prioritized'),
    accessor: 'prioritized',
    resizable: false,
    maxWidth: 100,
    className: style.cell__center,
    Cell: row => (
      <span
        className={classNames(style.point, {
          [style.point__active]: row.value,
        })}
      />
    ),
  },
  {
    Header: withArrow('title.online.ordering'),
    accessor: 'onlineOrder',
    resizable: false,
    maxWidth: 150,
    className: style.cell__center,
    Cell: row => (
      <span
        className={classNames(style.point, {
          [style.point__active]: row.value,
        })}
      />
    ),
  },
  {
    id: 'operations',
    resizable: false,
    width: 84,
    sortable: false,
    className: 'operations',
    Cell: row => (
      <Controls
        classNames={style.controls}
        onEdit={onEdit(row.original.id)}
        onDelete={onDelete(row.original.id)}
      />
    ),
  },
];

function getTimeRange(from?: string, to?: string) {
  if (from && to) {
    return <FormattedMessage id="time.range" values={{ from, to }} />;
  } else if (from) {
    return <FormattedMessage id="time.range.from" values={{ from }} />;
  } else if (to) {
    return <FormattedMessage id="time.range.to" values={{ to }} />;
  } else {
    return '';
  }
}

export default config;
