import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './StaffGroups.module.scss';
import 'react-table/react-table.css';

import Button from 'components/Button/Button';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Icon from 'components/Icon/Icon';
import Table from 'components/Table/Table';
import UserGroupModal from 'components/Modals/Groups/UserGroup/UserGroupModal';

import StaffGroupsStore from 'stores/Staff/StaffGroup';
import ModalStore from 'stores/ModalStore';
import { UserGroup } from 'stores/Staff/types';

import { config } from './columnsConfig';

@observer
class StaffGroups extends Component {
  store = new StaffGroupsStore();

  async componentDidMount() {
    await this.store.fetchStaffGroups();
  }

  openStaffGroupModal = (group?: UserGroup) => async () => {
    const { payload } = await ModalStore.showModal(UserGroupModal, { group });

    this.store.updateStaffGroup(payload);
  };

  deleteGroup = id => async () => {
    await this.store.deleteStaffGroup(id);
  };

  onSearch = value => {
    this.store.searchValue = value;
  };

  render() {
    const { searchValue, filteredData, loading } = this.store;

    return (
      <div className={style.content}>
        <div className={style.row}>
          <Button className={style.btn} onClick={this.openStaffGroupModal()}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.newGroup" />
          </Button>

          <SearchField
            className={style.model__search}
            onChange={this.onSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={filteredData}
          columns={config({
            onEdit: this.openStaffGroupModal,
            onDelete: this.deleteGroup,
          })}
          loading={loading}
        />
      </div>
    );
  }
}

export default StaffGroups;
