import { action, observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { TransactionItem } from 'http/Api/profile';

import { TransactionData, SocketDataType } from 'stores/Socket/types';

class UserReport {
  id: string;

  @observable balance = 0;
  @observable transactions: TransactionItem[] = [];

  @observable loading = false;

  constructor(id: string) {
    this.id = id;
  }

  @action.bound
  async fetchReport() {
    try {
      this.loading = true;

      const response = await httpFacade.profile.fetchReport(this.id);
      const { balance, transactions } = response.data;

      this.balance = balance;
      this.transactions = transactions;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateBalanceAsync(event) {
    const data: TransactionData = JSON.parse(event.data);

    if (data?.type === SocketDataType.NEW_TRANSACTION) {
      await this.fetchReport();
    }
  }
}

export { UserReport };
