import { observable, action } from 'mobx';

import httpFacade from 'http/httpFacade';

import Log from 'helpers/log';
import { downloadCsvBlob } from 'helpers/csv';
import { showDate } from 'helpers/datetime';
import { downloadPdfBlob } from 'helpers/pdf';
import RootStore from '../RootStore';

import { SubsidyReport, SubsidyReportReqParams } from './types';

class SubsidyReportStore {
  @observable report: SubsidyReport;
  @observable loading = false;

  @action.bound
  async getReport(params: SubsidyReportReqParams) {
    try {
      this.loading = true;

      const { data } = await httpFacade.order.fetchSubsidyReport(params);

      this.report = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getPdfReport(params: SubsidyReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.subsidy.pdf',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchSubsidyReportPdf(params);
      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], { type: 'application/pdf' });

      downloadPdfBlob({ fileName, pdfBlob, pdfBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async getCsvReport(params: SubsidyReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.subsidy.csv',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchSubsidyReportCsv(params);
      const csvBlobURL = URL.createObjectURL(data);
      const csvBlob = new Blob([data], { type: 'text/csv' });

      downloadCsvBlob({ fileName, csvBlob, csvBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }
}

export default SubsidyReportStore;
