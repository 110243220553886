import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './ArchiveImagesViewModal.module.scss';

import { getFormattedDate } from 'helpers/datetime';
import ModalStore, { ModalProps } from 'stores/ModalStore';
import { IArchive, IArchiveImage } from 'stores/Forms/Archive/types';

import SplitModalLayout from 'components/Layout/SplitModalLayout';
import ImagesCarousel from './ImagesCarousel/ImagesCarousel';

interface Props extends ModalProps {
  archive?: IArchive;
  images: IArchiveImage[];
  id: string;
  loading: boolean;
}

@observer
class ArchiveImagesViewModal extends Component<Props> {
  render() {
    const { loading, archive, images, ...rest } = this.props;

    return (
      <SplitModalLayout
        {...rest}
        loading={loading}
        className={style.modal}
        onClose={() => ModalStore.closeModal()}
      >
        <div className={style.column__first}>
          <header className={style.panel__header}>
            <h3 className={style.panel__title}>
              <FormattedMessage id="modal.archive.title" />
            </h3>
          </header>

          <ImagesCarousel images={images} />
        </div>

        <div className={style.column__second}>
          <div className={style.info}>
            <div className={style.field__wrap}>
              <span className={style.label}>
                <FormattedMessage id={'ORDER_DATE'} />
              </span>
              {archive?.createdDate && (
                <span className={style.text}>
                  {getFormattedDate(archive.createdDate)}
                </span>
              )}
            </div>

            <div className={style.field__wrap}>
              <span className={style.label}>
                <FormattedMessage id={'MEAL_TYPE'} />
              </span>
              <span className={style.text}>
                <FormattedMessage
                  id={`archive.meal.${archive?.type.toString().toLowerCase()}`}
                />
              </span>
            </div>

            <div className={style.field__wrap}>
              <span className={style.label}>
                <FormattedMessage id={'STATION'} />
              </span>
              <span className={style.text}>{archive?.station}</span>
            </div>

            <div className={style.field__wrap}>
              <span className={style.label}>
                <FormattedMessage id={'ROOM'} />
              </span>
              <span className={style.text}>{archive?.room}</span>
            </div>

            <div className={style.field__wrap}>
              <span className={style.label}>
                <FormattedMessage id={'PATIENT_ID'} />
              </span>
              <span className={style.text}>{archive?.patientId}</span>
            </div>
          </div>
        </div>
      </SplitModalLayout>
    );
  }
}

export default ArchiveImagesViewModal;
