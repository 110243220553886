import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';

import style from './StaffModal.module.scss';

import Spinner from 'components/Spinner/Spinner';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import Button from 'components/Button/Button';
import SelectField from 'components/Form/Fields/Select/SelectField';

import StaffForm from 'stores/Forms/StaffForm';
import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  id?: string;
}

@observer
class StaffModal extends Component<Props> {
  form = new StaffForm(this.props.id);

  async componentDidMount() {
    await this.form.init();
  }

  onSubmit = async event => {
    event.preventDefault();

    await this.form.onSubmit(payload => this.props.onSubmit!(payload));
  };

  render() {
    const FormControl = bindFormControl(this.form);
    const { id, ...rest } = this.props;
    const roles = this.form.roles.map(role => ({
      label: role.name,
      value: role.id,
    }));
    const editMode = Boolean(this.props.id);

    return (
      <Modal
        {...rest}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        className={style.modal}
        visible
      >
        {this.form.loading && <Spinner contain />}

        <div className={style.title}>
          <FormattedMessage
            id={this.form.id ? 'title.editUser' : 'title.newUser'}
          />
        </div>

        <form className={style.form} name="staff" onSubmit={this.onSubmit}>
          <FormControl
            name="firstName"
            render={props => <TextField {...props} className={style.model} />}
          />

          <FormControl
            name="lastName"
            render={props => <TextField {...props} className={style.model} />}
          />

          <FormControl
            name="email"
            className={style.form__row}
            render={props => (
              <TextField
                {...props}
                className={style.model}
                disabled={editMode}
              />
            )}
          />

          <FormControl
            name="role"
            render={props => (
              <SelectField
                {...props}
                options={roles}
                value={this.form.role}
                disabled={editMode}
                className={style.roles}
              />
            )}
          />

          <div className={style.form__row}>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this.props.onClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={style.btn}
              type="submit"
              disabled={!this.form.isFormValid}
            >
              <FormattedMessage
                id={this.props.id ? 'button.save' : 'button.create'}
              />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default StaffModal;
