import { action, observable } from 'mobx';

import { BaseFormModel, displayName, isRequired } from '../BaseForm';

import Log from 'helpers/log';
import noop from 'helpers/noop';

import httpFacade from 'http/httpFacade';

export const refillLimits = {
  maxAmountLength: 3,
};

interface User {
  id: string;
  name: string;
}

class BalanceMigrationForm extends BaseFormModel {
  @observable
  @displayName('modal.balance.amount')
  @isRequired(undefined, 'price')
  amount: number;

  @observable loading = false;

  readonly user: User;

  constructor(user: User) {
    super();

    this.user = user;
  }

  @action.bound
  async submit(onFulfilled = noop) {
    try {
      this.loading = true;

      await httpFacade.finance.refillBalance(this.user.id, {
        amount: this.amount,
      });

      onFulfilled();
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default BalanceMigrationForm;
