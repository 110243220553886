import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router';

import style from './Recognition.module.scss';

import { ROUTES } from 'routes/routes';

import NavigationTabs from 'components/NavigationTabs/NavigationTabs';
import DatasetsPage from './Datasets/DatasetsPage';
import ModelsPage from './Models/ModelsPage';
import ClusterAgentsPage from './ClusterAgents/ClusterAgentsPage';
import TasksPage from './Tasks/TasksPage';
import DeploymentsPage from './Deployments/DeploymentsPage';

@observer
class RecognitionPage extends Component {
  render() {
    return (
      <div className={style.content__tabs}>
        <NavigationTabs
          className={style.tabs}
          tabs={[
            { to: ROUTES.admin.datasets, title: 'title.datasets' },
            { to: ROUTES.admin.tasks, title: 'title.tasks' },
            { to: ROUTES.admin.models, title: 'title.models' },
            { to: ROUTES.admin.deployments, title: 'title.deployments' },
            { to: ROUTES.admin.agents, title: 'title.agents' },
          ]}
        />

        <Switch>
          <Route path={ROUTES.admin.datasets} component={DatasetsPage} exact />
          <Route path={ROUTES.admin.tasks} component={TasksPage} exact />
          <Route path={ROUTES.admin.models} component={ModelsPage} exact />
          <Route
            path={ROUTES.admin.deployments}
            component={DeploymentsPage}
            exact
          />
          <Route
            path={ROUTES.admin.agents}
            component={ClusterAgentsPage}
            exact
          />

          <Redirect to={ROUTES.admin.datasets} />
        </Switch>
      </div>
    );
  }
}

export default RecognitionPage;
