import React from 'react';
import classNames from 'classnames';

import style from './Icon.module.scss';

import { ReactComponent as check } from './icons/check.svg';
import { ReactComponent as close } from './icons/close.svg';
import { ReactComponent as info } from './icons/info.svg';
import { ReactComponent as arrow } from './icons/arrow.svg';
import { ReactComponent as form } from './icons/form.svg';
import { ReactComponent as edit } from './icons/edit.svg';
import { ReactComponent as loading } from './icons/loading.svg';
import { ReactComponent as assignTags } from './icons/assign-tags.svg';
import { ReactComponent as rfid } from './icons/icon-rfid.svg';
import { ReactComponent as personIcon } from './icons/icon-employee.svg';
import { ReactComponent as search } from './icons/icon-search.svg';
import { ReactComponent as assign } from './icons/icon-assign.svg';
import { ReactComponent as logout } from './icons/icon-logout.svg';
import { ReactComponent as nfc } from './icons/icon-nfc.svg';
import { ReactComponent as scanningTag } from './icons/icon-scanning-tag.svg';
import { ReactComponent as scanningQR } from './icons/icon-scanning-qr.svg';
import { ReactComponent as logo } from './icons/logo.svg';
import { ReactComponent as receipt } from './icons/receipt.svg';
import { ReactComponent as trash } from './icons/trash.svg';
import { ReactComponent as popup } from './icons/popup.svg';
import { ReactComponent as service } from './icons/500.svg';
import { ReactComponent as folder } from './icons/icon-folder.svg';
import { ReactComponent as users } from './icons/icon-users.svg';
import { ReactComponent as user } from './icons/user.svg';
import { ReactComponent as menu } from './icons/icon-menu.svg';
import { ReactComponent as discount } from './icons/icon-discount.svg';
import { ReactComponent as warning } from './icons/warning.svg';
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as arrowNext } from './icons/arrow-next.svg';
import { ReactComponent as schedule } from './icons/schedule.svg';
import { ReactComponent as plate } from './icons/plate.svg';
import { ReactComponent as plus } from './icons/plus.svg';
import { ReactComponent as important } from './icons/important.svg';
import { ReactComponent as recognition } from './icons/icon-recognition.svg';
import { ReactComponent as tag } from './icons/tag.svg';
import { ReactComponent as brokenImage } from './icons/broken-image.svg';
import { ReactComponent as zoomIn } from './icons/zoomIn.svg';
import { ReactComponent as zoomOut } from './icons/zoomOut.svg';
import { ReactComponent as dots } from './icons/dots.svg';
import { ReactComponent as report } from './icons/report.svg';
import { ReactComponent as help } from './icons/help.svg';
import { ReactComponent as chef } from './icons/chef.svg';
import { ReactComponent as qr } from './icons/qr.svg';
import { ReactComponent as statistic } from './icons/statistic.svg';
import { ReactComponent as circlePlus } from './icons/circle-plus.svg';
import { ReactComponent as circleMinus } from './icons/circle-minus.svg';
import { ReactComponent as smallTrash } from './icons/small-trash.svg';
import { ReactComponent as basket } from './icons/basket.svg';
import { ReactComponent as profile } from './icons/profile.svg';
import { ReactComponent as plateDeep } from './icons/plate-deep.svg';
import { ReactComponent as smallQR } from './icons/icon-qr.svg';
import { ReactComponent as print } from './icons/print.svg';
import { ReactComponent as iconInfo } from './icons/icon-info.svg';
import { ReactComponent as deploy } from './icons/deploy.svg';
import { ReactComponent as cancel } from './icons/cancel.svg';
import { ReactComponent as config } from './icons/icon-config.svg';
import { ReactComponent as hamburger } from './icons/hamburger.svg';
import { ReactComponent as more } from './icons/more.svg';
import { ReactComponent as iconAlipay } from './icons/icon-Alipay.svg';
import { ReactComponent as iconBankCard } from './icons/icon-BankCard.svg';
import { ReactComponent as iconCreditCard } from './icons/icon-CreditCard.svg';
import { ReactComponent as iconMaestro } from './icons/icon-Maestro.svg';
import { ReactComponent as iconGiroPay } from './icons/icon-GiroPay.svg';
import { ReactComponent as iconKlarna } from './icons/icon-Klarna.svg';
import { ReactComponent as iconPayPal } from './icons/icon-PayPal.svg';
import { ReactComponent as iconPostPay } from './icons/icon-PostPay.svg';
import { ReactComponent as iconSofort } from './icons/icon-Sofort.svg';
import { ReactComponent as reset } from './icons/reset.svg';
import { ReactComponent as refund } from './icons/icon-refund.svg';
import { ReactComponent as success } from './icons/icon-success.svg';
import { ReactComponent as menuEmpty } from './icons/icon-menu-empty.svg';
import { ReactComponent as iconOk } from './icons/icon-ok.svg';
import { ReactComponent as iconNotOk } from './icons/icon-not-ok.svg';
import { ReactComponent as feedback } from './icons/icon-feedback.svg';
import { ReactComponent as feedbackDone } from './icons/icon-feedback-done.svg';
import { ReactComponent as archive } from './icons/icon-archive.svg';
import { ReactComponent as calendar } from './icons/calendar.svg';
import { ReactComponent as noMenu } from './icons/icon-noMenu.svg';
import { ReactComponent as imageEmpty } from './icons/icon-image-empty.svg';
import { ReactComponent as star } from './icons/star.svg';
import { ReactComponent as chrome } from './icons/chrome.svg';
import { ReactComponent as safari } from './icons/safari.svg';
import { ReactComponent as helpCircle } from './icons/help-circle.svg';
import { ReactComponent as iconHide } from './icons/icon-hide.svg';
import { ReactComponent as iconShow } from './icons/icon-show.svg';
import { ReactComponent as shareIOS } from './icons/share-ios-line.svg';
import { ReactComponent as plusIOS } from './icons/plus-ios.svg';
import { ReactComponent as chromeMenu } from './icons/chrom-menu.svg';
import { ReactComponent as installMobile } from './icons/install_mobile.svg';

export const icons = {
  chrome,
  safari,
  star,
  cancel,
  deploy,
  iconInfo,
  print,
  user,
  qr,
  chef,
  help,
  report,
  dots,
  zoomIn,
  zoomOut,
  brokenImage,
  tag,
  recognition,
  important,
  plus,
  plate,
  config,
  schedule,
  arrowNext,
  cross,
  warning,
  discount,
  menu,
  users,
  folder,
  check,
  close,
  info,
  arrow,
  arrowBottom: arrow,
  arrowLeft: arrow,
  arrowRight: arrow,
  arrowTop: arrow,
  form,
  edit,
  loading,
  personIcon,
  rfid,
  nfc,
  search,
  assign,
  assignTags,
  logo,
  logout,
  scanningTag,
  scanningQR,
  receipt,
  trash,
  popup,
  service,
  statistic,
  circleMinus,
  circlePlus,
  smallTrash,
  basket,
  profile,
  plateDeep,
  smallQR,
  hamburger,
  more,
  iconAlipay,
  iconBankCard,
  iconCreditCard,
  iconMaestro,
  iconGiroPay,
  iconKlarna,
  iconPayPal,
  iconPostPay,
  iconSofort,
  reset,
  refund,
  success,
  menuEmpty,
  iconOk,
  iconNotOk,
  feedback,
  feedbackDone,
  archive,
  calendar,
  noMenu,
  imageEmpty,
  helpCircle,
  iconHide,
  iconShow,
  shareIOS,
  plusIOS,
  chromeMenu,
  installMobile,
};

const iconsClassName = {
  arrowRight: style.arrowRight,
  arrowTop: style.arrowTop,
  arrowLeft: style.arrowLeft,
};

interface Props {
  type: keyof typeof icons;
  className?: string;
  onClick?: () => void;
}

const Icon = ({ type, className, ...props }: Props) => {
  const SVGIcon = icons[type] || null;
  const iconClassName = iconsClassName[type] || null;

  return (
    SVGIcon && (
      <i className={classNames(style.icon, className)} {...props}>
        <SVGIcon className={classNames(iconClassName, style.svg)} />
      </i>
    )
  );
};

export default Icon;
