import React, { Component } from 'react';
import { Switch } from 'react-router';
import { observer } from 'mobx-react';

import style from './Layout.module.scss';

import AsideMenu from 'components/AsideMenu/AsideMenu';

import { Roles } from 'helpers/roles';

import PrivateRouter from 'routes/PrivateRouter';
import { ROUTES } from 'routes/routes';

import OnlineOrdersPage from 'pages/OnlineOrders/OnlineOrdersPage';
import { Desktop, Mobile } from 'types/layout';
import MenuMobile from 'components/MenuMobile/MenuMobile';
import classNames from 'classnames';

@observer
class AdaptiveBasicLayout extends Component {
  render() {
    return (
      <div className={classNames(style.layout, style.layout__adaptive)}>
        <Desktop>
          <AsideMenu />
        </Desktop>

        <Mobile>
          <MenuMobile />
        </Mobile>

        <Switch>
          <PrivateRouter
            path={ROUTES.service}
            roles={[Roles.service]}
            component={OnlineOrdersPage}
            exact
          />
        </Switch>
      </div>
    );
  }
}

export default AdaptiveBasicLayout;
