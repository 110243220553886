import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { TaskType } from 'stores/Recognition/types';

import TaskForm from 'stores/Forms/TasksForms/TaskDatasetGeneratorForm';
import { bindFormControl } from 'components/Form/FormControl/FormControl';

import style from './TaskForm.module.scss';
import Spinner from 'components/Spinner/Spinner';
import TaskInfo from './TaskInfo';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import classNames from 'classnames';
import TextField from 'components/Form/Fields/Text/TextField';
import { FormattedMessage } from 'react-intl';
import Controls from './Controls';

@observer
class TaskDatasetGeneratorForm extends Component<{
  type: TaskType.GENERATE_DATASET;
}> {
  form: TaskForm = new TaskForm(this.props.type);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onCreate();
  };

  async componentDidMount() {
    await this.form.init();
  }

  render() {
    const FormControl = this.formControl;

    return (
      <form className={style.task__form} onSubmit={this.onSubmit}>
        {this.form.loading && <Spinner contain />}

        <TaskInfo info={this.props.type} />

        <div
          className={classNames(
            style.form__body,
            style.form__options__top__aligned,
          )}
        >
          <div className={style.form__options}>
            <TagsSelect
              title="title.dataset.required"
              tags={this.form.datasetTags}
              selectedTags={this.form.selectedDatasets}
              onSubmit={this.form.setDatasets}
              onRemoveTag={this.form.removeDataset}
            />

            <TagsSelect
              title="title.bg.dataset.required"
              tags={this.form.datasetTags}
              selectedTags={this.form.selectedBgDatasets}
              onSubmit={this.form.setBgDatasets}
              onRemoveTag={this.form.removeBgDataset}
            />
          </div>

          <FormControl
            name="description"
            className={classNames(style.control, style.control__description)}
            render={props => <TextField {...props} />}
          />

          <p className={style.title}>
            <FormattedMessage id="title.options" />
          </p>

          <div className={style.form__options}>
            <FormControl
              name="amount"
              className={classNames(style.contol, style.control__option)}
              render={props => <TextField {...props} />}
            />

            <FormControl
              name="minObjectsPerImage"
              className={classNames(style.contol, style.control__option)}
              render={props => <TextField {...props} />}
            />

            <FormControl
              name="maxObjectsPerImage"
              className={classNames(style.contol, style.control__option)}
              render={props => <TextField {...props} />}
            />
          </div>
        </div>

        <Controls
          disabled={
            !this.form.isFormValid ||
            !this.form.selectedDatasets.length ||
            !this.form.selectedBgDatasets.length
          }
        />
      </form>
    );
  }
}

export default TaskDatasetGeneratorForm;
