import React, { Component, Suspense } from 'react';
import { RawIntlProvider } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';

import Spinner from 'components/Spinner/Spinner';
import Modals from 'components/Modals/Modals';
import BasicLayout from 'components/Layout/BasicLayout';

import { getDefaultRouteByRole, Roles } from 'helpers/roles';
import UserProfileLayout from 'pages/UserProfile/UserProfileLayout';

import PrivateRouter from 'routes/PrivateRouter';

import { ROUTES } from 'routes/routes';

import Log from './helpers/log';

import RootStore from 'stores/RootStore';

import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';

import Login from './pages/Login/Login';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import AdaptiveBasicLayout from './components/Layout/AdaptiveBasicLayout';

const Registration = React.lazy(() =>
  import('./pages/Registration/RegistrationPage'),
);

@observer
class App extends Component {
  async componentDidMount() {
    await RootStore.init();
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Log.warn(errorInfo);
  }

  render() {
    const { localization, hasErrors, loading } = RootStore;

    return loading ? (
      <Spinner fullscreen />
    ) : (
      <RawIntlProvider value={localization.intl}>
        {hasErrors ? (
          <ErrorPage />
        ) : (
          <Suspense fallback={<Spinner fullscreen />}>
            <Switch>
              <Route
                path={[
                  ROUTES.employees,
                  ROUTES.employeeProfile,
                  ROUTES.archiveAdmin.archive,
                  ...Object.values(ROUTES.admin),
                ]}
                component={BasicLayout}
              />

              <Route path={[ROUTES.service]} component={AdaptiveBasicLayout} />

              <PrivateRouter
                path={[
                  ROUTES.user.profile,
                  ROUTES.user.menu,
                  ...(RootStore.config.feature.sionumSpeedy.enabled
                    ? [ROUTES.user.onlineOrder]
                    : []),
                ]}
                roles={[Roles.employee]}
                component={UserProfileLayout}
                exact
              />

              <Route
                path={ROUTES.registration}
                component={Registration}
                exact
              />

              <Route
                path={[ROUTES.login, ROUTES.recover]}
                render={() =>
                  RootStore.user.roles.length ? (
                    <Redirect to={getDefaultRouteByRole()} />
                  ) : (
                    <Login />
                  )
                }
                exact
              />

              <Redirect to={ROUTES.login} />
            </Switch>

            <Modals />
            <ToastContainer />
          </Suspense>
        )}
      </RawIntlProvider>
    );
  }
}

export default App;
