import React from 'react';

import style from './AsideMenu.module.scss';

import AppLink from 'components/AppLink/AppLink';
import Icon from 'components/Icon/Icon';

interface Props {
  iconType: React.ComponentProps<typeof Icon>['type'];
  to: string;
  children: React.ReactNode;
}

const NavigationItem = ({ iconType, to, children }: Props) => (
  <li>
    <AppLink to={to} className={style.link} activeClass={style.link__active}>
      <Icon className={style.link__icon} aria-label="icon" type={iconType} />

      <span className={style.link__text}>{children}</span>
    </AppLink>
  </li>
);

export default NavigationItem;
