import { action } from 'mobx';
import * as signalR from '@aspnet/signalr';

import Log from 'helpers/log';

class QRHub {
  private _hubConnection: signalR.HubConnection;

  @action.bound
  create() {
    const url = 'http://localhost:8000/hubs/BarcodeScanners';

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    return this._hubConnection;
  }

  @action.bound
  async disconnect() {
    this._hubConnection.off('BarcodeScanned');
    await this._hubConnection.stop();

    Log.info('disconnected');
  }
}

export default QRHub;
