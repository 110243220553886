import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import style from './MenuCard.module.scss';
import { MenuItem } from 'stores/UserProfile/types';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import AmountCounter from 'components/AmountCounter/AmountCounter';

@observer
class MenuCard extends Component<{
  menuItem: MenuItem;
  editable?: boolean;
  className?: string;
}> {
  render() {
    const { menuItem, editable, className } = this.props;

    return (
      <div className={classNames(className, style.item)} key={menuItem.id}>
        <div className={style.item__title}>{menuItem.title}</div>

        <div className={style.item__data}>
          <div className={style.item__description}>{menuItem?.description}</div>

          <div className={style.item__price}>
            <FormattedCurrency value={menuItem.price} />
          </div>

          <ul className={style.item__allergens}>
            {menuItem?.allergens?.map?.(it => (
              <li key={it.id}>
                <img
                  className={style.allergen__icon}
                  src={it.icon}
                  alt="allergen"
                />
              </li>
            ))}
          </ul>

          {editable && <AmountCounter item={menuItem} />}
        </div>
      </div>
    );
  }
}

export default MenuCard;
