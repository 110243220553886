import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import style from './Category.module.scss';

import Icon from 'components/Icon/Icon';
import CategoryItem from './CategoryItem';

interface ICategory {
  id: string;
  title: string;
  items: Array<{
    title: string;
    tag: string;
    itemNumber: string;
    price: number;
  }>;
}

@observer
class Category extends Component<{
  category: ICategory;
}> {
  @observable open = false;

  toggleOpen = () => (this.open = !this.open);

  render() {
    const { category } = this.props;

    return (
      <li className={style.category}>
        <div className={style.category__row} onClick={this.toggleOpen}>
          <span className={style.category__title}>{category.title}</span>

          <Icon type={this.open ? 'arrowTop' : 'arrowBottom'} />
        </div>

        {this.open && (
          <ul>
            {category.items.map(item => (
              <li key={item.tag}>
                <CategoryItem item={item} />
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export default Category;
