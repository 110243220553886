import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/Button';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Spinner from 'components/Spinner/Spinner';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import TextField from 'components/Form/Fields/Text/TextField';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import RechargePayOneModal from 'components/Modals/Recharge/RechargePayOneModal';
import ChangePasswordModal from 'components/Modals/ChangePassword/ChangePassword';
import QRPaymentModal from 'components/Modals/QRPayment/QRPaymentModal';
import SubsectionLayout from '../SubsectionLayout/SubsectionLayout';

import { UserReport } from 'stores/UserProfile/UserReport';
import ContactForm from 'stores/Forms/ContactForm';
import { SpecificationType } from 'stores/Menu/types';
import { SpecificationItem } from 'stores/UserProfile/UserDishPreferences';
import ModalStore from 'stores/ModalStore';
import UserProfileStore from 'stores/UserProfile/UserProfileStore';

import style from './InfoSection.module.scss';
import HomeScreenOffering from '../HomeScreenOffering/HomeScreenOffering';

interface Props {
  profile?: UserProfileStore;
  report: UserReport;
}

@inject('profile')
@observer
class InfoSection extends Component<Props> {
  @observable editMode = false;
  @observable contactFrom: ContactForm;

  @computed
  get formControl() {
    return this.editMode
      ? bindFormControl(this.contactFrom)
      : bindFormControl(this.props.profile!);
  }

  @action.bound
  toggleMode() {
    this.editMode = !this.editMode;

    if (this.editMode) {
      this.contactFrom = new ContactForm(this.props.profile!);
    }
  }

  @action.bound
  async updateContactInfo() {
    const { profile } = this.props;

    if (this.contactFrom.validate()) {
      await profile!.updateContactInfo(this.contactFrom);

      this.toggleMode();
    }
  }

  @action.bound
  cancel() {
    this.contactFrom = new ContactForm(this.props.profile!);

    this.toggleMode();
  }

  onRecharge = async () => {
    await ModalStore.showModal(RechargePayOneModal, {
      profile: this.props.profile!,
    });
  };

  onQRPayment = async () => {
    await ModalStore.showModal(QRPaymentModal, {
      userId: this.props.report.id,
    });
  };

  onChangePassword = async () => {
    await ModalStore.showModal(ChangePasswordModal, {
      userId: this.props.profile!.id,
    });
  };

  updateSpecifications = (category: SpecificationType) => async (
    specs: SpecificationItem[],
  ) => {
    await this.props.profile!.dishPreferences.updateSpecifications(
      category,
      specs,
    );
  };

  render() {
    const { profile, report } = this.props;
    const { dishPreferences } = profile!;
    const FormControl = this.formControl;

    return (
      <section className={style.section__info}>
        <SubsectionLayout title="profile.balance.title">
          <div className={style.balance}>
            <div className={style.balance__value}>
              <FormattedCurrency value={report.balance} />
            </div>

            <Button
              styleType="primary"
              className={style.btn}
              onClick={this.onRecharge}
            >
              <FormattedMessage id="profile.button.recharge" />
            </Button>

            <Button
              styleType="ghost"
              className={style.btn}
              onClick={this.onQRPayment}
            >
              <FormattedMessage id="button.payment.qr" />
            </Button>
          </div>
        </SubsectionLayout>

        <SubsectionLayout
          title="profile.user.info"
          controls={
            this.editMode ? (
              <div className={style.controls}>
                <Button
                  styleType="ghost"
                  onClick={this.cancel}
                  className={style.btn}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>

                <Button
                  onClick={this.updateContactInfo}
                  disabled={!this.contactFrom?.isFormValid}
                  className={style.btn}
                >
                  <FormattedMessage id="button.save" />
                </Button>
              </div>
            ) : (
              <Button
                styleType="text"
                onClick={this.toggleMode}
                className={classNames(style.btn, style.btn__text)}
              >
                <FormattedMessage id="button.edit" />
              </Button>
            )
          }
        >
          <form className={style.form__contacts}>
            <div className={style.form__fieldLabel}>
              <FormattedMessage id="NICKNAME" />
            </div>

            <FormControl
              name="nickname"
              className={style.control}
              render={props => <TextField readOnly {...props} />}
            />

            <div className={style.form__fieldLabel}>
              <FormattedMessage id="LAST_NAME" />
            </div>

            <FormControl
              name="lastName"
              className={style.control}
              render={props => (
                <TextField readOnly={!this.editMode} {...props} />
              )}
            />

            <div className={style.form__fieldLabel}>
              <FormattedMessage id="FIRST_NAME" />
            </div>

            <FormControl
              name="firstName"
              className={style.control}
              render={props => (
                <TextField readOnly={!this.editMode} {...props} />
              )}
            />

            <div className={style.form__fieldLabel}>
              <FormattedMessage id="EMAIL" />
            </div>

            <FormControl
              name="email"
              className={style.control}
              render={props => <TextField readOnly {...props} />}
            />

            <div className={style.form__fieldLabel}>
              <FormattedMessage id="PASSWORD" />
            </div>
            <Button
              styleType="text"
              onClick={this.onChangePassword}
              className={classNames(
                style.btn,
                style.btn__text,
                style.btn__text__password,
              )}
            >
              <FormattedMessage id="PASSWORD_CHANGE" />
            </Button>
          </form>
        </SubsectionLayout>

        <SubsectionLayout>
          {dishPreferences.loading && <Spinner contain />}

          <TagsSelect
            title="title.allergens"
            tags={dishPreferences.allergens}
            selectedTags={dishPreferences.selectedAllergens}
            onSubmit={dishPreferences.saveAllergens}
            onRemoveTag={dishPreferences.removeAllergen}
            isShowSelectAll={true}
            className={{
              container: style.select,
              label: style.select__label,
              field: style.select__field,
            }}
          />

          {profile!.dishPreferences.specifications.map(group => {
            return (
              <TagsSelect
                key={group.id}
                title={group.name}
                tags={group.values}
                selectedTags={dishPreferences.groupedSpecs[group.name] || []}
                onSubmit={this.updateSpecifications(group?.name)}
                onRemoveTag={dishPreferences.removeSpecification}
                onResetAllTags={dishPreferences.resetSpecifications}
                isShowSelectAll={true}
                className={{
                  container: style.select,
                  label: style.select__label,
                  field: style.select__field,
                }}
              />
            );
          })}
        </SubsectionLayout>
        <HomeScreenOffering />
      </section>
    );
  }
}

export default InfoSection;
