import React from 'react';
import { Route, Switch } from 'react-router';

import LoginPage from './LoginPage';

import RecoverPage from './RecoverPage';
import { ROUTES } from 'routes/routes';

import './style.scss';

const Login = () => {
  return (
    <div className={'login__layout'}>
      <Switch>
        <Route path={ROUTES.login} component={LoginPage} />
        <Route path={ROUTES.recover} component={RecoverPage} />
      </Switch>
    </div>
  );
};

export default Login;
