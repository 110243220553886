import Compressor from 'compressorjs';

Compressor.setDefaults({
  quality: 0.6,
  convertSize: 150000,
});

class FileCompressor {
  compress(
    file: File | Blob,
    onSuccess: (result: Blob) => void,
    onError: (error: Error) => void,
    options?: Compressor.Options,
  ) {
    return new Compressor(file, {
      ...(options ?? {}),
      success: onSuccess,
      error: onError,
    });
  }
}

export default new FileCompressor();
