import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './RechargePayOne.module.scss';

import Icon, { icons } from 'components/Icon/Icon';
import RadioField from 'components/Form/Fields/Radio/RadioField';

import { PaymentOption } from 'stores/Recharge/types';

interface Props {
  option: PaymentOption;
  setPaymentType: (type: string) => void;
  isActive: boolean;
}

@observer
class PaymentItem extends Component<Props> {
  render() {
    const { option, setPaymentType, isActive } = this.props;

    return (
      <li className={style.methods__item}>
        <RadioField
          value={option.type}
          onChange={setPaymentType}
          checked={isActive}
          radioButtonStyleType="circle"
          label={
            <div className={style.label}>
              <FormattedMessage id={option.title} />

              <Icon
                type={option.icon as keyof typeof icons}
                className={classNames(style.icon, {
                  [style.icon__active]: isActive,
                })}
              />
            </div>
          }
        />
      </li>
    );
  }
}

export default PaymentItem;
