import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

interface ILogDTO {
  message: string;
}

export const loggerApi = {
  sendError(data: ILogDTO) {
    return http.post<ILogDTO, unknown>(api(URLS.log.error), data);
  },
};
