import { action, computed, observable, reaction, toJS } from 'mobx';

import { ISample, MenuItem } from '../Recognition/types';
import { FEATURE_PROPERTIES, SubTag } from '../OLStore/types';

import httpFacade from 'http/httpFacade';

import { groupByCategories } from '../Menu/helpers';
import {
  convertDataToFeature,
  convertFeatureToData,
  featureFabric,
} from '../OLStore/helpers';
import { sortByAccessor } from 'helpers/accessors';
import { delay } from 'helpers/promise';
import Log from 'helpers/log';
import { createGallerySample } from '../Recognition/helpers';

import OLStore from '../OLStore/OLStore';
import RootStore from '../RootStore';
import { isEqualValue } from '../../helpers/equal';

interface ConstructorData {
  initialIndex: number;
  samples: ISample[];
  menuItems: MenuItem[];
}

export enum DATASET_NOTE_TYPES {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface DatasetNote {
  type: DATASET_NOTE_TYPES;
  message: string;
}

export const ZOOM = 0.2;
export const STEP = 1;

const DELAY = 2000;

const LAST_USED_LENGTH = 15;
const LAST_USED_TITLE = 'LAST_USED';

class DatasetSamplesStore {
  static SUB_TAGS: SubTag[] = [
    SubTag.BACK,
    SubTag.FRONT,
    SubTag.HORIZONTAL,
    SubTag.VERTICAL,
  ];

  @observable ol = new OLStore();

  @observable samples: ISample[];
  @observable notificationTimer;
  @observable notification: DatasetNote | null;
  @observable searchValue = '';

  @observable loading = false;

  private baseImageSize = {
    width: 640,
    height: 360,
  };

  @observable private readonly menuItems: MenuItem[];

  @observable private lastUsed: MenuItem[] = [];
  @observable private activeIndex: number;
  @observable private activeItem: MenuItem | null;
  @observable private sortConfig = { accessor: 'title', desc: false };

  @computed
  get isLast() {
    return this.activeIndex === this.samples.length - 1;
  }

  @computed
  get isFirst() {
    return this.activeIndex === 0;
  }

  @computed
  get activeSample(): ISample {
    return this.samples[this.activeIndex];
  }

  @computed
  get lastUsedCategory() {
    return {
      id: 'menu.last',
      title: RootStore.localization.formatMessage(
        'modal.recognition.menu.last',
      ),
      items: [...this.lastUsed].sort?.(sortByAccessor(this.sortConfig)),
    };
  }

  @computed
  get filteredMenuItems() {
    const regExp = new RegExp(
      this.searchValue.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'),
      'i',
    );

    return this.menuItems.filter(item =>
      this.searchValue
        ? regExp.test(item.title) || regExp.test(item.itemNumber)
        : item,
    );
  }

  @computed
  get categories() {
    return groupByCategories(this.filteredMenuItems).sort(
      sortByAccessor(this.sortConfig),
    );
  }

  @computed
  get features() {
    const data = this.activeSample.labelling.data.map(feature =>
      convertDataToFeature(feature, this.activeSample.labelling.size.height),
    );

    return featureFabric(data);
  }

  @computed
  get dto() {
    return this.ol.features.map(feature =>
      convertFeatureToData(feature, this.activeSample.labelling.size.height),
    );
  }

  @computed
  get isChanged(): boolean {
    return !isEqualValue(
      toJS(
        this.activeSample?.labelling.data.map(it => ({
          type: it.type,
          coordinates: it.coordinates,
          tag: it.tag,
          subtag: it.subtag,
        })),
      ),
      this.dto,
    );
  }

  constructor({ samples, menuItems, initialIndex = 0 }: ConstructorData) {
    this.samples = samples;
    this.activeIndex = initialIndex;
    this.menuItems = menuItems.slice().sort(sortByAccessor(this.sortConfig));

    this.getLastUsedItems();

    reaction(
      () => this.activeSample,
      () => {
        this.ol.updateData(this.activeSample.imageFileLink, this.features);
      },
    );
  }

  @action.bound
  initOl() {
    this.ol.init(
      this.activeSample.imageFileLink,
      this.features,
      !!this.activeSample.labelling?.data?.length
        ? this.activeSample.labelling.size
        : this.baseImageSize,
    );
  }

  @action.bound
  async changeSample(value = 1) {
    const index =
      value > 0
        ? Math.max(this.activeIndex + value, 0)
        : Math.min(this.activeIndex + value, this.samples.length - 1);

    if (this.isChanged && this.ol.isSourceValid) {
      await this.saveChanges();

      this.activeIndex = index;
    } else if (this.ol.isSourceValid) {
      this.activeIndex = index;
    } else {
      await this.showNotification(DATASET_NOTE_TYPES.ERROR);
    }
  }

  @action.bound
  async saveChanges() {
    try {
      this.loading = true;

      const dto = {
        data: this.dto,
        size: this.activeSample.labelling.size,
      };
      const { data } = await httpFacade.recognition.updateSample(
        this.activeSample.id,
        dto,
      );

      await this.showNotification(DATASET_NOTE_TYPES.SUCCESS);

      this.addToLastUsedItems();
      this.updateSample(createGallerySample(data, this.menuItems));
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async autoLabellingActiveSample() {
    try {
      this.loading = true;

      const { imageFileLink } = this.activeSample;
      const { data } = await httpFacade.recognition.autoLabelling(
        imageFileLink,
      );

      this.activeSample.labelling.data = data.data;
      this.ol.addFeatures(this.features);
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  getLastUsedItems() {
    const items = sessionStorage.getItem(LAST_USED_TITLE);

    this.lastUsed = items ? JSON.parse(items) : [];
  }

  @action.bound
  showNotification(type: DATASET_NOTE_TYPES) {
    clearTimeout(this.notificationTimer);

    this.notification = {
      type,
      message:
        type === DATASET_NOTE_TYPES.ERROR
          ? 'modal.recognition.error'
          : 'modal.recognition.success',
    };
    this.notificationTimer = setTimeout(
      () => (this.notification = null),
      DELAY,
    );

    return delay(DELAY);
  }

  @action.bound
  updateSample(sample: ISample) {
    this.samples = this.samples.map(el => (el.id === sample.id ? sample : el));
  }

  @action.bound
  private addToLastUsedItems() {
    this.ol.features.forEach(el => {
      const tag = el.get(FEATURE_PROPERTIES.tag);
      const title = el.get(FEATURE_PROPERTIES.title);
      const subtag = el.get(FEATURE_PROPERTIES.subtag);
      const isExist = this.lastUsed.find(item => item.tag === tag);
      const itemNumber = el.get(FEATURE_PROPERTIES.itemNumber);
      const price = el.get(FEATURE_PROPERTIES.price);

      if (!isExist) {
        this.lastUsed.unshift({ tag, title, subtag, itemNumber, price });
      }
    });
    this.lastUsed =
      this.lastUsed.length > LAST_USED_LENGTH
        ? this.lastUsed.slice(0, LAST_USED_LENGTH)
        : this.lastUsed;

    sessionStorage.setItem(LAST_USED_TITLE, JSON.stringify(this.lastUsed));
  }
}

export default DatasetSamplesStore;
