import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import classNames from 'classnames';

import { DATE_FORMAT } from 'helpers/datetime';
import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { CopyMode } from 'stores/Menu/MenuCopy/types';

import PlannerGrid from './PlannerGrid';
import PlannerCalendar from './PlannerCalendar';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { CopyMenu } from 'pages/MenuPlanner/CopyMenu/CopyMenu';

import style from './Planner.module.scss';

@observer
class Planner extends Component<WrappedComponentProps> {
  store = new MenuPageStore();

  async componentDidMount() {
    const { menuPlanner } = this.store;
    await Promise.all([menuPlanner.fetchMenu(), menuPlanner.init()]);
  }

  render() {
    const { intl } = this.props;
    const { menuPlanner, isCopyMode, copyMenu } = this.store;

    const printTitle = `${intl.formatMessage({
      id: 'title.planner.print.title',
    })} (KW ${menuPlanner.weekNumber}, ${menuPlanner.weekStartDate.toFormat(
      DATE_FORMAT,
    )} - ${menuPlanner.weekEndDate.toFormat(DATE_FORMAT)})`;

    return (
      <Provider menuPage={this.store}>
        <div className={style.content}>
          <CopyMenu store={this.store} />
          <header className={style.header}>
            <div className={style.planner__title}>
              <div className={style.planner__week}>
                <FormattedMessage id="WEEK" />
                &nbsp;
                {menuPlanner.weekNumber}
                {isCopyMode && copyMenu?.copyMode === CopyMode.WEEK && (
                  <div
                    className={classNames(
                      style.selectionMask,
                      style.selectionMaskWeek,
                      {
                        [style.active]:
                          copyMenu.periodFrom[0].weekNumber ===
                          menuPlanner.weekNumber,
                      },
                    )}
                    onClick={copyMenu.setPeriodFrom(menuPlanner.date)}
                  />
                )}
              </div>

              <div className={style.planner__print}>
                {menuPlanner?.catering?.logo && (
                  <img
                    className={style.catering__logo}
                    src={menuPlanner.catering.logo}
                    alt="catering logo"
                  />
                )}
                <div className={style.print__header}>
                  <div className={style.print__title}>{printTitle}</div>
                  {menuPlanner?.catering?.name && (
                    <div>{menuPlanner.catering.name}</div>
                  )}
                </div>
              </div>
            </div>

            <PlannerCalendar menu={menuPlanner} />

            {!menuPlanner.canAddMenuLine && (
              <div className={style.warning}>
                <Icon type="info" className={style.icon__info} />
                <FormattedMessage id="warning.weekBlocked" />
              </div>
            )}

            {!this.store.isCopyMode && (
              <Button
                styleType="text"
                onClick={() => {
                  document.title = printTitle;
                  window.print();
                  document.title = 'sionum';
                }}
                className={style.btn__print}
              >
                <Icon type="print" />
              </Button>
            )}
          </header>

          <div className={style.planner__footer__container}>
            <PlannerGrid menuPage={this.store} />

            <footer className={style.planner__footer}>
              {menuPlanner.allergens.map(it => (
                <div key={it.id} className={style.footer__allergen}>
                  <img
                    src={it.icon}
                    alt="allergen"
                    className={style.allergen__icon}
                  />
                  <span className={style.allergen__title}>{it.title}</span>
                </div>
              ))}
            </footer>

            <div className={style.warning__message}>
              <FormattedMessage id="menu.warning.message" />
            </div>
          </div>

          <div className={style.copyright}>
            <FormattedMessage id="menu.copyright" />
          </div>

          {menuPlanner?.catering?.name && (
            <div className={style.watermark}>
              <p className={style.watermark__text}>
                {menuPlanner.catering.name}
              </p>
            </div>
          )}

          {menuPlanner.loading && <Spinner contain />}
        </div>
      </Provider>
    );
  }
}

export default injectIntl(Planner);
