import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './TaskForm.module.scss';

import Controls from './Controls';
import TaskInfo from './TaskInfo';
import TextField from 'components/Form/Fields/Text/TextField';
import Slider from 'components/Form/Fields/Slider/Slider';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Spinner from 'components/Spinner/Spinner';
import TagsSelect from 'components/TagsSelect/TagsSelect';

import TaskForm, {
  OptionsLimits,
} from 'stores/Forms/TasksForms/TaskDetectorForm';
import { TaskType } from 'stores/Recognition/types';
import SwitchButton from '../../../../components/Form/Fields/SwitchButton/SwitchButton';

@observer
class TaskDetectorForm extends Component<{
  type:
    | TaskType.TRAIN_DETECTOR_SSD
    | TaskType.TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL
    | TaskType.TRAIN_DETECTOR_TF_MRCNN;
}> {
  form: TaskForm = new TaskForm(this.props.type);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onCreate();
  };

  async componentDidMount() {
    await this.form.init();
  }

  render() {
    const FormControl = this.formControl;

    return (
      <form className={style.task__form} onSubmit={this.onSubmit}>
        {this.form.loading && <Spinner contain />}

        <TaskInfo info={this.props.type} />

        <div className={style.form__body}>
          <TagsSelect
            title="title.dataset.required"
            tags={this.form.datasetTags}
            selectedTags={this.form.selectedDatasets}
            onSubmit={this.form.setDatasets}
            onRemoveTag={this.form.removeDataset}
          />

          <FormControl
            name="description"
            className={classNames(
              style.control__medium,
              style.control__description,
            )}
            render={props => <TextField {...props} />}
          />

          <p className={style.title}>
            <FormattedMessage id="title.options" />
          </p>

          <div className={style.form__options}>
            <FormControl
              name="stepsNumber"
              className={classNames(
                style.control__medium,
                style.control__option,
              )}
              render={props => (
                <Slider
                  {...props}
                  min={OptionsLimits.stepsNumberMin}
                  max={OptionsLimits.stepsNumberMax}
                  step={OptionsLimits.stepsNumberStep}
                  label="title.steps"
                />
              )}
            />

            <FormControl
              name="duration"
              className={classNames(
                style.control__medium,
                style.control__option,
              )}
              render={props => (
                <Slider
                  {...props}
                  min={OptionsLimits.durationMin}
                  max={OptionsLimits.durationMax}
                  step={OptionsLimits.durationStep}
                  label="title.duration"
                />
              )}
            />
          </div>
          <div className={style.form__options}>
            <div className={style.remap__labels_title}>
              <FormattedMessage id="title.remapLabels" />
            </div>

            <div className={style.remap__labels_value}>
              <FormControl
                name="remapLabels"
                render={props => (
                  <SwitchButton {...props} checked={this.form.remapLabels} />
                )}
              />
            </div>
          </div>
        </div>

        <Controls disabled={!this.form.isParamsValid} />
      </form>
    );
  }
}

export default TaskDetectorForm;
