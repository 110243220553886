import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }: React.PropsWithChildren<{}>) => {
  const [element] = useState(() => document.createElement('div'));
  const container = useMemo(() => {
    return document.querySelector('#popovers');
  }, []);

  useEffect(() => {
    container?.appendChild(element);

    return () => {
      container?.removeChild(element);
    };
  });

  return ReactDOM.createPortal(children, element);
};
