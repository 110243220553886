import { observer } from 'mobx-react';
import React, { Component } from 'react';

import style from './BaseSpecifications.module.scss';

import { DishAspect } from 'stores/Menu/types';

interface Props {
  aspect: DishAspect;
  isActive: boolean;
  onChange: (id: string) => void;
}

@observer
class Aspect extends Component<Props> {
  onSpecChange = id => () => {
    this.props.onChange(id);
  };

  render() {
    const { aspect, isActive } = this.props;
    return (
      <div className={style.item}>
        <input
          type="checkbox"
          name="tag"
          id={aspect.id}
          className={style.checkbox}
          onChange={this.onSpecChange(aspect.id)}
          checked={isActive}
        />
        <label className={style.label} htmlFor={aspect.id}>
          {aspect.title}
        </label>
      </div>
    );
  }
}

export default Aspect;
