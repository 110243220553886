import { IFeature } from '../OLStore/types';

export enum ClusterAgentStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
}

export enum AgentType {
  TASK_AGENT = 'TASK_AGENT',
  INFERENCE_AGENT = 'INFERENCE_AGENT',
}

export interface TaskAgentReportDto {
  healthStatus: ClusterAgentStatus;
  id: string;
  name: string;
  startedAt: string;
  statistics: TaskAgentStatistics;
}

export interface InferenceAgentReportDto {
  healthStatus: ClusterAgentStatus;
  id: string;
  name: string;
  reference: number;
  startedAt: string;
  statistics: InferenceAgentStatistics;
}

export interface ServingTagsDto {
  healthStatus: ClusterAgentStatus;
  id: string;
  name: string;
  reference: number;
  startedAt: string;
  statistics: InferenceAgentStatistics;
  supportsDeployment: boolean;
  servingTag: string;
}

interface TaskAgentStatistics {
  completedTasksCount: number;
  runningTasksCount: number;
  startedTasksCount: number;
  failedTasksCount: number;
}

interface InferenceAgentStatistics {
  averageDetectionDuration: number;
  deployedModelsCount: number;
  successfulDetectionsCount: number;
  failedDetectionsCount: number;
}

export interface IDataset {
  createdDate: string;
  errorMessage: string;
  id: string;
  lastModifiedDate: string;
  version: number;
  name: string;
  state: string;
  statistic: DatasetStatistic;
  statisticRefreshedDate: string;
}

export interface DatasetStatistic {
  datasetValidation: ValidationState;
  avgSamplesPerTag: number;
  avgTagsPerSample: number;
  tagsAmount: number;
  samplesAmount: number;
  trainJobsAmount: number;
  sample2tags: Samples2Tag[];
  tag2Samples: Tag2Samples[];
}

export interface Tag2Samples {
  samplesAmount: number;
  tag: string;
}

export interface Samples2Tag {
  sampleId: string;
  tagsAmount: number;
}

export enum ArchitectureType {
  DETECTION = 'DETECTION',
  CLASSIFICATION = 'CLASSIFICATION',
}

export interface MLModelWithDeployments extends MLModel {
  [x: string]: any;
  cfg: object;
  metrics: object;
  metaInfo: object;
  deployments: {
    data: [
      {
        id: string;
        type: string;
        description: string;
        enabled: boolean;
        servingTag: string;
      },
    ];
    active: number;
    total: number;
  };
}

export interface MLModel {
  architecture: {
    id: string;
    name: string;
    type: ArchitectureType;
  };
  createdDate: string;
  dataset: {
    id: string;
    name: string;
  };
  description: string;
  id: string;
  lastModifiedDate: string;
  inferenceDefault: boolean;
  labelling: boolean;
  modelVersion: number;
  version: number;
  modelValidation: ValidationState;
  modelName?: string;
}

export interface DeploymentDTO {
  createdDate: string;
  description: string;
  enabled: boolean;
  id: string;
  lastModifiedDate: string;
  model: DeploymentModel;
  cfg: {
    baseDir: string;
    modelsConfigFilePath: string;
    servingTag: string;
    savedModelDir: string;
    forceDeploy: boolean;
    tfsBinariesDir: string;
  };
  result: {
    errMsg: string;
    successful: boolean;
  };
  type: string;
}

export interface DeploymentModel {
  description: string;
  enabled: boolean;
  id: string;
  modelVersion: number;
  name: string;
  createdDate: string;
  lastModifiedDate: string;
  deploymentId: string;
  type: string;
  cfg: object;
  result: {
    successful: boolean;
    errMsg: string;
  };
}

export interface ValidationState {
  hint: string;
  state: StateCondition;
}

export interface ISample {
  createdDate: string;
  dataset: {
    id: string;
    name: string;
  };
  imageFileLink: string;
  name: string;
  id: string;
  labelling: {
    data: IFeature[];
    size: {
      height: number;
      width: number;
    };
  };
  thumbnailBytes: string;
  thumbnailFormat: string;
  // remove in the future
  imageSize: {
    height: number;
    width: number;
  };
  // calculate property at front side
  thumbnail?: string;
}

export interface IDatasetSamples {
  content: ISample[];
  pageable: {
    sort: SortSample;
    offset: number;
    pageSize: number;
    pageNumber: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalElements: number;
  last: boolean;
  totalPages: number;
  first: boolean;
  sort: SortSample;
  size: number;
  number: number;
  numberOfElements: number;
  empty: boolean;
}

interface SortSample {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface ILabel {
  title?: string;
  tag: string;
  amount: number;
}

export enum StateCondition {
  BAD = 'BAD',
  RISQUE = 'RISQUE',
  GOOD = 'GOOD',
}

export interface IStretching {
  diffX: number;
  diffY: number;
}

export interface ISize {
  height: number;
  width: number;
}

export interface Artifacts {
  EVALUATION_REPORT: string;
  EVALUATION_IMAGES: string;
}

export interface MenuItem {
  title: string;
  tag: string;
  subtag?: string;
  itemNumber: string;
  price: number;
}

export enum TaskType {
  TRAIN_DETECTOR_SSD = 'TRAIN_DETECTOR_SSD',
  TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL = 'TRAIN_DETECTOR_TF_MRCNN_AUTOLABEL',
  TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4 = 'TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4',
  TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4 = 'TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4',
  GENERATE_DATASET = 'GENERATE_DATASET',
  TRAIN_DETECTOR_TF_MRCNN = 'TRAIN_DETECTOR_TF_MRCNN',
  EVAL_MODEL_DETECTOR_DVC = 'EVAL_MODEL_DETECTOR_DVC',
  EVAL_MODEL_DETECTOR = 'EVAL_MODEL_DETECTOR',
  CREATE_MODEL_DETECTOR_DVC = 'CREATE_MODEL_DETECTOR_DVC',
}

export enum InternalBETaskType {
  DEPLOY_MODEL_TFS_V2 = 'DEPLOY_MODEL_TFS_V2',
}

export enum TaskState {
  QUEUED = 'QUEUED',
  IN_PROCESS = 'IN_PROCESS',
  FINISHED = 'FINISHED',
  CANCELED_MANUALLY = 'CANCELED_MANUALLY',
  CANCELED_AUTOMATICALLY = 'CANCELED_AUTOMATICALLY',
  ERROR = 'ERROR',
  DEPLOYED = 'DEPLOYED',
  MARKED_FOR_DEPLOY = 'MARKED_FOR_DEPLOY',
}

export interface TaskViewDto {
  id: string;
  artifacts: object;
  cfg: object;
  createdDate: string;
  ctx: object;
  description: string;
  errorMessage: string;
  finishedAt: string;
  lastModifiedDate: string;
  metrics: Record<string, string> & {
    progress?: string;
  };
  startedAt: string;
  state: TaskState;
  taskAgentId?: string;
  type: string;
  wfAllowedActions: TaskAction[];
  workflowType: TaskWorkflowType;
}

export enum TaskWorkflowType {
  TRAIN = 'TRAIN',
  EVALUATION = 'EVALUATION',
}

export enum TaskAction {
  CREATE_TASK_CREATE_MODEL = 'create_task_create_model',
  CANCEl = 'cancel',
  IMPORT_DATASET_ARTIFACT = 'import_dataset_artifact',
}

export interface CreateTaskReqParams<T> {
  description?: string;
  params: T;
  type: TaskType | InternalBETaskType;
}

export interface TaskParams {
  modelDescription?: string;
}
export interface DeployModelTaskParams {
  modelId: string;
  deploymentDescription?: string;
  servingTag?: string;
  forceDeploy?: boolean;
}
export interface DeployTaskParams extends TaskParams {
  inferenceAgentRef: string | number;
}
