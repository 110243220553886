import { action, computed, observable } from 'mobx';
import { DateTime } from 'luxon';

import { getLocalDateTime } from 'helpers/datetime';
import { sortByAccessor } from 'helpers/accessors';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { CopyMode, ICopyMenu } from 'stores/Menu/MenuCopy/types';
import { CreateMenuLineDto, MenuItem } from 'stores/Menu/types';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import { getDatesPeriod, weekDayOptions } from 'stores/Menu/MenuCopy/helpers';
import { groupByCategories } from 'stores/Menu/helpers';
import { IMenuLine } from 'stores/UserProfile/types';

interface IPlannerMenuItemForCopy {
  lineId: string;
  menuItemId: string;
}

export class CopyMenuItemsForm implements ICopyMenu {
  readonly copyMode = CopyMode.MENU_ITEMS;

  @observable periodTo: [DateTime, DateTime] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];
  @observable selectedOptionIds: string[] = [];
  @observable searchValue: string = '';
  @observable menuLines: IMenuLine[] = [];
  @observable selectedMenuLineId: string = '';
  @observable menuItems: MenuItem[] = [];
  @observable selectedMenuItemsIds: string[] = [];
  @observable plannerMenuItems: IPlannerMenuItemForCopy[] = [];
  @observable isLoading = false;

  private menuPlanner: MenuPlannerStore;
  private sortConfig = { accessor: 'title', desc: false };

  constructor(menuPlanner: MenuPlannerStore) {
    this.menuPlanner = menuPlanner;
  }

  @computed
  get categories() {
    const regExp = new RegExp(this.searchValue, 'i');
    const filteredMenuItems = this.menuItems.filter(item =>
      this.searchValue ? regExp.test(item.title) : item,
    );

    return groupByCategories(filteredMenuItems).sort(
      sortByAccessor(this.sortConfig),
    );
  }

  @computed
  get copyOnPeriod(): string[] {
    const [periodStart, periodEnd] = this.periodTo;

    return getDatesPeriod(periodStart, periodEnd, this.selectedOptionIds);
  }

  @computed
  get dataToCopy(): CreateMenuLineDto[] {
    const lines = this.plannerMenuItems.reduce((acc, item) => {
      const line = acc.find(el => el.line === item.lineId);

      if (line) {
        line.items.push(item.menuItemId);
      } else {
        acc.push({
          line: item.lineId,
          items: [item.menuItemId],
        });
      }

      return acc;
    }, [] as Array<{ line: string; items: string[] }>);

    if (this.selectedMenuLineId && this.selectedMenuItemsIds.length) {
      const line = lines.find(el => el.line === this.selectedMenuLineId);
      if (line) {
        line.items = [...new Set(...this.selectedMenuItemsIds, ...line.items)];
      } else {
        lines.push({
          line: this.selectedMenuLineId,
          items: this.selectedMenuItemsIds,
        });
      }
    }

    return lines.map(el => ({
      line: el.line,
      days: this.copyOnPeriod.map(date => ({
        date,
        items: el.items,
      })),
    }));
  }

  @computed
  get isCopyDisabled(): boolean {
    return (
      !this.dataToCopy.length ||
      (!!this.selectedMenuItemsIds.length && !this.selectedMenuLineId)
    );
  }

  @computed
  get selectedMenuItems(): MenuItem[] {
    return this.menuItems.filter(item =>
      this.selectedMenuItemsIds.includes(item.id),
    );
  }

  @computed
  get menuLinesOptions(): Array<{ label: string; value: string }> {
    return this.menuLines.map(line => ({
      label: line.title,
      value: line.id,
    }));
  }

  @action.bound
  async init() {
    try {
      this.isLoading = true;

      const [menuItemsData, menuLinesData] = await Promise.all([
        httpFacade.menuItems.fetchMenuItems(),
        httpFacade.menu.fetchMenuLines(),
      ]);

      this.menuItems = menuItemsData.data.sort(sortByAccessor(this.sortConfig));
      this.menuLines = menuLinesData.data.filter(line => !line.deleted);
    } catch (error) {
      Log.warn(error);
    } finally {
      this.isLoading = false;
    }
  }

  @action.bound
  setSearchValue(value: string) {
    this.searchValue = value;
  }

  @action.bound
  setSelectedMenuLine(value?: string | null) {
    if (!value) {
      return;
    }

    this.selectedMenuLineId = value;
  }

  @action.bound
  toggleItem(item: MenuItem) {
    if (this.selectedMenuItemsIds.some(id => id === item.id)) {
      this.selectedMenuItemsIds = this.selectedMenuItemsIds.filter(
        id => id !== item.id,
      );
    } else {
      this.selectedMenuItemsIds.push(item.id);
    }
  }

  isPlannerItemSelected = (plannerItem: IPlannerMenuItemForCopy) => {
    return this.plannerMenuItems.some(
      item =>
        item.lineId === plannerItem.lineId &&
        item.menuItemId === plannerItem.menuItemId,
    );
  };

  @action
  togglePlannerMenuItem = (plannerItem: IPlannerMenuItemForCopy) => () => {
    if (this.isPlannerItemSelected(plannerItem)) {
      this.plannerMenuItems = this.plannerMenuItems.filter(
        item =>
          !(
            item.lineId === plannerItem.lineId &&
            item.menuItemId === plannerItem.menuItemId
          ),
      );
    } else {
      this.plannerMenuItems.push(plannerItem);
    }
  };

  @action.bound
  changePeriodTo([from, to]: DateTime[]) {
    this.periodTo = [from, to];
  }

  @action.bound
  toggleOption(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      this.selectedOptionIds = this.selectedOptionIds.filter(
        id => id !== optionId,
      );
    } else {
      this.selectedOptionIds.push(optionId);
    }
  }

  @action.bound
  clearSelectedOptions() {
    this.selectedOptionIds = [];
  }

  @action.bound
  selectWeekdays() {
    this.selectedOptionIds = weekDayOptions.reduce((acc, option) => {
      if (!option.holiday) {
        acc.push(option.id);
      }

      return acc;
    }, [] as string[]);
  }

  @action.bound
  reset() {
    this.selectedMenuItemsIds = [];
    this.selectedMenuLineId = '';
    this.plannerMenuItems = [];
    this.selectedOptionIds = [];
  }
}
