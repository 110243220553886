import { convertStrToBool } from '../../../helpers/stringToBoolean';

export function defineRequiredFields(fields) {
  return Object.keys(fields)
    .map(key => {
      return { [key]: fields[key] };
    })
    .filter?.((field: any) => {
      const key = Object.keys(field)[0];
      return (
        !!convertStrToBool(field[key].enabled) &&
        !!convertStrToBool(field[key].required)
      );
    });
}

export function ckeckedIfRequiredFieldsFilled(allFields, requiredFields) {
  const fields = Object.keys(allFields)
    .map(key => {
      return { [key]: allFields[key] };
    })
    .filter(obj => {
      const key = Object.keys(obj)[0];
      return key !== 'validated';
    });
  const keys = requiredFields.reduce?.((acc, field: any) => {
    const key = Object.keys(field)[0];
    return [...acc, key];
  }, []);
  return keys.every(key => {
    return fields.some(field => {
      return !!field[key];
    });
  });
}
