import React, { Component } from 'react';
import style from './ConsumptionConditionModal.module.scss';
import { ModalProps } from 'stores/ModalStore';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button/Button';

interface Props extends ModalProps {
  onConsumptionConditionSelected: (consumptionCondition: string) => void;
  onReject: () => void;
}

class ConsumptionConditionModal extends Component<Props> {
  render() {
    const {
      onConsumptionConditionSelected,
      onReject,
      onClose,
      onSubmit,
    } = this.props;

    return (
      <Modal
        className={style.modal}
        maskClosable={false}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        maskStyle={{ backgroundColor: 'transparent' }}
        onClose={() => {
          onReject();
          onClose();
        }}
      >
        <div className={style.text}>
          <FormattedMessage id="consumption.condition.text" />
        </div>

        <div className={style.buttons}>
          <Button
            className={style.btn}
            styleType="primary"
            onClick={() => {
              onConsumptionConditionSelected('IN_PLACE');
              if (typeof onSubmit !== 'undefined') {
                onSubmit();
              }
            }}
          >
            <FormattedMessage id="consumption.condition.in.place" />
          </Button>

          <Button
            className={style.btn}
            styleType="primary"
            onClick={() => {
              onConsumptionConditionSelected('TAKE_AWAY');
              if (typeof onSubmit !== 'undefined') {
                onSubmit();
              }
            }}
          >
            <FormattedMessage id="consumption.condition.take.away" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ConsumptionConditionModal;
