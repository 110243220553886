import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import './style.scss';

import ProfileStore from 'stores/EmployeeProfile/ProfileStore';
import RootStore from 'stores/RootStore';
import ModalStore from 'stores/ModalStore';

import TagWithText from 'components/Tag/withText';
import { TagStatus } from 'components/Tag/Tag';
import Button from 'components/Button/Button';
import WarningModal from 'components/Modals/Warning/WarningModal';

interface Props {
  profile: ProfileStore;
}

@observer
class Tags extends Component<Props> {
  removeTags = async () => {
    const description = RootStore.localization.formatMessage(
      'modal.removeTag.confirm.description',
      {
        nickname: this.props.profile.nickname,
      },
    );

    await ModalStore.showModal(WarningModal, {
      title: 'modal.removeTag.confirm.title',
      description,
      cancelBtnText: 'button.cancel.tags',
      submitBtnText: 'button.submit.tags',
      type: 'confirm',
    });
    await this.props.profile.removeTags();
  };

  resetTokenAssignment = async () => {
    const description = RootStore.localization.formatMessage(
      'modal.token.assignment.reset.description',
      {
        nickname: this.props.profile.nickname,
        boldValue: (
          <span className={'tags__description_bold'}>
            {
              <FormattedMessage
                id={'modal.token.assignment.reset.description.boldValue'}
              />
            }
          </span>
        ),
      },
    );
    await ModalStore.showModal(WarningModal, {
      title: 'modal.token.assignment.reset.title',
      description,
      cancelBtnText: 'button.cancel.tags',
      submitBtnText: 'button.submit.tags',
      type: 'confirm',
    });
    await this.props.profile.resetTokenAssignment();
  };

  render() {
    const { profile } = this.props;

    return (
      <section className={'tags__tags'}>
        <h3 className={'tags__title'}>
          <FormattedMessage id="profile.tags.title" />
        </h3>

        <div className={'tags__controls'}>
          <Button
            onClick={profile.attachTag}
            className={'tags__btn'}
            styleType="ghost"
            disabled={!profile.canAttachTag}
          >
            <FormattedMessage id="profile.tags.button.reassign" />
          </Button>

          <Button
            onClick={this.resetTokenAssignment}
            className={'tags__btn'}
            styleType="ghost"
            disabled={!profile.isTagsAvailable}
          >
            <FormattedMessage id="profile.tags.button.loss" />
          </Button>

          <Button
            onClick={this.removeTags}
            className={'tags__btn'}
            styleType="warning"
            disabled={!profile.isTagsAvailable}
          >
            <FormattedMessage id="profile.tags.button.delete" />
          </Button>
        </div>

        <div className={'tags__tags_wrapper'}>
          {/*<TagWithText*/}
          {/*  type="nfc"*/}
          {/*  border={false}*/}
          {/*  tag={profile.nfc}*/}
          {/*  className={classNames(*/}
          {/*    'tags__tag',*/}
          {/*    profile.nfc.status === TagStatus.available && 'tags__tag_assigned',*/}
          {/*  )}*/}
          {/*/>*/}

          <TagWithText
            type="rfid"
            border={false}
            tag={profile.rfid}
            className={classNames('tags__tag', {
              tags__tag_assigned: profile.rfid.status === TagStatus.available,
            })}
          />
        </div>
      </section>
    );
  }
}

export default Tags;
