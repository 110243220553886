import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './SamplePanel.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';
import Fallback from 'components/Fallback/Fallback';

import DatasetSamplesStore, {
  DATASET_NOTE_TYPES,
  STEP,
  ZOOM,
} from 'stores/Modals/DatasetSamplesStore';

interface Props {
  store?: DatasetSamplesStore;
}

@inject('store')
@observer
class SamplePanel extends Component<Props> {
  panel = React.createRef<HTMLElement>();

  componentDidMount() {
    this.props.store?.initOl();
    this.panel?.current?.focus();
  }

  changeSample = index => () => {
    this.props.store?.changeSample(index);
  };

  onPanelKeyDown = async event => {
    const { store } = this.props;

    switch (event.keyCode) {
      case 188:
        await store!.changeSample(-STEP);
        break;
      case 190:
        await store!.changeSample(STEP);
        break;
      case 65:
        store!.ol.toggleDrawFunction();
        break;
      case 68:
      case 46:
      case 8:
        store!.ol.deleteActiveFeature();
        break;
      case 70:
        store!.ol.fitView();
        break;
      case 189:
        store!.ol.setZoom(-ZOOM);
        break;
      case 187:
        store!.ol.setZoom(ZOOM);
        break;
      case 67:
        store!.ol.resetData();
        break;
      case 76:
        await store!.autoLabellingActiveSample();
        break;
    }
  };

  render() {
    const { store } = this.props;
    const { ol } = store!;

    return (
      <section
        onKeyDown={this.onPanelKeyDown}
        className={style.panel}
        ref={this.panel}
        tabIndex={0}
      >
        <Button
          title="<"
          styleType="text"
          className={style.btn__arrow}
          onClick={this.changeSample(-STEP)}
          disabled={store?.ol?.drawModeOn || store?.isFirst}
        >
          <Icon type="arrowLeft" />
        </Button>

        <div className={style.map__wrapper}>
          {ol.loading && <Spinner contain />}

          {ol.isImageBroken ? (
            <Fallback />
          ) : (
            <div id="map" className={style.map} />
          )}
        </div>

        <Button
          title=">"
          styleType="text"
          className={style.btn__arrow}
          onClick={this.changeSample(STEP)}
          disabled={store?.ol?.drawModeOn || store?.isLast}
        >
          <Icon type="arrowRight" />
        </Button>

        {store?.notification && (
          <div
            className={classNames(style.notification, {
              [style.notification__error]:
                store?.notification.type === DATASET_NOTE_TYPES.ERROR,
            })}
          >
            <FormattedMessage id={store?.notification.message} />
          </div>
        )}
      </section>
    );
  }
}

export default SamplePanel;
