import { DishGroupManagement } from '../../../stores/Menu/types';

export const getSubsidesetItemsAmount = (
  dishGroups: DishGroupManagement[],
  allExistedDishGroups: DishGroupManagement[],
) => {
  let subsidizedItemsCountSum = 0;
  let itemsCountSum = 0;

  const categories = !!dishGroups.length ? dishGroups : allExistedDishGroups;

  categories.forEach(item => {
    subsidizedItemsCountSum += item?.subsidizedItemsCount;
    itemsCountSum += item?.itemsCount;
  });
  return `${subsidizedItemsCountSum} /
  ${itemsCountSum}`;
};
