import { MOBILE_DEVICES } from './constants';

export function checkIsMobileOrTablet() {
  const devices = new RegExp(MOBILE_DEVICES, 'i');
  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

  return devices.test(navigator.userAgent) || isIOS;
}
