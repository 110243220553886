import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { observable } from 'mobx';

import style from './FloatMenu.module.scss';

@observer
class Options extends Component<{ options }> {
  menu = React.createRef<HTMLUListElement>();

  @observable top: string;
  @observable left: string;

  componentDidMount() {
    const { height } = document.documentElement.getBoundingClientRect();
    const { current: menu } = this.menu;
    const parent = menu?.parentElement;

    if (parent && menu) {
      const {
        top: topEdge,
        bottom: bottomEdge,
        right: rightEdge,
      } = parent.getBoundingClientRect();
      const {
        height: menuHeight,
        width: menuWidth,
      } = menu.getBoundingClientRect();
      const top =
        topEdge + menuHeight > height ? bottomEdge - menuHeight : topEdge;
      const left = rightEdge - menuWidth;

      this.top = `${top}px`;
      this.left = `${left}px`;
    }
  }

  render() {
    const { options } = this.props;

    return (
      <ul
        className={style.menu}
        style={{
          top: this.top,
          left: this.left,
        }}
        ref={this.menu}
      >
        {options.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>
    );
  }
}

export default Options;
