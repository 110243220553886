import http from '../http';
import URLS from 'http/urls';
import { api } from 'http/helpers';

export interface ReceiptItem {
  title: string;
  pricePerUnit: number;
  finalPrice: number;
  amount: number;
  vat: {
    rate: number;
    value: number;
  };
}

export interface ReceiptDTO {
  company: {
    name: string;
    town: string;
    street: string;
    postCode: string;
    taxNumber: string;
  };
  initialTransactionId?: string;
  transactionId: string;
  transactionDate: string;
  type: 'ORDER' | 'ORDER_STORNO';
  originalPrice: number;
  finalPrice: number;
  discount: number;
  items: ReceiptItem[];
  totalVatValue: number;
  initialReceiptNumber?: string;
  receiptNumber: string;
  tseData?: {
    certificateSerial: string;
    clientSerialNumber: string;
    qrCodeData: string;
    signatureAlgorithm: string;
    signatureCounter: number;
    signaturePublicKey: string;
    signatureValue: string;
    start: number;
    stop: number;
    timestampFormat: string;
    transactionNumber: number;
  };
}

// interface UserBalanceDTO {
//   balance: number;
//   weeklyRefill: number;
// }

interface RefillBalanceDTO {
  amount: number;
}

export const financeApi = {
  refillBalance(userId, data: RefillBalanceDTO) {
    return http.post<RefillBalanceDTO, { balance: number }>(
      api(URLS.finance.refillBalance),
      data,
      {
        replacePathParams: { userId },
      },
    );
  },

  fetchReceipt(userId: string, transactionId: string) {
    return http.get<ReceiptDTO>(api(URLS.users.receipt), {
      replacePathParams: { userId, transactionId },
    });
  },
};
