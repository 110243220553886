import AppRouter from 'stores/AppRouter';

export function setUrlParamsToObject(targetObject: object) {
  const urlSearchParams = AppRouter.getSearchParams();

  const params = Object.entries(urlSearchParams);

  if (params.length) {
    params.forEach(([key, value]) => {
      if (targetObject.hasOwnProperty(key)) {
        targetObject[key] = value;
      }
    });

    AppRouter.clearSearchParams();
  }
}
