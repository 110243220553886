import { observable, action } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { SocketDataType, TaskUpdateData } from '../Socket/types';

import { TaskViewDto, TaskAction, DeployTaskParams, TaskParams } from './types';
import ModalStore from 'stores/ModalStore';
import InfoMessageModal from 'components/Modals/InfoMessage/InfoMessageModal';

class TasksStore {
  @observable tasks: TaskViewDto[] = [];

  @observable loading = false;

  @action.bound
  async fetchTasks() {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.fetchTasks();

      this.tasks = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteTask(id: string) {
    try {
      this.loading = true;

      await httpFacade.recognition.deleteTask(id);

      this.tasks = this.tasks.filter(dataset => dataset.id !== id);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateTask(id: string, description?: string) {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.updateTask(id, description);

      this.tasks = this.tasks.map(task => (task.id === id ? data : task));
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async taskAction(
    id: string,
    taskAction: TaskAction,
    params?: TaskParams | DeployTaskParams,
  ) {
    try {
      this.loading = true;
      const { data } = await httpFacade.recognition.actionTask(
        id,
        taskAction,
        params,
      );

      this.tasks = this.tasks.map(task => (task.id === id ? data : task));
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateTasksAsync(event) {
    const data: TaskUpdateData = JSON.parse(event.data);

    if (data?.type === SocketDataType.taskUpdate) {
      const { id, state, finishedAt, wfAllowedActions, metrics } = data.payload;

      this.tasks = this.tasks.map(task =>
        task.id === id
          ? {
              ...task,
              state,
              finishedAt,
              wfAllowedActions,
              metrics,
            }
          : task,
      );
    }
  }

  @action.bound
  async importDatasetArtifact(taskId: string) {
    try {
      this.loading = true;
      await httpFacade.recognition.importDatasetArtifact(taskId);
      ModalStore.showModal(InfoMessageModal, {
        message: 'modal.dataset.import.started',
      });
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }
}

export default TasksStore;
