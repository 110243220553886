import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isEmail,
  isRequired,
} from 'stores/BaseForm';
import ModalStore from 'stores/ModalStore';

import noop from 'helpers/noop';
import Log from 'helpers/log';

import { Dictionary } from 'types/general';

import WarningModal from 'components/Modals/Warning/WarningModal';

class StaffForm extends BaseFormModel {
  @observable
  @displayName('EMAIL')
  @isRequired()
  @isEmail()
  @hasMaxLength(100)
  email = '';

  @observable
  @displayName('FIRST_NAME')
  @isRequired()
  @hasMaxLength(50)
  firstName = '';

  @observable
  @displayName('LAST_NAME')
  @isRequired()
  @hasMaxLength(50)
  lastName = '';

  @observable
  @displayName('ROLE')
  @isRequired()
  role = '';

  @observable loading = false;
  @observable readonly id: string;

  @observable roles: Dictionary[] = [];

  constructor(id?) {
    super();

    this.id = id;
  }

  @action
  async init() {
    if (this.id) {
      this.fetchStaff();
    } else {
      this.fetchRoles();
    }
  }

  async fetchRoles() {
    this.loading = true;

    try {
      const { data } = await httpFacade.staff.fetchRoles();

      this.roles = data;
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchStaff() {
    try {
      this.loading = true;

      const [staff, roles] = await Promise.all([
        httpFacade.staff.fetchStaffByID(this.id),
        httpFacade.staff.fetchRoles(),
      ]);

      this.email = staff.data.email;
      this.firstName = staff.data.firstName;
      this.lastName = staff.data.lastName;
      this.role = staff.data.roles[0] && staff.data.roles[0].id;

      this.roles = roles.data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onSubmit(onFulfilled = noop) {
    if (this.validate()) {
      try {
        const data = {
          lastName: this.lastName,
          firstName: this.firstName,
          email: this.email,
          roles: [this.role],
        };

        let response;

        if (this.id) {
          await ModalStore.showModal(WarningModal, {
            description: 'modal.warning.change.item',
            type: 'change',
          });

          this.loading = true;
          response = await httpFacade.staff.editStaff(this.id, data);
        } else {
          this.loading = true;
          response = await httpFacade.staff.createStaff(data);
        }

        onFulfilled(response.data);
      } catch (error) {
        Log.info(error);
      } finally {
        this.loading = false;
      }
    }
  }
}

export default StaffForm;
