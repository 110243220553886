import { observable, action } from 'mobx';

import httpFacade from 'http/httpFacade';

import Log from 'helpers/log';
import { showDate } from 'helpers/datetime';
import { downloadPdfBlob } from 'helpers/pdf';
import { downloadCsvBlob } from 'helpers/csv';

import RootStore from '../RootStore';

import { StornoReport, StornoReportReqParams } from './types';

class StornoReportStore {
  @observable report: StornoReport;
  @observable loading = false;

  @action.bound
  async getReport(params: StornoReportReqParams) {
    try {
      this.loading = true;

      const { data } = await httpFacade.order.fetchStornoReport(params);

      this.report = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async getPdfReport(params: StornoReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.storno.pdf',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchStornoReportPdf(params);
      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], { type: 'application/pdf' });

      downloadPdfBlob({ fileName, pdfBlob, pdfBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }

  @action.bound
  async getCsvReport(params: StornoReportReqParams) {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.storno.csv',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.order.fetchStornoReportCsv(params);
      const csvBlobURL = URL.createObjectURL(data);
      const csvBlob = new Blob([data], { type: 'text/csv' });

      downloadCsvBlob({ fileName, csvBlob, csvBlobURL });
    } catch (error) {
      Log.info(error);
    }
  }
}

export default StornoReportStore;
