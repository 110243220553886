import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './TaskForm.module.scss';

import TaskForm, {
  OptionsLimits,
} from 'stores/Forms/TasksForms/TaskClassifierForm';
import { TaskType } from 'stores/Recognition/types';

import Slider from 'components/Form/Fields/Slider/Slider';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import Controls from './Controls';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import Spinner from 'components/Spinner/Spinner';
import TaskInfo from './TaskInfo';

@observer
class TaskClassifierForm extends Component<{
  type: TaskType.TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4;
}> {
  form: TaskForm = new TaskForm(this.props.type);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onCreate();
  };

  async componentDidMount() {
    await this.form.init();
  }

  render() {
    const FormControl = this.formControl;

    return (
      <form className={style.task__form} onSubmit={this.onSubmit}>
        {this.form.loading && <Spinner contain />}

        <TaskInfo info={this.props.type} />

        <div className={style.form__body}>
          <TagsSelect
            title="title.dataset.required"
            tags={this.form.datasetTags}
            selectedTags={this.form.selectedDatasets}
            onSubmit={this.form.setDatasets}
            onRemoveTag={this.form.removeDataset}
          />

          <FormControl
            name="description"
            className={classNames(style.control, style.control__description)}
            render={props => <TextField {...props} />}
          />

          <p className={style.title}>
            <FormattedMessage id="title.options" />
          </p>

          <div className={style.form__options}>
            <FormControl
              name="numEpochStage1"
              className={classNames(style.control, style.control__option)}
              render={props => (
                <Slider
                  {...props}
                  min={OptionsLimits.numEpochStage1Min}
                  max={OptionsLimits.numEpochStage1Max}
                  step={OptionsLimits.step}
                  label="task.form.label.epochStage1"
                />
              )}
            />

            <FormControl
              name="numEpochStage2"
              className={classNames(style.control, style.control__option)}
              render={props => (
                <Slider
                  {...props}
                  min={OptionsLimits.numEpochStage2Min}
                  max={OptionsLimits.numEpochStage2Max}
                  step={OptionsLimits.step}
                  label="task.form.label.epochStage2"
                />
              )}
            />

            <FormControl
              name="maxNumClasses"
              className={classNames(style.control, style.control__option)}
              render={props => (
                <Slider
                  {...props}
                  min={OptionsLimits.maxNumClassesMin}
                  max={OptionsLimits.maxNumClassesMax}
                  step={OptionsLimits.step}
                  label="task.form.label.maxNumClasses"
                />
              )}
            />
          </div>
        </div>

        <Controls
          disabled={
            !this.form.isFormValid || !this.form.selectedDatasets.length
          }
        />
      </form>
    );
  }
}

export default TaskClassifierForm;
