import React, { Component } from 'react';
import { observer } from 'mobx-react';
import style from './Allergen.module.scss';
import Allergen from './Allergen';
import ProfileMenuStore from '../../../../../stores/UserProfile/ProfileMenuStore';

interface Props {
  menu: ProfileMenuStore;
}

@observer
class Allergens extends Component<Props> {
  render() {
    const { menu } = this.props;
    return menu.sortedAllergens?.map?.(allergen => (
      <li key={allergen.id} className={style.allergen__item}>
        <Allergen
          allergen={allergen}
          menu={menu}
          isActive={
            menu.filteredPreferences?.allergens?.includes?.(allergen.id) ||
            false
          }
        />
      </li>
    ));
  }
}

export default Allergens;
