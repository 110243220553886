import React, { FC } from 'react';

import style from './DropdownMenu.module.scss';

interface Props<T = any> {
  options: Array<{
    value: T;
    onSelect?: () => void;
    label: string;
  }>;
}

const DropdownMenu: FC<Props> = ({ options }) => (
  <ul className={style.dropdown__menu}>
    {options.map(option => (
      <li
        className={style.dropdown__item}
        key={option.value}
        onClick={option.onSelect}
      >
        {option.label}
      </li>
    ))}
  </ul>
);

export default DropdownMenu;
