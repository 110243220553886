import React from 'react';
import { Column } from 'react-table';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import style from '../Recognition.module.scss';

import { withArrow } from 'components/Table/helpers';
import Button from 'components/Button/Button';
import FloatMenu from 'components/FloatMenu/FloatMenu';

import {
  DATE_TIME_FORMAT,
  getFormattedDate,
  getFormattedDateTime,
} from 'helpers/datetime';
import { getStateColor } from '../helpers';

import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';

import RootStore from 'stores/RootStore';

const config = ({ onEdit, onDelete, onCopy }): Column[] => [
  {
    Header: withArrow('title.dataset.name'),
    accessor: 'name',
    Cell: row => (
      <Link
        className={classNames(style.name, style.dataset__link)}
        to={replaceUrlParams(ROUTES.admin.dataset, {
          id: row.original.id,
        })}
        title={row.value}
      >
        {row.value}
      </Link>
    ),
  },
  {
    Header: withArrow('title.createdDate'),
    accessor: 'createdDate',
    resizable: false,
    width: 160,
    Cell: row => getFormattedDateTime(row.value, DATE_TIME_FORMAT),
  },
  {
    Header: withArrow('title.dateChanges'),
    accessor: 'lastModifiedDate',
    resizable: false,
    width: 180,
    Cell: row => getFormattedDate(row.value),
  },
  {
    Header: withArrow('title.images'),
    accessor: 'statistic.samplesAmount',
    resizable: false,
    maxWidth: 100,
  },
  {
    Header: withArrow('title.tags'),
    accessor: 'statistic.tagsAmount',
    resizable: false,
    maxWidth: 100,
  },
  {
    Header: withArrow('title.condition'),
    accessor: 'statistic.datasetValidation.state',
    resizable: false,
    width: 140,
    Cell: row => (
      <span className={classNames(style.state, getStateColor(row.value))}>
        {RootStore.localization.formatMessage(row.value)}
      </span>
    ),
  },
  {
    id: 'operations',
    resizable: false,
    width: 50,
    sortable: false,
    className: classNames('operations', style.operations),
    Cell: row => {
      const options = [
        <Button
          styleType="text"
          onClick={onEdit(row.original.id)}
          className={style.action}
          key={row.original.id}
        >
          <FormattedMessage id="button.edit" />
        </Button>,
        <Button
          styleType="text"
          onClick={onDelete(row.original.id)}
          className={style.action}
          key={row.original.id}
        >
          <FormattedMessage id="button.delete" />
        </Button>,
        <Button
          styleType="text"
          onClick={onCopy(row.original)}
          className={style.action}
          key={row.original.id}
        >
          <FormattedMessage id="button.copy.dataset" />
        </Button>,
      ];

      return <FloatMenu options={options} />;
    },
  },
];

export default config;
