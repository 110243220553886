import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { computed } from 'mobx';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../../routes/routes';
import RootStore from '../../../stores/RootStore';
import Button from '../../../components/Button/Button';
import { Steps } from './FormSteps';
import Icon from '../../../components/Icon/Icon';
import AppRouter from '../../../stores/AppRouter';
import SubsidyForm, {
  DiscountAmount,
  SubsidyType,
} from '../../../stores/Forms/SubsidyFormStore';
import { bindFormControl } from '../../../components/Form/FormControl/FormControl';
import Spinner from '../../../components/Spinner/Spinner';
import TextArea from '../../../components/Form/Fields/TextArea/TextAreaField';
import RadioField from '../../../components/Form/Fields/Radio/RadioField';
import SelectField from '../../../components/Form/Fields/Select/SelectField';
import SwitchButton from '../../../components/Form/Fields/SwitchButton/SwitchButton';
import CurrencyInput from '../../../components/Form/Fields/Number/CurrencyInput';
import { EUR } from '../../../helpers/currency';
import TimeRange from '../../../components/Form/Fields/TimeRange/TimeRange';
import TagsSelect from '../../../components/TagsSelect/TagsSelect';
import TooltipComponent from '../../../components/Tooltip/Tooltip';
import NumberInput from '../../../components/Form/Fields/Number/NumberInput';
import { subsidySteps } from './SubsidyConstants';

import style from './SubsidyPage.module.scss';

@observer
class SubsidyPage extends Component {
  form: SubsidyForm;
  formControl;
  translation = {
    placeholderName: RootStore.localization.formatMessage('NAME'),
    placeholderGroupName: RootStore.localization.formatMessage(
      'placeholder.groupName',
    ),
  };
  state = {
    step: 0,
  };

  constructor(props) {
    super(props);

    this.form = new SubsidyForm(props.match.params.id);
    this.formControl = bindFormControl(this.form);
  }

  async componentDidMount() {
    await this.form.init();
  }

  onSubmit = async event => {
    event.preventDefault();
    await this.form.onSubmit(AppRouter.goBack);
  };

  setStep = (increase = true) => {
    const { step } = this.state;
    const newStep = increase ? step + 1 : step - 1;
    this.setState({
      step: newStep,
    });
  };

  onBack = () => {
    if (this.state.step === 0) {
      AppRouter.goBack();
    } else {
      this.setStep(false);
    }
  };

  onNext = async event => {
    if (this.state.step === subsidySteps.length - 1) {
      await this.onSubmit(event);
    } else {
      this.setStep();
    }
  };

  onStepClickCb = (newStep: number) => {
    if (!this.form.title) {
      return;
    }
    this.setState({ step: newStep });
  };

  onDiscountAmountChange = (val: DiscountAmount | undefined) => {
    let amount = this.form.amount;
    if (!amount) {
      return;
    }
    if (val === DiscountAmount.PERCENT) {
      amount = amount / 100;
      amount = amount > 100 ? 100 : amount;
    } else if (val === DiscountAmount.AMOUNT) {
      amount = amount * 100;
    } else {
      amount = undefined;
    }
    this.form.setValue('amount', amount);
  };

  onDiscountAmountClick = type => {
    if (this.form.discountAmount === type) {
      this.onDiscountAmountChange(undefined);
      this.form.discountAmount = undefined;
    }
  };

  @computed
  get isDisabledBtn() {
    const { step } = this.state;
    switch (step) {
      case 0:
        return !this.form.title;
      case 5:
        return !this.form.isFormValid;
      default:
        return false;
    }
  }

  render() {
    const { step } = this.state;
    const FormControl = this.formControl;
    const { placeholderName, placeholderGroupName } = this.translation;

    return (
      <Provider form={this.form}>
        <main className={style.main}>
          <header className={style.header}>
            <Breadcrumbs
              links={[
                { to: ROUTES.admin.discounts, name: 'title.subsidy' },
                {
                  name: RootStore.localization.formatMessage(
                    'title.new.subsidy',
                  ),
                },
              ]}
              className="admin-header--breadcrumbs"
            />
          </header>
          {this.form.loading ? (
            <Spinner contain />
          ) : (
            <form className={style.form} onSubmit={this.onSubmit}>
              <div className={style.body}>
                <div className={style.steps__wrapper}>
                  <Steps
                    steps={subsidySteps}
                    activeStep={step}
                    onClick={this.onStepClickCb}
                    isClickDisabled={!this.form.title}
                  />
                </div>
                <div className={style.form__container}>
                  {step === 0 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.subsidy.type" />
                      <div className={style.form__group}>
                        <div className={style.form__label}>
                          <FormattedMessage id="subsidy.title.label" />
                        </div>
                        <div className={style.form__description}>
                          <FormattedMessage id="subsidy.title.label.description" />
                        </div>
                        <FormControl
                          name="title"
                          className={style.form__control}
                          render={props => (
                            <TextArea
                              {...props}
                              placeholder={placeholderName}
                            />
                          )}
                        />
                      </div>
                      <ul className={style.subsidy__types}>
                        {Object.values(SubsidyType).map(type => (
                          <li className={style.subsidy__type} key={type}>
                            <FormControl
                              name="type"
                              render={fieldProps => (
                                <RadioField
                                  {...fieldProps}
                                  title={type}
                                  name={type}
                                  value={type}
                                  checked={this.form.type === type}
                                  radioButtonStyleType={'circle'}
                                  className={style.form__label}
                                />
                              )}
                            />
                            <div
                              className={classNames(
                                style.form__description,
                                style.radio__description,
                              )}
                            >
                              <FormattedMessage id={`${type}.DESCRIPTION`} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {step === 1 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.group.settings" />
                      <div className={style.form__group}>
                        <FormControl
                          name="userGroupId"
                          render={props => (
                            <SelectField
                              {...props}
                              placeholder={placeholderGroupName}
                              options={this.form.userGroups}
                            />
                          )}
                        />
                      </div>
                      <div className={classNames(style.switcher)}>
                        <FormattedMessage id={'USE.CARD.NUMBER'} />
                        <FormControl
                          name="useCardNumber"
                          render={btnProps => (
                            <SwitchButton
                              {...btnProps}
                              checked={this.form.useCardNumber}
                            />
                          )}
                        />
                      </div>
                      <div className={style.form__description}>
                        <FormattedMessage
                          id={'subsidy.description.use.card.number'}
                        />
                      </div>

                      <div className={style.input__with_label}>
                        <div className={style.input__label}>
                          <FormattedMessage id={'NUMBER'} />
                        </div>
                        <FormControl
                          name="bayerCardSubsidyLevel"
                          className={style.number__input}
                          render={props => (
                            <TextArea
                              disabled={!this.form.useCardNumber}
                              {...props}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {step === 2 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.inclusions.exclusions" />
                      <div
                        className={classNames(
                          style.form__group,
                          style.form__divider,
                        )}
                      >
                        <TagsSelect
                          title="subsidy.exclude.items.title"
                          description="subsidy.exclude.items.description"
                          tags={this.form.menuItems}
                          selectedTags={this.form.excludeItems}
                          onSubmit={this.form.selectExcludeItems}
                          onRemoveTag={this.form.removeExcludeItems}
                          isShowSearchField={true}
                          isShowTags={false}
                          withGroups={true}
                        />
                      </div>
                      <TagsSelect
                        title="subsidy.include.items.title"
                        description="subsidy.include.items.description"
                        tags={this.form.menuItems}
                        selectedTags={this.form.includeItems}
                        onSubmit={this.form.selectIncludeItems}
                        onRemoveTag={this.form.removeIncludeItems}
                        isShowSearchField={true}
                        isShowTags={false}
                        withGroups={true}
                      />
                    </div>
                  )}
                  {step === 3 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.discount.amount" />
                      <div className={style.form__group}>
                        <ul>
                          {Object.values(DiscountAmount).map(type => (
                            <li className={style.subsidy__type} key={type}>
                              <FormControl
                                name="discountAmount"
                                render={fieldProps => (
                                  <RadioField
                                    {...fieldProps}
                                    onChange={(val: DiscountAmount, event) => {
                                      this.onDiscountAmountChange(val);
                                      fieldProps.onChange(val, event);
                                    }}
                                    onClick={() =>
                                      this.onDiscountAmountClick(type)
                                    }
                                    title={`DISCOUNT.${type}`}
                                    name={type}
                                    value={type}
                                    checked={this.form.discountAmount === type}
                                    radioButtonStyleType={'circle'}
                                    className={style.form__label}
                                  />
                                )}
                              />
                              <div
                                className={classNames(
                                  style.form__description,
                                  style.radio__description,
                                )}
                              >
                                <FormattedMessage
                                  id={`DISCOUNT.${type}.DESCRIPTION`}
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div
                          className={classNames(
                            style.input__with_label,
                            style.input__amount,
                          )}
                        >
                          <div className={style.input__label}>
                            <FormattedMessage id={`DISCOUNT.AMOUNT.LABEL`} />
                          </div>
                          <FormControl
                            name="amount"
                            className={style.number__input}
                            render={fieldProps =>
                              this.form.discountAmount ===
                              DiscountAmount.AMOUNT ? (
                                <CurrencyInput
                                  {...fieldProps}
                                  postfix={this.form.discountAmount ? EUR : ''}
                                  maskOptions={{ integerLimit: undefined }}
                                  disabled={!this.form.discountAmount}
                                  className={style.field__amount}
                                />
                              ) : (
                                <NumberInput
                                  {...fieldProps}
                                  postfix={this.form.discountAmount ? '%' : ''}
                                  max={100}
                                  placeholder="0"
                                  disabled={!this.form.discountAmount}
                                  className={style.field__amount}
                                />
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {step === 4 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.rules.setup" />
                      <DiscountSwitcher
                        name="noVat"
                        label={'subsidy.rules.novat'}
                        description={'subsidy.rules.novat.description'}
                        form={this.form}
                        FormControlEl={FormControl}
                      />
                      <DiscountSwitcher
                        name="paidAmountLimited"
                        label={'subsidy.rules.paidAmountLimited'}
                        description={
                          'subsidy.rules.paidAmountLimited.description'
                        }
                        form={this.form}
                        FormControlEl={FormControl}
                      />
                      <DiscountSwitcher
                        name="entireReductionAmountAvailable"
                        label={'subsidy.rules.entireReductionAmountAvailable'}
                        description={
                          'subsidy.rules.entireReductionAmountAvailable.description'
                        }
                        form={this.form}
                        FormControlEl={FormControl}
                      />
                      <DiscountSwitcher
                        name="minimumConsumption"
                        label={'subsidy.rules.minimumConsumption'}
                        form={this.form}
                        FormControlEl={FormControl}
                      />
                      <div
                        className={classNames(
                          style.input__with_label,
                          style.input__consumption,
                        )}
                      >
                        <div className={style.input__label}>
                          <FormattedMessage id={`DISCOUNT.AMOUNT.LABEL`} />
                        </div>
                        <FormControl
                          name="minimumConsumptionAmountAmount"
                          className={style.number__input}
                          render={fieldProps => (
                            <CurrencyInput
                              {...fieldProps}
                              disabled={!this.form.minimumConsumption}
                              postfix={EUR}
                              maskOptions={{ integerLimit: undefined }}
                              className={style.field__amount}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                  {step === 5 && (
                    <div className={style.form__step_item}>
                      <Title title="subsidy.title.daily.limits" />
                      <div className={style.form__group}>
                        <div className={style.form__group_description}>
                          <FormattedMessage id="subsidy.availableTime" />
                        </div>
                        <FormControl
                          name="availableTime"
                          render={props => <TimeRange expanded {...props} />}
                        />
                      </div>
                      <div className={style.form__group}>
                        <div className={style.form__group_description}>
                          <FormattedMessage id="subsidy.validityInterval" />
                        </div>
                        <FormControl
                          name="validityInterval"
                          render={props => (
                            <SelectField
                              {...props}
                              options={this.form.validityIntervals}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={style.controls}>
                <Button
                  className={style.btn}
                  styleType="ghost"
                  onClick={this.onBack}
                >
                  <FormattedMessage id="button.back" />
                </Button>
                <Button
                  disabled={this.isDisabledBtn}
                  className={classNames(style.btn, style.btn__next)}
                  styleType="primary"
                  onClick={this.onNext}
                >
                  {step === 5 ? (
                    <FormattedMessage
                      id={this.form.id ? 'button.save' : 'button.create'}
                    />
                  ) : (
                    <>
                      <FormattedMessage id={'button.next'} />
                      <Icon
                        className={style.btn__next_arrow}
                        type="arrowNext"
                      />
                    </>
                  )}
                </Button>
              </div>
            </form>
          )}
        </main>
      </Provider>
    );
  }
}

interface IDSwitcherProps {
  label: string;
  description?: string;
  FormControlEl: any;
  form: SubsidyForm;
  name: string;
}

const DiscountSwitcher = ({
  label,
  description,
  FormControlEl,
  form,
  name,
}: IDSwitcherProps) => {
  return (
    <div className={classNames(style.switcher)}>
      <div>
        <FormattedMessage id={label} />
        {description && (
          <div className={style.form__description}>
            <FormattedMessage id={description} />
          </div>
        )}
      </div>
      <FormControlEl
        name={name}
        render={btnProps => <SwitchButton {...btnProps} checked={form[name]} />}
      />
    </div>
  );
};

const Title = ({ title }: { title: string }) => {
  return (
    <div className={style.step__title}>
      <span>
        <FormattedMessage id={title} />
      </span>
      <TooltipComponent
        content={
          <span>
            <FormattedMessage id={`${title}.info`} />
          </span>
        }
      >
        <Icon className={style.step__icon} type={'helpCircle'} />
      </TooltipComponent>
    </div>
  );
};

export default SubsidyPage;
