import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import style from './SubsidiesList.module.scss';
import Table from 'components/Table/Table';
import WarningModal from 'components/Modals/Warning/WarningModal';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import SearchField from 'components/Form/Fields/Search/SearchField';
import ModalStore from 'stores/ModalStore';
import config, { bayerConfig } from './columnsConfig';
import SubsidyModal from '../../../components/Modals/Subsidy/SubsidyModal';
import SubsidiesStore from '../../../stores/Subsidies/SubsidiesStore';
import AppRouter from '../../../stores/AppRouter';
import { ROUTES } from '../../../routes/routes';

@observer
class SubsidiesList extends Component {
  store = new SubsidiesStore();

  async componentDidMount() {
    await this.store.init();
  }

  onSubsidyEdit = subsidy => async () => {
    if (this.store.isBayerSpecific) {
      AppRouter.push(ROUTES.admin.discountItem, {
        id: subsidy.id,
      });
    } else {
      await ModalStore.showModal(SubsidyModal, {
        discount: subsidy,
        userGroupsWithSubsidies: this.store.activeUserGroupsWithSubsidies,
        cbSubmit: this.store.updateSubsidy,
      });
    }
  };

  onSubsidyCreate = async () => {
    if (this.store.isBayerSpecific) {
      AppRouter.push(ROUTES.admin.discountItem);
    } else {
      await ModalStore.showModal(SubsidyModal, {
        userGroupsWithSubsidies: this.store.activeUserGroupsWithSubsidies,
        cbSubmit: data => this.store.addSubsidy(data),
      });
    }
  };

  onSubsidyDelete = id => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    await this.store.deleteSubsidy(id);
  };

  onSearch = value => {
    this.store.searchValue = value;
  };

  render() {
    const { searchValue, isBayerSpecific } = this.store;
    const columnsConfig = isBayerSpecific ? bayerConfig : config;

    return (
      <>
        <div className={style.row}>
          <Button className={style.btn} onClick={this.onSubsidyCreate}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.newSubsidy" />
          </Button>

          <SearchField
            className={style.model__search}
            onChange={this.onSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={this.store.filteredData}
          columns={columnsConfig({
            onEdit: this.onSubsidyEdit,
            onDelete: this.onSubsidyDelete,
          })}
          loading={this.store.loading}
        />
      </>
    );
  }
}

export default SubsidiesList;
