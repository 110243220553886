import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Button from 'components/Button/Button';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import SelectField from 'components/Form/Fields/Select/SelectField';
import ActionSelect from 'components/ActionSelect/ActionSelect';

import SubsidyReportForm from 'stores/Forms/Reports/SubsidyReportForm';
import RootStore from 'stores/RootStore';
import SubsidyReportStore from 'stores/Report/SubsidyReportStore';

import style from './SubsidyReport.module.scss';

interface Props {
  form: SubsidyReportForm;
  store: SubsidyReportStore;
}

@observer
class SubsidyForm extends Component<Props> {
  formControl = bindFormControl(this.props.form);

  options = [
    {
      title: 'title.pdf',
      action: () =>
        this.props.store.getPdfReport(this.props.form.requestParams),
    },
    {
      title: 'title.csv',
      action: () =>
        this.props.store.getCsvReport(this.props.form.requestParams),
    },
  ];

  @action.bound
  async getReport() {
    const { form } = this.props;

    if (form.isFormValid) {
      await this.props.store.getReport(form.requestParams);
    }
  }

  render() {
    const { form } = this.props;
    const FormControl = this.formControl;

    return (
      <form className={style.form}>
        <div className={style.form__fields}>
          <div className={style.form__field}>
            <label className={style.form__label}>
              <FormattedMessage id="title.period" />
            </label>

            <FormControl
              name="period"
              render={props => (
                <Datepicker
                  {...props}
                  styles={{
                    calendar: style.datepicker,
                    button: style.datepicker__btn,
                  }}
                  value={form.period}
                />
              )}
            />
          </div>

          <FormControl
            name="companyId"
            className={style.form__field}
            render={props => (
              <SelectField
                {...props}
                options={[
                  {
                    value: '',
                    label: RootStore.localization.formatMessage('title.none'),
                  },
                  ...form.companiesValues,
                ]}
              />
            )}
          />

          <FormControl
            name="userGroupId"
            className={style.form__field}
            render={props => (
              <SelectField
                {...props}
                options={[
                  {
                    value: '',
                    label: RootStore.localization.formatMessage('title.all'),
                  },
                  ...form.userGroupsValues,
                ]}
              />
            )}
          />
        </div>

        <div className={style.form__reportControls}>
          <Button
            className={style.button}
            onClick={this.getReport}
            disabled={!form.isFormValid}
          >
            <FormattedMessage id="button.generate.report" />
          </Button>

          <ActionSelect title="title.export.report" options={this.options} />
        </div>
      </form>
    );
  }
}

export default SubsidyForm;
