import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './QRScanner.module.scss';
import Button from '../../../Button/Button';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';

@observer
class QRScanner extends Component<{
  scanning: boolean;
  scanQR: (value: string) => void;
  onClose: () => void;
}> {
  onScanQR = async (err, result) => {
    if (result && !this.props.scanning) {
      await this.props.scanQR(result.text);
    }
  };

  render() {
    const { scanning, onClose } = this.props;

    return (
      <div className={style.body}>
        <div className={style.content}>
          <p className={style.text}>
            <FormattedMessage id={'modal.online.orders.qr.text'} />
          </p>
          <BarcodeScannerComponent
            width={500}
            height={500}
            onUpdate={this.onScanQR}
          />
          {scanning && (
            <p className={style.text__search}>
              <FormattedMessage id={'modal.online.orders.qr.search'} />
            </p>
          )}
        </div>

        <Button styleType="ghost" onClick={onClose} className={style.btn}>
          <FormattedMessage id="button.cancel" />
        </Button>
      </div>
    );
  }
}

export default QRScanner;
