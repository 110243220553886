import { getFormattedDate } from 'helpers/datetime';

export const createStickerContent = (orderItem, titles) => {
  const printedElement = document.getElementById('print');
  printedElement!.innerHTML = `
    <p class="onlineOrders__print__date">${getFormattedDate(
      orderItem.deliveryDate,
    )}</p>
     <p class="onlineOrders__print__nickname">${orderItem?.nickname || ''}</p>
     <div class="onlineOrders__print__info">
       <div class="onlineOrders__print__info_content">
          <p class="onlineOrders__print__info_item">${
            titles.queueNumberTitle
          }</p>
          <p class="onlineOrders__print__info_item">${
            titles.deliveryLocationTitle
          }</p>
       </div>
     </div>`;

  orderItem.items.forEach(item => {
    printedElement!.insertAdjacentHTML(
      'beforeend',
      `
                <div key={item.tag}>
                 <span>${item.amount}x&nbsp;</span><span>${item.title}</span>
                </div>`,
    );
  });
  printedElement!.className = 'onlineOrders__print__sticker';
};
