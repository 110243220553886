import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import Button from 'components/Button/Button';

import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  code: string;
  title?: string;
  info?: JSX.Element;
}

@observer
class QRModal extends Component<Props> {
  render() {
    const { code, title, info, ...props } = this.props;

    return (
      <Modal
        {...props}
        className={'qr__modal'}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <h3 className={'qr__title'}>
          <FormattedMessage id={title || 'modal.qr.title'} />
        </h3>

        <div className={'qr__body'}>
          {info && <div className={'qr__body_info'}>{info}</div>}

          <div className={'qr__code_wrapper'}>
            <img src={code} alt="qr-code" className={'qr__code'} />
          </div>

          <Button
            styleType="ghost"
            onClick={this.props.onClose}
            className={'qr__btn'}
          >
            <FormattedMessage id="button.close" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default QRModal;
