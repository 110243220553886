import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactTable, { Column } from 'react-table';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import TableSpinner from 'components/Spinner/TableSpinner';
import SearchField from 'components/Form/Fields/Search/SearchField';
import AssignModal from 'components/Modals/Assign/AssignModal';

import ModalStore from 'stores/ModalStore';
import router from 'stores/AppRouter';
import EmployeesStore from 'stores/Employees/EmployeesStore';
import { DEFAULT_PAGE_SIZE } from 'stores/constants';

import { ROUTES } from 'routes/routes';

import style from './Employees.module.scss';

import { accountantConfig, hostessConfig } from './columnsConfig';

import { getRole, Roles } from 'helpers/roles';

@observer
class EmployeesList extends Component<WrappedComponentProps> {
  employees: EmployeesStore;
  config: Column[];

  constructor(props) {
    super(props);

    this.config = getRole(Roles.accountant) ? accountantConfig : hostessConfig;
    this.employees = new EmployeesStore();
  }

  async componentDidMount() {
    await this.employees.fetchEmployees();
  }

  onRowClick(state, rowInfo) {
    return {
      onClick(event) {
        const { target } = event;
        const isOperation = target.closest('.operations');

        if (isOperation) {
          const { id } = rowInfo.original;

          ModalStore.showModal(AssignModal, { userId: id });
        } else {
          router.push(ROUTES.employeeProfile, { id: rowInfo.original.id });
        }
      },
    };
  }

  render() {
    const { intl } = this.props;
    const {
      changeFilter,
      changeSort,
      changePageCurrent,
      searchTerm,
      data,
      loading,
      pageCount,
    } = this.employees;

    return (
      <main className={style.main}>
        <div className={style.search__bar}>
          <SearchField onChange={changeFilter} value={searchTerm} />
        </div>

        <ReactTable
          className={style.table}
          manual={true}
          pages={pageCount}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          showPageSizeOptions={false}
          data={data}
          showPagination={!!data.length}
          minRows={0}
          onSortedChange={changeSort}
          loading={loading}
          LoadingComponent={TableSpinner}
          columns={this.config}
          onPageChange={changePageCurrent}
          getTrProps={this.onRowClick}
          nextText={intl.formatMessage({ id: 'button.next' })}
          previousText={intl.formatMessage({ id: 'button.previous' })}
          pageText={intl.formatMessage({ id: 'table.pageText' })}
          ofText={intl.formatMessage({ id: 'table.of' })}
          noDataText={intl.formatMessage({ id: 'table.noDataText' })}
        />
      </main>
    );
  }
}

export default injectIntl(EmployeesList);
