import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './DatasetSamples.module.scss';

import Category from './Category/Category';

import DatasetSamplesStore from 'stores/Modals/DatasetSamplesStore';

@observer
class Categories extends Component<{ store: DatasetSamplesStore }> {
  render() {
    const { store } = this.props;

    return (
      <ul className={style.categories}>
        {store.categories.map(category => (
          <Category key={category.id} category={category} />
        ))}
      </ul>
    );
  }
}

export default Categories;
