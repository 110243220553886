import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import style from './AmountCounter.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

export interface Item {
  title: string;
  price: number;
  amount?: number;
}

@observer
class AmountCounter extends Component<{
  item: Item;
  fixedMaxAmount?: boolean;
  className?: string;
}> {
  initialAmount = this.props.item.amount;

  onReduceAmount = e => {
    e.stopPropagation();
    this.props.item.amount = Math.max(this.props.item.amount! - 1, 0);
  };

  onIncreaseAmount = e => {
    e.stopPropagation();
    this.props.item.amount = this.props.item.amount! + 1;
  };

  render() {
    const { item, fixedMaxAmount, className } = this.props;
    const isActive = Boolean(item.amount);

    const disabledIncrease =
      fixedMaxAmount && item.amount! >= this.initialAmount!;

    return (
      <div
        className={classNames(
          style.controls__wrapper,
          {
            [style.controls__active]: isActive,
          },
          className,
        )}
      >
        {!item.amount ? (
          <Button
            styleType="ghost"
            className={style.btn__choose}
            onClick={this.onIncreaseAmount}
          >
            <FormattedMessage id="button.choose" />
          </Button>
        ) : (
          <>
            <div className={style.counter}>
              <Button
                styleType="text"
                className={style.btn__counter}
                onClick={this.onReduceAmount}
              >
                <Icon type="circleMinus" />
              </Button>

              <span className={style.amount}>{item.amount}</span>

              <Button
                styleType="text"
                className={style.btn__counter}
                onClick={this.onIncreaseAmount}
                disabled={disabledIncrease}
              >
                <Icon type="circlePlus" />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AmountCounter;
