import { computed } from 'mobx';

import {
  ERROR_FIELDS_NAME_PROP,
  IS_VALID_FORM_NAME_PROP,
  VALIDATION_ERROR_NAME_PROP,
  VALIDATORS_NAME_PROP,
} from './constants';

import { getErrorPropName } from './helpers';

import { ValidationRule } from './types';

function defineComputedProperty(
  target: any,
  name: string,
  descriptor: PropertyDescriptor & ThisType<any>,
) {
  Object.defineProperty(target, name, descriptor);
  computed(target, name, descriptor);
}

export function addValidation(
  target: any,
  name: any,
  validationRule: ValidationRule,
) {
  const validateErrorPropName = getErrorPropName(name);

  if (!target.hasOwnProperty(VALIDATORS_NAME_PROP)) {
    Object.defineProperty(target, VALIDATORS_NAME_PROP, {
      configurable: true,
      enumerable: false,
      value: [],
    });
  }

  if (!target.hasOwnProperty(VALIDATION_ERROR_NAME_PROP)) {
    const descriptor = {
      configurable: true,
      enumerable: false,
      get: function getter(this: any) {
        const errorList: any = [];
        const validators = this[VALIDATORS_NAME_PROP];
        validators.forEach((validator: any) => {
          const error = validator.validateFunction(this);
          if (error) {
            errorList.push(error);
          }
        });
        return errorList;
      },
    };

    defineComputedProperty(target, VALIDATION_ERROR_NAME_PROP, descriptor);
  }

  if (!target.hasOwnProperty(IS_VALID_FORM_NAME_PROP)) {
    const descriptor = {
      configurable: true,
      enumerable: false,
      get: function getter(this: any) {
        let isValid = true;
        const validators = this[VALIDATORS_NAME_PROP];
        if (!validators.length) {
          return isValid;
        }
        validators.forEach((validator: any) => {
          const error = validator.validateFunction(this);
          if (error) {
            isValid = false;
          }
        });
        return isValid;
      },
    };

    defineComputedProperty(target, IS_VALID_FORM_NAME_PROP, descriptor);
  }

  if (!target.hasOwnProperty(validateErrorPropName)) {
    const descriptor = {
      configurable: true,
      enumerable: false,
      get: function getter(this: any) {
        const validators = this[VALIDATORS_NAME_PROP];
        const errorList: any = [];
        validators.forEach((validator: any) => {
          if (validator.fieldName === name) {
            const error = validator.validateFunction(this);
            if (error) {
              errorList.push(error);
            }
          }
        });
        return errorList;
      },
    };

    defineComputedProperty(target, validateErrorPropName, descriptor);
  }

  if (!target.hasOwnProperty(ERROR_FIELDS_NAME_PROP)) {
    const descriptor = {
      configurable: true,
      enumerable: false,
      get: function getter(this: any) {
        const validators = this[VALIDATORS_NAME_PROP];
        const errorNames: any = [];
        validators.forEach((validator: any) => {
          const error = validator.validateFunction(this);
          if (error) {
            errorNames.push(validator.fieldName);
          }
        });
        return errorNames;
      },
    };

    defineComputedProperty(target, ERROR_FIELDS_NAME_PROP, descriptor);
  }

  target[VALIDATORS_NAME_PROP].push(validationRule);
}
