import { UserGroup } from '../Staff/types';
import { DishGroupManagement } from '../Menu/types';

export const validVatRate = 700;

export interface Discount {
  details: {
    price: number;
    period: DiscountPeriod;
    limit: number;
    userGroup: UserGroup;
    dishGroups: DishGroupManagement[];
    confirmationMode?: ConfirmationDiscountMode;
  };
  id?: string;
  type?: string;
  allDishGroups?: DishGroupManagement[];
  userGroups?: UserGroup[];
}

export interface DiscountDTO {
  details: {
    price: number;
    userGroup?: { id: string };
    dishGroups?: Array<{ id: string }>;
    limit: number;
    period: DiscountPeriod;
    confirmationMode?: ConfirmationDiscountMode;
  };
  id?: string;
}

export enum DiscountPeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ConfirmationDiscountMode {
  AUTO = 'AUTO',
  MANUAL_BY_CATERER = 'MANUAL_BY_CATERER',
}
