import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { DATE_FORMAT } from 'helpers/datetime';

import style from './TimeSlots.module.scss';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Button from 'components/Button/Button';

import { BasketMode, DeliveryTimeSlot } from 'stores/UserProfile/types';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import { getTimeSlotPeriod } from 'stores/UserProfile/helpers';

interface Props {
  store?: UserProfileOrder;
}

@observer
class TimeSlots extends Component<Props> {
  setActiveTimeSlot = (slot: DeliveryTimeSlot) => () => {
    this.props.store!.setSelectedTimeSlot(slot);
  };
  onConfirm = () => this.props.store!.onConfirmTimeSlotsStep();

  render() {
    const { store } = this.props;

    return (
      <>
        <div className={style.text}>
          {!store?.isDeliveryFeatureExist && (
            <FormattedMessage
              id="modal.confirm.order.text1"
              values={{
                value: (
                  <span className={style.text__bold}>
                    <FormattedCurrency
                      value={store!.orderPreview?.finalPrice || 0}
                    />
                  </span>
                ),
              }}
              tagName="p"
            />
          )}
          <FormattedMessage
            id="modal.confirm.order.text2"
            tagName="p"
            values={{
              value: (
                <span className={style.text__bold}>
                  <FormattedMessage id="modal.confirm.order.text2.value" />
                </span>
              ),
            }}
          />

          <FormattedMessage
            id="modal.confirm.order.date.info"
            tagName="p"
            values={{
              date: (
                <span className={style.text__bold}>
                  {store?.deliveryDate.toFormat(DATE_FORMAT)}
                </span>
              ),
            }}
          />
        </div>

        <ul className={style.timeSlots}>
          {store!.timeSlots.map(slot => (
            <li key={slot.id}>
              <Button
                styleType="text"
                className={classNames(style.timeSlot, {
                  [style.timeSlot__selected]: slot === store?.selectedTimeSlot,
                })}
                onClick={this.setActiveTimeSlot(slot)}
                disabled={slot.blocked || !slot.available}
              >
                {getTimeSlotPeriod(slot)}
              </Button>
            </li>
          ))}
        </ul>

        {store?.basketMode !== BasketMode.savedOrder &&
          store?.basketMode !== BasketMode.delivery && (
            <div className={style.controls}>
              <Button
                styleType="ghost"
                className={style.btn}
                onClick={() => {
                  store!.changeBasketMode(BasketMode.orderList);
                }}
              >
                <FormattedMessage id="button.back" />
              </Button>

              <Button
                className={style.btn}
                disabled={!store?.selectedTimeSlot}
                onClick={this.onConfirm}
              >
                <FormattedMessage
                  id={
                    store?.isDeliveryFeatureExist
                      ? 'button.next'
                      : 'button.confirm'
                  }
                />
              </Button>
            </div>
          )}
      </>
    );
  }
}

export default TimeSlots;
