import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './TaskStatus.module.scss';

import { TaskState } from 'stores/Recognition/types';

interface Props {
  state: TaskState;
}

const TaskStatus = ({ state }: Props) => (
  <span
    className={classNames(style.state, style.state__common, {
      [style.state__error]: state === TaskState.ERROR,
      [style.state__finished]: state === TaskState.FINISHED,
      [style.state__success]: state === TaskState.DEPLOYED,
      [style.state__created]: state === TaskState.MARKED_FOR_DEPLOY,
      [style.state__queue]: state === TaskState.QUEUED,
      [style.state__process]: state === TaskState.IN_PROCESS,
    })}
  >
    <FormattedMessage id={state} defaultMessage={state} />
  </span>
);

export { TaskStatus };
