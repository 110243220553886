import { action, observable } from 'mobx';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';

import Log from 'helpers/log';

class QRStore {
  @observable base64Code = '';

  @action.bound
  async generateQR(data: string): Promise<string> {
    try {
      this.base64Code = await QRCode.toDataURL(data, { width: 256 });
    } catch (error) {
      Log.info(error);
    }

    return this.base64Code;
  }

  @action.bound
  async saveAsPdf() {
    const doc = new JsPDF();
    const element = document.getElementById('QR-print-container');

    if (!element) {
      Log.info('Printable element not found');

      return;
    }

    const { scrollTop, scrollLeft } = document.body;

    const canvas = await html2canvas(element, {
      onclone(canvasDocument) {
        const elementContainer = canvasDocument.getElementById(
          'QR-print-container',
        );

        if (!elementContainer) {
          Log.error('Printable element not found');

          return;
        }

        elementContainer.style.visibility = 'visible';
      },
      scrollX: scrollLeft,
      scrollY: scrollTop,
      scale: 1,
    });

    const imgData = canvas.toDataURL('image/png');

    doc.addImage(imgData, 'PNG', 10, 10);
    doc.save('Sionum-QR.pdf');
  }

  @action.bound
  printAsPDF() {
    window.print();
  }
}

export default QRStore;
