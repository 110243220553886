import React from 'react';
import { Column } from 'react-table';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from '../Recognition.module.scss';

import { withArrow } from 'components/Table/helpers';
import Button from 'components/Button/Button';
import FloatMenu from 'components/FloatMenu/FloatMenu';

import { DATE_TIME_FORMAT, getFormattedDateTime } from 'helpers/datetime';

import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';

const config = ({
  onDelete,
  onUpdate,
  onDefaultChange,
  onLabellingChange,
  onNewDeployment,
  onShowModelInfo,
}): Column[] => [
  {
    Header: withArrow('title.dataset.used'),
    resizable: true,
    minWidth: 200,
    accessor: 'dataset.name',
    Cell: row => (
      <Link
        className={classNames(style.dataset__link, style.name)}
        to={replaceUrlParams(ROUTES.admin.dataset, {
          id: row.original.dataset?.id,
        })}
        title={row.value}
      >
        {row.value}
      </Link>
    ),
  },
  {
    Header: withArrow('title.architecture.name'),
    resizable: true,
    minWidth: 200,
    accessor: 'architecture.name',
    Cell: row => (
      <div className={style.name} onClick={onShowModelInfo(row.original)}>
        {row.value}
      </div>
    ),
  },
  {
    Header: withArrow('title.comment'),
    resizable: true,
    accessor: 'description',
    minWidth: 200,
    Cell: row => (
      <Button
        styleType="text"
        onClick={onUpdate(row.original)}
        className={style.btn__description}
      >
        {row.value}
      </Button>
    ),
  },
  {
    Header: withArrow('title.dateRange'),
    accessor: 'createdDate',
    width: 150,
    resizable: false,
    Cell: row => getFormattedDateTime(row.value, DATE_TIME_FORMAT),
  },
  {
    Header: withArrow('title.labelling'),
    accessor: 'labelling',
    width: 100,
    className: style.cell__center,
    resizable: false,
    Cell: row => (
      <Button
        styleType="text"
        className={classNames(
          row.value ? style.state__good : style.state__common,
          style.btn__switcher,
        )}
        onClick={onLabellingChange(row.original)}
      />
    ),
  },
  {
    Header: withArrow('title.active'),
    accessor: 'inferenceDefault',
    width: 100,
    className: style.cell__center,
    resizable: false,
    Cell: row => (
      <Button
        styleType="text"
        className={classNames(
          row.value ? style.state__good : style.state__common,
          style.btn__switcher,
        )}
        onClick={onDefaultChange(row.original)}
      />
    ),
  },
  {
    Header: withArrow('title.deployments'),
    className: style.cell__center,
    width: 120,
    accessor: 'deployments',
    resizable: false,
    Cell: row => (
      <span className={style.name}>
        {row?.value
          ? `${row.value?.active ?? 0}/${row.value?.total ?? 0}`
          : '0/0'}
      </span>
    ),
  },
  {
    id: 'operations',
    resizable: false,
    width: 50,
    sortable: false,
    className: 'operations',
    Cell: row => {
      const options = [
        <Button
          styleType="text"
          onClick={onNewDeployment(row.original)}
          className={style.action}
          key={row.original.id}
        >
          <FormattedMessage id="button.new.deployment" />
        </Button>,
        <Button
          styleType="text"
          onClick={onUpdate(row.original)}
          className={style.action}
          key={row.original.id}
        >
          <FormattedMessage id="button.edit" />
        </Button>,
      ];

      if (!row.original.inferenceDefault) {
        options.push(
          <Button
            styleType="text"
            onClick={onDelete(row.original)}
            className={style.action}
            key={row.original.id}
          >
            <FormattedMessage id="button.delete" />
          </Button>,
        );
      }

      return <FloatMenu options={options} />;
    },
  },
];

export default config;
