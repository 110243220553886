import { observable, action, computed } from 'mobx';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

import { BaseFormModel, isRequired, displayName } from 'stores/BaseForm';
import {
  TaskType,
  CreateTaskReqParams,
  IDataset,
} from 'stores/Recognition/types';
import AppRouter from 'stores/AppRouter';
import { ITag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { sortByAccessor } from 'helpers/accessors';

export const OptionsLimits = {
  numEpochStage1Min: 50,
  numEpochStage1Max: 100,
  numEpochStage2Min: 50,
  numEpochStage2Max: 150,
  maxNumClassesMin: 50,
  maxNumClassesMax: 300,
  step: 10,
};

interface TaskParams {
  datasetIds: string[];
  numEpochStage1: number;
  numEpochStage2: number;
  maxNumClasses: number;
}

class TaskClassifierForm extends BaseFormModel {
  type: TaskType.TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4;

  @observable
  selectedDatasets: ITag[] = [];

  @observable
  @displayName('DESCRIPTION*')
  @isRequired()
  description: string;

  @observable
  @isRequired()
  numEpochStage1 = 50;

  @observable
  @isRequired()
  numEpochStage2 = 50;

  @observable
  @isRequired()
  maxNumClasses = 50;

  @observable loading = false;

  @observable datasets: IDataset[] = [];

  @computed
  get datasetsOptions() {
    return this.datasets.map(it => ({
      value: it.id,
      label: it.name,
    }));
  }

  @computed
  get datasetIds() {
    return this.selectedDatasets.map(dataset => dataset.id);
  }

  @computed
  get datasetTags(): ITag[] {
    return this.datasets
      .map(dataset => ({
        id: dataset.id,
        title: dataset.name,
        date: dataset.createdDate,
      }))
      .sort(sortByAccessor({ desc: true, accessor: 'date' }));
  }

  constructor(type: TaskType.TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4) {
    super();

    this.type = type;
  }

  @computed
  get requestParams(): CreateTaskReqParams<TaskParams> {
    return {
      type: this.type,
      description: this.description,
      params: {
        datasetIds: this.datasetIds,
        numEpochStage1: Number(this.numEpochStage1),
        numEpochStage2: Number(this.numEpochStage2),
        maxNumClasses: Number(this.maxNumClasses),
      },
    };
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const { data } = await httpFacade.recognition.fetchDatasets();

      this.datasets = data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onCreate() {
    try {
      this.loading = true;

      await httpFacade.recognition.createTask(this.requestParams);

      AppRouter.goBack();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setDatasets(tags: ITag[]) {
    this.selectedDatasets = tags;
  }

  @action.bound
  removeDataset(id?: string) {
    this.selectedDatasets = id
      ? this.selectedDatasets.filter(it => it.id !== id)
      : [];
  }
}

export default TaskClassifierForm;
