import { Fill, Stroke, Style, Text } from 'ol/style';

const text = new Text({
  font: '16px Calibri,sans-serif',
  fill: new Fill({
    color: '#000',
  }),
  overflow: true,
  stroke: new Stroke({
    color: '#fff',
    width: 4,
  }),
  text: '',
});

export const selectedStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  }),
  stroke: new Stroke({
    color: '#98C11D',
    width: 2,
  }),
  text,
});

export const defaultStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  }),
  stroke: new Stroke({
    color: '#2E384D',
    width: 2,
  }),
  text,
});

export const errorStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  }),
  stroke: new Stroke({
    color: '#F31C02',
    width: 2,
  }),
  text,
});
