import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './MenuConfiguration.module.scss';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import Allergens from './Allergens/Allergens';
import BaseSpecifications from './BaseSpecifications/BaseSpecifications';

import { SpecificationType } from 'stores/Menu/types';
import ProfileMenuStore from 'stores/UserProfile/ProfileMenuStore';

interface Props {
  menu: ProfileMenuStore;
}

@observer
class MenuSpecsList extends Component<Props> {
  handleSetActiveSpecCategory = (name: string) => {
    this.props.menu.setActiveSpecCategory(name || '');
  };

  render() {
    const { menu } = this.props;
    const { availableSpecs } = this.props.menu;

    return (
      <ul className={classNames(style.specs__list)}>
        {availableSpecs.map(spec => (
          <li
            key={spec.id}
            className={classNames(
              style.spec,
              menu.activeSpecCategory.includes(spec.name) && style.spec__active,
            )}
          >
            <div className={style.spec__title}>
              <div className={style.spec__btn__wrapper}>
                <Button
                  id={spec.name}
                  styleType="text"
                  onClick={() =>
                    this.handleSetActiveSpecCategory(spec.name || '')
                  }
                  className={style.spec__btn}
                >
                  <Icon
                    type={
                      menu.activeSpecCategory.includes(spec.name)
                        ? 'arrowBottom'
                        : 'arrowRight'
                    }
                  />
                </Button>

                <label htmlFor={spec.name}>
                  <FormattedMessage id={spec.name} />
                </label>
              </div>

              <div className={style.quantity__selected}>
                {spec.name === SpecificationType.ALLERGENS
                  ? menu.filteredPreferences?.allergens?.length || 0
                  : menu.filteredPreferences?.specifications?.filter(
                      item => item.category === spec.name,
                    ).length || 0}
              </div>
            </div>
            <ul
              className={classNames(
                style.filters__list,
                menu.activeSpecCategory.includes(spec.name) &&
                  style.filters__show,
              )}
            >
              {spec.name === SpecificationType.ALLERGENS && (
                <Allergens menu={menu} />
              )}
              {menu.baseSpecifications.map(baseSpec => {
                return (
                  baseSpec.name === spec.name && (
                    <BaseSpecifications
                      baseSpecification={baseSpec}
                      menu={menu}
                      specName={spec.name}
                      key={baseSpec.id}
                    />
                  )
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    );
  }
}

export default MenuSpecsList;
