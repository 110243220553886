export type GetPromiseProps<T> = T extends Promise<infer P> ? P : never;

export function invertPromise<T>(promise: Promise<T>): Promise<T> {
  return new Promise((resolve, reject) => promise.then(reject, resolve));
}

export function raceToSuccess<T>(promises: Array<Promise<T>>): Promise<T> {
  return invertPromise(Promise.all(promises.map(invertPromise))) as any;
}

export function raceExToSuccess<
  T extends Record<K, Promise<any>>,
  K extends keyof T
>(promises: T): Promise<Record<K, GetPromiseProps<T[K]> | undefined>> {
  return invertPromise(
    Promise.all(
      Object.keys(promises)
        .map(key => {
          return promises[key].then(v => {
            return { [key]: v };
          });
        })
        .map(invertPromise),
    ) as any,
  );
}

export function delay(milliseconds: number) {
  return new Promise(resolve => setTimeout(() => resolve(), milliseconds));
}
