import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { computed, observable } from 'mobx';

import style from './FormControl.module.scss';

import { Omit } from 'helpers/types';

import { BaseFormModel } from 'stores/BaseForm/BaseFormModel';
import { ValidationResult } from 'stores/BaseForm/types';

import FormControlError from './FormControlError';

type Validation = 'change' | 'blur';

interface Props<T> {
  className?: string;
  errorClass?: string;
  name: string;
  form: BaseFormModel;
  render: (
    props: {
      className?: string;
      name: string;
      value: T;
      onChange: (value: T) => void;
    },
    validateFn?: () => void,
  ) => React.ReactElement;
  validateOn?: Validation;
}

const defaultValidationOn: Validation = 'change';

@observer
class FormControl<T = any> extends Component<Props<T>> {
  @observable invalid = false;
  @observable dirty = false;

  @computed
  get errors(): ValidationResult[] {
    const { form, name } = this.props;

    return this.dirty && this.invalid ? form.errorFor(name) : [];
  }

  onChange = value => {
    this.props.form.setValue(this.props.name, value);
    if (this.props.validateOn === 'change') {
      this.validate();
    }
  };

  onBlur = () => {
    if (this.props.validateOn === 'blur') {
      this.validate();
    }
  };

  validate = () => {
    const { name, form } = this.props;

    this.dirty = true;
    this.invalid = !form.isValid(name);
  };

  render() {
    const { className, errorClass, name, form, render } = this.props;
    const value = form[name];
    const error = this.errors[0];

    const renderProps = {
      value,
      name,
      onChange: this.onChange,
      onBlur: this.onBlur,
      invalid: Boolean(error),
    };

    return (
      <div className={classNames(style.form__field, className)}>
        {form.displayName(name) && (
          <label className={style.label} htmlFor={name}>
            <FormattedMessage
              id={form.displayName(name)}
              defaultMessage={name}
            />
          </label>
        )}

        {render(renderProps, this.validate)}

        {this.dirty && this.invalid && error?.message && (
          <div className={classNames(style.error, errorClass)}>
            <FormControlError error={error} />
          </div>
        )}
      </div>
    );
  }
}

const bindFormControl = (form: BaseFormModel) => <T extends unknown = any>(
  props: Omit<Props<T>, 'form'>,
) => <FormControl form={form} validateOn={defaultValidationOn} {...props} />;

export { FormControl, bindFormControl };
