import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { UserReport } from 'stores/UserProfile/UserReport';
import ProfileReport from 'stores/EmployeeProfile/ProfileReport';

import style from './Transactions.module.scss';

import Spinner from 'components/Spinner/Spinner';
import Transaction from './Transaction/Transaction';

interface Props {
  report: UserReport | ProfileReport;
  className?: string;
}

@observer
class Transactions extends Component<Props> {
  render() {
    const { report, className } = this.props;
    const { transactions, loading } = report!;
    const isEmpty = !Boolean(transactions.length);

    return (
      <section
        className={classNames(style.section, className, {
          [style.section__empty]: isEmpty,
        })}
      >
        {loading && <Spinner contain />}

        {isEmpty ? (
          <FormattedMessage id="employeeProfile.transactionsList.emptyMessage" />
        ) : (
          <ul className={style.transactions}>
            {transactions.map((tr, i) => (
              <Transaction key={i} userId={report!.id} {...tr} />
            ))}
          </ul>
        )}
      </section>
    );
  }
}

export default Transactions;
