import React from 'react';
import { Link } from 'react-router-dom';

import style from './AppLogo.module.scss';

import RootStore from 'stores/RootStore';
import classNames from 'classnames';
import { AppLogoTypes } from './types';

interface Props {
  type?: 'general' | 'header' | 'registration' | 'registration-mobile';
  styles?: {
    link?: string;
    image?: string;
  };
}

const AppLogo = (props: Props) => {
  const { type, styles } = props;
  const logoPath = () => {
    switch (type) {
      case AppLogoTypes.HEADER:
        return '/header-logo.png';
      case AppLogoTypes.REGISTRATION:
        return '/registration-success-logo.png';
      case AppLogoTypes.REGISTRATION_MOBILE:
        return '/registration-success-logo-mobile.png';
      default:
        return '/logo.png';
    }
  };

  return (
    <Link
      to="/"
      className={classNames(styles?.link, style.link)}
      onClick={RootStore.resetErrors}
    >
      <img
        src={process.env.PUBLIC_URL + logoPath()}
        className={classNames(styles?.image, style.image)}
        alt="logo"
      />
    </Link>
  );
};

export default AppLogo;
