import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';

import style from './Scanners.module.scss';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import DotsSpinner from 'components/Spinner/DotsSpinner';

import { ScanStatus } from 'stores/Scanners/types';
import TagScanner from 'stores/Scanners/TagScanner';
import { ModalProps } from 'stores/ModalStore';

@observer
class TagScanModal extends Component<ModalProps> {
  scanner = new TagScanner();

  async componentDidMount() {
    await this.scanner.scan();
  }

  async componentWillUnmount() {
    await this.scanner.unsubscribe();
  }

  getScanStatus(status: ScanStatus) {
    const { scanner } = this;

    switch (status) {
      case ScanStatus.ERROR:
        return (
          <Button className={style.btn} onClick={scanner.scan}>
            <FormattedMessage id="button.try.again" />
          </Button>
        );
      case ScanStatus.REDIRECTING:
        return (
          <div className={style.statusText}>
            <FormattedMessage id="modal.scan.status.redirecting" />
          </div>
        );
      default:
        return (
          <div className={style.spinner__wrapper}>
            <DotsSpinner />
          </div>
        );
    }
  }

  render() {
    const { scanner } = this;
    const description =
      scanner.status === ScanStatus.ERROR
        ? 'modal.scan.message.error.tagNotFound'
        : 'modal.scan.tag.description';

    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div className={style.content}>
          <h3 className={style.title}>
            <FormattedMessage id="modal.scan.title" />
          </h3>

          <div className={style.iconWrapper}>
            <Icon type="scanningTag" />
          </div>

          <p className={style.description}>
            <FormattedMessage id={description} />
          </p>

          <div className={style.status}>
            {this.getScanStatus(scanner.status)}
          </div>
        </div>
      </Modal>
    );
  }
}

export default TagScanModal;
