import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';

import './polyfill';

import router from 'stores/AppRouter';

import App from './App';

import 'styles/main.scss';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router history={router.synchronizedHistory}>
    <App />
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
