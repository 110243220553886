import { toast } from 'react-toastify';

export type TOAST_TYPES = keyof typeof toast.TYPE;

const basicConfig = {
  autoClose: 5000,
  draggable: false,
  pauseOnHover: false,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
};

class Popup {
  dismiss() {
    toast.dismiss();
  }

  notify(message: string, type: TOAST_TYPES = 'ERROR', config = basicConfig) {
    toast[type.toLocaleLowerCase()](message, config);
  }
}

export const popup = new Popup();
