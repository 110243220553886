import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import { Staff } from './types';

import Log from 'helpers/log';

import ModalStore from '../ModalStore';

import WarningModal from 'components/Modals/Warning/WarningModal';

class StaffStore {
  @observable data: Staff[] = [];
  @observable searchValue = '';

  @observable loading = false;

  @computed
  get filteredStaff() {
    const value = this.searchValue.toLocaleLowerCase();

    return this.searchValue
      ? this.data.filter(
          item =>
            item.firstName.toLocaleLowerCase().includes(value) ||
            item.lastName.toLocaleLowerCase().includes(value) ||
            item.email.toLocaleLowerCase().includes(value),
        )
      : this.data;
  }

  @action.bound
  async fetchStaff() {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.fetchStaff();

      this.data = data.content;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteStaff(id: string) {
    try {
      await ModalStore.showModal(WarningModal, {
        title: 'modal.warning.title.delete',
        description: 'modal.warning.delete.item',
      });

      this.loading = true;

      await httpFacade.staff.deleteStaff(id);

      this.data = this.data.filter(staff => staff.id !== id);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateStaff(payload: Staff) {
    const staff = this.data.find(item => item.id === payload.id);

    this.data = staff
      ? this.data.map(item => (item.id === payload.id ? payload : item))
      : [...this.data, payload];
  }
}

export default StaffStore;
