import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ModalProps } from 'stores/ModalStore';
import Modal from 'rc-dialog';
import style from './InfoMessageModal.module.scss';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button/Button';

interface Props extends ModalProps {
  message: string;
}

@observer
class InfoMessageModal extends Component<Props> {
  render() {
    const { message, onClose } = this.props;

    return (
      <Modal
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={onClose}
        visible
      >
        <div className={style.text}>
          <FormattedMessage id={message} />
        </div>

        <div className={style.buttons}>
          <Button className={style.btn} styleType="primary" onClick={onClose}>
            <FormattedMessage id="button.ok" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default InfoMessageModal;
