import http from '../http';

import SETTINGS from 'settings';
import URLS from '../urls';
import { api } from '../helpers';

interface FetchDefaultConfigResponse {
  ui: {
    nfc: string;
    rfid: string;
  };
}
interface FetchConfigResponse {
  id: string;
  paramKey: string;
  paramValue: string;
}

export const rootApi = {
  fetchConfig() {
    return http.get<FetchConfigResponse[]>(api(URLS.config));
  },

  fetchDefaultConfig() {
    return http.get<FetchDefaultConfigResponse>(
      `${SETTINGS.publicURL}/config.json`,
    );
  },

  fetchLocalization(locale) {
    return http.get<Record<string, string>>(URLS.locale, {
      replacePathParams: { locale },
    });
  },
};
