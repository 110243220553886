import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { CopyMenuItemsForm } from 'stores/Menu/MenuCopy/CopyMenuItemsForm';
import { weekDayOptions } from 'stores/Menu/MenuCopy/helpers';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import { MultiSelectField } from 'components/MultiSelect/MultiSelectField';
import { MultiSelectOptions } from 'components/MultiSelect/MultiSelectOptions';
import { MultiSelectContainer } from 'components/MultiSelect/MultiSelectContainer';
import { MenuItemsSearch } from 'components/MenuItemsSearch/MenuItemsSearch';
import { PopoverSelect } from 'components/PopoverSelect/PopoverSelect';
import SelectField from 'components/Form/Fields/Select/SelectField';
import Button from 'components/Button/Button';

import style from 'pages/MenuPlanner/CopyMenu/CopyMenu.module.scss';

interface IProps {
  form: CopyMenuItemsForm;
  onSubmit: () => void;
  disabled: boolean;
}

@observer
class CopyMenuItemsComponent extends Component<IProps> {
  async componentDidMount() {
    await this.props.form.init();
  }

  render() {
    const { form, onSubmit, disabled } = this.props;
    const additionalOptions = [
      {
        id: 'menu.copy.everyDay',
        label: 'menu.copy.everyDay',
        onClick: form.clearSelectedOptions,
      },
      {
        id: 'menu.copy.selectWeekdays',
        label: 'menu.copy.selectWeekdays',
        onClick: form.selectWeekdays,
      },
    ];

    return (
      <>
        <div className={style.formContentLine}>
          <div className={style.controlWrapper}>
            <div className={style.controlLabel}>
              <FormattedMessage id="menu.copy.label.menuItems" />
            </div>
            <div className={style.menuItemsSelect}>
              <PopoverSelect
                value={form.selectedMenuItems
                  .map(item => item.title)
                  .join(', ')}
              >
                <MenuItemsSearch
                  onSearch={form.setSearchValue}
                  searchValue={form.searchValue}
                  categories={form.categories}
                  isLoading={form.isLoading}
                  toggleItem={form.toggleItem}
                  selectedItemsIds={form.selectedMenuItemsIds}
                  className={style.menuItemsPopover}
                />
              </PopoverSelect>
            </div>
          </div>

          <div className={style.controlWrapper}>
            <div className={style.controlLabel}>
              <FormattedMessage id="menu.copy.label.toPeriod" />
            </div>
            <Datepicker
              value={form.periodTo}
              onChange={form.changePeriodTo}
              selectRange={true}
              showPeriods={false}
              icon={true}
              styles={{
                calendar: style.datepicker,
                button: style.datepickerButton,
                icon: style.datepickerIcon,
              }}
            />
          </div>

          <div className={style.controlWrapper}>
            <div className={style.controlLabel}>
              <FormattedMessage id="menu.copy.label.menuLine" />
            </div>
            <SelectField
              value={form.selectedMenuLineId}
              onChange={form.setSelectedMenuLine}
              options={form.menuLinesOptions}
              className={style.menuLineSelect}
            />
          </div>
        </div>

        <div className={style.formContentLine}>
          <div className={style.controlWrapper}>
            <div className={style.controlLabel}>
              <FormattedMessage id="menu.copy.label.copy" />
            </div>
            <MultiSelectContainer
              context={{
                options: weekDayOptions,
                additionalOptions,
                selectedOptionIds: form.selectedOptionIds,
                toggleOption: form.toggleOption,
              }}
            >
              <MultiSelectField
                className={classNames(
                  style.multiSelect,
                  style.multiSelectField,
                )}
              />
              <MultiSelectOptions />
            </MultiSelectContainer>
          </div>

          <Button
            onClick={onSubmit}
            className={style.btnCopy}
            disabled={form.isCopyDisabled || disabled}
          >
            <FormattedMessage id="button.copy" />
          </Button>
        </div>
      </>
    );
  }
}

export { CopyMenuItemsComponent };
