import React from 'react';
import classNames from 'classnames';

import style from './Tag.module.scss';

import Icon from 'components/Icon/Icon';

export interface AppTag {
  value: string;
  messageId: string;
  status: TagStatus;
}

export enum TagStatus {
  default = 'default',
  available = 'available',
  error = 'error',
}

export enum TagMessage {
  notAssigned = 'tag.status.notAssigned',
  available = 'tag.status.available',
  manyTagsFound = 'tag.status.manyTagsFound',
  notFound = 'tag.status.notFound',
  hardwareError = 'tag.status.hardwareConnectionFailure',
}

export interface TagProps {
  type: 'nfc' | 'rfid';
  status?: TagStatus;
  border?: boolean;
  className?: string;
}

const Tag = (props: TagProps) => {
  const {
    type,
    status = TagStatus.default,
    className = '',
    border = true,
  } = props;

  return (
    <div
      className={classNames(style.tag, className, {
        [style.tag__available]: status === TagStatus.available,
        [style.tag__error]: status === TagStatus.error,
        [style.tag__border]: border,
      })}
    >
      <Icon type={type} />
    </div>
  );
};

export default Tag;
