import axios, { AxiosError } from 'axios';

import RootStore from 'stores/RootStore';
import router from 'stores/AppRouter';
import ModalStore from 'stores/ModalStore';
import { popup } from 'stores/Popup';
import { ILodData, LogService } from 'stores/Services/LogService';

import { ROUTES } from 'routes/routes';

import { api, getHttpError, isAuthTokenExpired } from './helpers';
import URLS from 'http/urls';
import { ResponseErrorConfig } from './types';
import { TokensResponse } from './Api/User/types';

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (
  error: AxiosError | null,
  token: TokensResponse | null = null,
): void => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export async function updateAuthInterceptor(error: AxiosError | any) {
  const { refreshTokens, getAuthTokens } = RootStore.user;
  const { refreshToken } = getAuthTokens();
  const { response } = error;
  const originalRequest = error.config;

  if (
    refreshToken &&
    response &&
    response.status === 401 &&
    response.data &&
    !originalRequest._retry
  ) {
    const { errors } = response.data;

    if (Array.isArray(errors) && errors.length && isAuthTokenExpired(errors)) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token: TokensResponse | any) => {
            originalRequest.headers.Authorization =
              'Bearer ' + token.access_token;

            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const data = await refreshTokens(refreshToken);
        setAuthInterceptor(response.config);
        processQueue(null, data);

        return axios.request(originalRequest);
      } catch (err) {
        ModalStore.closeAllModal();
        RootStore.user.logout();
        router.push(ROUTES.login);
        processQueue(err, null);

        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }
  }

  return Promise.reject(error);
}

export function unauthorizedInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status === 401) {
    ModalStore.closeAllModal();
    RootStore.user.logout();
  } else {
    return Promise.reject(error);
  }
}

export function internalServerErrorInterceptor(error: AxiosError) {
  const { response } = error;

  if (response && response.status >= 500) {
    ModalStore.closeAllModal();
    RootStore.serverError = true;
  } else {
    return Promise.reject(error);
  }
}

export function setAuthInterceptor(config) {
  const { user } = RootStore;
  const tokens = user.getAuthTokens();

  if (tokens.accessToken) {
    config.headers.Authorization = `Bearer ${tokens.accessToken}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
}

export async function httpErrorHandler(error: ResponseErrorConfig) {
  if (error.config?.skipHttpErrorHandler) {
    return Promise.reject(error);
  }

  const { id, params } = getHttpError(error);

  if (error.response?.status === 404) {
    RootStore.notFoundError = true;
  } else {
    const errorMessage = RootStore.localization.formatMessage(id, params);
    const url = error.response?.config.url;

    if (url !== api(URLS.log.error)) {
      const errorData: ILodData = {
        errorMessage,
        errorMessageId: id,
        statusCode: error.response?.status,
        url,
      };

      LogService.error(errorData);
      popup.notify(errorMessage);
    }
  }

  return Promise.reject(error);
}
