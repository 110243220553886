import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import ModelsStore from 'stores/Recognition/ModelsStore';
import ModalStore from 'stores/ModalStore';
import { MLModelWithDeployments } from 'stores/Recognition/types';

import Table from 'components/Table/Table';
import WarningModal from 'components/Modals/Warning/WarningModal';
import DescriptionModal from 'components/Modals/Description/DescriptionModal';

import config from './columnsConfig';
import NewDeploymentModal from '../../../components/Modals/NewDeployment/NewDeploymentModal';
import ModelInfoModal from '../../../components/Modals/ModelInfo/ModelInfoModal';

@observer
class ModelsPage extends Component {
  store = new ModelsStore();

  async componentDidMount() {
    await this.store.fetchModels();
  }

  deleteModel = model => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });
    await this.store.deleteModel(model);
  };

  updateModel = model => async () => {
    const { payload } = await ModalStore.showModal(DescriptionModal, {
      title: (
        <FormattedMessage
          id="modal.description.title"
          values={{
            name: model.modelName,
          }}
        />
      ),
      label: 'title.comment',
      initialDescription: model.description,
    });

    await this.store.updateModel(model.id, model.version, payload);
  };

  onLabelingEnable = model => async () => {
    await this.store.enableLabelingModel(model);
  };

  onDefaultEnable = model => async () => {
    await this.store.enableDefaultModel(model);
  };

  onShowModelInfo = model => async () => {
    this.store.setShowedModel(model.id);
    ModalStore.showModal(ModelInfoModal, {
      store: this.store,
    });
  };

  onNewDeployment = (model: MLModelWithDeployments) => async () => {
    await ModalStore.showModal(NewDeploymentModal, {
      model,
    });
  };

  render() {
    return (
      <Table
        data={this.store.models}
        columns={config({
          onDelete: this.deleteModel,
          onUpdate: this.updateModel,
          onDefaultChange: this.onDefaultEnable,
          onLabellingChange: this.onLabelingEnable,
          onNewDeployment: this.onNewDeployment,
          onShowModelInfo: this.onShowModelInfo,
        })}
        loading={this.store.loading}
        defaultSorted={[
          {
            id: 'createdDate',
            desc: true,
          },
        ]}
      />
    );
  }
}

export default ModelsPage;
