import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon/Icon';

import style from './TaskForm.module.scss';

const TaskInfo: FunctionComponent<{ info: string }> = ({ info }) => (
  <div className={style.task__info}>
    <Icon type="iconInfo" className={style.icon__info} />

    <p>
      <FormattedMessage id={info} />
    </p>
  </div>
);

export default TaskInfo;
