import React, { Component } from 'react';
import classNames from 'classnames';
import { uniqueId } from 'helpers/uniqueId';

import { OrderItemModel } from 'stores/UserProfile/types';

import style from 'components/OrderItemsList/OrderItemsList.module.scss';

interface Props {
  items: OrderItemModel[];
  styles?: {
    list?: string;
    item?: string;
  };
  recent: boolean;
}

class OrderItemsList extends Component<Props> {
  render() {
    const { items, styles, recent } = this.props;

    return (
      <ul
        className={classNames(
          style.items,
          styles?.list,
          recent ? style.recentOrder : '',
        )}
      >
        {items.map?.((item: OrderItemModel) => (
          <li key={uniqueId()} className={classNames(style.item, styles?.item)}>
            <span className={style.item__amount}>{item.amount}x</span>
            <span className={style.item__title}>{item.title}</span>
          </li>
        ))}
      </ul>
    );
  }
}

export default OrderItemsList;
