import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { RouteChildrenProps } from 'react-router';

import style from './DatasetPage.module.scss';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Spinner from 'components/Spinner/Spinner';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import DatasetModal from 'components/Modals/Dataset/DatasetModal';
import StatisticItem from './StatisticItem/StatisticItem';
import Label from './Label/Label';
import FileLoader from './Fileloader/Fileloader';
import Controls from './Controls/Controls';
import Gallery from './Gallery/Gallery';

import { ROUTES } from 'routes/routes';

import DatasetStore, { MISSING__LABELS } from 'stores/Recognition/DatasetStore';
import ModalStore from 'stores/ModalStore';

import RootStore from 'stores/RootStore';

type Props = RouteChildrenProps<{
  id: string;
}>;

@observer
class DatasetPage extends Component<Props> {
  store: DatasetStore;

  constructor(props) {
    super(props);

    const id = (props.match && props.match.params.id) || '';

    this.store = new DatasetStore(id);
  }

  async componentDidMount() {
    await this.store.init();

    RootStore.sampleLoaderService.subscribe(this.store.updateSamplesAsync);
  }

  componentWillUnmount() {
    RootStore.sampleLoaderService.unsubscribe(this.store.updateSamplesAsync);
  }

  changeName = async () => {
    const { payload } = await ModalStore.showModal(DatasetModal, {
      name: this.store.dataset?.name,
    });

    await this.store.changeDatasetName(payload);
  };

  changeFilter = value => () => {
    this.store.toggleFilter(value);
  };

  render() {
    const {
      dataset,
      labels,
      missingLabels,
      unknownTags,
      loading,
      filteredSamples,
      totalSamples,
    } = this.store;

    return (
      <Provider store={this.store}>
        <main className={style.main}>
          {loading && <Spinner fullscreen />}

          <header className="admin-header--breadcrumbs">
            <Breadcrumbs
              links={[
                { to: ROUTES.admin.datasets, name: 'title.recognition' },
                { name: dataset?.name },
              ]}
            />
          </header>

          <div className={style.content}>
            <section className={style.information}>
              <div className={style.name}>
                <span className={style.dataset__name}>{dataset?.name}</span>

                <Button styleType="text" onClick={this.changeName}>
                  <Icon type="edit" className={style.icon__edit} />
                </Button>
              </div>

              <Controls />

              <FileLoader />

              <ul className={style.statistic__list}>
                <StatisticItem title="title.images" value={totalSamples} />

                <StatisticItem
                  onClick={this.changeFilter(MISSING__LABELS)}
                  title="title.missingLabels"
                  value={missingLabels}
                  isActive={this.store.filters.includes(MISSING__LABELS)}
                />
                <StatisticItem
                  title={'UNDEFINED'}
                  value={unknownTags}
                  onClick={this.changeFilter(undefined)}
                  isActive={this.store.filters.some(filter => !filter)}
                />
                <StatisticItem
                  title="title.tags"
                  value={this.store.labelsAmount}
                  className={style.labels__item}
                >
                  <ul className={style.labels}>
                    {labels?.map(label => {
                      if (label.tag) {
                        return <Label key={label.title} label={label} />;
                      }
                    })}
                  </ul>
                </StatisticItem>
              </ul>
            </section>

            <Gallery gallery={filteredSamples} />
          </div>
        </main>
      </Provider>
    );
  }
}

export default DatasetPage;
