import React, { Component } from 'react';
import Modal from 'rc-dialog';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import style from './Layout.module.scss';

import { ModalProps } from 'stores/ModalStore';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';

interface Props extends ModalProps {
  className?: string;
  loading?: boolean;
}

@observer
class SplitModalLayout extends Component<Props> {
  render() {
    const { className, children, loading, ...props } = this.props;
    const [first, second] = children;

    return (
      <Modal
        {...props}
        className={classNames(style.modal, className)}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div className={style.modal__section}>
          <Button
            styleType="text"
            className={style.btn__close}
            onClick={this.props.onClose}
          >
            <Icon type="close" className={style.icon__close} />
          </Button>

          {first}
        </div>

        <div className={style.modal__section}>{second}</div>

        {loading && <Spinner fullscreen />}
      </Modal>
    );
  }
}

export default SplitModalLayout;
