import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Omit } from 'helpers/types';

import style from './Radio.module.scss';

enum radioInputStyles {
  CIRCLE = 'circle',
  HIDDEN = 'hidden',
}

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string | number;
  label?: ReactElement;
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  radioButtonStyleType?: 'hidden' | 'circle';
}

const RadioField = (props: Props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.onChange && props.onChange(event.target.value, event);

  const {
    value = '',
    title = '',
    name = '',
    checked = false,
    className,
    label,
    radioButtonStyleType = 'hidden',
    ...rest
  } = props;

  return (
    <div
      className={classNames(
        style.radio,
        radioButtonStyleType === radioInputStyles.CIRCLE && style.radio__circle,
        className,
      )}
    >
      <input
        {...rest}
        type="radio"
        checked={checked}
        name={name}
        value={value}
        id={String(value)}
        className={classNames(
          style.input,
          radioButtonStyleType === radioInputStyles.CIRCLE &&
            style.input__circle,
        )}
        onChange={onChange}
      />

      <label
        htmlFor={String(value)}
        className={classNames(
          style.label,
          radioButtonStyleType === radioInputStyles.CIRCLE &&
            style.label__circleInput,
        )}
      >
        {label || <FormattedMessage id={title} defaultMessage={title} />}
      </label>
    </div>
  );
};

export default RadioField;
