class StorageStore {
  static subscribe(callback) {
    window.addEventListener('storage', callback);

    return StorageStore;
  }

  static unsubscribe(callback) {
    window.removeEventListener('storage', callback);

    return StorageStore;
  }
}

export default StorageStore;
