import { action, observable } from 'mobx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Log from 'helpers/log';
import { DATE_TIME_FORMAT, getFormattedDateTime } from 'helpers/datetime';

import httpFacade from 'http/httpFacade';
import { ReceiptDTO } from 'http/Api/finance';

import RootStore from 'stores/RootStore';

class ReceiptModalStore {
  @observable loading = true;

  @observable receipt: ReceiptDTO;

  private readonly userId: string;
  private readonly id: string;

  constructor(userId: string, id: string) {
    this.id = id;
    this.userId = userId;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      ({ data: this.receipt } = await httpFacade.finance.fetchReceipt(
        this.userId,
        this.id,
      ));

      this.receipt = {
        ...this.receipt,
        transactionDate: getFormattedDateTime(
          this.receipt.transactionDate,
          DATE_TIME_FORMAT,
        ),
      };
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  async download(element: HTMLElement) {
    const scrollTop = document.body.scrollTop;
    const scrollLeft = document.body.scrollLeft;

    const canvas = await html2canvas(element, {
      scrollX: scrollLeft,
      scrollY: scrollTop,
      scale: 1,
      onclone(canvasDocument) {
        const elementContainer = canvasDocument.getElementById(
          'receipt-print-container',
        );

        if (!elementContainer) {
          Log.warn('Printable element not found');
          return;
        }

        elementContainer.classList.add('print');
      },
    });
    const imgData = canvas.toDataURL('image/png');
    const fileName = RootStore.localization.formatMessage(
      this.receipt.type === 'ORDER_STORNO'
        ? 'filename.receipt.storno'
        : 'filename.receipt',
      {
        date: this.receipt.transactionDate,
      },
    );

    const doc = new jsPDF();
    const pdfHeight = doc.internal.pageSize.getHeight();
    doc.addImage(imgData, 'PNG', 10, 10, 90, pdfHeight - 20);
    doc.save(fileName);
  }
}

export default ReceiptModalStore;
