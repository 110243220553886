import { action, computed, observable } from 'mobx';

import { BaseFormModel } from '../BaseForm';
import Log from '../../helpers/log';
import httpFacade from '../../http/httpFacade';
import {
  ClusterAgentStatus,
  CreateTaskReqParams,
  DeployModelTaskParams,
  InternalBETaskType,
  MLModelWithDeployments,
} from '../Recognition/types';

interface ServingTag {
  id: string;
  title: string;
  disabled: boolean;
}
export enum NewDeploymentFormStates {
  DEFAULT = 'DEFAULT',
  SELECT_SERVING_TAG = 'SELECT_SERVING_TAG',
}

class NewDeploymentForm extends BaseFormModel {
  @observable description: string;
  @observable selectedServingTag: string = '';

  @observable formState: string = NewDeploymentFormStates.DEFAULT;
  @observable loading: boolean = false;
  @observable servingTags: ServingTag[] = [];
  @observable model: MLModelWithDeployments;

  constructor(model) {
    super();
    this.model = model;
    this.description = model?.description;
  }

  @action.bound
  changeFormState(state: string) {
    this.formState = state;
  }
  @action.bound
  selectServingTag(tag: string) {
    this.selectedServingTag = tag;
  }

  @action.bound
  async fetchServingTags() {
    try {
      this.loading = true;

      const {
        data,
      } = await httpFacade.recognition.fetchClusterDeploymentAgents();

      this.servingTags = data.reduce<ServingTag[]>(
        (acc, it) =>
          it.supportsDeployment &&
          it.healthStatus === ClusterAgentStatus.HEALTHY
            ? [
                ...acc,
                {
                  id: it.id,
                  title: it.servingTag,
                  disabled: this.model.deployments.data.some(
                    deployment => deployment.servingTag === it.servingTag,
                  ),
                },
              ]
            : acc,
        [],
      );
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @computed
  get requestParams(): CreateTaskReqParams<DeployModelTaskParams> {
    return {
      type: InternalBETaskType.DEPLOY_MODEL_TFS_V2,
      description: this.model?.description,
      params: {
        modelId: this.model.id,
        deploymentDescription: this.description,
        servingTag: this.selectedServingTag,
        forceDeploy: false,
      },
    };
  }

  @action.bound
  async onSubmit() {
    try {
      await httpFacade.recognition.createTask(this.requestParams);
    } catch (error) {
      Log.warn(error);
    }
  }
}

export default NewDeploymentForm;
