import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './style.scss';
import { OrderModel } from 'stores/UserProfile/types';
import { getTimeSlotPeriod } from 'stores/UserProfile/helpers';
import { getFormattedDate } from 'helpers/datetime';
import Icon from 'components/Icon/Icon';

@observer
class Orders extends Component<{
  orders: OrderModel[];
  onOpenOrder: (orderNumber: number) => void;
  onPrintSticker: (item: OrderModel) => void;
}> {
  render() {
    return (
      <section className={'orders__section'}>
        {this.props?.orders?.map?.(order => (
          <div
            className={'orders__wrap'}
            key={order.id}
            onClick={() => this.props.onOpenOrder(order.queueNumber)}
          >
            <div className={'orders__order__line'}>
              <div className={'orders__order__info'}>
                <p className={'orders__order__number'}>
                  <FormattedMessage
                    id="user.order.number"
                    values={{
                      number: order.queueNumber,
                    }}
                  />
                </p>
                <div className={'orders__order__info__line'}>
                  <ul>
                    {order?.items?.map?.(item => (
                      <li key={item.tag}>
                        <span className={'orders__item__amount'}>
                          {item.amount}x
                        </span>
                        <span>{item.title}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={'orders__order__info__line'}>
                  {getFormattedDate(order.deliveryDate)}
                  {order.deliveryTimeSlot &&
                    ` | ${getTimeSlotPeriod(order.deliveryTimeSlot)}`}
                </div>

                <div className={'orders__order__info__line'}>
                  {order?.deliveryLocation?.title ||
                  order?.deliveryInfo?.pointOfDelivery ? (
                    <>
                      <FormattedMessage id="user.orders.delivery.label" />
                      &nbsp;
                      {order?.deliveryLocation?.title ||
                        order?.deliveryInfo?.pointOfDelivery}
                    </>
                  ) : (
                    <FormattedMessage id="user.orders.delivery.pickup.label" />
                  )}
                </div>

                {order?.deliveryInfo && (
                  <>
                    {order?.deliveryInfo?.email && (
                      <div className={'orders__order__info__line'}>
                        <FormattedMessage id="user.orders.delivery.email.label" />
                        &nbsp;
                        {order.deliveryInfo.email}
                      </div>
                    )}
                    {order?.deliveryInfo?.phone && (
                      <div className={'orders__order__info__line'}>
                        <FormattedMessage id="user.orders.delivery.phone.label" />
                        &nbsp;
                        {order.deliveryInfo.phone}
                      </div>
                    )}
                    {order?.deliveryInfo?.comment && (
                      <div className={'orders__order__info__line'}>
                        <FormattedMessage id="user.orders.delivery.comment.label" />
                        &nbsp;
                        {order.deliveryInfo.comment}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={'orders__icons'}>
                {order.orderProcessingFinished && (
                  <Icon type="success" className={'orders__icon__delivered'} />
                )}
                <div
                  className={'orders__btn_print'}
                  onClick={e => {
                    e.stopPropagation();
                    this.props.onPrintSticker(order);
                  }}
                >
                  <Icon type="print" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    );
  }
}

export default Orders;
