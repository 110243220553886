import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';

import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isRequired,
  isNumber,
} from 'stores/BaseForm';
import ModalStore from 'stores/ModalStore';

import noop from 'helpers/noop';

import WarningModal from 'components/Modals/Warning/WarningModal';
import { hasMinLength } from '../BaseForm/validators/hasMinLength';
import { DishGroupManagement } from '../Menu/types';
import { DishGroupDTO } from '../../http/types';
import Log from '../../helpers/log';
import { getHttpError } from '../../http/helpers';

const DISHGROUP_FORDEPOSITITEMS_NOTUNIQUE_ERROR =
  'error.business.dishGroup.forDepositItems.validation.constraints.NotUnique';

export const FIELDS_LIMITS = {
  itemNumberLength: 3,
  initialItemNumber: '',
  maxTitle: 100,
  minItemNumberLength: 3,
};

class CategoryForm extends BaseFormModel {
  @observable
  @displayName('NAME')
  @isRequired()
  @hasMaxLength(FIELDS_LIMITS.maxTitle)
  title = '';

  @observable
  @displayName('VENDOR_CODE')
  @isNumber(' ')
  @hasMinLength(FIELDS_LIMITS.minItemNumberLength, ' ')
  @isRequired(' ')
  itemNumber = FIELDS_LIMITS.initialItemNumber;

  @observable
  forDepositItems = false;

  @observable
  standardMenuItems = false;

  @observable
  notVisibleKioskSelfCheckout = false;

  @observable loading = false;

  @observable readonly category: DishGroupManagement;

  constructor(category?: DishGroupManagement) {
    super();

    if (category) {
      this.category = category;
      this.title = category.title;
      this.itemNumber = category.itemNumber;
      this.forDepositItems = category.forDepositItems;
      this.standardMenuItems = category.standardMenuItems;
      this.notVisibleKioskSelfCheckout = category.notVisibleKioskSelfCheckout;
    }
  }

  @action.bound
  async submit(onFulfilled = noop) {
    if (this.validate()) {
      const data: DishGroupDTO = {
        title: this.title,
        itemNumber: this.itemNumber,
        forDepositItems: this.forDepositItems,
        standardMenuItems: this.standardMenuItems,
        notVisibleKioskSelfCheckout: this.notVisibleKioskSelfCheckout,
      };

      let response;

      try {
        if (this.category) {
          await ModalStore.showModal(WarningModal, {
            description: 'modal.warning.change.item',
            type: 'change',
          });

          this.loading = true;
          response = await httpFacade.menu.updateCategory(
            this.category.id,
            data,
          );
        } else {
          this.loading = true;
          response = await httpFacade.menu.createCategory(data);
        }
        onFulfilled(response.data);
      } catch (error) {
        if (
          getHttpError(error)?.id === DISHGROUP_FORDEPOSITITEMS_NOTUNIQUE_ERROR
        ) {
          this.forDepositItems = false;
        }
        Log.info(error);
      } finally {
        this.loading = false;
      }
    }
  }
}

export default CategoryForm;
