import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import RecoverFormModel, {
  RecoveryFormStatus,
} from 'stores/Forms/RecoveryForm';

import TextField from 'components/Form/Fields/Text/TextField';
import Button from 'components/Button/Button';
import AppLink from 'components/AppLink/AppLink';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import AppLogo from 'components/AppLogo/AppLogo';

import { ROUTES } from 'routes/routes';
import { setUrlParamsToObject } from 'stores/utils';

@observer
class RecoverPage extends React.Component {
  recoverForm = new RecoverFormModel();
  FormControl = bindFormControl(this.recoverForm);

  submit = async event => {
    event.preventDefault();

    await this.recoverForm.send();
  };

  componentDidMount() {
    setUrlParamsToObject(this.recoverForm);
  }

  render() {
    const FormControl = this.FormControl;

    return (
      <section className={'login__section'}>
        <div className={'login__logo_wrapper'}>
          <AppLogo />
        </div>

        {this.recoverForm.status === RecoveryFormStatus.success ? (
          <div className={'login__status_wrapper'}>
            <p>
              <FormattedMessage id="recovery.form.success" />
            </p>
            <p>
              <FormattedMessage id="recovery.form.success2" />
            </p>
          </div>
        ) : (
          <div className={'login__form_wrapper'}>
            <form onSubmit={this.submit} name="recover-form">
              <h3 className={'login__title login__title_recover'}>
                <FormattedMessage id="page.recover.title" />
              </h3>

              <div className={'login__description'}>
                <FormattedMessage id="page.recover.text" />
              </div>

              <div className={'login__form_row'}>
                <FormControl
                  name="email"
                  render={props => <TextField {...props} />}
                />
              </div>

              <div className={'login__btn_wrapper'}>
                <Button
                  className={'login__btn login__btn_submit'}
                  type="submit"
                >
                  <FormattedMessage id="button.recover" />
                </Button>
              </div>
            </form>
          </div>
        )}

        <div className={'login__toggle'}>
          <div className={'login__toggle_text'}>
            <FormattedMessage id="page.recover.note" />
          </div>

          <div>
            <AppLink to={ROUTES.login} className={'login__link'}>
              <FormattedMessage id="page.recover.link" />
            </AppLink>
          </div>
        </div>
      </section>
    );
  }
}

export default RecoverPage;
