import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';

import style from './Campaign.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Table from 'components/Table/Table';
import WarningModal from 'components/Modals/Warning/WarningModal';
import CampaignModal from 'components/Modals/Campaign/CampaignModal';

import config from './columnsConfig';

import { copyToClipboard } from 'helpers/copy';

import CampaignsStore from 'stores/Staff/CampaignsStore';
import ModalStore from 'stores/ModalStore';

@observer
class CampaignPage extends Component {
  store = new CampaignsStore();

  async componentDidMount() {
    await this.store.init();
  }

  onSearch = value => {
    this.store.searchValue = value;
  };

  onCampaignCreate = async () => {
    const { payload } = await ModalStore.showModal(CampaignModal);

    this.store.addCampaign(payload);
  };

  onCampaignEdit = campaign => async () => {
    const { payload } = await ModalStore.showModal(CampaignModal, {
      campaign,
    });

    this.store.updateCampaign(payload);
  };

  onCampaignDelete = id => async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });
    await this.store.deleteCampaign(id);
  };

  onLinkCopy = campaignId => () => {
    const link = `${
      window.location.origin
    }${replaceUrlParams(ROUTES.registration, { campaignId })}`;

    copyToClipboard(link);
  };

  render() {
    const { searchValue, loading, filteredData } = this.store;

    return (
      <div className={style.content}>
        <div className={style.row}>
          <Button className={style.btn__search} onClick={this.onCampaignCreate}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.newCampaign" />
          </Button>

          <SearchField
            className={style.model__search}
            onChange={this.onSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={filteredData}
          columns={config({
            onEdit: this.onCampaignEdit,
            onDelete: this.onCampaignDelete,
            onLinkCopy: this.onLinkCopy,
          })}
          loading={loading}
        />
      </div>
    );
  }
}

export default CampaignPage;
