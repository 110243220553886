import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import style from './Controls.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import DatasetStore from 'stores/Recognition/DatasetStore';
import ModalStore from '../../../stores/ModalStore';
import WarningModal from '../../../components/Modals/Warning/WarningModal';
import classNames from 'classnames';

interface Props {
  store?: DatasetStore;
}

@inject('store')
@observer
class Controls extends Component<Props> {
  onMultiDelete = async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });
    await this.props.store?.multiSampleDelete();
  };

  render() {
    const { store } = this.props;

    return (
      <div className={style.controls}>
        <div className={style.wrapper}>
          <Button
            onClick={store!.switchMultiMode}
            styleType={store!.multiMode ? 'primary' : 'ghost'}
            className={style.btn__select__mode}
          >
            <div
              className={classNames(
                style.btn__title,
                !store!.multiMode && style.btn__title_multi,
              )}
            >
              <FormattedMessage id="dataset.selection.mode" />
            </div>
            {store!.multiMode && (
              <Icon type="close" className={style.icon__cancel} />
            )}
          </Button>
          {store!.multiMode && (
            <>
              {!!store!.checkedSamples.length ? (
                <div className={style.btn__block}>
                  <span className={style.images__selected}>
                    <FormattedMessage
                      id="dataset.images.selected"
                      values={{ value: store!.checkedSamples.length }}
                    />
                  </span>
                  <Button
                    onClick={store!.resetCheckedSamples}
                    styleType="ghost"
                    className={style.btn__reset}
                    disabled={!Boolean(store!.checkedSamples.length)}
                  >
                    <FormattedMessage id="button.reset" />
                  </Button>
                </div>
              ) : (
                <span className={style.title__no__selecteted}>
                  <FormattedMessage id="dataset.no.selected.images" />
                </span>
              )}
              <div className={style.btn__block}>
                <Button
                  onClick={store!.toggleCheckedAllSample}
                  styleType="ghost"
                  className={style.btn__select__all}
                >
                  <FormattedMessage id="button.select.all" />
                </Button>
                <Button
                  onClick={this.onMultiDelete}
                  styleType="warning"
                  disabled={!Boolean(store!.checkedSamples.length)}
                  className={style.btn__delete}
                >
                  <FormattedMessage id="button.delete" />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Controls;
