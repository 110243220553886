import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './UserGroup.module.scss';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import Button from 'components/Button/Button';
import SplitModalLayout from 'components/Layout/SplitModalLayout';
import FormSelect from 'components/Modals/Components/FormSelect/FormSelect';

import { UserGroup } from 'stores/Staff/types';
import GroupForm from 'stores/Forms/GroupForm';
import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  group?: UserGroup;
}

@observer
class UserGroupModal extends Component<Props> {
  form = new GroupForm(this.props.group);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();

    const onFulfilled = payload => this.props.onSubmit!(payload);

    if (this.form.id) {
      await this.form.updateStaffGroup(onFulfilled);
    } else {
      await this.form.createStaffGroup(onFulfilled);
    }
  };

  onCompanyClick = (company?) => {
    this.form.companyID = company?.id;
  };

  async componentDidMount() {
    await this.form.fetchCompanies();
  }

  render() {
    const { group, ...rest } = this.props;
    const FormControl = this.formControl;

    return (
      <SplitModalLayout
        {...rest}
        className={style.modal}
        loading={this.form.loading}
      >
        <form name="group" className={style.form__section}>
          <h2 className={style.form__title}>
            <FormattedMessage
              id={this.form.id ? 'title.editGroup' : 'title.newGroup'}
            />
          </h2>

          <div className={style.form__body}>
            <FormControl
              name="title"
              className={style.control}
              render={props => <TextField {...props} className={style.model} />}
            />

            <p className={style.text}>
              <FormattedMessage id="modal.staffGroup.text" />
            </p>

            <div className={style.company}>
              <label className={style.company__label}>
                <FormattedMessage id="title.enterprise" />
              </label>

              <div className={style.company__name}>
                {this.form.selectedCompany?.title}
              </div>
            </div>
          </div>

          <div className={style.form__controls}>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this.props.onClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={style.btn}
              disabled={!this.form.isFormValid}
              onClick={this.onSubmit}
            >
              <FormattedMessage
                id={this.form.id ? 'button.save' : 'button.create'}
              />
            </Button>
          </div>
        </form>

        <FormSelect
          title="title.enterprise"
          data={this.form.companies}
          onLineClick={this.onCompanyClick}
          activeLineID={this.form.companyID}
          withNone
        />
      </SplitModalLayout>
    );
  }
}

export default UserGroupModal;
