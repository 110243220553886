import { IMenuCategory } from './types';

export function groupByCategories(data): IMenuCategory[] {
  const categories = data.reduce((result, item) => {
    const { dishGroup } = item;

    if (result.has(dishGroup.id)) {
      result.get(dishGroup.id)!.items.push(item);
    } else {
      result.set(dishGroup.id, {
        id: dishGroup.id,
        title: item.dishGroup.title,
        items: [item],
      });
    }

    return result;
  }, new Map());

  return [...categories.values()];
}
