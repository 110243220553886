import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './RevenueReport.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Table from 'components/Table/Table';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import { ICategoryReport } from 'stores/Report/types';
import config from './columnsConfig';

const OFFSET_TITLE = 80;
const OFFSET_AMOUNT = 100;

interface Props {
  report: ICategoryReport;
  tableStyle?: string;
}

@observer
class ReportTable extends Component<Props> {
  @observable show = true;
  @observable styles = new Map();

  toggleTable = () => {
    this.show = !this.show;

    if (!this.show) {
      this.styles.clear();
    }
  };

  onResize = newResized => {
    newResized.forEach(it => {
      this.styles.set(it.id, it.value);
    });
  };

  render() {
    const { report, tableStyle } = this.props;
    const { total } = report;

    return (
      <>
        <h3 className={style.table__title}>{report.title}</h3>

        <div key={report.title} className={style.table__wrapper}>
          <div className={style.total__row} onClick={this.toggleTable}>
            <div
              className={style.total__value}
              style={
                this.styles.get('title') && {
                  width: this.styles.get('title') + OFFSET_TITLE,
                }
              }
            >
              <FormattedMessage id="title.total" />
            </div>

            <div
              className={style.total__value}
              style={
                this.styles.get('amount') && {
                  width: this.styles.get('amount') + OFFSET_AMOUNT,
                }
              }
            >
              {total.amount}
            </div>

            <div className={style.total__value}>
              <FormattedCurrency
                value={report.total.brutto}
                withSymbol={false}
              />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency value={total.vatAmount} withSymbol={false} />
            </div>

            <div className={style.total__value}>
              <FormattedCurrency value={total.netto} withSymbol={false} />
            </div>

            <Button styleType="text" className={style.btn__arrow}>
              <Icon type={this.show ? 'arrowTop' : 'arrow'} />
            </Button>
          </div>

          {this.show && (
            <Table
              data={report.menuItems}
              columns={config()}
              className={classNames(style.table, tableStyle)}
              onResizedChange={this.onResize}
              tableClassName={style.reportTable}
            />
          )}
        </div>
      </>
    );
  }
}

export default ReportTable;
