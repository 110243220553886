import React from 'react';
import { FormattedMessage } from 'react-intl';
import style from '../../OnlineOrder.module.scss';
import Icon from 'components/Icon/Icon';

const NoMenuForToday: React.FC = () => (
  <div className={style.noMenu__wrapper}>
    <Icon type={'noMenu'} className={style.noMenu__icon} />
    <FormattedMessage id={'profile.noMenu.forToday.title'} tagName={'p'} />
    <FormattedMessage id={'profile.noMenu.forToday.text'} tagName={'p'} />
  </div>
);

export default NoMenuForToday;
