import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './MenuLines.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Table from 'components/Table/Table';
import MenuLineModal from 'components/Modals/Groups/MenuLine/MenuLineModal';

import config from './columnsConfig';

import ModalStore from 'stores/ModalStore';
import LinesStore from 'stores/Menu/LinesStore';

@observer
class MenuLines extends Component {
  store = new LinesStore();

  async componentDidMount() {
    await this.store.fetchLines();
  }

  openLineModel = (id?) => async () => {
    const { payload } = await ModalStore.showModal(MenuLineModal, { id });
    this.store.updateLines(payload);
  };

  deleteLine = id => async () => {
    await this.store.deleteLine(id);
  };

  onSearch = value => {
    this.store.searchValue = value;
  };

  render() {
    const { searchValue, filteredItems, loading } = this.store;

    return (
      <div className={style.content}>
        <div className={style.row}>
          <Button className={style.btn} onClick={this.openLineModel()}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="button.newLine" />
          </Button>

          <SearchField
            className={style.search}
            onChange={this.onSearch}
            value={searchValue}
          />
        </div>

        <Table
          data={filteredItems}
          columns={config({
            onEdit: this.openLineModel,
            onDelete: this.deleteLine,
          })}
          loading={loading}
        />
      </div>
    );
  }
}

export default MenuLines;
