import { action, computed, observable } from 'mobx';

import { Campaign } from './types';

import httpFacade from 'http/httpFacade';

import Log from 'helpers/log';

class CampaignsStore {
  @observable
  searchValue = '';

  @observable
  data: Campaign[] = [];

  @observable loading = false;

  @action.bound
  async init() {
    try {
      this.loading = true;

      ({ data: this.data } = await httpFacade.staff.fetchCampaigns());
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteCampaign(id: string) {
    try {
      this.loading = true;

      await httpFacade.staff.deleteCampaign(id);

      this.data = this.data.filter(it => it.id !== id);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  updateCampaign(data: Campaign) {
    this.data = this.data.map(it => (it.id === data.id ? data : it));
  }

  @action.bound
  addCampaign(data: Campaign) {
    this.data = [...this.data, data];
  }

  @computed
  get filteredData() {
    const regExp = new RegExp(this.searchValue, 'i');

    return this.searchValue
      ? this.data.filter(it => regExp.test(it.title))
      : this.data;
  }
}

export default CampaignsStore;
