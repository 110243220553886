import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import style from './Spinner.module.scss';

interface Props {
  children?: ReactNode;
  className?: {
    wrap?: string;
    spinner?: string;
    inner?: string;
  };
  style?: CSSProperties;
  fullscreen?: boolean;
  contain?: boolean;
}

const Spinner = (props: Props) => (
  <div
    className={classNames(style.container, props.className?.wrap, {
      [style.fullscreen]: props.fullscreen,
      [style.contain]: props.contain,
    })}
    style={props.style}
  >
    <div className={classNames(style.spinner, props.className?.spinner)}>
      <div
        className={classNames(style.spinner__inner, props.className?.inner)}
      />
    </div>

    {props.children}
  </div>
);

export default Spinner;
