import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { MenuItem } from 'stores/Menu/types';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';
import Icon from 'components/Icon/Icon';
import style from './Category.module.scss';

interface ICategory {
  id: string;
  title: string;
  items: MenuItem[];
}

interface IProps {
  category: ICategory;
  toggleItem: (item: MenuItem) => void;
  selectedItemsIds: string[];
}

@observer
class Category extends Component<IProps> {
  @observable open = false;

  toggleOpen = () => (this.open = !this.open);

  toggleItem = (item: MenuItem) => () => {
    this.props.toggleItem(item);
  };

  render() {
    const { category, selectedItemsIds } = this.props;
    const checkedItems = category.items.reduce(
      (amount, item) =>
        selectedItemsIds.includes(item.id) ? ++amount : amount,
      0,
    );

    return (
      <li className={style.category}>
        <div className={style.category__row} onClick={this.toggleOpen}>
          <span className={style.category__title}>{category.title}</span>

          {Boolean(checkedItems) && (
            <span className={style.category__amount}>{checkedItems}</span>
          )}

          <Icon type={this.open ? 'arrowTop' : 'arrowBottom'} />
        </div>

        {this.open && (
          <ul>
            {category.items.map(item => (
              <li key={item.title}>
                <CheckboxField
                  checked={selectedItemsIds.includes(item.id)}
                  type="green"
                  className={{ wrapper: style.submenu__item }}
                  onChange={this.toggleItem(item)}
                >
                  {item.title}
                </CheckboxField>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export default Category;
