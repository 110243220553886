import URLS from 'http/urls';
import http from 'http/http';
import { api, arrayParamsSerializer, replaceUrlParams } from 'http/helpers';
import {
  CreateUpdateMenuItemDto,
  CreateUpdateMenuItemLabelDto,
  MenuItemLabel,
} from './types';
import {
  AllergenType,
  DishItem,
  MenuItem,
  MenuItemImage,
  MenuItems,
} from 'stores/Menu/types';

export const menuItemsApi = {
  fetchMenuItems() {
    return http.get<MenuItem[]>(api(URLS.menuItems.items));
  },

  fetchMenuItemsPageable(groupId?: string, size?: number, page?: number) {
    return http.get<MenuItems>(api(URLS.menuItems.itemsPageable), {
      params: { groupId, size, page },
    });
  },

  fetchMenuItemsSearch(
    size: number,
    page: number,
    searchQuery: string,
    groupId?: string,
  ) {
    return http.get<MenuItems>(api(URLS.menuItems.itemsSearch), {
      params: { size, page, title: searchQuery, groupId },
    });
  },

  fetchMenuItem(id: string) {
    return http.get<DishItem>(api(URLS.menuItems.item), {
      replacePathParams: { id },
    });
  },

  updateMenuItem(id: string, data: CreateUpdateMenuItemDto) {
    return http.put<CreateUpdateMenuItemDto, MenuItem>(
      api(URLS.menuItems.item),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  deleteMenuItem(id: string) {
    return http.delete(api(URLS.menuItems.item), {
      replacePathParams: { id },
    });
  },

  createMenuItem(data) {
    return http.post(api(URLS.menuItems.item), { ...data });
  },

  setImage(data: FormData, id: string) {
    return http.post(
      api(URLS.menuItems.menuItemImages),
      { ...data },
      {
        headers: {
          Accept: '*/*',
          'Content-type': 'multipart/form-data',
        },
        replacePathParams: { id },
      },
    );
  },

  getImage(id: string) {
    return http.get<string>(api(URLS.menuItems.menuItemImage), {
      replacePathParams: { id },
    });
  },

  deleteImage(id: string) {
    return http.delete(api(URLS.menuItems.menuItemImages), {
      replacePathParams: { id },
    });
  },

  getMenuItemsImages(ids: string[]) {
    return http.get<MenuItemImage[]>(api(URLS.menuItems.menuItemsCardImages), {
      paramsSerializer: arrayParamsSerializer,
      params: { ids },
    });
  },

  markSubsidisedMenuItem(id: string, isSubsidised) {
    return http.put(api(URLS.menuItems.markSubsidizedItem), null, {
      replacePathParams: { id },
      params: { id, value: isSubsidised },
    });
  },

  markSubsidisedCategory(id: string, isSubsidised) {
    return http.put(api(URLS.menuItems.markSubsidizedDishGroup), null, {
      params: {
        dishGroupId: id,
        value: isSubsidised,
      },
    });
  },

  fetchAllergens() {
    return http.get<AllergenType[]>(api(URLS.menuItems.allergens));
  },

  fetchDepositItems() {
    return http.get<DishItem[]>(api(URLS.menuItems.depositItems));
  },

  // Labels

  fetchLabels() {
    return http.get<MenuItemLabel[]>(api(URLS.menuItems.labels));
  },

  createLabel(data: CreateUpdateMenuItemLabelDto) {
    return http.post<CreateUpdateMenuItemLabelDto, MenuItemLabel>(
      api(URLS.menuItems.labels),
      data,
    );
  },

  updateLabel(id: string, data: CreateUpdateMenuItemLabelDto) {
    return http.put<CreateUpdateMenuItemLabelDto, MenuItemLabel>(
      api(URLS.menuItems.labels),
      data,
      {
        replacePathParams: { id },
      },
    );
  },

  deleteLabel(id: string) {
    return http.delete(api(URLS.menuItems.labels), {
      replacePathParams: { id },
    });
  },

  changeNotVisibleKioskSelfCheckout(
    id: string,
    notVisibleKioskSelfCheckout: boolean,
  ) {
    return http.post(
      api(
        replaceUrlParams(URLS.menuItems.changeNotVisibleKioskSelfCheckout, {
          id,
        }),
      ),
      {},
      {
        params: {
          notVisibleKioskSelfCheckout,
        },
      },
    );
  },
};
