import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './SuccessBlock.module.scss';
import Button from 'components/Button/Button';

import { BasketMode } from 'stores/UserProfile/types';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import Icon from '../../../Icon/Icon';

interface Props {
  store?: UserProfileOrder;
  onClose: () => void;
}

@observer
class SuccessBlock extends Component<Props> {
  onConfirmOK = () => {
    this.props.onClose();
    this.props.store!.changeBasketMode(BasketMode.orderList);
  };

  render() {
    return (
      <div className={style.body}>
        <Icon type="plateDeep" className={style.icon} />

        <h2 className={style.success__title}>
          <FormattedMessage id="modal.confirm.order.success.title" />
        </h2>

        <div className={style.success__text}>
          <FormattedMessage id="modal.confirm.order.success.text" />
        </div>

        <Button className={style.btn__ok} onClick={this.onConfirmOK}>
          <FormattedMessage id="button.ok" />
        </Button>
      </div>
    );
  }
}

export default SuccessBlock;
