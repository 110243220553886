import React from 'react';

import style from './Spinner.module.scss';

const DotsSpinner = () => (
  <div className={style.dotsContainer}>
    <span className={style.dot} />
    <span className={style.dot} />
    <span className={style.dot} />
  </div>
);

export default DotsSpinner;
