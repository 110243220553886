import { observable } from 'mobx';

import {
  BaseFormModel,
  hasMaxLength,
  isRequired,
  displayName,
} from '../BaseForm';

class ContactForm extends BaseFormModel {
  id: string;

  @observable
  @displayName('FIRST_NAME')
  @isRequired()
  @hasMaxLength(50)
  firstName = '';

  @observable
  @displayName('LAST_NAME')
  @isRequired()
  @hasMaxLength(50)
  lastName = '';

  @observable
  @displayName('NICKNAME')
  @isRequired()
  @hasMaxLength(20)
  nickname = '';

  email = '';

  constructor({ firstName, lastName, nickname, email }) {
    super();

    this.firstName = firstName;
    this.lastName = lastName;
    this.nickname = nickname;
    this.email = email;
  }
}

export default ContactForm;
