import { DateTime } from 'luxon';
import { action, computed, observable, reaction } from 'mobx';
import httpFacade from 'http/httpFacade';
import {
  AllergenType,
  DishPreferences,
  IMenuLine,
  MenuItem,
  MenuLineDTO,
  Periods,
  ProfileMenu,
  SwipeDirection,
} from './types';
import Log from 'helpers/log';
import { sortByAccessor } from 'helpers/accessors';
import { Specification, SpecificationType } from '../Menu/types';
import {
  filterCustomPreferences,
  getFormattedFilteredPreferences,
  getPlannerMenu,
  getPlannerMenuForCurrentDay,
} from './helpers';
import { SortConfig } from 'helpers/types';
import { getDateTimeFromISO, getLocalDateTime } from 'helpers/datetime';

const PERIOD = 7;

class ProfileMenuStore {
  @observable userId: string;
  @observable date = getLocalDateTime();
  @observable currentDate = this.date.toISODate();
  @observable activeWeekDate = this.currentDate;
  @observable activeSpecCategory: string[] = [];
  @observable plannerLines: ProfileMenu[] = [];
  @observable plannerLinesForCurrentDay: ProfileMenu[] = [];
  @observable recommendedMenu: ProfileMenu[] = [];
  @observable recommendedMenuForCurrentDay: ProfileMenu[] = [];
  @observable menuLines: IMenuLine[] = [];
  @observable allergens: AllergenType[] = [];
  @observable activeMenuLine: MenuLineDTO;
  @observable preferences: DishPreferences | any;
  @observable filteredPreferences: any;
  @observable baseSpecifications: Specification[] = [];
  @observable customFilters: string[] = [];
  @observable loading = false;
  @observable menuItemsCount: number = 0;
  @observable isSetProfilePreferences: boolean = false;
  @observable specsCounts: any = {
    HEALTH_ASPECTS: 0,
    QUALITY_STANDARDS: 0,
    ADDITIVES: 0,
  };

  @observable private sortConfig = { accessor: 'title', desc: false };

  private defaultAllergensSort: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  constructor(id: string) {
    this.userId = id;
    reaction(
      () => this.date,
      async () => {
        await this.fetchMenu();
      },
    );
  }

  @action.bound
  async init() {
    try {
      [
        { data: this.baseSpecifications },
        { data: this.allergens },
      ] = await Promise.all([
        httpFacade.menu.fetchBaseSpecifications(),
        httpFacade.menuItems.fetchAllergens(),
      ]);
    } catch (error) {
      Log.warn(error);
    }
  }

  @computed
  get sortedAllergens() {
    return [...this.allergens].sort(sortByAccessor(this.defaultAllergensSort));
  }

  @computed
  get weekStartDate() {
    return this.date.startOf(Periods.week);
  }

  @computed
  get weekEndDate() {
    return this.date.endOf(Periods.week);
  }

  @computed
  get weekNumber() {
    return this.date.weekNumber;
  }

  @computed
  get filters() {
    const filters: any[] = [];
    if (this.preferences?.allergens?.length > 0) {
      this.allergens?.forEach?.(allergen =>
        this.preferences?.allergens?.forEach(item => {
          if (item === allergen.id) {
            filters.push(allergen);
          }
        }),
      );
    }
    if (this.preferences?.specifications?.length > 0) {
      this.baseSpecifications?.forEach?.(spec =>
        this.preferences?.specifications?.forEach(item => {
          spec.values.forEach(value => {
            if (value.id === item.id) {
              filters.push(value);
            }
          });
        }),
      );
    }
    return filters;
  }

  @computed
  get availableSpecs() {
    return [
      {
        id: 1,
        title: 'Allergene',
        name: SpecificationType.ALLERGENS,
        values: this.allergens,
      },
      ...this.baseSpecifications,
    ];
  }

  @action.bound
  setActiveSpecCategory(name: string) {
    this.activeSpecCategory = this.activeSpecCategory.includes(name)
      ? this.activeSpecCategory.filter(category => category !== name)
      : [...this.activeSpecCategory, name];
  }

  @action.bound
  async toggleAllergen(type) {
    let updatedAllergens;

    updatedAllergens = this.filteredPreferences?.allergens?.includes?.(type)
      ? this.filteredPreferences?.allergens?.filter?.(it => it !== type)
      : [...this.filteredPreferences?.allergens, type];

    this.filteredPreferences = {
      ...this.filteredPreferences,
      allergens: updatedAllergens,
    };

    this.customFilters = filterCustomPreferences(this.customFilters, type);
    await this.fetchMenuItemsCount(
      getFormattedFilteredPreferences(this.filteredPreferences),
    );
  }

  @action.bound
  async toggleSpec(id: string, specName?: string) {
    let updatedSpecifications;
    updatedSpecifications = this.filteredPreferences?.specifications?.some(
      item => item.id === id,
    )
      ? this.filteredPreferences?.specifications?.filter?.(
          specification => specification.id !== id,
        )
      : [
          ...this.filteredPreferences?.specifications,
          { id, category: specName },
        ];

    this.customFilters = filterCustomPreferences(this.customFilters, id);

    this.filteredPreferences = {
      ...this.filteredPreferences,
      specifications: updatedSpecifications,
    };
    await this.fetchMenuItemsCount(
      getFormattedFilteredPreferences(this.filteredPreferences),
    );
  }

  @computed
  get activeDay() {
    return this.activeMenuLine.days.find(
      day => day.date === this.activeWeekDate,
    );
  }

  @computed
  get selectedItems(): string[] {
    return this.activeDay ? this.activeDay.items.map(item => item.id) : [];
  }

  @computed
  get period(): string[] {
    const period: string[] = [];

    for (
      let day = this.weekStartDate;
      day.weekday <= PERIOD && period.length < PERIOD;
      day = day.plus({ day: 1 })
    ) {
      period.push(day.toISODate());
    }

    return period;
  }

  @computed
  get activeDate(): string {
    return !this.period.includes(this.currentDate)
      ? this.period[0]
      : this.currentDate;
  }

  @action.bound
  swipeWeek(value: SwipeDirection) {
    this.date =
      value === 'previous'
        ? this.date.minus({ week: 1 })
        : this.date.plus({ week: 1 });
  }

  @action.bound
  toggleItem(item: MenuItem) {
    if (this.activeDay) {
      if (this.activeDay.items.some(value => value.id === item.id)) {
        this.activeDay.items = this.activeDay.items.filter(
          value => value.id !== item.id,
        );
      } else {
        this.activeDay.items.push(item);
      }
    }
  }

  @action.bound
  changeDate([date]: DateTime[]) {
    this.date = date;
  }
  @action.bound
  async selectDate(date: string) {
    this.activeWeekDate = date;
    this.plannerLinesForCurrentDay = getPlannerMenuForCurrentDay(
      this.plannerLines,
      this.activeWeekDate,
    );
    this.recommendedMenuForCurrentDay = getPlannerMenuForCurrentDay(
      this.recommendedMenu,
      this.activeWeekDate,
    );
    const preferencesRequest = getFormattedFilteredPreferences(
      this.preferences,
    );
    await this.fetchMenuItemsCount(preferencesRequest);
  }

  @action.bound
  nextWeekDay() {
    // last day of the with according to DateTime
    const last = this.period.slice(-1)[0];
    const activeWeekDay = getDateTimeFromISO(this.activeWeekDate);

    this.activeWeekDate =
      activeWeekDay.toISODate() !== last
        ? activeWeekDay.plus({ day: 1 }).toISODate()
        : this.activeWeekDate;
  }

  @action.bound
  previousWeekDay() {
    // first day of the with according to DateTime
    const first = this.period[0];
    const activeWeekDay = getDateTimeFromISO(this.activeWeekDate);

    this.activeWeekDate =
      activeWeekDay.toISODate() !== first
        ? activeWeekDay.minus({ day: 1 }).toISODate()
        : this.activeWeekDate;
  }

  @action.bound
  async fetchMenu() {
    try {
      this.loading = true;
      const [menu, lines, dishPreference] = await Promise.all([
        httpFacade.menu.fetchPlannerMenu(
          this.weekStartDate.toISODate(),
          this.weekEndDate.toISODate(),
        ),
        httpFacade.menu.fetchMenuLines(),
        httpFacade.profile.fetchDishPreference(this.userId),
      ]);

      this.preferences = dishPreference.data as DishPreferences;
      this.filteredPreferences = this.preferences;
      const preferencesRequest = getFormattedFilteredPreferences(
        this.preferences,
      );

      this.activeWeekDate = this.activeDate;
      await this.fetchRecommendedMenu(preferencesRequest);
      this.menuLines = lines.data;
      this.plannerLines = getPlannerMenu(
        menu.data,
        this.menuLines,
        this.period,
      );
      this.plannerLinesForCurrentDay = getPlannerMenuForCurrentDay(
        this.plannerLines,
        this.activeWeekDate,
      );
      await this.fetchMenuItemsCount(preferencesRequest);
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async showConfiguredMenuItems() {
    await this.fetchRecommendedMenu(
      getFormattedFilteredPreferences(this.filteredPreferences),
    );
    this.activeSpecCategory = [];
    this.preferences = this.filteredPreferences;
  }

  @action.bound
  async fetchRecommendedMenu(data?: any) {
    try {
      this.loading = true;

      const menuData = await httpFacade.menu.fetchRecommendedPlannerdMenu(
        this.weekStartDate.toISODate(),
        this.weekEndDate.toISODate(),
        data || this.filteredPreferences,
      );
      this.recommendedMenu = getPlannerMenu(
        menuData.data,
        this.menuLines,
        this.period,
      );
      this.recommendedMenuForCurrentDay = getPlannerMenuForCurrentDay(
        this.recommendedMenu,
        this.activeWeekDate,
      );
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetchMenuItemsCount(data: DishPreferences) {
    const responseDataCount = await httpFacade.menu.fetchMenuItemsCount(
      this.activeWeekDate,
      this.activeWeekDate,
      data,
    );
    if (!data!.allergens?.length && !data!.specifications?.length) {
      this.customFilters = [];
    }
    this.menuItemsCount = responseDataCount?.data || 0;
  }

  @action.bound
  async deleteFilter(id: string) {
    let updatedAllergens;
    let updatedSpecifications;
    if (this.preferences.allergens.includes(id)) {
      updatedAllergens = this.preferences.allergens.filter(
        allergen => allergen !== id,
      );
      this.preferences = { ...this.preferences, allergens: updatedAllergens };
    } else {
      updatedSpecifications = this.preferences.specifications.filter(
        spec => spec.id !== id,
      );
      this.preferences = {
        ...this.preferences,
        specifications: updatedSpecifications,
      };
    }
    this.customFilters = this.customFilters.filter(item => item !== id);
    this.filteredPreferences = this.preferences;
    const preferencesRequest = getFormattedFilteredPreferences(
      this.preferences,
    );

    await Promise.all([
      await httpFacade.profile.updateDishPreference(
        this.userId,
        preferencesRequest,
      ),
      await this.fetchRecommendedMenu(preferencesRequest),
      await this.fetchMenuItemsCount(preferencesRequest),
    ]);
  }

  @action.bound
  async resetFilters() {
    this.preferences = { specifications: [], allergens: [] };
    this.filteredPreferences = this.preferences;
    this.customFilters = [];
    await this.fetchMenuItemsCount(this.filteredPreferences);
  }

  @action.bound
  closeModal() {
    this.filteredPreferences = this.preferences;
    this.customFilters = [];
  }
}

export default ProfileMenuStore;
