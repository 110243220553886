import React, { Component } from 'react';
import { RouteChildrenProps } from 'react-router';
import { observer, Provider } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import { getRole, Roles } from 'helpers/roles';

import ProfileStore from 'stores/EmployeeProfile/ProfileStore';
import { ProfileStatus } from 'stores/EmployeeProfile/types';
import ModalStore from 'stores/ModalStore';

import ProfileContacts from './ProfileContacts/ProfileContacts';
import BalanceMigrationModal from 'components/Modals/BalanceMigration/BalanceMigrationModal';
import Spinner from 'components/Spinner/Spinner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Tabs, { AppTab } from 'components/Tabs/Tabs';
import Transactions from 'components/Transactions/Transactions';
import Button from 'components/Button/Button';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import CheckboxField from 'components/Form/Fields/Checkbox/CheckboxField';

import { ROUTES } from 'routes/routes';
import classNames from 'classnames';

type Props = RouteChildrenProps<{
  id: string;
}>;

@observer
class EmployeeProfile extends Component<Props> {
  profile: ProfileStore;
  tabs: AppTab[] = [];

  constructor(props: Props) {
    super(props);

    const id = (props.match && props.match.params.id) || '';

    this.profile = new ProfileStore(id);
    this.tabs = [
      {
        title: <FormattedMessage id="title.transactions" />,
        component: <Transactions report={this.profile.report} />,
      },
    ];
  }

  onBalanceRefill = async () => {
    const user = {
      id: this.profile.id,
      name: this.profile.nickname,
    };

    await ModalStore.showModal(BalanceMigrationModal, { user });
    await this.profile.report.fetchReport();
  };

  async componentDidMount() {
    await this.profile.init();
  }

  render() {
    const isHostess = getRole(Roles.hostess);
    const isAccountant = getRole(Roles.accountant);
    const { nickname } = this.profile;

    return (
      <Provider store={this.profile}>
        <main
          className={classNames(
            'employeeProfile__main',
            isHostess && 'employeeProfile__main_hostess',
          )}
        >
          {this.profile.loading && <Spinner contain />}

          <header className={'employeeProfile__header'}>
            <Breadcrumbs
              type="general"
              links={[
                { to: ROUTES.employees, name: 'breadcrumbs.employees' },
                { name: `${nickname}` },
              ]}
              className={'employeeProfile__breadcrumbs_wrapper'}
            />

            <div className={'employeeProfile__balance_wrapper'}>
              {isAccountant && (
                <div className={'employeeProfile__balance'}>
                  <FormattedMessage id="profile.balance.title" />
                  <FormattedCurrency value={this.profile.report.balance} />

                  <Button
                    styleType="primary"
                    className={'employeeProfile__btn__refill'}
                    onClick={this.onBalanceRefill}
                  >
                    +
                  </Button>
                </div>
              )}

              {isHostess && (
                <div>
                  {this.profile.profileStatus === ProfileStatus.active ? (
                    <Button
                      styleType="warning"
                      className={'employeeProfile__btn_block'}
                      onClick={this.profile.blockProfile}
                    >
                      <FormattedMessage id="profile.button.block" />
                    </Button>
                  ) : (
                    <Button
                      styleType="ghost"
                      className={'employeeProfile__btn_block'}
                      onClick={this.profile.unblockProfile}
                    >
                      <FormattedMessage id="profile.button.unblock" />
                    </Button>
                  )}
                </div>
              )}

              {isAccountant && (
                <CheckboxField
                  checked={this.profile.refunded}
                  type="green"
                  className={{
                    wrapper: 'employeeProfile__accountant__checkbox',
                    input: 'employeeProfile__accountant__checkbox__input',
                    icon: 'employeeProfile__accountant__checkbox__icon',
                  }}
                  onChange={this.profile.toggleRefund}
                  withTick
                >
                  <FormattedMessage id="profile.checkbox.refund" />
                </CheckboxField>
              )}
            </div>
          </header>

          <ProfileContacts profile={this.profile} />

          {isAccountant && (
            <section className={'employeeProfile__employee_panel'}>
              <Tabs tabs={this.tabs} />
            </section>
          )}
        </main>
      </Provider>
    );
  }
}

export default EmployeeProfile;
