import { action, computed, observable, reaction } from 'mobx';
import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isNumberMoreOrEqual,
  isRequired,
} from 'stores/BaseForm';

import httpFacade from 'http/httpFacade';

import noop from 'helpers/noop';

import {
  ConfirmationDiscountMode,
  Discount,
  DiscountDTO,
  DiscountPeriod,
} from '../Subsidies/types';
import { UserGroup } from '../Staff/types';
import Log from '../../helpers/log';
import { DishGroupManagement } from '../Menu/types';

export const DISCOUNT_LIMITS = {
  maxLimit: 999,
};

class SubsidyForm extends BaseFormModel {
  @observable
  @displayName('SUM')
  @isRequired()
  price: number;

  @observable
  @displayName('USAGE_LIMITS')
  defaultLimit = 1;

  @observable
  defaultPeriod: DiscountPeriod = DiscountPeriod.DAILY;

  @observable
  @displayName('OTHER_LIMITS')
  otherLimits = false;

  @observable
  @displayName('MANUAL')
  confirmationMode = false;

  @observable
  period: DiscountPeriod = DiscountPeriod.DAILY;

  @observable
  @displayName('USAGE_LIMITS')
  @hasMaxLength(DISCOUNT_LIMITS.maxLimit, '')
  @isNumberMoreOrEqual(1)
  limit = 1;

  @observable loading = false;

  @observable readonly id;

  private selectedUserGroup?: UserGroup;
  private dishCategories: DishGroupManagement[];

  @computed
  get dto(): DiscountDTO {
    return {
      details: {
        price: this.price,
        limit: this.limit,
        dishGroups: this.dishCategories.map(group => ({ id: group.id })),
        userGroup: { id: this.selectedUserGroup?.id || '' },
        period: this.period,
        confirmationMode: this.confirmationMode
          ? ConfirmationDiscountMode.MANUAL_BY_CATERER
          : ConfirmationDiscountMode.AUTO,
      },
      id: this.id,
    };
  }

  constructor(
    selectedUserGroup?: UserGroup,
    dishCategories?: DishGroupManagement[],
    discount?: Discount,
  ) {
    super();

    this.selectedUserGroup = selectedUserGroup;
    this.dishCategories = dishCategories || [];

    if (discount) {
      const { details } = discount;
      this.price = details.price;
      this.period = details.period;
      this.limit = details.limit;
      this.defaultLimit = details.limit;
      this.defaultPeriod = details.period;
      this.confirmationMode =
        details.confirmationMode === ConfirmationDiscountMode.MANUAL_BY_CATERER;
      this.id = discount.id;
    }

    reaction(
      () => this.otherLimits,
      isOtherLimits => {
        if (!isOtherLimits) {
          this.period = DiscountPeriod.DAILY;
          this.limit = 1;
        }
      },
    );
  }

  @action.bound
  async onSubmit(onFulfilled = noop) {
    if (this.validate()) {
      try {
        let response;
        if (this.id) {
          this.loading = true;
          response = await httpFacade.staff.updateSubsidy(this.id, this.dto);
        } else {
          this.loading = true;
          response = await httpFacade.staff.createSubsidy(this.dto);
        }
        onFulfilled(response.data);
      } catch (error) {
        Log.info(error);
      } finally {
        this.loading = false;
      }
    }
  }
}

export default SubsidyForm;
