import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import './style.scss';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Button from 'components/Button/Button';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import { ModalProps } from 'stores/ModalStore';

interface Props extends ModalProps {
  store?: UserProfileOrder;
  onClose: () => void;
}

@observer
class OrderList extends Component<Props> {
  render() {
    const { store, onClose } = this.props;

    return (
      <>
        <p className={'basket__note'}>
          <FormattedMessage id="basket.time.note1" />
        </p>
        <p className={'basket__note'}>
          <FormattedMessage id="basket.time.note2" />
        </p>
        <ul className={'basket__items'}>
          {store?.orderMenu?.map?.(it => (
            <li key={it.id} className={'basket__item'}>
              <div className={'basket__item_content'}>
                <span className={'basket__item__name'}>{it.title}</span>

                <span className={'basket__item__amount'}>{it.amount}x</span>

                <span className={'basket__item__price'}>
                  <FormattedCurrency value={it.price} />
                </span>
              </div>
              {it?.depositItemId && (
                <div className={'basket__item_content'}>
                  <span className={'basket__item__name'}>
                    {it.depositTitle}
                  </span>

                  <span className={'basket__item__amount'}>{it.amount}x</span>

                  <span className={'basket__item__price'}>
                    <FormattedCurrency value={it.depositPrice || 0} />
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>

        <div className={'basket__summary'}>
          <div className={'basket__summary__line basket__line__discount'}>
            <FormattedMessage id="basket.personal.discount" />

            <FormattedCurrency value={store?.orderPreview?.discount ?? 0} />
          </div>

          <div className={'basket__summary__line basket__line__total'}>
            <FormattedMessage id="basket.total.toPay" />

            <FormattedCurrency value={store?.orderPreview?.finalPrice ?? 0} />
          </div>

          <div className={'basket__controls'}>
            <Button
              styleType="ghost"
              className={'basket__btn'}
              onClick={onClose}
            >
              <FormattedMessage id="button.reset" />
            </Button>

            <Button
              className={'basket__btn'}
              onClick={() => this.props.store!.onConfirmOrderListStep()}
              disabled={
                !store?.orderPreview?.finalPrice &&
                !store?.orderPreview?.discount
              }
            >
              <FormattedMessage
                id={
                  this.props.store!.isTimeSlotsFeatureExist ||
                  this.props.store!.isDeliveryFeatureExist
                    ? 'button.next'
                    : 'button.confirm'
                }
              />
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default OrderList;
