import { action, observable, computed, reaction } from 'mobx';

import httpFacade from 'http/httpFacade';
import noop from 'helpers/noop';

import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isRequired,
} from 'stores/BaseForm';
import ModalStore from '../ModalStore';
import { isTimeRange } from 'stores/BaseForm/validators/isTimeRange';

import WarningModal from 'components/Modals/Warning/WarningModal';
import { TimeRangeType } from 'components/Form/Fields/TimeRange/TimeRange';
import { MenuLineCreateUpdateDto } from 'stores/Menu/types';
import { getFormattedTime, SHORT_TIME_FORMAT } from 'helpers/datetime';

class LineForm extends BaseFormModel {
  @observable
  @displayName('NAME')
  @hasMaxLength(100)
  @isRequired()
  title = '';

  @observable
  @displayName('TIME_ONLINE_ORDER')
  @isTimeRange({ from: false, to: false })
  timeOnlineOrder: TimeRangeType = [null, null];

  @observable
  onlineOrder = false;

  @observable
  prioritized = false;

  @observable loading = false;

  @observable readonly id: string;
  code: string = '';

  constructor(id?) {
    super();

    this.id = id;

    reaction(
      () => this.onlineOrder,
      value => {
        if (!value) {
          this.timeOnlineOrder = [null, null];
        }
      },
    );
  }

  @action.bound
  async fetchLine() {
    try {
      this.loading = true;

      const { data } = await httpFacade.menu.fetchMenuLine(this.id);

      this.title = data.title;
      this.onlineOrder = data.onlineOrder;
      this.prioritized = data.prioritized;
      this.timeOnlineOrder = [
        data.orderTimeFrom
          ? getFormattedTime(data.orderTimeFrom, SHORT_TIME_FORMAT)
          : null,
        data.orderTimeTo
          ? getFormattedTime(data.orderTimeTo, SHORT_TIME_FORMAT)
          : null,
      ];
      this.code = data.code;
    } finally {
      this.loading = false;
    }
  }

  @computed
  get requestData(): MenuLineCreateUpdateDto {
    const [orderTimeFrom, orderTimeTo] = this.timeOnlineOrder;

    return {
      title: this.title,
      onlineOrder: this.onlineOrder,
      prioritized: this.prioritized,
      ...(orderTimeFrom && { orderTimeFrom: getFormattedTime(orderTimeFrom) }),
      ...(orderTimeTo && { orderTimeTo: getFormattedTime(orderTimeTo) }),
      code: this.code,
    };
  }

  @action.bound
  async updateMenuLine(onFulfilled = noop) {
    if (this.isFormValid) {
      try {
        await ModalStore.showModal(WarningModal, {
          description: 'modal.warning.change.item',
          type: 'change',
        });

        this.loading = true;
        const { data } = await httpFacade.menu.updateMenuLine(
          this.id,
          this.requestData,
        );

        onFulfilled(data);
      } finally {
        this.loading = false;
      }
    }
  }

  @action.bound
  async createMenuLine(onFulfilled = noop) {
    if (this.isFormValid) {
      try {
        this.loading = true;

        const { data } = await httpFacade.menu.createMenuLine(this.requestData);

        onFulfilled(data);
      } finally {
        this.loading = false;
      }
    }
  }
}

export default LineForm;
