import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './SubsectionLayout.module.scss';

interface Props {
  title?: string;
  controls?: JSX.Element;
}

@observer
class SubsectionLayout extends Component<Props> {
  render() {
    const { title, controls, children } = this.props;

    return (
      <div className={style.wrapper}>
        {title && (
          <div className={style.header}>
            <h2 className={style.title}>
              <FormattedMessage id={title} />
            </h2>

            {controls}
          </div>
        )}
        {children}
      </div>
    );
  }
}

export default SubsectionLayout;
