import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from '../OrderDelivery.module.scss';

import RadioField from '../../../../Form/Fields/Radio/RadioField';
import classNames from 'classnames';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import {
  OrderDeliveryDTO,
  TypesOfDelivery,
} from 'stores/Forms/OrderDeliveryForm/types';
import LocationsDeliveryForm from './LocationsDeliveryForm';
import PickupDeliveryForm from './PickupDeliveryForm';

interface Props {
  userProfileOrderStore?: UserProfileOrder;
  onChangeBasketMode: () => void;
  onSubmit: (data: OrderDeliveryDTO) => void;
}

@observer
class Delivery extends Component<Props> {
  onSubmit = requestData => {
    this.props.onSubmit(requestData);
  };

  onChangeDeliveryType = value => {
    this.props.userProfileOrderStore!.changeDeliveryType(value);
  };

  render() {
    const {
      deliveryTypes,
      isPickupFeatureExist,
      isDelivery,
      typeOfDelivery = deliveryTypes[0],
    } = this.props.userProfileOrderStore!;

    return (
      <>
        <div className={classNames(style.form__row, style.form__row__delivery)}>
          {deliveryTypes.map(value => (
            <RadioField
              title={value}
              key={value}
              label={
                <div className={style.radio__label}>
                  <div
                    className={classNames(
                      style.icon,
                      value === TypesOfDelivery.pickup
                        ? style.icon__pickup
                        : style.icon__delivery,
                    )}
                  />
                  <FormattedMessage id={value} defaultMessage={value} />
                </div>
              }
              name={value}
              value={value}
              checked={typeOfDelivery === value}
              onChange={this.onChangeDeliveryType}
              radioButtonStyleType={'circle'}
              className={classNames(style.radio, {
                [style.radio__active]: typeOfDelivery === value,
              })}
            />
          ))}
        </div>

        {isDelivery ? (
          <LocationsDeliveryForm
            onSubmit={this.onSubmit}
            onChangeBasketMode={this.props.onChangeBasketMode}
            userProfileOrderStore={this.props.userProfileOrderStore}
          />
        ) : isPickupFeatureExist ? (
          <PickupDeliveryForm
            onSubmit={this.onSubmit}
            onChangeBasketMode={this.props.onChangeBasketMode}
            userProfileOrderStore={this.props.userProfileOrderStore}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}

export default Delivery;
