import RootStore from '../stores/RootStore';

export const EUR = '€';

export function toCurrency(value: number): number {
  return Number(
    // @ts-ignore
    new Intl.NumberFormat(RootStore.localization.locale, {
      style: 'decimal',
      maximumFractionDigits: 2,
      useGrouping: false,
    }).format(value * 100),
  );
}
