import { DISPLAY_NAME_PREFIX, VALIDATE_ERROR_PREFIX } from './constants';

export function camelCase(prefix: string, others: string) {
  return prefix + others[0].toUpperCase() + others.substr(1);
}

export function getDisplayNameProp(name: string) {
  return camelCase(DISPLAY_NAME_PREFIX, name);
}

export function getErrorPropName(name: string) {
  return camelCase(VALIDATE_ERROR_PREFIX, name);
}

export function getDisplayName(target: any, propName: string) {
  const displayNameProp = getDisplayNameProp(propName);
  return target[displayNameProp] || camelCase('', propName);
}

export function setDisplayName(target: any, propName: string, value: string) {
  const displayNameProp = getDisplayNameProp(propName);
  target[displayNameProp] = value;
}
