import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { WrappedComponentProps } from 'react-intl';

import style from './UserProfileMenu.module.scss';

import ProfileMenuStore from 'stores/UserProfile/ProfileMenuStore';
import RootStore from 'stores/RootStore';

import Spinner from 'components/Spinner/Spinner';
import { Col } from 'components/Grid/Grid';
import MenuCalendar from './MenuCalendar/MenuCalendar';
import MenuRecommendation from './MenuRecommendation/MenuRecommendation';
import MenuList from './MenuList/MenuList';
import MenuConfiguration from './MenuConfiguration/MenuConfiguration';

@observer
class UserProfileMenu extends Component<WrappedComponentProps> {
  menu = new ProfileMenuStore(RootStore.user.id);

  async componentDidMount() {
    await Promise.all([this.menu.fetchMenu(), this.menu.init()]);
  }

  render() {
    return (
      <Provider menu={this.menu}>
        <div className={style.content}>
          <Col sizes={{ md: 3, sm: 12 }} className={style.col__calendar}>
            <div className={style.calendar__block}>
              <MenuCalendar menu={this.menu} />

              <MenuConfiguration menu={this.menu} />
            </div>
          </Col>
          {this.menu.loading ? (
            <Spinner contain />
          ) : (
            <Col sizes={{ md: 9, sm: 12 }} className={style.col__menu__block}>
              <Col
                sizes={{ md: 3, sm: 12 }}
                className={style.col__recommendation}
              >
                <MenuRecommendation menu={this.menu} />
              </Col>
              <Col sizes={{ md: 9, sm: 12 }} className={style.col__menu}>
                <MenuList menu={this.menu} />
              </Col>
            </Col>
          )}
        </div>
      </Provider>
    );
  }
}

export default UserProfileMenu;
