import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import style from './ChangePassword.module.scss';
import { ModalProps } from 'stores/ModalStore';
import Modal from 'rc-dialog';
import Button from '../../Button/Button';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import TextField from '../../Form/Fields/Text/TextField';
import ChangePasswordForm from '../../../stores/Forms/ChangePasswordForm';
import ValidationBlock from './components/ValidationBlock';

interface Props extends ModalProps {
  userId: string;
}

@observer
class ChangePasswordModal extends Component<Props> {
  form = new ChangePasswordForm(this.props.userId);
  formControl = bindFormControl(this.form);

  onSubmit = async event => {
    event.preventDefault();
    const onFulfilled = payload => this.props.onSubmit!(payload);
    this.form.submit(onFulfilled);
  };

  render() {
    const FormControl = this.formControl;
    const {
      isValidCharactersNumber,
      isValidUppercaseAndLowercase,
      isValidHaveNumber,
      isFormValid,
    } = this.form;

    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <form name="comment-form" onSubmit={this.onSubmit}>
          <h3 className={style.title}>
            <FormattedMessage id={'PASSWORD_CHANGE'} />
          </h3>
          <div className={style.body}>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="previousPassword"
                render={props => <TextField {...props} />}
              />
            </div>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="newPassword"
                render={props => <TextField {...props} />}
              />
            </div>
            <div className={style.form__row}>
              <FormControl
                className={style.form__control}
                name="confirmNewPassword"
                render={props => <TextField {...props} />}
              />
            </div>
            <ValidationBlock
              validationStatus={{
                isValidCharactersNumber,
                isValidHaveNumber,
                isValidUppercaseAndLowercase,
              }}
            />
          </div>

          <div className={style.form__controls}>
            <Button
              styleType="ghost"
              onClick={this.props.onClose}
              className={style.btn}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              type="submit"
              styleType="primary"
              className={style.btn}
              disabled={!isFormValid}
            >
              <FormattedMessage id={'button.changePassword'} />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default ChangePasswordModal;
