import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './StornoReport.module.scss';

import Table from 'components/Table/Table';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

import { StornoReport } from 'stores/Report/types';
import config from './columnsConfig';

const OFFSET_TITLE = 80;
const OFFSET_AMOUNT = 100;

interface Props {
  report: StornoReport;
}

@observer
class ReportTable extends Component<Props> {
  @observable styles = new Map();

  onResize = newResized => {
    newResized.forEach(it => {
      this.styles.set(it.id, it.value);
    });
  };

  render() {
    const { report } = this.props;
    const { total } = report;

    return (
      <div className={style.table__wrapper}>
        <div className={style.total__row}>
          <div
            className={style.total__value}
            style={
              this.styles.get('title') && {
                width: this.styles.get('title') + OFFSET_TITLE,
              }
            }
          >
            <FormattedMessage id="title.total" />
          </div>

          <div
            className={style.total__value}
            style={
              this.styles.get('amount') && {
                width: this.styles.get('amount') + OFFSET_AMOUNT,
              }
            }
          >
            {total.amount}
          </div>

          <div className={style.total__value}>
            <FormattedCurrency value={report.total.brutto} withSymbol={false} />
          </div>

          <div className={style.total__value}>
            <FormattedCurrency value={total.vatAmount} withSymbol={false} />
          </div>

          <div className={style.total__value}>
            <FormattedCurrency value={total.netto} withSymbol={false} />
          </div>
        </div>

        <Table
          data={report.menuItems}
          columns={config()}
          className={style.table}
          onResizedChange={this.onResize}
          tableClassName={style.reportTable}
        />
      </div>
    );
  }
}

export default ReportTable;
