import { observable, action, computed } from 'mobx';

import {
  ERROR_FIELDS_NAME_PROP,
  IS_VALID_FORM_NAME_PROP,
  VALIDATORS_NAME_PROP,
} from './constants';

import { getErrorPropName, getDisplayNameProp } from './helpers';

import { ValidationResult } from './types';

export class BaseFormModel {
  @observable validated: boolean = false;

  @action.bound
  errorFor(name: string): ValidationResult[] {
    return this.validationErrorsName(name).filter((er: any) => er);
  }

  @action.bound
  isValid(name: string): boolean {
    return !this.validationErrorsName(name).length;
  }

  @action.bound
  setValue(name: string, value: unknown) {
    this[name] = value;
  }

  @action.bound
  displayName(name: string) {
    const displayedName = this[getDisplayNameProp(name)];
    return displayedName || '';
  }

  @action.bound
  validate(): boolean {
    this.validated = true;
    return this.isFormValid;
  }

  @action.bound
  reset() {
    this.validated = false;
  }

  @action.bound
  private validationErrorsName(name: string) {
    const errors = this[getErrorPropName(name)];
    return errors || [];
  }

  @computed
  get isFormValid() {
    return this[VALIDATORS_NAME_PROP] ? this[IS_VALID_FORM_NAME_PROP] : true;
  }

  @computed
  get invalidFields() {
    return this[ERROR_FIELDS_NAME_PROP] || [];
  }
}
