import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import style from './AsideMenu.module.scss';

import Icon from 'components/Icon/Icon';
import Controls from './Controls';
import Navigation from './Navigation';

import { getRole, Roles } from 'helpers/roles';

import { ROUTES } from 'routes/routes';

import RootStore from 'stores/RootStore';
import AppLogo from '../AppLogo/AppLogo';

@observer
class AsideMenu extends React.Component {
  render() {
    const { user } = RootStore;
    const isHostess = getRole(Roles.hostess);

    return (
      <aside className={style.menu}>
        <AppLogo
          type="header"
          styles={{ link: style.logo__wrapper, image: style.logo }}
        />

        <Navigation />

        {isHostess && <Controls />}

        <div className={style.profile}>
          <div className={style.user__name}>{user.username}</div>

          <Link
            to={ROUTES.login}
            className={style.link__logout}
            onClick={user.logout}
          >
            <Icon type="logout" />
          </Link>
        </div>
      </aside>
    );
  }
}

export default AsideMenu;
