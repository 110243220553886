import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Omit } from 'helpers/types';
import { FormattedMessage } from 'react-intl';

import style from './Slider.module.scss';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (v: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  className?: string;
  label?: string;
  [key: string]: unknown;
}

const Slider: FunctionComponent<Props> = ({ onChange, ...props }) => {
  const onRangeChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(event.target.value, event);

  const { value, className, label, invalid, ...rest } = props;

  return (
    <div className={classNames(style.wrapper, className)}>
      {label && (
        <div className={style.label}>
          <FormattedMessage id={label} />
        </div>
      )}

      <div className={style.slider__wrapper}>
        <input
          type="range"
          onChange={onRangeChange}
          className={style.slider}
          value={value}
          {...rest}
        />

        <div className={style.range__value}>{value}</div>
      </div>
    </div>
  );
};

export default Slider;
