import { ISODateTimeString } from 'helpers/datetime';

export interface MealTypes {
  value: string;
  label: string;
}

export interface ArchiveReqParams {
  orderType?: string;
  patientId?: string;
  station?: string;
  room?: string;
  size: number;
  page: number;
}

export interface IArchiveImage {
  attachmentType: string;
  contentType: string;
  createdDate: string;
  id: string;
  orderId: string;
}

export interface IArchive {
  createdDate?: ISODateTimeString;
  id: string;
  lastModifiedDate?: ISODateTimeString;
  patientId: string;
  patientName?: string;
  referenceNumber?: string;
  room: string;
  state?: OrderState;
  station: string;
  type: MealTypes | string;
}

export interface IArchivePage {
  content: IArchive[];
  pageable: {
    sort: SortArchive;
    offset: number;
    pageSize: number;
    pageNumber: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalElements: number;
  last: boolean;
  totalPages: number;
  first: boolean;
  sort: SortArchive;
  size: number;
  number: number;
  numberOfElements: number;
  empty: boolean;
}

interface SortArchive {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface IImageContent {
  content: string;
}

export enum OrderState {
  DRAFT = 'DRAFT',
  DELIVERED = 'DELIVERED',
  CLOSED_DELIVERED = 'CLOSED_DELIVERED',
  CLOSED_DRAFT = 'CLOSED_DRAFT',
  CLOSED = 'CLOSED',
}
