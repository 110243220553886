import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import style from './FiltersModal.module.scss';
import { DialogActionType, ModalProps } from 'stores/ModalStore';
import Button from 'components/Button/Button';
import ProfileMenuStore from '../../../stores/UserProfile/ProfileMenuStore';
import Icon from '../../Icon/Icon';
import ModalStore from '../../../stores/ModalStore';
import Modal from 'rc-dialog';
import MenuSpecsList from '../../../pages/UserProfile/UserProfileMenu/MenuConfiguration/MenuSpecsList';

interface Props extends ModalProps {
  menu: ProfileMenuStore;
}

@observer
class FiltersModal extends Component<Props> {
  showItems = async () => {
    this.props.menu.showConfiguredMenuItems();
    this.props.onSubmit!();
  };
  onClose = () => {
    this.props.menu.closeModal();
    ModalStore.closeModal(DialogActionType.close);
  };

  render() {
    const { menu } = this.props;

    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={this.onClose}
        visible
      >
        <div className={style.specs__panel}>
          <div className={style.specs}>
            <div className={style.head}>
              <h3 className={style.title}>
                <FormattedMessage id="employeeProfile.menu.filter.title" />
              </h3>

              <Button
                styleType="text"
                onClick={menu.resetFilters}
                className={style.reset}
              >
                <Icon type="reset" />
              </Button>
            </div>
            <MenuSpecsList menu={menu} />
          </div>

          <div className={style.btn__wrapper}>
            <Button onClick={this.showItems} className={style.btn}>
              <FormattedMessage
                id="title.show"
                values={{
                  value: `${menu.menuItemsCount}`,
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default FiltersModal;
