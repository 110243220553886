import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import Modal from 'rc-dialog';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import style from './DatasetSamples.module.scss';

import ModalStore, { ModalProps } from 'stores/ModalStore';
import DatasetSamplesStore, { ZOOM } from 'stores/Modals/DatasetSamplesStore';
import { ISample, MenuItem } from 'stores/Recognition/types';

import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import SamplePanel from './SamplePanel/SamplePanel';
import WarningModal from '../Warning/WarningModal';
import SearchField from 'components/Form/Fields/Search/SearchField';
import Tabs, { AppTab } from 'components/Tabs/Tabs';
import Categories from './Categories';
import LastUsed from './LastUsed';
import SubTags from './SubTags';

interface Props extends ModalProps {
  initialIndex: number;
  samples: ISample[];
  menuItems: MenuItem[];
}

@observer
class DatasetSamplesModal extends Component<Props> {
  store = new DatasetSamplesStore(this.props);
  tabs: AppTab[] = [
    {
      title: 'title.menu',
      component: <Categories store={this.store} />,
    },
    {
      title: 'modal.recognition.menu.last',
      component: <LastUsed store={this.store} />,
    },
  ];

  onZoom = (value: number) => () => {
    this.store.ol.setZoom(value);
  };

  onClose = async () => {
    if (!this.store.isChanged) {
      ModalStore.closeModal();
    }

    if (this.store.isChanged && this.store.ol.isSourceValid) {
      await this.store.saveChanges();
    } else if (this.store.isChanged && !this.store.ol.isSourceValid) {
      await ModalStore.showModal(WarningModal, {
        description: 'modal.recognition.warning',
        type: 'change',
      });
    }

    this.props.onSubmit!(this.store.samples);
  };

  onSearch = value => {
    this.store.searchValue = value;
  };

  render() {
    const { ol, loading, autoLabellingActiveSample } = this.store;

    return (
      <Provider store={this.store}>
        <Modal
          animation="zoom"
          maskAnimation="fade"
          destroyOnClose={true}
          onClose={this.onClose}
          className={style.modal}
          visible
        >
          {loading && <Spinner fullscreen />}

          <div className={style.content}>
            <div className={style.column}>
              <header className={style.panel__header}>
                <h3 className={style.panel__title}>
                  <FormattedMessage id="modal.recognition.panel.title" />
                </h3>

                <Button styleType="text" onClick={this.onClose}>
                  <Icon type="close" className={style.iconClose} />
                </Button>
              </header>

              <SamplePanel />

              <div className={style.controls}>
                <div className={style.btn__wrapper}>
                  <Button
                    title="C"
                    styleType="text"
                    className={style.btn__clear}
                    onClick={ol.resetData}
                  >
                    <FormattedMessage id="modal.recognition.panel.clear" />
                  </Button>

                  <Button
                    title="L"
                    styleType="text"
                    className={style.btn__clear}
                    onClick={autoLabellingActiveSample}
                  >
                    <FormattedMessage id="modal.recognition.panel.auto" />
                  </Button>

                  <Button
                    title="A"
                    styleType="text"
                    className={classNames(style.btn, style.btn__add, {
                      [style.draw__active]: ol.drawModeOn,
                    })}
                    onClick={ol.toggleDrawFunction}
                  >
                    <Icon type="plus" />
                  </Button>

                  <Button
                    title="D"
                    styleType="text"
                    className={style.btn}
                    disabled={!Boolean(ol.activeFeature)}
                    onClick={ol.deleteActiveFeature}
                  >
                    <Icon type="trash" />
                  </Button>
                </div>

                <div className={style.btn__wrapper}>
                  <div className={style.zoom}>
                    <Button
                      title="-"
                      className={style.btn__zoom}
                      styleType="text"
                      onClick={this.onZoom(-ZOOM)}
                    >
                      <Icon type="zoomOut" />
                    </Button>

                    <span className={style.zoom__value}>
                      {ol.zoomPercentage}
                    </span>

                    <Button
                      title="+"
                      className={style.btn__zoom}
                      styleType="text"
                      onClick={this.onZoom(ZOOM)}
                    >
                      <Icon type="zoomIn" />
                    </Button>
                  </div>

                  <Button
                    title="F"
                    onClick={ol.fitView}
                    styleType="text"
                    className={style.btn__fit}
                  >
                    <FormattedMessage id="modal.recognition.panel.fit" />
                  </Button>
                </div>
              </div>
            </div>

            <div className={style.column}>
              <div className={style.field__search}>
                <SearchField
                  onChange={this.onSearch}
                  value={this.store.searchValue}
                />
              </div>

              <Tabs
                persistent={true}
                tabListClass={style.tab__list}
                tabs={this.tabs}
              />

              <SubTags />
            </div>
          </div>
        </Modal>
      </Provider>
    );
  }
}

export default DatasetSamplesModal;
