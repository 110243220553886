import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Modal from 'rc-dialog';

import style from './Receipt.module.scss';

import { ModalProps } from 'stores/ModalStore';
import ReceiptModalStore from 'stores/Modals/ReceiptModal';

import Spinner from 'components/Spinner/Spinner';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Button from 'components/Button/Button';

interface Props extends ModalProps {
  transactionId: string;
  userId: string;
}

@observer
class ReceiptModal extends Component<Props> {
  store = new ReceiptModalStore(this.props.userId, this.props.transactionId);
  containerRef = React.createRef<HTMLDivElement>();

  async componentDidMount() {
    await this.store.init();
  }

  onDownloadClick = async () => {
    if (this.containerRef.current) {
      await this.store.download(this.containerRef.current);
    }
  };

  render() {
    const { transactionId, userId, ...otherProps } = this.props;
    const { receipt } = this.store;
    const { company } = receipt || {};

    return this.store.loading ? (
      <Spinner fullscreen />
    ) : (
      <Modal
        {...otherProps}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div
          id="receipt-print-container"
          className={style.print__wrapper}
          ref={this.containerRef}
        >
          <div className={style.title}>
            <p className={style.title__text}>{company?.name}</p>
            <p className={style.title__text}>{company?.street}</p>
            <p className={style.title__text}>
              {`${company?.postCode} ${company?.town}`}
            </p>
          </div>

          <div className={style.data}>
            <FormattedMessage id="receipt.date" />
            <div>{receipt.transactionDate}</div>

            <FormattedMessage
              id={
                receipt.initialTransactionId
                  ? 'receipt.id.storno'
                  : 'receipt.id'
              }
            />
            <div>{receipt.receiptNumber}</div>

            {receipt.initialTransactionId && receipt.initialReceiptNumber && (
              <>
                <FormattedMessage id="receipt.id.refund" />
                <div>{receipt.initialReceiptNumber}</div>
              </>
            )}
          </div>

          <hr className={style.hr} />

          <div className={style.items}>
            <div className={style.items__header}>
              {/*<FormattedMessage id="receipt.item.column.title"/>*/}
              <FormattedMessage id="receipt.item.column.amount" />
              <FormattedMessage id="receipt.item.column.price.unit" />
              <FormattedMessage id="receipt.item.column.price.final" />

              <hr className={style.grid__hr} />
            </div>

            {receipt.items?.map(it => (
              <div className={style.items__item} key={it.title}>
                <div className={style.item__title}>{it.title}</div>

                <FormattedNumber value={it.amount} />
                <FormattedCurrency value={it.pricePerUnit} />
                <FormattedCurrency value={it.finalPrice} />

                <div>
                  <FormattedMessage id="receipt.item.column.vat.total" />{' '}
                  <FormattedNumber value={it.vat.rate} />%
                </div>

                <FormattedCurrency value={it.vat.value} />
              </div>
            ))}
          </div>

          <hr className={style.hr} />

          <div className={style.receipt__prices}>
            <FormattedMessage id="receipt.price.original" />
            <FormattedCurrency value={receipt.originalPrice} />

            <FormattedMessage id="receipt.vat.total" />
            <FormattedCurrency value={receipt.totalVatValue} />

            <FormattedMessage id="receipt.discount" />
            <FormattedCurrency value={receipt.discount} />
          </div>

          <hr className={style.hr} />

          <div className={style.price__final}>
            <FormattedMessage id="receipt.price.final" />
            <FormattedCurrency value={receipt.finalPrice} />
          </div>

          {receipt.tseData && (
            <>
              <hr className={style.hr} />

              <div className={style.receipt__tseData}>
                <FormattedMessage id="receipt.tse.signatureValue" />
                <div>{receipt.tseData.signatureValue}</div>

                <FormattedMessage id="receipt.tse.transactionNumber" />
                <div>{receipt.tseData.transactionNumber}</div>

                <FormattedMessage id="receipt.tse.start" />
                <div>{receipt.tseData.start}</div>

                <FormattedMessage id="receipt.tse.stop" />
                <div>{receipt.tseData.stop}</div>

                <FormattedMessage id="receipt.tse.clientSerialNumber" />
                <div>{receipt.tseData.clientSerialNumber}</div>

                <FormattedMessage id="receipt.tse.signatureCounter" />
                <div>{receipt.tseData.signatureCounter}</div>

                <FormattedMessage id="receipt.tse.timestampFormat" />
                <div>{receipt.tseData.timestampFormat}</div>

                <FormattedMessage id="receipt.tse.signatureAlgorithm" />
                <div>{receipt.tseData.signatureAlgorithm}</div>

                <FormattedMessage id="receipt.tse.signaturePublicKey" />
                <div>{receipt.tseData.signaturePublicKey}</div>
              </div>
            </>
          )}
        </div>

        <div className={style.btn__wrapper}>
          <Button
            className={style.btn__download}
            onClick={this.onDownloadClick}
          >
            <FormattedMessage id="button.save.receipt" />
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ReceiptModal;
