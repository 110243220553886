import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import style from '../OnlineOrder.module.scss';
import NoContentBlock from 'components/NoContentBlock/NoContentBlock';
import NoMenuForToday from './components/NoMenuForToday';
import MenuHeader from './components/MenuHeader';
import Menu from './components/Menu';

@inject('store')
@observer
class MenuLayout extends Component<{ store?: UserProfileOrder }> {
  render() {
    const { store } = this.props;
    return (
      <section className={style.menu}>
        <MenuHeader />

        {store!.isShowNoContentBlock ? (
          <NoContentBlock
            text={'title.noMenu'}
            icon={'menuEmpty'}
            className={style.noContent__block}
          />
        ) : store?.isExitDateOffsetForOrders ? (
          <NoMenuForToday />
        ) : store!.isShowTodayMenu ? (
          <Menu collection={store!.collectionMenu} menu={store!.sortedMenu} />
        ) : (
          <Menu
            collection={store!.collectionStandardMenu}
            menu={store!.sortedStandardMenu}
          />
        )}
      </section>
    );
  }
}

export default MenuLayout;
