import React, { Component } from 'react';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';

import ServerError from 'components/Errors/500/ServerError';
import NotFoundError from 'components/Errors/404/NotFoundError';

function getErrorPage() {
  if (RootStore.serverError) {
    return <ServerError />;
  } else if (RootStore.notFoundError) {
    return <NotFoundError />;
  }

  return null;
}

@observer
class ErrorPage extends Component {
  render() {
    return getErrorPage();
  }
}

export default ErrorPage;
