import React from 'react';
import { Column } from 'react-table';
import classNames from 'classnames';

import style from './Employees.module.scss';

import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import { withArrow } from 'components/Table/helpers';

export const hostessConfig: Column[] = [
  {
    Header: withArrow('title.nutzerNummer'),
    accessor: 'id',
    resizable: false,
    minWidth: 200,
  },
  {
    Header: withArrow('title.nickname'),
    accessor: 'nickname',
    resizable: false,
    minWidth: 280,
  },
  {
    Header: withArrow('title.rfid'),
    accessor: 'rfid',
    resizable: false,
    minWidth: 55,
    className: 'tags',
    Cell: row => (
      <span
        className={classNames(style.tag, {
          [style.tag__exist]: row.value,
        })}
      />
    ),
  },
  {
    id: 'operation',
    resizable: false,
    minWidth: 40,
    className: 'operations',
    Cell: <Icon type="assign" className="assign-icon" />,
    sortable: false,
  },
];

export const accountantConfig: Column[] = [
  {
    Header: withArrow('title.nutzerNummer'),
    accessor: 'id',
    resizable: false,
    minWidth: 250,
  },
  {
    Header: withArrow('title.nickname'),
    accessor: 'nickname',
    resizable: false,
    minWidth: 280,
  },
  {
    Header: withArrow('title.balance'),
    accessor: 'balance',
    resizable: false,
    minWidth: 120,
    className: 'balance',
    Cell: row => <FormattedCurrency value={row.value} />,
  },
  {
    Header: withArrow('title.refund', style.refunded__header),
    accessor: 'refunded',
    resizable: false,
    minWidth: 80,
    className: 'tags',
    Cell: row => (
      <span
        className={classNames(style.tag, {
          [style.tag__exist]: row.value,
        })}
      />
    ),
  },
];
