export type CurrencyValue = number;

export enum TransactionTypes {
  ORDER = 'ORDER',
  DEPOSIT = 'DEPOSIT',
  UNDEPOSIT = 'UNDEPOSIT',
  OVERDRAFT = 'OVERDRAFT',
  UNOVERDRAFT = 'UNOVERDRAFT',
  STRIPE_REFILL = 'STRIPE_REFILL',
  STRIPE_REFUND = 'STRIPE_REFUND',
  BALANCE_MIGRATION = 'BALANCE_MIGRATION',
  ORDER_STORNO = 'ORDER_STORNO',
  PAYONE_REFILL = 'PAYONE_REFILL',
  PAYONE_REFUND = 'PAYONE_REFUND',
  DEPOSIT_ITEMS_REFUND = 'DEPOSIT_ITEMS_REFUND',
}

export interface Dictionary {
  id: string;
  name: string;
}

export enum FORM_STATUS {
  pending = 'pending',
  open = 'open',
  submitted = 'submitted',
  error = 'error',
}
