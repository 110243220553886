import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import { getRole, Roles } from 'helpers/roles';
import ProfileStore from 'stores/EmployeeProfile/ProfileStore';
import Tags from '../Tags/Tags';

interface Props {
  profile?: ProfileStore;
}

@inject('profile')
@observer
class ProfileContacts extends Component<Props> {
  render() {
    const { profile } = this.props;
    const isHostess = getRole(Roles.hostess);
    const userContacts = [
      {
        value: profile!.id,
        label: 'NUTZERNUMMER',
      },
      {
        value: profile!.nickname,
        label: 'NICKNAME',
      },
    ];

    return (
      <section className={'profileContacts__contacts'}>
        {isHostess && (
          <div className={'profileContacts__tags_wrapper'}>
            <Tags profile={profile!} />
          </div>
        )}

        <h3 className={'profileContacts__title'}>
          <FormattedMessage id="profile.information.title" />
        </h3>

        <div className={'profileContacts__info'}>
          {userContacts.map(it => (
            <Fragment key={it.label}>
              <div className={'profileContacts__info_label'}>
                <FormattedMessage id={it.label} />
              </div>

              <div className={'profileContacts__info_value'}>{it.value}</div>
            </Fragment>
          ))}
        </div>
      </section>
    );
  }
}

export default ProfileContacts;
