import { adapterApi as adapter } from './Api/adapter';
import { financeApi as finance } from './Api/finance';
import { orderApi as order } from './Api/order';
import { RegistrationApi as registration } from './Api/registration';
import { staffApi as staff } from './Api/staff';
import { userApi as user } from './Api/User/user';
import { employeesApi as employees } from './Api/employees';
import { profileApi as profile } from './Api/profile';
import { paymentApi as payment } from './Api/payment';
import { rootApi as root } from './Api/root';
import { menuApi as menu } from './Api/menu';
import { recognitionApi as recognition } from './Api/recognition';
import { onlineOrdersApi as onlineOrders } from './Api/onlineOrders';
import { archiveApi as archive } from './Api/archive';
import { cateringApi as catering } from './Api/catering';
import { menuItemsApi as menuItems } from 'http/Api/MenuItem/menuItems';
import { loggerApi as logger } from 'http/Api/logger';

const httpFacade = {
  menuItems,
  catering,
  menu,
  adapter,
  root,
  finance,
  order,
  registration,
  user,
  staff,
  employees,
  profile,
  payment,
  recognition,
  onlineOrders,
  archive,
  logger,
};

export default httpFacade;
