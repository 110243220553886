import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './Fallback.module.scss';

import Icon from 'components/Icon/Icon';
import classNames from 'classnames';

class Fallback extends Component<{ className?: string }> {
  render() {
    return (
      <div className={classNames(style.fallback, this.props.className)}>
        <Icon type="brokenImage" className={style.icon} />

        <p className={style.description}>
          <FormattedMessage id="error.image.broken" />
        </p>
      </div>
    );
  }
}

export default Fallback;
