import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './ArchiveForm.module.scss';

import { bindFormControl } from 'components/Form/FormControl/FormControl';
import SelectField from 'components/Form/Fields/Select/SelectField';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';

import ArchiveSearchForm from 'stores/Forms/Archive/ArchiveSearchForm';

@observer
class ArchiveForm extends Component<{ form: ArchiveSearchForm }> {
  formControl = bindFormControl(this.props.form);

  searchArchive = async () => {
    await this.props.form.onSearch();
  };

  resetForm = async () => {
    await this.props.form.onReset();
  };

  render() {
    const FormControl = this.formControl;
    const { form } = this.props;

    return (
      <form className={style.form}>
        <div className={style.form__fields}>
          <div>
            <FormControl
              name="date"
              render={props => (
                <Datepicker
                  {...props}
                  styles={{
                    calendar: style.datepicker,
                    button: style.datepicker__button,
                  }}
                  value={form.date}
                  selectRange={false}
                  showPeriods={false}
                  icon={true}
                />
              )}
            />
          </div>

          <FormControl
            name="mealType"
            className={style.form__field}
            render={props => (
              <SelectField
                {...props}
                options={form.mealTypes}
                value={form.mealType}
              />
            )}
          />

          <FormControl
            name="station"
            className={style.form__field}
            render={props => (
              <TextField {...props} className={style.field__text} />
            )}
          />
          <FormControl
            name="room"
            className={style.form__field__small}
            render={props => (
              <TextField {...props} className={style.field__text} />
            )}
          />
          <FormControl
            name="patientId"
            className={style.form__field__small}
            render={props => (
              <TextField {...props} className={style.field__text} />
            )}
          />
        </div>

        <div className={style.form__controls}>
          <Button
            styleType={'ghost'}
            className={style.control}
            onClick={this.resetForm}
          >
            <FormattedMessage id="button.reset" />
          </Button>
          <Button
            styleType={'primary'}
            className={style.control}
            onClick={this.searchArchive}
            disabled={!form.isFormValid}
          >
            <FormattedMessage id="button.search" />
          </Button>
        </div>
      </form>
    );
  }
}

export default ArchiveForm;
