import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './DishPage.module.scss';

import DishForm, { MENU_ITEM_LIMITS } from 'stores/Forms/DishForm';
import AppRouter from 'stores/AppRouter';
import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';

import { ROUTES } from 'routes/routes';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { bindFormControl } from 'components/Form/FormControl/FormControl';
import Button from 'components/Button/Button';
import SelectField from 'components/Form/Fields/Select/SelectField';
import CategoryModal from 'components/Modals/Category/CategoryModal';
import TextArea from 'components/Form/Fields/TextArea/TextAreaField';
import Spinner from 'components/Spinner/Spinner';
import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import Allergens from './Allergens/Allergens';
import { AdditionalInformation } from './AdditionalInformation/AdditionalInformation';
import BaseSpecifications from './BaseSpecifications/BaseSpecifications';
import Additives from './BaseSpecifications/Additives';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import FileUploader from 'components/Form/Fields/FileUpload/FileUploader';
import CurrencyInput from 'components/Form/Fields/Number/CurrencyInput';
import TooltipComponent from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';

@observer
class DishPage extends Component {
  form: DishForm;
  formControl;
  translation = {
    mg: RootStore.localization.formatMessage('MG'),
    gr: RootStore.localization.formatMessage('GR'),
    placeholderName: RootStore.localization.formatMessage('placeholder.name'),
  };

  constructor(props) {
    super(props);

    this.form = new DishForm(props.match.params.id);
    this.formControl = bindFormControl(this.form);
  }

  async componentDidMount() {
    await this.form.init();
  }

  onSubmit = async event => {
    event.preventDefault();

    await this.form.submit(AppRouter.goBack);
  };

  onCancel = () => {
    AppRouter.goBack();
  };

  openCategoryModal = async () => {
    const { payload } = await ModalStore.showModal(CategoryModal);

    this.form.updateDishCategories(payload);
  };

  onToggleVatRate = (isChecked: boolean, ratValue: number): void => {
    this.form.toggleVatRate(ratValue);
  };

  toVatHint = (vatValue: number): string => {
    if (vatValue === 700) {
      return 'form.dish.vat.takeAwayHint';
    } else if (vatValue === 1900) {
      return 'form.dish.vat.onSiteHint';
    } else {
      return 'unknown vat';
    }
  };

  render() {
    const FormControl = this.formControl;
    const { placeholderName } = this.translation;

    return (
      <Provider form={this.form}>
        <main className={style.main}>
          <header className={style.header}>
            <Breadcrumbs
              links={[
                { to: ROUTES.admin.menuItems, name: 'title.dishes' },
                { name: RootStore.localization.formatMessage('title.newDish') },
              ]}
              className="admin-header--breadcrumbs"
            />
          </header>

          {this.form.loading ? (
            <Spinner contain />
          ) : (
            <form className={style.form} onSubmit={this.onSubmit}>
              <section className={style.basic}>
                <h2 className={style.basic__title}>
                  <FormattedMessage id="form.dish.basic.title" />
                </h2>

                <div className={style.form__group}>
                  <FormControl
                    name="category"
                    className={style.category__field}
                    render={props => (
                      <SelectField {...props} options={this.form.categories} />
                    )}
                  />

                  <div className={style.dish__category}>
                    <FormattedMessage id="form.dish.basic.category.text" />

                    <Button
                      styleType="text"
                      className={style.category__btn}
                      onClick={this.openCategoryModal}
                    >
                      <FormattedMessage id="form.dish.basic.category.button" />
                    </Button>
                  </div>
                </div>

                <TagsSelect
                  title="form.dish.labels"
                  tags={this.form.labelsManager.labels}
                  selectedTags={this.form.sortedLabels}
                  onSubmit={this.form.setLabels}
                  onRemoveTag={this.form.unselectLabel}
                  inputTypes="checkbox"
                  isShowTags={false}
                  isShowSearchField={true}
                  editModeActions={{
                    onDeleteTag: this.form.labelsManager.deleteLabel,
                    onCreateTag: this.form.labelsManager.createLabel,
                  }}
                />

                <div className={style.form__group}>
                  <FormControl
                    name="title"
                    render={props => (
                      <TextArea
                        {...props}
                        maxLength={MENU_ITEM_LIMITS.maxTitleLength}
                        placeholder={placeholderName}
                      />
                    )}
                  />
                </div>

                <div className={style.form__group}>
                  <FormControl
                    name="description"
                    render={props => (
                      <TextArea
                        {...props}
                        maxLength={MENU_ITEM_LIMITS.maxDescriptionLength}
                        className={style.textarea}
                        showLimit
                      />
                    )}
                  />
                </div>

                <div className={style.form__group}>
                  <FormControl
                    name="price"
                    render={props => <CurrencyInput {...props} />}
                  />
                </div>

                <div
                  className={classNames(
                    style.form__group,
                    style.vat_form__rates_container,
                  )}
                >
                  <FormControl
                    name="vatRates"
                    render={(props, validateFn) => {
                      return (
                        <div className={classNames(style.vat_form__grid)}>
                          {this.form.vats.map(it => (
                            <div
                              key={it.value}
                              className={classNames(style.vat__rates__column)}
                            >
                              <div
                                className={classNames(
                                  style.vat__value_switcher_container,
                                )}
                              >
                                <SwitchButton
                                  className={style.vat__value_switcher}
                                  checked={this.isVatRatePresent(it.value)}
                                  onChange={(
                                    isChecked: boolean,
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    this.onToggleVatRate(isChecked, it.value);
                                    validateFn();
                                  }}
                                />
                                <TooltipComponent
                                  content={
                                    <span>
                                      <FormattedMessage
                                        id={`form.dish.vat.tooltip.${it.value}`}
                                      />
                                    </span>
                                  }
                                >
                                  <Icon
                                    className={style.tooltip}
                                    type="helpCircle"
                                  />
                                </TooltipComponent>
                              </div>

                              <span
                                className={classNames(
                                  style.label,
                                  style.vat__value_hint,
                                )}
                              >
                                <FormattedMessage
                                  id={this.toVatHint(it.value)}
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>

                <div className={classNames(style.form__group, style.switcher)}>
                  <span
                    className={classNames(
                      style.label,
                      this.form.isDisabledSubsidyField && style.label__disabled,
                    )}
                  >
                    <FormattedMessage
                      id={
                        this.form.subsidized ? 'Subsidised' : 'Not_Subsidesed'
                      }
                    />
                  </span>

                  <FormControl
                    name="subsidized"
                    render={props => (
                      <SwitchButton
                        {...props}
                        checked={this.form.subsidized}
                        disabled={this.form.isDisabledSubsidyField}
                      />
                    )}
                  />
                </div>

                <div className={classNames(style.form__group, style.switcher)}>
                  <FormattedMessage id="form.dish.quick.access" />

                  <FormControl
                    name="quickAccess"
                    render={props => (
                      <SwitchButton
                        {...props}
                        checked={this.form.quickAccess}
                      />
                    )}
                  />
                </div>

                <div className={classNames(style.form__group, style.switcher)}>
                  <FormattedMessage id="form.dish.not.visible.kiosk.self.checkout" />

                  <FormControl
                    name="notVisibleKioskSelfCheckout"
                    render={props => (
                      <SwitchButton
                        {...props}
                        checked={this.form.notVisibleKioskSelfCheckout}
                      />
                    )}
                  />
                </div>

                <div className={style.form__group}>
                  <FormControl
                    name="depositItemId"
                    className={style.field__depositItem}
                    render={props => (
                      <SelectField
                        {...props}
                        options={this.form.depositItemsValues}
                        value={this.form.depositItemId}
                        disabled={this.form.isDepositItemsCategory}
                      />
                    )}
                  />
                </div>

                <div className={classNames(style.form__group)}>
                  <FormControl
                    name="image"
                    className={style.field__fileUploader}
                    render={props => (
                      <FileUploader
                        {...props}
                        file={this.form.image || this.form.dishImageUrl}
                        onRemove={this.form.removeImage}
                      />
                    )}
                  />
                </div>

                <div className={style.form__group}>
                  <h3 className={style.group__title__allergen}>
                    <FormattedMessage id="title.allergens" />
                  </h3>
                  <Allergens />
                </div>

                <Additives />
              </section>

              <div className={style.wrapper}>
                {this.form?.isExtendedAvailable.enabled && (
                  <AdditionalInformation />
                )}
                <div
                  className={classNames(style.form__group, style.spec_block)}
                >
                  <BaseSpecifications />
                </div>
              </div>

              <div className={style.controls}>
                <Button
                  className={style.btn}
                  styleType="ghost"
                  onClick={this.onCancel}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>

                <Button
                  disabled={!Boolean(this.form.isFormValid)}
                  className={style.btn}
                  styleType="primary"
                  type="submit"
                >
                  <FormattedMessage
                    id={this.form.id ? 'button.save' : 'button.create.dish'}
                  />
                </Button>

                <div className={style.explanation__text}>
                  <FormattedMessage id={'form.explanation.text'} />
                </div>
              </div>
            </form>
          )}
        </main>
      </Provider>
    );
  }

  private isVatRatePresent(vatRateToCheck: number) {
    const found = this.form.vatRates.find(v => v === vatRateToCheck);
    return typeof found !== 'undefined';
  }
}

export default DishPage;
