import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import classNames from 'classnames';
import {
  AutoSizer,
  Collection,
  CollectionCellSizeAndPositionGetter,
  Grid,
} from 'react-virtualized';
import style from '../../OnlineOrder.module.scss';

import Icon from 'components/Icon/Icon';
import MenuCard from '../../MenuCard/MenuCard';
import { Desktop, Mobile } from '../../../../../types/layout';
import {
  MenuItem,
  OnlineOrderMenuStandard,
} from '../../../../../stores/UserProfile/types';
import { MenuLine } from '../../../../../stores/UserProfile/helpers';
import UserProfileOrder from '../../../../../stores/UserProfile/UserProfileOrder';

const GUTTER_SIZE = 24;
const CELL_WIDTH = 280;
const CELL_HEIGHT = 400;
const COLUMN_WIDTH = 186;
const CONTENT_PADDING = 16;

interface Props {
  collection: MenuItem[];
  menu: Array<OnlineOrderMenuStandard | MenuLine | any>;
  store?: UserProfileOrder;
}

@observer
class Menu extends Component<Props> {
  private columnYMap: number[];
  private collectionRef;

  constructor(props) {
    super(props);
    this.columnYMap = [];
    this.collectionRef = React.createRef<HTMLElement>();
  }

  // ForceUpdate  cells of Collection after cancelling or submitting order
  componentDidUpdate(prevProps) {
    if (
      this?.collectionRef?.current &&
      this.props.collection !== prevProps.collection
    ) {
      this.collectionRef.current.forceUpdate();
    }
  }

  cellMenuSizeAndPositionGetter = ({ index }: { index: number }): any => {
    let y;

    if (index === 0) {
      this.columnYMap = [];
    }
    const columnCount = this?.props?.menu?.length ?? 0;
    const columnPosition = index % (columnCount || 1);

    const height = CELL_HEIGHT;
    const width = CELL_WIDTH;
    const x = columnPosition * (GUTTER_SIZE + width);

    y = this.columnYMap[columnPosition] || 0;

    this.columnYMap[columnPosition] = y + height + GUTTER_SIZE;

    return ({
      height,
      width,
      x,
      y,
    } as unknown) as CollectionCellSizeAndPositionGetter;
  };

  cellMenuRenderer = ({ index, key, style: styles }) => {
    return (
      <div key={key} style={styles}>
        {this?.props?.collection?.[index]?.id ? (
          this.renderMenuCard(this?.props?.collection?.[index])
        ) : (
          <div style={{ width: CELL_WIDTH, height: 1 }} />
        )}
      </div>
    );
  };

  cellMobileMenuRenderer = ({ columnIndex, style: styles, key }) => items => {
    const item = items[columnIndex];
    return (
      <div key={key} style={styles}>
        {this.renderMenuCard(item)}
      </div>
    );
  };

  render() {
    const { collection, menu } = this.props;

    return (
      <>
        <Desktop>
          <div className={style.menu__lines__wrap}>
            <div className={style.menu__lines}>
              {this?.props?.menu?.map?.(line => (
                <div
                  key={line?.id || line?.dishGroupId}
                  className={classNames(style.menu__line, {
                    [style.menu__line__prioritized]: line?.prioritized,
                  })}
                >
                  {this.renderLineHeader(line)}
                </div>
              ))}
            </div>
            <div style={{ height: '100%' }}>
              <AutoSizer disableWidth>
                {({ height }) => (
                  <Collection
                    ref={this.collectionRef}
                    cellCount={collection?.length}
                    cellRenderer={this.cellMenuRenderer}
                    cellSizeAndPositionGetter={
                      this.cellMenuSizeAndPositionGetter
                    }
                    width={menu?.length * (CELL_WIDTH + GUTTER_SIZE)}
                    height={height - CONTENT_PADDING}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
        </Desktop>

        <Mobile>
          <div className={style.menu__lines}>
            {menu?.map?.(line => (
              <div
                key={line?.id || line?.dishGroupId}
                className={classNames(style.menu__line, {
                  [style.menu__line__prioritized]: line?.prioritized,
                })}
              >
                {this.renderLineHeader(line)}

                <AutoSizer>
                  {({ width, height }) => (
                    <Grid
                      cellRenderer={props =>
                        this.cellMobileMenuRenderer(props)(line?.items)
                      }
                      columnCount={line?.items?.length}
                      columnWidth={COLUMN_WIDTH}
                      height={height - CONTENT_PADDING}
                      rowCount={1}
                      rowHeight={CELL_HEIGHT}
                      width={width}
                      overscanColumnCount={1}
                    />
                  )}
                </AutoSizer>
              </div>
            ))}
          </div>
        </Mobile>
      </>
    );
  }

  @action
  renderLineHeader(line) {
    return (
      <div className={style.line__header}>
        {line?.prioritized && <Icon type="star" className={style.line__icon} />}
        <h3 className={style.line__title}>
          {line?.title || line?.dishGroupTitle}
        </h3>
      </div>
    );
  }

  @action
  renderMenuCard(item) {
    return (
      <MenuCard
        key={item.id}
        className={classNames(style.menu__card, {
          [style.menu__card__active]: Boolean(item.amount),
        })}
        menuItem={item}
        editable
      />
    );
  }
}

export default Menu;
