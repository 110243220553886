import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './Help.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

interface Props {
  message: string;
  onClose: () => void;
  className?: string;
}

@observer
class Help extends Component<Props> {
  render() {
    const { message, onClose, className } = this.props;

    return (
      <div className={classNames(style.popup, className)}>
        <Button className={style.btn} onClick={onClose}>
          Х
        </Button>

        <div className={style.message}>
          <div className={style.icon__wrapper}>
            <Icon type="popup" />
          </div>

          <FormattedMessage id={message} />
        </div>
      </div>
    );
  }
}

export default Help;
