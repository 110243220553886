import React, { RefObject } from 'react';

export const useClickOutside = (
  refs: Array<RefObject<HTMLElement | null>>,
  callback: any,
) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (
        refs.some(ref => ref.current && ref.current.contains(target)) ||
        !callback
      ) {
        return;
      }

      callback();
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [refs, callback]);
};
