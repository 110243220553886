import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import style from './MenuList.module.scss';

import MenuRow from '../MenuRow/MenuRow';
import { Col } from 'components/Grid/Grid';
import NoContentBlock from 'components/NoContentBlock/NoContentBlock';

import ProfileMenuStore from 'stores/UserProfile/ProfileMenuStore';
import { action } from 'mobx';

@observer
class MenuList extends Component<{
  menu: ProfileMenuStore;
}> {
  render() {
    const { menu } = this.props;

    if (
      menu.plannerLinesForCurrentDay?.every?.(
        line => !line?.days[0]?.items?.length,
      )
    ) {
      return (
        <NoContentBlock
          text={'title.noMenu'}
          icon={'menuEmpty'}
          className={style.noContent}
        />
      );
    }
    return (
      <>
        <div className={style.wrapper}>
          <div className={style.menu}>
            {menu.plannerLinesForCurrentDay?.map?.((line, index) => {
              if (line.days.length > 0 && line.days[0].items.length > 0) {
                return (
                  <Col
                    sizes={{ md: 3, sm: 12 }}
                    key={line.id}
                    className={classNames(style.menu__line, {
                      [style.line__prioritized]: line.prioritized,
                    })}
                  >
                    <span
                      className={classNames(style.title, {
                        [style.title__prioritized]: line.prioritized,
                      })}
                    >
                      {line.title}
                    </span>
                  </Col>
                );
              }
            })}
          </div>
          <div className={style.menu}>
            {this.renderMenuLine(menu.plannerLinesForCurrentDay)}
          </div>
        </div>

        <div className={classNames(style.menu, style.menu_mobile)}>
          {this.renderMenuLine(menu.plannerLinesForCurrentDay)}
        </div>
      </>
    );
  }

  @action
  renderMenuLine(lines) {
    return lines?.map?.((line, index) => {
      if (line.days.length > 0 && line.days[0].items.length > 0) {
        return (
          <Col
            sizes={{ md: 3, sm: 12 }}
            key={line.id}
            className={classNames(style.menu__line, {
              [style.line__prioritized]: line.prioritized,
            })}
          >
            <MenuRow line={line} key={line.id || index} />
          </Col>
        );
      }
    });
  }
}

export default MenuList;
