import { action, observable } from 'mobx';
import { MenuItemLabel } from 'http/Api/MenuItem/types';
import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';

class MenuItemLabelsStore {
  @observable labels: MenuItemLabel[] = [];
  @observable loading = false;

  @action.bound
  async init() {
    try {
      this.loading = true;

      const { data } = await httpFacade.menuItems.fetchLabels();

      this.labels = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async createLabel(title: string) {
    try {
      this.loading = true;
      const { data } = await httpFacade.menuItems.createLabel({ title });

      this.labels = [...this.labels, data];
      return { tag: data };
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async deleteLabel(id: string) {
    try {
      this.loading = true;
      await httpFacade.menuItems.deleteLabel(id);

      this.labels = this.labels.filter(it => it.id !== id);
      return { id };
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default MenuItemLabelsStore;
