import { action, computed, observable } from 'mobx';

import Log from 'helpers/log';

import httpFacade from 'http/httpFacade';

import { UserGroup } from '../Staff/types';
import { DishGroupManagement } from '../Menu/types';
import { Discount } from '../Subsidies/types';
import { sortByAccessor } from '../../helpers/accessors';

class SubsidyModalStore {
  @observable loading = false;
  @observable id?: string = '';
  @observable step = 0;
  @observable userGroups: UserGroup[] = [];
  @observable categories: DishGroupManagement[] = [];
  @observable selectedUserGroup?: UserGroup;
  @observable selectedCategories: DishGroupManagement[] = [];

  @observable userGroupsWithSubsidies?: string[] = [];
  @observable private sortConfig = { accessor: 'title', desc: false };

  constructor(discount?: Discount, userGroupsWithSubsidies?: string[]) {
    if (discount) {
      const { details } = discount;

      this.selectedUserGroup = details.userGroup;
      this.id = discount.id;
      this.selectedCategories = [...details.dishGroups].sort(
        sortByAccessor(this.sortConfig),
      );
    }
    this.userGroupsWithSubsidies = userGroupsWithSubsidies;
  }

  @computed
  get filteredUserGroups() {
    return this.userGroupsWithSubsidies?.length
      ? this.userGroups.filter(
          userGroup =>
            !this.userGroupsWithSubsidies?.some?.(id => id === userGroup.id),
        )
      : this.userGroups;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const [groups, categories] = await Promise.all([
        httpFacade.staff.fetchUserGroups(),
        httpFacade.menu.fetchCategories(),
      ]);
      this.userGroups = groups.data.content;
      this.categories = [...categories.data].sort?.(
        sortByAccessor(this.sortConfig),
      );
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setStep(sign: string) {
    this.step = sign === '+' ? this.step + 1 : this.step - 1;
  }

  @action.bound
  selectUserGroup(group: UserGroup[]) {
    this.selectedUserGroup = group[0];
  }

  @action.bound
  removeUserGroup(groupId?: string) {
    const currentGroupId = groupId || this.selectedUserGroup?.id;
    this.selectedUserGroup = undefined;
    if (currentGroupId) {
      this.userGroupsWithSubsidies = this.userGroupsWithSubsidies?.filter(
        id => id !== currentGroupId,
      );
    }
  }

  @action.bound
  selectCategories(categories: DishGroupManagement[]) {
    this.selectedCategories = [...categories].sort(
      sortByAccessor(this.sortConfig),
    );
  }

  @action.bound
  removeCategories(id?: string) {
    this.selectedCategories = id
      ? this.selectedCategories?.filter(item => item.id !== id)
      : [];
  }
}

export default SubsidyModalStore;
