import React, { ReactNode, RefObject, useRef } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { Portal } from 'components/Portal';
import style from './Popover.module.scss';

interface IProps {
  refElement: RefObject<HTMLElement | null>;
  onClose?: () => void;
  children: ReactNode;
}

export const Popover = ({ children, refElement, onClose }: IProps) => {
  const rect = refElement.current?.getBoundingClientRect();
  const popoverRef = useRef<HTMLDivElement>(null);
  useClickOutside([popoverRef, refElement], onClose);

  const styles = rect
    ? {
        top: rect.bottom + 4 + 'px',
        left: rect.left + 'px',
        width: rect.width + 'px',
      }
    : {};

  return (
    <Portal>
      <div style={styles} className={style.popover} ref={popoverRef}>
        {children}
      </div>
    </Portal>
  );
};
