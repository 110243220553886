import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import style from './UserProfileInfo.module.scss';

import { UserReport } from 'stores/UserProfile/UserReport';
import RootStore from 'stores/RootStore';
import UserProfileStore from 'stores/UserProfile/UserProfileStore';

import InfoSection from './InfoSection/InfoSection';
import Transactions from 'components/Transactions/Transactions';
import Tabs, { AppTab } from 'components/Tabs/Tabs';
import Spinner from 'components/Spinner/Spinner';

import { Desktop, Mobile } from 'types/layout';

@observer
class UserProfileInfo extends Component<{ report: UserReport }> {
  profile: UserProfileStore = new UserProfileStore(RootStore.user.id);
  mobileTabs: AppTab[] = [
    {
      component: <InfoSection report={this.props.report} />,
      title: 'link.profile',
    },
    {
      component: <Transactions report={this.props.report} />,
      title: 'title.transactions',
    },
  ];

  async componentDidMount() {
    await this.profile.init();
  }

  render() {
    const { report } = this.props;

    return (
      <Provider profile={this.profile}>
        <main className={style.main}>
          {this.profile.loading && <Spinner contain />}

          <Desktop>
            <InfoSection report={report} />

            <section className={style.transactions__section}>
              <h3 className={style.transactions__title}>
                <FormattedMessage id="title.userProfile.transactions" />
              </h3>

              <Transactions className={style.transactions} report={report} />
            </section>
          </Desktop>

          <Mobile>
            <Tabs tabs={this.mobileTabs} />
          </Mobile>
        </main>
      </Provider>
    );
  }
}

export default UserProfileInfo;
