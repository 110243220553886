import classNames from 'classnames';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { observer } from 'mobx-react';

import style from './UserProfileLayout.module.scss';

import { ROUTES } from 'routes/routes';
import { UserReport } from 'stores/UserProfile/UserReport';
import RootStore from 'stores/RootStore';

import OnlineOrder from './OnlineOrder/OnlineOrder';
import UserProfileInfo from './UserProfileInfo/UserProfileInfo';
import UserProfileHeader from './UserProfileHeader/UserProfileHeader';
import UserProfileMenu from './UserProfileMenu/UserProfileMenu';

@observer
class UserProfileLayout extends Component {
  report = new UserReport(RootStore.user.id);

  async componentDidMount() {
    await this.report.fetchReport();
    RootStore.socket.subscribe(this.report.updateBalanceAsync);
  }

  componentWillUnmount() {
    RootStore.socket.unsubscribe(this.report.updateBalanceAsync);
  }

  render() {
    return (
      <div className={classNames(style.layout, style.layout__profile)}>
        <UserProfileHeader report={this.report} />

        <Switch>
          {RootStore.config.feature.sionumSpeedy.enabled && (
            <Route
              path={ROUTES.user.onlineOrder}
              render={() => <OnlineOrder report={this.report} />}
              exact
            />
          )}

          <Route path={ROUTES.user.menu} component={UserProfileMenu} exact />

          <Route
            path={ROUTES.user.profile}
            render={() => <UserProfileInfo report={this.report} />}
            exact
          />

          <Redirect to={ROUTES.user.profile} />
        </Switch>
      </div>
    );
  }
}

export default UserProfileLayout;
