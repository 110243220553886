import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import style from './MenuCalendar.module.scss';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import FiltersModal from 'components/Modals/Filters/FiltersModal';

import { SpecificationType } from 'stores/Menu/types';
import ModalStore from 'stores/ModalStore';
import ProfileMenuStore from 'stores/UserProfile/ProfileMenuStore';
import { SwipeDirection } from 'stores/UserProfile/types';
import { getDateTimeWithLocale } from 'helpers/datetime';

@observer
class MenuCalendar extends Component<{ menu: ProfileMenuStore }> {
  calendarRef = React.createRef<HTMLDivElement>();
  onArrowClick = (value: SwipeDirection) => () => {
    this.props.menu.swipeWeek(value);
    if (this.calendarRef.current) {
      this.calendarRef.current.scrollTo(0, 0);
    }
  };
  onSelectDate = (date: string) => {
    this.props.menu!.activeWeekDate = date;
    this.props.menu.selectDate(date);
  };

  openConfig = async () => {
    this.props.menu.activeSpecCategory.includes(SpecificationType.ALLERGENS);
    await ModalStore.showModal(FiltersModal, {
      menu: this.props.menu,
    });
  };

  render() {
    const { menu } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.head}>
          <div className={style.week}>
            <FormattedMessage id="WEEK_SHORT" />
            &nbsp;
            {menu.weekNumber}
          </div>
          <Button
            styleType="text"
            onClick={this.openConfig}
            className={style.button__config}
          >
            <Icon type="config" className={style.icon__config} />
            <div className={style.quantity__selected} />
          </Button>
        </div>

        <div className={style.datePeriod}>
          <Button styleType="text" onClick={this.onArrowClick('previous')}>
            <Icon type="arrowLeft" />
          </Button>
          <Datepicker
            value={[menu.weekStartDate, menu.weekEndDate]}
            onChange={menu.changeDate}
            selectRange={false}
            showPeriods={false}
            styles={{
              calendar: style.datePicker,
              calendarWrapper: style.calendarWrapper,
            }}
          />
          <Button styleType="text" onClick={this.onArrowClick('next')}>
            <Icon type="arrowRight" />
          </Button>
        </div>
        <div className={style.calendar} ref={this.calendarRef}>
          {menu.period.map(date => (
            <div
              key={date}
              className={classNames(
                style.weekday,
                menu.activeWeekDate === date && style.weekday__current,
              )}
              onClick={() => this.onSelectDate(date)}
            >
              <span className={style.day}>
                {getDateTimeWithLocale(date).weekdayShort}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MenuCalendar;
