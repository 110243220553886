export function groupBy<T extends {}, K extends keyof T>(
  arr: T[],
  propsName: K,
): Record<string, T[]> {
  return arr.reduce(
    (a, v) => ({
      ...a,
      [v[propsName as string]]:
        v[propsName as string] in a ? [...a[v[propsName as string]], v] : [v],
    }),
    {},
  );
}

export function hasDuplicate(data: any[]): boolean {
  return data.some((v, i) => data.slice(i + 1).includes(v));
}

export function includes<T>(arr: T[], value: T): boolean {
  return arr.some(el => el === value);
}
