import { getDisplayName } from '../helpers';
import { addValidation } from '../Validation';

export function isRequired(
  message = 'VM_IS_REQUIRED',
  type: '' | 'price' = '',
) {
  return (target: any, fieldName: string) => {
    const validation = {
      fieldName,
      validateFunction: (obj: any) => {
        const name = getDisplayName(obj, fieldName);
        const value = obj[fieldName];

        const isValid = type === 'price' ? value : value || value === 0;
        return isValid ? undefined : { message, data: { name, value } };
      },
    };

    addValidation(target, fieldName, validation);
  };
}
