import classNames from 'classnames';
import { observable } from 'mobx';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { getFormattedDate } from 'helpers/datetime';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import QRModal from 'components/Modals/QR/QRModal';

import { FormattedMessage } from 'react-intl';

import ModalStore from 'stores/ModalStore';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';
import { getOrderDate, getTimeSlotPeriod } from 'stores/UserProfile/helpers';
import { OrderModel, DeliveryTimeSlot } from 'stores/UserProfile/types';

import style from './Order.module.scss';
import FeedbackModal from '../../../../components/Modals/Feedback/FeedbackModal';
import RootStore from '../../../../stores/RootStore';
import FormattedCurrency from '../../../../components/FormattedCurrency/FormattedCurrency';

interface Props {
  store?: UserProfileOrder;
  order: OrderModel;
  deliveryTimeSlot?: DeliveryTimeSlot;
}

@inject('store')
@observer
class Order extends Component<Props> {
  @observable expanded = false;

  onQRCodeShow = (order: OrderModel) => async event => {
    event.stopPropagation();
    await this.props.store!.generateQRCode(
      JSON.stringify({ qrCode: order.qrCode, id: order.id }),
    );

    await ModalStore.showModal(QRModal, {
      code: this.props.store!.qrCode,
      info: (
        <div className={style.modal__info}>
          <p>{getOrderDate(order.createdDate)}</p>

          <FormattedMessage
            id="user.order.number"
            values={{
              number: order.queueNumber,
            }}
            tagName="p"
          />

          {this.props.deliveryTimeSlot && (
            <FormattedMessage
              id="user.orders.delivery.period"
              values={{
                period: getTimeSlotPeriod(this.props.deliveryTimeSlot),
              }}
              tagName="p"
            />
          )}

          <FormattedMessage
            id="user.orders.delivery.on"
            values={{
              date: getFormattedDate(order.deliveryDate),
            }}
          />
        </div>
      ),
    });
  };

  toggleOrder = () => {
    this.expanded = !this.expanded;
  };

  addFeedback = async () => {
    await ModalStore.showModal(FeedbackModal, {
      order: this.props.order,
      deliveryTimeSlot: this.props.deliveryTimeSlot,
    });
    await this.props.store?.getOrders();
  };

  render() {
    const { order, deliveryTimeSlot } = this.props;
    return (
      <div className={style.order}>
        <div className={style.order__line}>
          <Button
            styleType="text"
            className={style.btn__arrow}
            onClick={this.toggleOrder}
          >
            <Icon
              type={this.expanded ? 'arrowBottom' : 'arrowRight'}
              className={style.icon}
            />
          </Button>

          <div className={style.order__info} onClick={this.toggleOrder}>
            <div className={style.order__number}>
              <FormattedMessage
                id="user.order.number"
                values={{
                  number: order.queueNumber,
                }}
              />
            </div>

            <div className={style.order__info__line}>
              {getOrderDate(order.createdDate)}
            </div>

            {deliveryTimeSlot && (
              <div className={style.order__info__line}>
                <FormattedMessage
                  id="user.orders.delivery.period"
                  values={{ period: getTimeSlotPeriod(deliveryTimeSlot) }}
                />
              </div>
            )}

            <div className={style.order__info__line}>
              <FormattedMessage
                id="user.orders.delivery.on"
                values={{
                  date: getFormattedDate(order.deliveryDate),
                }}
              />
            </div>

            <div className={style.order__info__line}>
              {order?.deliveryLocation?.title ||
              order?.deliveryInfo?.pointOfDelivery ? (
                <>
                  <FormattedMessage id="user.orders.delivery.label" />
                  &nbsp;
                  {order?.deliveryLocation?.title ||
                    order?.deliveryInfo?.pointOfDelivery}
                </>
              ) : (
                <FormattedMessage id="user.orders.delivery.pickup.label" />
              )}
            </div>

            {}

            {order?.deliveryInfo && (
              <>
                {order?.deliveryInfo?.email && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.email.label" />
                    &nbsp;
                    {order.deliveryInfo.email}
                  </div>
                )}
                {order?.deliveryInfo?.phone && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.phone.label" />
                    &nbsp;
                    {order.deliveryInfo.phone}
                  </div>
                )}
                {order?.deliveryInfo?.comment && (
                  <div className={style.order__info__line}>
                    <FormattedMessage id="user.orders.delivery.comment.label" />
                    &nbsp;
                    {order.deliveryInfo.comment}
                  </div>
                )}
              </>
            )}
            {RootStore.config.feature.feedback?.enabled && (
              <span className={classNames(style.price, style.price__bottom)}>
                <FormattedCurrency value={order.finalPrice || 0} />
              </span>
            )}
          </div>

          {RootStore.config.feature.feedback?.enabled ? (
            <Button styleType="text" onClick={this.addFeedback}>
              <Icon
                type={order.onFeedback ? 'feedbackDone' : 'feedback'}
                className={classNames(style.icon, {
                  [style.icon__active]: order.onFeedback,
                })}
              />
            </Button>
          ) : (
            <span className={style.price}>
              <FormattedCurrency value={order.finalPrice || 0} />
            </span>
          )}

          <Button
            styleType="text"
            onClick={this.onQRCodeShow(order)}
            className={style.btn__qr}
          >
            <Icon type="qr" className={style.icon} />
          </Button>
        </div>

        <ul
          key={order.id}
          className={classNames(style.order__items, {
            [style.order__items__expanded]: this.expanded,
          })}
        >
          {order.items.map(item => (
            <li key={item.tag} className={style.order__item}>
              <span className={style.item__amount}>{item.amount}x</span>
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Order;
