import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import noop from 'helpers/noop';

import {
  BaseFormModel,
  displayName,
  hasMaxLength,
  isRequired,
} from 'stores/BaseForm';
import {
  UserCompany,
  UserGroup,
  UserGroupRequestData,
} from 'stores/Staff/types';

class GroupForm extends BaseFormModel {
  @observable
  @displayName('GROUP_NAME')
  @hasMaxLength(100)
  @isRequired()
  title = '';

  @observable
  companyID: string | undefined;

  @observable companies: UserCompany[] = [];

  @observable loading = false;

  @observable readonly id: string;

  constructor(group?: UserGroup) {
    super();

    if (group) {
      this.title = group.title;
      this.companyID = group.company?.id;
      this.id = group.id as string;
    }
  }

  @computed
  get selectedCompany() {
    return this.companies.find(company => company.id === this.companyID);
  }

  @action.bound
  async fetchCompanies() {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.fetchUserCompanies();

      this.companies = data.content;
    } finally {
      this.loading = false;
    }
  }

  @computed
  get requestData(): UserGroupRequestData {
    return {
      title: this.title,
      companyId: this.companyID,
    };
  }

  @action.bound
  async createStaffGroup(onFulfilled = noop) {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.createStaffGroup(
        this.requestData,
      );

      onFulfilled(data);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateStaffGroup(onFulfilled = noop) {
    try {
      this.loading = true;

      const { data } = await httpFacade.staff.updateStaffGroup(
        this.id,
        this.requestData,
      );

      onFulfilled(data);
    } finally {
      this.loading = false;
    }
  }
}

export default GroupForm;
