import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';
import {
  IArchivePage,
  IArchiveImage,
  IImageContent,
} from '../../stores/Forms/Archive/types';

export const archiveApi = {
  fetchArchiveOrdersPageable(orderDate, params) {
    return http.get<IArchivePage>(api(URLS.archive.searchArchive), {
      replacePathParams: { date: orderDate },
      params,
    });
  },
  searchImages(orderId) {
    return http.get<IArchiveImage[]>(api(URLS.archive.searchImages), {
      replacePathParams: { id: orderId },
    });
  },
  fetchImage(imageId) {
    return http.get<IImageContent>(api(URLS.archive.getImage), {
      replacePathParams: { attachmentId: imageId },
      skipHttpErrorHandler: true,
    });
  },
};
