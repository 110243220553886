import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Table from 'components/Table/Table';

import config from './columnsConfig';

import ClusterAgentsStore from 'stores/Recognition/ClusterAgentsStore';

@observer
class ClusterAgentsPage extends Component {
  store = new ClusterAgentsStore();

  async componentDidMount() {
    await this.store.fetchClusterAgents();
  }

  render() {
    return (
      <Table
        data={[...this.store.taskAgents, ...this.store.inferenceAgents]}
        columns={config()}
        loading={this.store.loading}
      />
    );
  }
}

export default ClusterAgentsPage;
