import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Desktop } from 'types/layout';
import UserProfileOrder from 'stores/UserProfile/UserProfileOrder';

import Basket from './Basket/Basket';
import Order from './Order/Order';

import style from './OnlineOrder.module.scss';

@inject('store')
@observer
class Orders extends Component<{ store?: UserProfileOrder }> {
  render() {
    const { store } = this.props;

    return (
      <section className={style.orders__section}>
        <Desktop>
          <h3 className={style.title}>
            <FormattedMessage id="title.orders" />
          </h3>
        </Desktop>

        <div className={style.orders}>
          {store!.orders.map(order => (
            <Order
              key={order.id}
              order={order}
              deliveryTimeSlot={order.deliveryTimeSlot}
            />
          ))}
        </div>

        <Desktop>{Boolean(store!.orderMenu.length) && <Basket />}</Desktop>
      </section>
    );
  }
}

export default Orders;
