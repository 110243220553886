import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import style from './NotFoundError.module.scss';

import Icon from 'components/Icon/Icon';
import AppLogo from 'components/AppLogo/AppLogo';

import RootStore from 'stores/RootStore';

import { ROUTES } from 'routes/routes';

@observer
class NotFoundError extends Component {
  onLinkClick = event => {
    event.preventDefault();
    RootStore.resetErrors();
  };

  render() {
    return (
      <div className={style.content}>
        <div className={style.logo__wrapper}>
          <AppLogo type="header" styles={{ image: style.logo }} />
        </div>

        <div className={style.content__row}>
          <div className={style.description}>
            <h2 className={style.title}>404</h2>

            <p className={style.explanation}>
              <FormattedMessage id="error.404.explanation" />
            </p>
          </div>

          <Link
            onClick={this.onLinkClick}
            className={style.icon__wrapper}
            to={ROUTES.root}
          >
            <Icon type="service" className={style.icon} />
          </Link>
        </div>
      </div>
    );
  }
}

export default NotFoundError;
