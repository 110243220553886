import React, { ReactElement, ReactNode } from 'react';
import 'rc-tooltip/assets/bootstrap_white.css';
import Tooltip from 'rc-tooltip';
import './tooltip.scss';

interface Props {
  children?: ReactElement;
  className?: string;
  placement?: string;
  trigger?: string;
  content?: ReactNode;
  id?: string;
}

const TooltipComponent: React.FC<Props> = (props: Props) => {
  const {
    placement = 'top',
    trigger,
    content,
    children,
    className,
    ...rest
  } = props;
  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      overlay={content}
      overlayClassName={className}
      destroyTooltipOnHide={true}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
