import React, { Component } from 'react';
import { observer } from 'mobx-react';

import style from './Planner.module.scss';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import MenuPlannerStore, { SwipeDirection } from 'stores/Menu/MenuPlannerStore';

@observer
class PlannerCalendar extends Component<{ menu: MenuPlannerStore }> {
  onArrowClick = (value: SwipeDirection) => () => {
    this.props.menu.swipeWeek(value);
  };

  render() {
    const { menu } = this.props;

    return (
      <div className={style.calendar}>
        <Button styleType="text" onClick={this.onArrowClick('previous')}>
          <Icon type="arrowLeft" />
        </Button>

        <Datepicker
          value={[menu.weekStartDate, menu.weekEndDate]}
          onChange={menu.changeDate}
          selectRange={false}
          showPeriods={false}
        />

        <Button styleType="text" onClick={this.onArrowClick('next')}>
          <Icon type="arrowRight" />
        </Button>
      </div>
    );
  }
}

export default PlannerCalendar;
