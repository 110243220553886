import { observable, action } from 'mobx';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Desktop, Mobile } from 'types/layout';
import { ROUTES } from 'routes/routes';

import ModalStore from 'stores/ModalStore';
import RootStore from 'stores/RootStore';
import { UserReport } from 'stores/UserProfile/UserReport';

import './style.scss';

import AppLogo from 'components/AppLogo/AppLogo';
import Icon from 'components/Icon/Icon';
import HelpModal from 'components/Modals/Help/HelpModal';
import Button from 'components/Button/Button';
import ActionSelect from 'components/ActionSelect/ActionSelect';
import { RefundRequestModal } from 'components/Modals/RefundRequest/RefundRequestModal';

@observer
class UserProfileHeader extends Component<{ report: UserReport }> {
  @observable showMenu = false;
  options: Array<{ action: () => void; title: string }> = [];

  constructor(props) {
    super(props);

    this.options = [
      {
        action: this.onRefundRequest,
        title: 'button.request.refund',
      },
      {
        action: this.onHelpHandler,
        title: 'button.impressum',
      },
      {
        action: RootStore.user.logout,
        title: 'button.logout',
      },
    ];
  }

  @action
  toggleMenu = () => {
    this.showMenu = !this.showMenu;
  };

  onHelpHandler = async () => {
    await ModalStore.showModal(HelpModal);
  };

  onRefundRequest = async () => {
    await ModalStore.showModal(RefundRequestModal);
  };

  render() {
    return (
      <>
        <header className={'userProfileHeader__header'}>
          <Mobile>
            <Button
              styleType="text"
              onClick={this.toggleMenu}
              className={'userProfileHeader__btn_icon'}
            >
              <Icon type={this.showMenu ? 'close' : 'hamburger'} />
            </Button>
          </Mobile>

          <AppLogo
            type="header"
            styles={{
              link: 'userProfileHeader__logo__link',
              image: 'userProfileHeader__logo',
            }}
          />

          <Desktop>
            <div className={'userProfileHeader__links'}>
              <NavLink
                className={'userProfileHeader__link'}
                activeClassName={'userProfileHeader__link_active'}
                to={ROUTES.user.profile}
                exact
              >
                <FormattedMessage id="link.profile" />
              </NavLink>

              <NavLink
                className={'userProfileHeader__link'}
                activeClassName={'userProfileHeader__link_active'}
                to={ROUTES.user.menu}
                exact
              >
                <FormattedMessage id="link.menu" />
              </NavLink>

              {RootStore.config.feature.sionumSpeedy.enabled && (
                <NavLink
                  className={'userProfileHeader__link'}
                  activeClassName={'userProfileHeader__link_active'}
                  to={ROUTES.user.onlineOrder}
                  exact
                >
                  <FormattedMessage id="link.onlineOrder" />
                </NavLink>
              )}
            </div>
          </Desktop>

          {!this.showMenu && (
            <ActionSelect
              styles={{
                buttonStyle: 'userProfileHeader__btn_select',
                listStyle: 'userProfileHeader__actions__list',
              }}
              options={this.options}
              icon="more"
            />
          )}
        </header>

        <div
          className={classNames('userProfileHeader__menu', {
            userProfileHeader__menu_show: this.showMenu,
          })}
        >
          <div className={'userProfileHeader__menu__links'}>
            <NavLink
              className={'userProfileHeader__link'}
              activeClassName={'userProfileHeader__link_active'}
              to={ROUTES.user.profile}
              onClick={this.toggleMenu}
              exact
            >
              <FormattedMessage id="link.profile" />
            </NavLink>

            <NavLink
              className={'userProfileHeader__link'}
              activeClassName={'userProfileHeader__link_active'}
              to={ROUTES.user.menu}
              onClick={this.toggleMenu}
              exact
            >
              <FormattedMessage id="link.menu" />
            </NavLink>

            {RootStore.config.feature.sionumSpeedy.enabled && (
              <NavLink
                className={'userProfileHeader__link'}
                activeClassName={'userProfileHeader__link_active'}
                to={ROUTES.user.onlineOrder}
                onClick={this.toggleMenu}
                exact
              >
                <FormattedMessage id="link.onlineOrder" />
              </NavLink>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default UserProfileHeader;
