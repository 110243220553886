import { AxiosPromise } from 'axios';

import { replaceUrlParams } from './helpers';
import { instance } from './instance';
import { RequestConfig } from './types';

function replaceParams(url: string, config: RequestConfig = {}): string {
  return config ? replaceUrlParams(url, config.replacePathParams) : url;
}

const http = {
  get<T>(url: string, config?: RequestConfig): AxiosPromise<T> {
    return instance.get<T>(
      config && config.skipParamReplace ? url : replaceParams(url, config),
      config,
    );
  },

  put<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.put(replaceParams(url, config), data, config);
  },

  post<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.post(replaceParams(url, config), data, config);
  },

  patch<T, S>(url: string, data: T, config?: RequestConfig): AxiosPromise<S> {
    return instance.patch(replaceParams(url, config), data, config);
  },

  delete<T>(url: string, config?: RequestConfig): AxiosPromise<T> {
    return instance.delete(replaceParams(url, config), config);
  },
};

export default http;
