import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getDateTimeWithLocale } from 'helpers/datetime';
import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { CopyMode } from 'stores/Menu/MenuCopy/types';

import PlannerRow from './PlannerRow';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';

import style from './Planner.module.scss';

@observer
class PlannerGrid extends Component<{ menuPage: MenuPageStore }> {
  plannerRef = React.createRef<HTMLDivElement>();
  @observable plannerHeight = 0;

  @action
  setPlannerHeight = () => {
    this.plannerHeight =
      this.plannerRef.current?.getBoundingClientRect().height ?? 0;
  };

  componentDidUpdate() {
    this.setPlannerHeight();
  }

  componentDidMount() {
    this.setPlannerHeight();
  }

  render() {
    const { menuPage } = this.props;
    const { menuPlanner } = menuPage;

    return (
      <>
        <div className={style.planner} ref={this.plannerRef}>
          <div className={style.caption}>
            <div className={style.th}>
              <div className={style.caption__wrapper}>
                <FormattedMessage id="title.menuLine" />

                {menuPlanner.canAddMenuLine && (
                  <Button
                    styleType="text"
                    className={style.planner__btn}
                    onClick={menuPlanner.addLine}
                    disabled={
                      !menuPlanner.availableLines.length ||
                      menuPlanner.plannerLines.some(
                        item => !Boolean(item.id),
                      ) ||
                      menuPage.isCopyMode
                    }
                  >
                    <Icon type="plus" />
                  </Button>
                )}
              </div>
            </div>

            {menuPlanner.period.map(date => {
              const dateTime = getDateTimeWithLocale(date);

              return (
                <div key={date} className={classNames(style.th, style.weekday)}>
                  <span>{dateTime.day}</span>
                  <span>{dateTime.weekdayShort}</span>

                  {menuPage?.isCopyMode &&
                    menuPage?.copyMenu?.copyMode === CopyMode.DAY && (
                      <div
                        className={classNames(
                          style.selectionMask,
                          style.selectionMaskDay,
                          {
                            [style.active]:
                              menuPage.copyMenu.from.toISODate() === date,
                          },
                        )}
                        style={{
                          height: this.plannerHeight - 8 + 'px',
                        }}
                        onClick={menuPage.copyMenu.setDateFrom(dateTime)}
                      />
                    )}
                </div>
              );
            })}
            <div className={classNames(style.th, style.th__hidden)} />
          </div>

          {menuPlanner.plannerLines.map((line, index) => (
            <PlannerRow line={line} key={line.id || index} />
          ))}
        </div>
      </>
    );
  }
}

export default PlannerGrid;
