import Modal from 'rc-dialog';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ModalProps } from 'stores/ModalStore';

import style from './ModelInfoModal.module.scss';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';
import ModelsStore from '../../../stores/Recognition/ModelsStore';
import { observer } from 'mobx-react';
import SwitchButton from '../../Form/Fields/SwitchButton/SwitchButton';
import ModalStore from '../../../stores/ModalStore';
import NewDeploymentModal from '../NewDeployment/NewDeploymentModal';
import { observable } from 'mobx';
export enum ModelInfoModalState {
  DEFAULT = 'DEFAULT',
  DEPLOYMENTS = 'DEPLOYMENTS',
  META_INFO = 'META_INFO',
}

interface Props extends ModalProps {
  store: ModelsStore;
}

@observer
class ModelInfoModal extends Component<Props> {
  @observable modalState: string = ModelInfoModalState.DEFAULT;

  onChangeModalState = mode => (this.modalState = mode);

  onEnableDeployment = (id, isChecked) =>
    this.props.store.enableDeployment(id, isChecked);

  onDelete = servingTagId => () =>
    this.props.store.deleteDeployment(servingTagId);

  onNewDeployment = async () => {
    this.props.onClose();
    await ModalStore.showModal(NewDeploymentModal, {
      model: this.props.store.showedModel,
    });
  };

  render() {
    const { store, ...props } = this.props;
    const { showedModel: model } = store;
    const { metaInfo, description } = model;

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        {this.modalState === ModelInfoModalState.DEFAULT && (
          <>
            <div className={style.header}>
              <FormattedMessage
                id="title.model.name"
                values={{ modelName: model.architecture.name }}
                tagName={'h2'}
              />
            </div>
            <div className={style.body}>
              <div className={style.content}>
                <div className={style.row}>
                  <FormattedMessage id="title.id" values={{ id: model.id }} />
                </div>
                <div className={style.row}>
                  <FormattedMessage
                    id="title.version"
                    values={{ version: model.modelVersion }}
                  />
                </div>
                <div className={style.column}>
                  <span className={style.description__label}>
                    <FormattedMessage id="title.description" />
                  </span>
                  <span className={style.description}>{description}</span>
                </div>
                <div className={classNames(style.row, style.row__button)}>
                  <span className={style.label}>
                    <FormattedMessage id="title.deployments" />
                  </span>

                  <div className={style.btn__arrow__block}>
                    <span className={style.count}>
                      {model.deployments?.data?.length ?? 0}
                    </span>
                    <Button
                      styleType="text"
                      onClick={() =>
                        this.onChangeModalState(ModelInfoModalState.DEPLOYMENTS)
                      }
                      className={style.btn__arrow}
                    >
                      <Icon type={'arrowRight'} />
                    </Button>
                  </div>
                </div>

                <div className={classNames(style.row, style.row__button)}>
                  <span className={style.label}>
                    <FormattedMessage id="title.metaInfo" />
                  </span>
                  <div className={style.btn__arrow__block}>
                    <Button
                      styleType="text"
                      onClick={() =>
                        this.onChangeModalState(ModelInfoModalState.META_INFO)
                      }
                      className={style.btn__arrow}
                    >
                      <Icon type={'arrowRight'} />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.modalState === ModelInfoModalState.DEPLOYMENTS && (
          <>
            <div className={classNames(style.header, style.header__arrow)}>
              <Button
                styleType="text"
                onClick={() =>
                  this.onChangeModalState(ModelInfoModalState.DEFAULT)
                }
                className={style.btn__arrow}
              >
                <Icon type={'arrowLeft'} />
              </Button>
              <FormattedMessage id="title.deployments" tagName={'h2'} />
            </div>
            <div className={classNames(style.body__development)}>
              <div className={classNames(style.content)}>
                {model?.deployments?.data?.map(deployment => (
                  <div className={style.deployment} key={deployment.id}>
                    <div className={style.deployment__info}>
                      <div className={style.deployment__text}>
                        <div
                          className={classNames(style.deployment__indicator, {
                            [style.deployment__indicator_active]:
                              deployment.enabled,
                          })}
                        />
                        <div className={style.deployment__title}>
                          {deployment.servingTag}
                        </div>
                      </div>

                      <div className={style.deployment__controls}>
                        <SwitchButton
                          onChange={(isChecked, e) =>
                            this.onEnableDeployment(deployment.id, isChecked)
                          }
                          checked={deployment.enabled}
                          className={style.btn__switcher}
                          isShowTitle
                        />
                        <Button
                          styleType="text"
                          className={style.btn__trash}
                          onClick={this.onDelete(deployment.id)}
                        >
                          <Icon type="trash" />
                        </Button>
                      </div>
                    </div>
                    <div className={style.deployment__description}>
                      {deployment.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={style.deployment__footer}>
              <Button
                styleType={'ghost'}
                className={style.btn_newDeployment}
                onClick={this.onNewDeployment}
              >
                <FormattedMessage id="button.add.new.deployment" />
              </Button>
            </div>
          </>
        )}

        {this.modalState === ModelInfoModalState.META_INFO && (
          <>
            <div className={classNames(style.header, style.header__arrow)}>
              <Button
                styleType="text"
                onClick={() =>
                  this.onChangeModalState(ModelInfoModalState.DEFAULT)
                }
                className={style.btn__arrow}
              >
                <Icon type={'arrowLeft'} />
              </Button>
              <FormattedMessage id="title.metaInfo" tagName={'h2'} />
            </div>
            <div className={style.body}>
              <div
                className={classNames(style.content, style.content_scrolled)}
              >
                {Object.keys(metaInfo)?.map?.((title, index) => (
                  <div key={index} className={style.metaInfo}>
                    <span>{`${title}: `}</span>
                    <span>{JSON.stringify(metaInfo?.[title])}</span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default ModelInfoModal;
