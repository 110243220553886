import { DiscountPeriod } from '../../../stores/Subsidies/types';

export const getTitleWithPeriod = (period: string) => {
  let title = '';
  switch (period) {
    case DiscountPeriod.DAILY:
      title = 'modal.discount.period.daily';
      break;
    case DiscountPeriod.WEEKLY:
      title = 'modal.discount.period.weekly';
      break;
    case DiscountPeriod.MONTHLY:
      title = 'modal.discount.period.monthly';
      break;
    default:
      title = 'modal.discounts.period.daily';
  }
  return title;
};
