import React from 'react';

import style from './Menu.module.scss';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

const DayItem = ({ item }) => (
  <li key={item.id} className={style.day__item}>
    <p>{item.title}</p>
    <p>
      <FormattedCurrency value={item.price} />
    </p>
  </li>
);

export default DayItem;
