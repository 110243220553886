import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';
import classNames from 'classnames';

import style from './RefundRequestModal.module.scss';

import { RefundRequestStore } from 'stores/Modals/RefundRequestStore';
import { ModalProps } from 'stores/ModalStore';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import Spinner from 'components/Spinner/Spinner';

@observer
class RefundRequestModal extends Component<ModalProps> {
  store = new RefundRequestStore();

  render() {
    return (
      <Modal
        {...this.props}
        className={style.modal}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        visible
        closable
      >
        {this.store.loading && <Spinner contain />}

        <h2 className={style.title}>
          <FormattedMessage id="modal.request.refund.title" />
        </h2>

        <div className={style.body}>
          {!this.store.refunded ? (
            <>
              <div className={style.content}>
                <div className={style.icon__wrapper}>
                  <Icon type="refund" />
                </div>

                <div className={style.info}>
                  <FormattedMessage id="modal.request.refund.info" />
                </div>
                <div className={style.info}>
                  <FormattedMessage id="modal.request.refund.description" />
                </div>
              </div>

              <div className={style.controls}>
                <Button
                  styleType="ghost"
                  className={style.btn}
                  onClick={this.props.onClose}
                >
                  <FormattedMessage id="button.cancel" />
                </Button>

                <Button
                  styleType="ghost"
                  className={style.btn}
                  onClick={this.store.requestRefund}
                >
                  <FormattedMessage id="button.request.refund" />
                </Button>
              </div>
            </>
          ) : (
            this.successMessage
          )}
        </div>
      </Modal>
    );
  }

  @computed
  get successMessage() {
    return (
      <>
        <div className={style.content}>
          <div className={style.icon__success}>
            <Icon type="success" />
          </div>

          <div className={style.info}>
            <FormattedMessage id="modal.request.refund.success.info" />
          </div>
          <div className={style.description}>
            <FormattedMessage id="modal.request.refund.success.description" />
          </div>
        </div>

        <Button
          styleType="ghost"
          className={classNames(style.btn, style.btn__ok)}
          onClick={this.props.onClose}
        >
          <FormattedMessage id="button.ok" />
        </Button>
      </>
    );
  }
}

export { RefundRequestModal };
