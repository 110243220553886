import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import './style.scss';
import { ModalProps } from '../../../stores/ModalStore';
import Icon from '../../Icon/Icon';
import FormattedCurrency from '../../FormattedCurrency/FormattedCurrency';
import AmountCounter from '../../AmountCounter/AmountCounter';
import { MenuItem } from '../../../stores/UserProfile/types';
import { FormattedMessage } from 'react-intl';

interface Props extends ModalProps {
  menuItem: MenuItem;
  editable?: boolean;
  className?: string;
}

@observer
class MenuCardrModal extends Component<Props> {
  render() {
    const { menuItem, editable = true, className } = this.props;
    return (
      <Modal
        {...this.props}
        className={'MenuCardModal__modal'}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <div className={`${className} item`} key={menuItem.id}>
          {menuItem?.image ? (
            <img
              src={menuItem.image}
              alt=""
              className={'MenuCardModal__item__image'}
            />
          ) : (
            <div
              className={
                'MenuCardModal__item__image MenuCardModal__item__image_empty'
              }
            >
              <Icon
                type="imageEmpty"
                className={'MenuCardModal__icon__imageEmpty'}
              />
            </div>
          )}

          <div className={'MenuCardModal__content'}>
            <div className={'MenuCardModal__row'}>
              <div className={'MenuCardModal__item__price'}>
                <FormattedCurrency value={menuItem.price} />
              </div>
              {editable && (
                <AmountCounter
                  item={menuItem}
                  className={'MenuCardModal__item__amountCounter'}
                />
              )}
            </div>
            {!!menuItem?.labels?.length && (
              <div className={'MenuCardModal__item__additional'}>
                {menuItem.labels.map((tag, index) => (
                  <React.Fragment key={tag.id}>
                    <>
                      {`${tag.title}${index !== menuItem.labels!.length - 1 &&
                        ', '}`}
                      &nbsp;
                    </>
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className={'MenuCardModal__item__title'}>{menuItem.title}</div>

            {menuItem?.description && (
              <div className={'MenuCardModal__item__description'}>
                {menuItem.description}
              </div>
            )}
            {!!menuItem?.allergens?.length && (
              <>
                <div className={'MenuCardModal__item__text'}>
                  <FormattedMessage id={'title.allergens'} />
                </div>
                <ul className={'MenuCardModal__item__allergens'}>
                  {menuItem?.allergens?.map?.(it => (
                    <li key={it.id}>
                      <img
                        className={'MenuCardModal__allergen__icon'}
                        src={it.icon}
                        alt="allergen"
                      />
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default MenuCardrModal;
