import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';
import { computed } from 'mobx';

import style from './FeedbackModal.module.scss';

import { DialogActionType, ModalProps } from 'stores/ModalStore';
import Button from '../../Button/Button';
import ModalStore from '../../../stores/ModalStore';
import TextArea from '../../Form/Fields/TextArea/TextAreaField';
import { bindFormControl } from '../../Form/FormControl/FormControl';
import FeedbackForm from '../../../stores/Forms/FeedbackForm';
import Icon from '../../Icon/Icon';
import classNames from 'classnames';
import { DeliveryTimeSlot, OrderModel } from 'stores/UserProfile/types';
import OrderInfo from './OrderInfo';

interface Props extends ModalProps {
  order: OrderModel;
  deliveryTimeSlot?: DeliveryTimeSlot;
}

@observer
class FeedbackModal extends Component<Props> {
  form = new FeedbackForm(this.props.order.id);
  formControl = bindFormControl(this.form);

  onSave = async e => {
    e.preventDefault();
    const onFulfilled = payload => this.props.onSubmit!(payload);

    await this.form.submit(onFulfilled);
  };

  render() {
    const FormControl = this.formControl;
    const { onClose, ...props } = this.props;

    return (
      <Modal
        {...props}
        className={classNames(style.modal, {
          [style.modal__success]: this.form.confirm,
        })}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        onClose={() => ModalStore.closeModal(DialogActionType.close)}
        visible
      >
        {!this.form.confirm ? (
          <>
            <h2 className={style.title}>
              <FormattedMessage id={'title.feedback'} />
            </h2>
            <form name="feedback-form" className={style.body}>
              <div className={style.subtitle}>
                <FormattedMessage id="title.feedback.subtitle" />
              </div>
              <OrderInfo
                order={this.props.order}
                deliveryTimeSlot={this.props.deliveryTimeSlot}
              />
              <div className={style.field__feedback}>
                <FormControl
                  className={style.form__control}
                  name="feedback"
                  render={propsField => (
                    <TextArea
                      {...propsField}
                      rows={6}
                      className={style.textarea}
                    />
                  )}
                />
              </div>
            </form>

            <div className={style.controls}>
              <Button className={style.btn} styleType="ghost" onClick={onClose}>
                <FormattedMessage id="button.cancel" />
              </Button>

              <Button
                className={style.btn}
                onClick={this.onSave}
                disabled={!this.form.feedback}
              >
                <FormattedMessage id={'button.send'} />
              </Button>
            </div>
          </>
        ) : (
          this.successBlock
        )}
      </Modal>
    );
  }

  @computed
  get successBlock() {
    return (
      <div className={style.success__block}>
        <Icon type="success" />
        <div className={style.title__success}>
          <FormattedMessage id="title.feedback.successful" />
        </div>
        <div className={style.success__description}>
          <FormattedMessage id="title.feedback.successful.description1" />
        </div>
        <div className={style.success__description}>
          <FormattedMessage id="title.feedback.successful.description2" />
        </div>
        <Button
          className={style.btn}
          styleType="ghost"
          onClick={this.props.onClose}
        >
          <FormattedMessage id="button.ok" />
        </Button>
      </div>
    );
  }
}

export default FeedbackModal;
