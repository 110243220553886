import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import style from './Menu.module.scss';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import { getDateTimeWithLocale } from 'helpers/datetime';

@inject('menu')
@observer
class Weekdays extends Component<{
  fullMode;
  switchMode;
  menu?: MenuPlannerStore;
}> {
  selectDay = date => () => {
    this.props.menu!.activeWeekDate = date;
    this.props.switchMode();
  };

  render() {
    const { menu, fullMode, switchMode } = this.props;

    return (
      <div
        className={classNames(style.weekdays, {
          [style.weekdays__full]: fullMode,
        })}
      >
        {fullMode ? (
          <ul className={style.weekdays__list}>
            {menu!.availableWeekDays.map(day => (
              <li
                className={style.weekday__long}
                key={day}
                onClick={this.selectDay(day)}
              >
                {getDateTimeWithLocale(day).weekdayLong}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <Button onClick={menu!.previousWeekDay} styleType="text">
              <Icon type="arrowLeft" />
            </Button>

            <Button
              styleType="text"
              className={style.weekday}
              onClick={switchMode}
            >
              {getDateTimeWithLocale(menu!.activeWeekDate).weekdayLong}
            </Button>

            <Button onClick={menu!.nextWeekDay} styleType="text">
              <Icon type="arrowRight" />
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default Weekdays;
