import React from 'react';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';
import Controls from 'components/Table/Controls';

import style from './StaffGroups.module.scss';

export const config = ({ onEdit, onDelete }): Column[] => [
  {
    Header: withArrow('title.groupName'),
    accessor: 'title',
    resizable: true,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    Header: withArrow('title.enterprise'),
    accessor: 'company.title',
    resizable: true,
    Cell: row => <span className={style.cell__text}>{row.value}</span>,
  },
  {
    id: 'operations',
    resizable: false,
    width: 84,
    sortable: false,
    className: 'operations',
    Cell: row => (
      <Controls
        onEdit={onEdit(row.original)}
        onDelete={onDelete(row.original.id)}
      />
    ),
  },
];
