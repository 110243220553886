import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import style from './FloatMenu.module.scss';

import Button from 'components/Button/Button';
import Icon, { icons } from 'components/Icon/Icon';
import Options from './Options';

interface Props {
  options: React.ReactElement[];
  icon?: keyof typeof icons;
}

@observer
class FloatMenu extends Component<Props> {
  @observable open = false;

  toggleMenu = () => {
    this.open = !this.open;
  };

  render() {
    const { options, icon = 'dots' } = this.props;

    return (
      <div
        className={style.controls}
        onMouseEnter={this.toggleMenu}
        onMouseLeave={this.toggleMenu}
        onClick={this.toggleMenu}
      >
        <Button styleType="text">
          <Icon type={icon} className={style.icon} />
        </Button>

        {this.open ? <Options options={options} /> : null}
      </div>
    );
  }
}

export default FloatMenu;
