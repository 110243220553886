import { observable, action, computed } from 'mobx';
import { groupBy } from 'helpers/array';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

import {
  AllergenType,
  Specification,
  SpecificationType,
} from 'stores/Menu/types';

import { ITag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { UpdateDishPreferencesDto } from './types';

export interface SpecificationItem extends ITag {
  category: SpecificationType;
}

interface SpecificationAggregated extends Specification {
  values: SpecificationItem[];
}

class UserDishPreferences {
  userId: string;
  @observable selectedAllergens: AllergenType[] = [];
  @observable selectedSpecifications: SpecificationItem[] = [];

  @observable allergens: AllergenType[] = [];
  @observable specifications: SpecificationAggregated[] = [];

  @observable loading = false;

  @computed
  get groupedSpecs(): Record<SpecificationType, SpecificationItem[]> {
    return groupBy(this.selectedSpecifications, 'category');
  }

  constructor(id: string) {
    this.userId = id;
  }

  @action.bound
  async init() {
    const [allergens, specifications, dishPreferences] = await Promise.all([
      httpFacade.menuItems.fetchAllergens(),
      httpFacade.menu.fetchBaseSpecifications(),
      httpFacade.profile.fetchDishPreferences(this.userId),
    ]);

    this.allergens = allergens.data;
    this.specifications = specifications.data.map(group => ({
      ...group,
      values: group.values.map(val => ({
        ...val,
        category: group.name || '',
      })),
    }));

    this.selectedSpecifications = dishPreferences.data.specifications;
    this.selectedAllergens = dishPreferences.data.allergens.map(
      id => this.allergens.find(item => item.id === id) as AllergenType,
    );
  }

  @action.bound
  async saveAllergens(allergens: AllergenType[]) {
    try {
      this.loading = true;
      const reqData: UpdateDishPreferencesDto = {
        allergens: allergens.map(allergen => allergen.id),
        specifications: this.selectedSpecifications.map(spec => spec.id),
      };

      await httpFacade.profile.updateDishPreferences(reqData, this.userId);

      this.selectedAllergens = allergens;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async removeAllergen(allergenId?: string) {
    try {
      this.loading = true;
      const filteredAllergens = allergenId
        ? this.selectedAllergens.filter(it => it.id !== allergenId)
        : [];
      const reqData: UpdateDishPreferencesDto = {
        allergens: filteredAllergens?.map?.(it => it.id),
        specifications: this.selectedSpecifications?.map?.(spec => spec.id),
      };

      await httpFacade.profile.updateDishPreferences(reqData, this.userId);

      this.selectedAllergens = filteredAllergens;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async updateSpecifications(
    updatedCategory: SpecificationType,
    specs: SpecificationItem[],
  ) {
    try {
      this.loading = true;
      const newSpecs = [
        ...this.selectedSpecifications.filter(
          it => it.category !== updatedCategory,
        ),
        ...specs,
      ];

      const reqData: UpdateDishPreferencesDto = {
        allergens: this.selectedAllergens.map(allergen => allergen.id),
        specifications: newSpecs.map(spec => spec.id),
      };

      await httpFacade.profile.updateDishPreferences(reqData, this.userId);

      this.selectedSpecifications = newSpecs;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async removeSpecification(specificationId?: string) {
    try {
      this.loading = true;
      const filteredSpecifications = specificationId
        ? this.selectedSpecifications.filter(it => it.id !== specificationId)
        : [];
      const reqData: UpdateDishPreferencesDto = {
        allergens: this.selectedAllergens?.map?.(allergen => allergen.id),
        specifications: filteredSpecifications?.map?.(spec => spec.id),
      };

      await httpFacade.profile.updateDishPreferences(reqData, this.userId);

      this.selectedSpecifications = filteredSpecifications;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async resetSpecifications(specifications?: SpecificationItem[]) {
    try {
      this.loading = true;
      const filteredSpecifications = this.selectedSpecifications?.filter?.(
        it =>
          !specifications?.some?.(specification => it.id === specification.id),
      );
      const reqData: UpdateDishPreferencesDto = {
        allergens: this.selectedAllergens?.map?.(allergen => allergen.id),
        specifications: filteredSpecifications?.map?.(spec => spec.id),
      };

      await httpFacade.profile.updateDishPreferences(reqData, this.userId);

      this.selectedSpecifications = filteredSpecifications;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export default UserDishPreferences;
