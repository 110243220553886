import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'rc-dialog';
import classNames from 'classnames';

import style from './DatasetCopyModal.module.scss';

import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';

import { ModalProps } from 'stores/ModalStore';
import { IDataset } from 'stores/Recognition/types';
import DatasetCopyStore from 'stores/Modals/DatasetCopyStore';

import { getFormattedDate } from 'helpers/datetime';

interface Props extends ModalProps {
  datasetId: string;
  datasets: IDataset[];
}

@observer
class DatasetCopyModal extends Component<Props> {
  store = new DatasetCopyStore(this.props.datasetId, this.props.datasets);

  setDestination = id => () => {
    this.store.targetDatasetId = id;
  };

  onSubmit = async event => {
    event.preventDefault();

    await this.store.copy();
    this.props.onSubmit!(this.props.datasetId);
  };

  render() {
    const { datasets } = this.store;

    return (
      <Modal
        {...this.props}
        animation="zoom"
        maskAnimation="fade"
        destroyOnClose={true}
        className={style.modal}
        visible
      >
        {this.store.loading && <Spinner fullscreen />}

        <form name="dataset-copy-form" onSubmit={this.onSubmit}>
          <h3 className={style.title}>
            <FormattedMessage id="modal.copy.title" />
          </h3>

          <div className={style.description}>
            <FormattedMessage id="modal.copy.description" />
          </div>

          <ul className={style.datasets}>
            {datasets.map(dataset => (
              <li key={dataset.id}>
                {
                  <Button
                    styleType="text"
                    className={classNames(style.btn__dataset, {
                      [style.btn__active]:
                        this.store.targetDatasetId === dataset.id,
                    })}
                    onClick={this.setDestination(dataset.id)}
                  >
                    <span>{dataset.name}</span>
                    <span>{getFormattedDate(dataset.createdDate)}</span>
                  </Button>
                }
              </li>
            ))}
          </ul>

          <div className={style.btn__wrapper}>
            <Button
              styleType="ghost"
              onClick={this.props.onClose}
              className={style.btn}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              type="submit"
              styleType="primary"
              className={style.btn}
              disabled={!Boolean(this.store.targetDatasetId)}
            >
              <FormattedMessage id="button.start.dataset.copy" />
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default DatasetCopyModal;
