import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import style from './WarningModal.module.scss';

import { ModalProps } from 'stores/ModalStore';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

interface Props extends ModalProps {
  title?: string;
  description?: React.ReactElement | string;
  cancelBtnText?: string;
  submitBtnText?: string;
  className?: string;
  isShowConrols?: boolean;
  type?: 'change' | 'warning' | 'confirm';
  useCancel?: boolean;
  onCancel?: () => void;
  useAutofocus?: boolean;
  closeable?: boolean;
}

function getBtnType(type) {
  switch (type) {
    case 'change':
      return 'ghost';
    case 'confirm':
      return 'primary';
    default:
      return 'warning';
  }
}

@observer
class WarningModal extends Component<Props> {
  onSubmit = event => {
    event.preventDefault();

    this.props.onSubmit?.();
  };

  render() {
    const {
      title,
      description,
      cancelBtnText = 'NO',
      submitBtnText = 'YES',
      className,
      isShowConrols = true,
      type = 'warning',
      useCancel = false,
      useAutofocus = true,
      closeable,
      ...props
    } = this.props;

    return (
      <Modal
        {...props}
        className={className || style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
        closable={closeable}
      >
        <form onSubmit={this.onSubmit}>
          {title && (
            <h3 className={style.title}>
              <Icon type="important" className={style.icon} />
              <FormattedMessage id={title} />
            </h3>
          )}

          {description && (
            <p className={style.description}>
              {typeof description === 'string' ? (
                <FormattedMessage id={description} />
              ) : (
                description
              )}
            </p>
          )}

          {isShowConrols && (
            <div>
              <Button
                styleType="ghost"
                className={style.btn}
                onClick={useCancel ? this.props.onCancel : this.props.onClose}
              >
                <FormattedMessage id={cancelBtnText} />
              </Button>

              <Button
                type="submit"
                styleType={getBtnType(type)}
                className={style.btn}
                autoFocus={useAutofocus ? true : false}
              >
                <FormattedMessage id={submitBtnText} />
              </Button>
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

export default WarningModal;
