import React from 'react';
import { FormattedNumber, FormattedNumberParts } from 'react-intl';

import RootStore from 'stores/RootStore';

interface Props {
  value: number;
  withSymbol?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  withWrap?: boolean;
}

const style = 'currency';

const FormattedCurrency = ({
  value,
  withSymbol = true,
  withWrap = true,
}: Props) => {
  const currency = RootStore.localization.locale === 'de' ? 'EUR' : 'USD';
  const floatValue = value / 100;

  return (
    <>
      {withSymbol ? (
        withWrap ? (
          <FormattedNumber
            value={floatValue}
            // eslint-disable-next-line
            style={style}
            currency={currency}
          />
        ) : (
          <FormattedNumber
            value={floatValue}
            // eslint-disable-next-line
            style={style}
            currency={currency}
            children={formattedMessage => <>{formattedMessage}</>}
          />
        )
      ) : (
        <FormattedNumberParts
          value={floatValue}
          // eslint-disable-next-line
          style={style}
          currency={currency}
        >
          {parts => (
            <>
              {parts.slice(0, -1).reduce((acc, part) => acc + part.value, '')}
            </>
          )}
        </FormattedNumberParts>
      )}
    </>
  );
};

export default FormattedCurrency;
