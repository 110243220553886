import Log from './log';

import { SortConfig } from './types';

export function getValueByAccessor(obj: any, accessor: string) {
  const path = accessor.split('.');

  return path.reduce((value, property) => value[property], obj);
}

export function sortByAccessor(
  config: SortConfig | undefined,
  caseSensitive = false,
) {
  return (a, b) => {
    if (!config) {
      return 0;
    }

    try {
      let valueA =
        typeof config.accessor === 'function'
          ? config.accessor(a)
          : typeof config.accessor === 'number'
          ? config.accessor
          : getValueByAccessor(a, config.accessor);
      let valueB =
        typeof config.accessor === 'function'
          ? config.accessor(b)
          : typeof config.accessor === 'number'
          ? config.accessor
          : getValueByAccessor(b, config.accessor);

      if (!caseSensitive && typeof valueA === 'string') {
        valueA = valueA?.toLowerCase();
        valueB = valueB?.toLowerCase();
      }

      return (
        (valueA < valueB ? -1 : valueA > valueB ? 1 : 0) *
        (config.desc ? -1 : 1)
      );
    } catch (error) {
      Log.info(error);
    }

    return 0;
  };
}
