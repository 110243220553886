import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './TaskForm.module.scss';

import { ROUTES } from 'routes/routes';
import AppRouter from 'stores/AppRouter';

import Button from 'components/Button/Button';

interface Props {
  disabled?: boolean;
}

const Controls: FunctionComponent<Props> = ({ disabled }) => {
  const onCancel = () => {
    AppRouter.push(ROUTES.admin.tasks);
  };

  return (
    <div className={style.controls}>
      <Button onClick={onCancel} styleType="ghost" className={style.btn}>
        <FormattedMessage id="button.cancel" />
      </Button>

      <Button type="submit" className={style.btn} disabled={disabled}>
        <FormattedMessage id="button.create" />
      </Button>
    </div>
  );
};

export default Controls;
