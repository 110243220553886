import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './DatasetSamples.module.scss';

import DatasetSamplesStore from 'stores/Modals/DatasetSamplesStore';

import Button from 'components/Button/Button';

@inject('store')
@observer
class SubTags extends Component<{ store?: DatasetSamplesStore }> {
  onTagClick = value => () => {
    const { store } = this.props;

    store?.ol.setFeatureSubTag(store?.ol.activeSubTag === value ? null : value);
  };

  render() {
    const { ol } = this.props.store!;

    return (
      <div className={style.subtags}>
        <h3 className={style.subtag__title}>
          <FormattedMessage id="modal.recognition.subtag.title" />
        </h3>

        <ul className={style.subtags__list}>
          {DatasetSamplesStore.SUB_TAGS.map(value => (
            <Button
              key={value}
              styleType="text"
              className={classNames(style.subtag, {
                [style.subtag__active]: ol.activeSubTag === value,
              })}
              onClick={this.onTagClick(value)}
            >
              {value}
            </Button>
          ))}
        </ul>
      </div>
    );
  }
}

export default SubTags;
