import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/Icon/Icon';
import { Popover } from 'components/MultiSelect/Popover/Popover';
import style from './PopoverSelect.module.scss';

interface IProps {
  value: string;
  children: ReactNode;
}

export const PopoverSelect = ({ value, children }: IProps) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const refElement = React.useRef<HTMLDivElement | null>(null);
  const togglePopover = () => setIsOpened(!isOpened);

  return (
    <div ref={refElement}>
      <div
        className={style.field}
        onClick={togglePopover}
        aria-expanded={isOpened}
        tabIndex={0}
      >
        {value ? (
          <span className={style.value}>{value}</span>
        ) : (
          <span className={style.placeholder}>
            <FormattedMessage id="select.placeholder" />
          </span>
        )}

        <Icon
          type={isOpened ? 'arrowTop' : 'arrowBottom'}
          className={style.icon}
        />
      </div>

      {isOpened && (
        <Popover refElement={refElement} onClose={togglePopover}>
          {children}
        </Popover>
      )}
    </div>
  );
};
