import React from 'react';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import { FormattedMessage } from 'react-intl';

import './HelpModal.scss';

import Spinner from 'components/Spinner/Spinner';

import { ModalProps } from 'stores/ModalStore';
import RootStore from 'stores/RootStore';
import HelpModalStore from 'stores/Modals/HelpModalStore';

@observer
class HelpModal extends React.Component<ModalProps> {
  store = new HelpModalStore();

  async componentDidMount() {
    await this.store.fetchCatering();
  }

  render() {
    const { dsvgo: DSVGO, rules } = RootStore.config;
    const { catering, loading } = this.store;
    const info =
      (catering?.cateringProviderInfo || '') +
      (catering?.techProviderInfo || '');

    return (
      <Modal
        {...this.props}
        className={'help__modal'}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        {loading && <Spinner contain />}

        <div className="help__content">
          <h2 className="help__title">
            <FormattedMessage id="modal.help.title" />
          </h2>

          {info && (
            <>
              <div
                className="help__info"
                dangerouslySetInnerHTML={{ __html: info }}
              />

              <div className="help__links_wrapper">
                <a
                  href={DSVGO}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="help__link"
                >
                  DSGVO
                </a>

                <a
                  href={rules}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="help__link"
                >
                  <FormattedMessage id="modal.help.rules" />
                </a>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  }
}

export default HelpModal;
