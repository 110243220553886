import { action, observable } from 'mobx';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import RootStore from 'stores/RootStore';

class RefundRequestStore {
  @observable loading = false;
  @observable refunded = false;

  @action.bound
  async requestRefund() {
    try {
      this.loading = true;

      await httpFacade.profile.requestRefund(RootStore.user.id);

      this.refunded = true;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  }
}

export { RefundRequestStore };
