import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import { getDate, getLocalDateTime } from 'helpers/datetime';
import Log from 'helpers/log';

import { BaseFormModel, displayName, isRequired } from 'stores/BaseForm';
import { ArchiveReqParams, IArchive, IArchiveImage, MealTypes } from './types';
import RootStore from '../../RootStore';
import { DEFAULT_PAGE } from '../../constants';

const mealTypes = [
  { value: 'All', label: 'archive.meal.all' },
  { value: 'BREAKFAST', label: 'archive.meal.breakfast' },
  { value: 'SUPPER', label: 'archive.meal.supper' },
  { value: 'DINNER', label: 'archive.meal.dinner' },
];

const DEFAULT_ARCHIVE_ORDERS_PAGE_SIZE = 50;

class ArchiveSearchForm extends BaseFormModel {
  @observable
  @displayName('ORDER_DATE')
  @isRequired('')
  date: DateTime = getLocalDateTime();

  @observable
  @displayName('ROOM')
  room = '';

  @observable
  @displayName('STATION')
  station: '';

  @observable
  @displayName('PATIENT_ID')
  patientId: '';

  @observable
  @displayName('MEAL_TYPE')
  mealType;

  @observable mealTypes: MealTypes[] = mealTypes.map(type => {
    type.label = RootStore.localization.formatMessage(type.label);
    return type;
  });

  @observable archive: IArchive[] = [];
  @observable pageCount: number;
  @observable size = DEFAULT_ARCHIVE_ORDERS_PAGE_SIZE;
  @observable page = DEFAULT_PAGE;
  @observable totalArchiveOrders: number;

  @observable images: IArchiveImage[] = [];

  @observable isEmptyArchiveSearchResult: boolean = false;

  @observable loading = false;

  @computed
  get orders() {
    return this.archive;
  }

  requestParams(selectedPage: number): ArchiveReqParams {
    return {
      orderType: this.mealType === 'All' ? '' : this.mealType,
      patientId: this.patientId,
      station: this.station,
      room: this.room,
      page: selectedPage !== undefined ? selectedPage : this.page,
      size: this.size,
    };
  }

  @action.bound
  async onSearch() {
    this.loading = true;
    await this.fetchArchiveOrdersPageable(0);
    this.loading = false;
  }

  @action.bound
  async changePage(selectedPage: number) {
    await this.fetchArchiveOrdersPageable(selectedPage);
  }

  @action.bound
  async fetchArchiveOrdersPageable(selectedPage: number) {
    this.loading = true;
    try {
      const { data } = await httpFacade.archive.fetchArchiveOrdersPageable(
        getDate(this.date.toJSDate()),
        this.requestParams(selectedPage),
      );

      this.archive = data.content;
      this.pageCount = data.totalPages;
      this.page = data.number;
      this.size = data.size;
      this.totalArchiveOrders = data.totalElements;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action
  onReset() {
    this.date = getLocalDateTime();
    this.mealType = this.mealTypes[0].value;
    this.patientId = '';
    this.station = '';
    this.room = '';
    this.isEmptyArchiveSearchResult = false;
  }

  @action.bound
  async getImages(orderId: string) {
    try {
      const { data } = await httpFacade.archive.searchImages(orderId);
      this.images = data;
    } catch (error) {
      Log.error(error);
    }
  }
}

export default ArchiveSearchForm;
