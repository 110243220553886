import { observable, action, computed } from 'mobx';

import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

import { BaseFormModel, isRequired, displayName } from 'stores/BaseForm';
import {
  TaskType,
  CreateTaskReqParams,
  IDataset,
  TaskViewDto,
  TaskState,
} from 'stores/Recognition/types';
import AppRouter from 'stores/AppRouter';
import { ITag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { sortByAccessor } from 'helpers/accessors';

export const OptionsLimits = {
  numEpochStageMin: 5,
  numEpochStageMax: 50,
};

interface TaskParams {
  datasetIds: string[];
  previousTrainTaskId: string;
  numEpochStage1: number;
  numEpochStage2: number;
}

class TaskExtraClassifierForm extends BaseFormModel {
  type: TaskType.TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4;

  @observable
  @displayName('DESCRIPTION*')
  @isRequired()
  description: string;

  @observable
  selectedDatasets: ITag[] = [];

  @observable
  @displayName('*TASK')
  @isRequired()
  previousTrainTaskId: string;

  @observable
  @isRequired()
  numEpochStage1 = 5;

  @observable
  @isRequired()
  numEpochStage2 = 5;

  @observable loading = false;

  @observable datasets: IDataset[] = [];
  @observable tasks: TaskViewDto[] = [];

  @computed
  get datasetsOptions() {
    return this.datasets.map(it => ({
      value: it.id,
      label: it.name,
    }));
  }

  @computed
  get tasksOptions() {
    return this.tasks
      .filter(
        task =>
          (task.state === TaskState.DEPLOYED ||
            task.state === TaskState.FINISHED) &&
          (task.type === TaskType.TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4 ||
            task.type === TaskType.TRAIN_CLASSIFIER_TF_EFFICIENT_NET_B4),
      )
      .map(it => ({
        value: it.id,
        label: (it.description ? it.description + ' - ' : '') + it.type,
      }));
  }

  @computed
  get datasetIds() {
    return this.selectedDatasets.map(dataset => dataset.id);
  }

  @computed
  get datasetTags(): ITag[] {
    return this.datasets
      .map(dataset => ({
        id: dataset.id,
        title: dataset.name,
        date: dataset.createdDate,
      }))
      .sort(sortByAccessor({ desc: true, accessor: 'date' }));
  }

  constructor(type: TaskType.TRAIN_EXTRA_CLASSIFIER_TF_EFFICIENT_NET_B4) {
    super();

    this.type = type;
  }

  @computed
  get requestParams(): CreateTaskReqParams<TaskParams> {
    return {
      type: this.type,
      description: this.description,
      params: {
        datasetIds: this.datasetIds,
        previousTrainTaskId: this.previousTrainTaskId,
        numEpochStage1: Number(this.numEpochStage1),
        numEpochStage2: Number(this.numEpochStage2),
      },
    };
  }

  @action
  async init() {
    try {
      this.loading = true;

      const [datasets, tasks] = await Promise.all([
        httpFacade.recognition.fetchDatasets(),
        httpFacade.recognition.fetchTasks(),
      ]);

      this.datasets = datasets.data;
      this.tasks = tasks.data;
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async onCreate() {
    try {
      this.loading = true;

      await httpFacade.recognition.createTask(this.requestParams);

      AppRouter.goBack();
    } catch (error) {
      Log.info(error);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  setDatasets(tags: ITag[]) {
    this.selectedDatasets = tags;
  }

  @action.bound
  removeDataset(id?: string) {
    this.selectedDatasets = id
      ? this.selectedDatasets.filter(it => it.id !== id)
      : [];
  }
}

export default TaskExtraClassifierForm;
