import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { IMenuCategory, MenuItem } from 'stores/Menu/types';

import SearchField from 'components/Form/Fields/Search/SearchField';
import Category from 'components/MenuItemsSearch/Category/Category';
import Spinner from 'components/Spinner/Spinner';

import style from './MenuItemsSearch.module.scss';

interface IProps {
  onSearch: (value: string) => void;
  searchValue: string;
  categories: IMenuCategory[];
  isLoading?: boolean;
  toggleItem: (item: MenuItem) => void;
  selectedItemsIds: string[];
  className?: string;
}

@observer
class MenuItemsSearch extends Component<IProps> {
  render() {
    const {
      onSearch,
      searchValue,
      categories,
      isLoading,
      toggleItem,
      selectedItemsIds,
      className,
    } = this.props;

    return (
      <div className={classNames(style.wrapper, className)}>
        {isLoading && <Spinner contain />}
        <div className={style.searchField}>
          <SearchField onChange={onSearch} value={searchValue} />
        </div>
        <ul className={style.menuList}>
          {categories?.map(category => (
            <Category
              key={category.id}
              category={category}
              toggleItem={toggleItem}
              selectedItemsIds={selectedItemsIds}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export { MenuItemsSearch };
